/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./HomePage.css";
import Layout from "../../components/Layout";
import { Box, Input, Button, Avatar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "../../assets/HomepageAssests/filter-icon.svg";
import AgentIcon from "../../assets/HomepageAssests/agent-icon.svg";
import axiosInstance from "../../components/axiosInstance";
import { useSelector } from "react-redux";
import AgentDetailsModal from "../../components/HomePage/AgentDetailsModal/AgentDetailsModal";
import { useNavigate } from "react-router-dom";
import { selectIsCreator } from "../../redux/organizationSlice";
import { useFlags } from "launchdarkly-react-client-sdk";
const HomePage = () => {
  const { selectedOrgId = "" } = useSelector(
    (state) => state?.organization || {},
  );
  const isCreator = useSelector(selectIsCreator);
  const [myAgentsData, setMyAgentsData] = useState([]);
  const [trendingAgentsData, setTrendingAgentsData] = useState([]);
  const [recentChatsData, setRecentChatsData] = useState([]);
  const [favouriteAgentsData, setFavouriteAgentsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [agentId, setAgentId] = useState("");
  const navigate = useNavigate();
  const { marketplaceFlag } = useFlags();

  const handleOpen = (agentId) => {
    if (!agentId) {
      return;
    }
    setAgentId(agentId);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // Clear the agent ID when closing the modal to prevent stale data
    setAgentId("");
  };

  const handleChangeSearch = (e) => {
    // console.log(e.target.value);
  };

  useEffect(() => {
    if (selectedOrgId) {
      const fetchMyAgentsData = async () => {
        try {
          const response = await axiosInstance.get(
            `/v1/usersV2/chat-metrics/my-agents`,
          );
          setMyAgentsData(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchMyAgentsData();
    }
  }, [selectedOrgId]);

  useEffect(() => {
    const getFavouriteAgents = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/usersV2/chat-metrics/favorites`,
        );
        setFavouriteAgentsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getFavouriteAgents();
  }, [selectedOrgId]);

  useEffect(() => {
    const getRecentChats = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/usersV2/chat-metrics/recent`,
        );
        setRecentChatsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRecentChats();
  }, [selectedOrgId]);

  useEffect(() => {
    const getTrendingAgents = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/usersV2/trending?organization_id=${selectedOrgId}&limit=10`,
        );
        setTrendingAgentsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTrendingAgents();
  }, [selectedOrgId]);

  return (
    <Layout title="Pragya | HomePage">
      <Box className="h-[calc(100vh-90px)] overflow-auto relative bg-[#070707] pt-4 flex flex-col gap-4">
        <Box className="agents-container">
          <Box className="flex justify-between items-center">
            <Box className="flex flex-col gap-2">
              <div className="my-dashboard-title">My Dashboard</div>
            </Box>
            <Box className="flex items-center gap-4 mr-2">
              {marketplaceFlag && (
                <>
                  <Input
                    sx={{
                      textDecoration: "none",
                      border: "0.2px solid var(--Border, #CDCDCD)",
                      borderRadius: "8px",
                      "&:before, &:after": {
                        borderBottom: "none !important",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "none !important",
                      },
                      padding: "8px 10px",
                      "& .MuiInputBase-input": {
                        padding: "3px 5px 3px 5px",
                      },
                      background: "var(--Filler, #1C1C1C)",
                      width: "380px",
                      height: "40px",
                      color: "#CDCDCD",
                    }}
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={handleChangeSearch}
                    startAdornment={<SearchIcon className="search-icon-css" />}
                  />
                  <Box className="filter-icon-container">
                    <img src={FilterIcon} alt="filter" />
                  </Box>
                </>
              )}
              {isCreator && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    borderRadius: "8px",
                    padding: "8px 10px",
                    width: "180px",
                    height: "40px",
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  disabled={!isCreator}
                  onClick={() => navigate("/creator/create-agent")}
                >
                  <AddIcon sx={{ fontSize: "20px" }} />
                  <span className="text-[14px] font-medium">Create Agent</span>
                </Button>
              )}
            </Box>
          </Box>
          <Box className="flex flex-col gap-4">
            <div className="favourite-agent-title">My Agents</div>
            <Box className="flex flex-wrap gap-4">
              {myAgentsData?.length > 0 ? (
                myAgentsData.map((agent, index) => (
                  <Box
                    key={index}
                    className="single-agent-container"
                    onClick={() => handleOpen(agent?.id)}
                  >
                    <Box className="flex items-center w-full gap-3 border-b-[0.5px] border-[#CDCDCD] pb-2">
                      <img src={AgentIcon} alt="agent" />
                      <div className="agent-name">{agent?.agent_name}</div>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="created-by-title">Created by</div>
                      <Box className="flex items-center gap-2">
                        <Avatar className="!w-[15px] !h-[15px]" />
                        <div className="agent-created-by-name">
                          {agent?.creator_name}
                        </div>
                      </Box>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="agent-description-title">
                        Descriptions
                      </div>
                      <div className="agent-description max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {agent?.agent_description}
                      </div>
                    </Box>
                  </Box>
                ))
              ) : (
                <div className="text-gray-400">No agents found</div>
              )}
            </Box>
          </Box>
          <Box className="flex flex-col gap-4">
            <div className="favourite-agent-title">Favourite Agent</div>
            <Box className="flex flex-wrap gap-4">
              {favouriteAgentsData?.length > 0 ? (
                favouriteAgentsData.map((agent, index) => (
                  <Box
                    key={index}
                    className="single-agent-container"
                    onClick={() => handleOpen(agent?.id)}
                  >
                    <Box className="flex items-center w-full gap-3 border-b-[0.5px] border-[#CDCDCD] pb-2">
                      <img src={AgentIcon} alt="agent" />
                      <div className="agent-name">{agent?.agent_name}</div>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="created-by-title">Created by</div>
                      <Box className="flex items-center gap-2">
                        <Avatar className="!w-[15px] !h-[15px]" />
                        <div className="agent-created-by-name">
                          {agent?.creator_name}
                        </div>
                      </Box>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="agent-description-title">
                        Descriptions
                      </div>
                      <div className="agent-description max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {agent?.agent_description}
                      </div>
                    </Box>
                  </Box>
                ))
              ) : (
                <div className="text-gray-400">No favourite agents found</div>
              )}
            </Box>
          </Box>
          <Box className="flex flex-col gap-4">
            <Box className="flex justify-between items-center">
              <div className="favourite-agent-title">Trending Agent</div>
              {marketplaceFlag && (
                <div
                  className="see-more-title"
                  onClick={() => navigate("/market-place")}
                >
                  See more
                </div>
              )}
            </Box>
            <Box className="flex flex-wrap gap-4 w-full">
              {trendingAgentsData?.length > 0 ? (
                trendingAgentsData.map((agent, index) => (
                  <Box
                    key={index}
                    className="single-agent-container w-[15%]"
                    onClick={() => handleOpen(agent?.id)}
                  >
                    <Box className="flex items-center w-full gap-3 border-b-[0.5px] border-[#CDCDCD] pb-2">
                      <img src={AgentIcon} alt="agent" />
                      <div className="agent-name">{agent?.agent_name}</div>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="created-by-title">Created by</div>
                      <Box className="flex items-center gap-2">
                        <Avatar className="!w-[15px] !h-[15px]" />
                        <div className="agent-created-by-name">
                          {agent?.creator_name}
                        </div>
                      </Box>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="agent-description-title">
                        Descriptions
                      </div>
                      <div className="agent-description max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {agent?.agent_description}
                      </div>
                    </Box>
                  </Box>
                ))
              ) : (
                <div className="text-gray-400">No trending agents found</div>
              )}
            </Box>
          </Box>
          <Box className="flex flex-col gap-4">
            <div className="favourite-agent-title">Recent Chats</div>
            <Box className="flex flex-wrap gap-4">
              {recentChatsData?.length > 0 ? (
                recentChatsData.map((chat, index) => (
                  <Box
                    key={index}
                    className="single-agent-container"
                    onClick={() => handleOpen(chat?.id)}
                  >
                    <Box className="flex items-center w-full gap-3 border-b-[0.5px] border-[#CDCDCD] pb-2">
                      <img src={AgentIcon} alt="agent" />
                      <div className="agent-name">{chat?.agent_name}</div>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="created-by-title">Created by</div>
                      <Box className="flex items-center gap-2">
                        <Avatar className="!w-[15px] !h-[15px]" />
                        <div className="agent-created-by-name">
                          {chat?.creator_name}
                        </div>
                      </Box>
                    </Box>
                    <Box className="flex flex-col gap-2">
                      <div className="agent-description-title">
                        Descriptions
                      </div>
                      <div className="agent-description max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {chat?.agent_description}
                      </div>
                    </Box>
                  </Box>
                ))
              ) : (
                <div className="text-gray-400">No recent chats found</div>
              )}
            </Box>
          </Box>
          <AgentDetailsModal
            open={open}
            onClose={handleClose}
            agentId={agentId}
            selectedOrgId={selectedOrgId}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default HomePage;
