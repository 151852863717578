import React from "react";
import "./MarketPlace.css";
import Layout from "../../components/Layout";
import { Box } from "@mui/material";

const MarketPlace = () => {
  return (
    <Layout title="Pragya | MarketPlace">
      <Box className="h-[calc(100vh-90px)] overflow-auto relative bg-[#070707]">
        <h1 className="text-white">MarketPlace</h1>
      </Box>
    </Layout>
  );
};

export default MarketPlace;
