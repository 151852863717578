/* eslint-disable no-unused-vars */
import React from "react";
import "./AgentChatHistoryModal.css";
import { Modal, Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../../assets/AgentChatAssets/edit-icon.svg";
import DeleteIcon from "../../../assets/AgentChatAssets/delete-icon.svg";
import NewChatIcon from "../../../assets/AgentChatAssets/new-chat-button-icon.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  height: "80vh",
  bgcolor: "#1C1C1C",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  outline: "none",
  border: "1px solid #CDCDCD",
  display: "flex",
  flexDirection: "column",
};

const getCategory = (updatedAt) => {
  const today = new Date();
  const yesterday = new Date(today);
  const last7Days = new Date(today);
  const last30Days = new Date(today);

  yesterday.setDate(today.getDate() - 1);
  last7Days.setDate(today.getDate() - 7);
  last30Days.setDate(today.getDate() - 30);

  const date = new Date(updatedAt);

  if (date.toDateString() === today.toDateString()) return "Today";
  if (date.toDateString() === yesterday.toDateString()) return "Yesterday";
  if (date > last7Days) return "Last 7 Days";
  if (date > last30Days) return "Last 30 Days";
  return "Older";
};

const categorizedConversations = (conversations) => {
  // First sort all conversations by updated_at in descending order (most recent first)
  const sortedConversations = [...conversations].sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
  );

  // Then categorize the sorted conversations
  const categorized = sortedConversations.reduce((acc, conversation) => {
    const category = getCategory(conversation.updated_at);
    if (!acc[category]) acc[category] = [];
    acc[category].push(conversation);
    return acc;
  }, {});

  // Define the preferred order of categories
  const categoryOrder = [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Older",
  ];

  // Create a new object with ordered categories
  const orderedCategories = {};
  categoryOrder.forEach((category) => {
    if (categorized[category]) {
      orderedCategories[category] = categorized[category];
    }
  });

  return orderedCategories;
};

const AgentChatHistoryModal = ({
  open,
  onClose,
  conversations,
  selectedConversationId,
  handleConversationSelect,
  handleDeleteConversation,
  handleCreateConversation,
  handleRenameConversation,
  editingConversationId,
  editedTitle = "",
  setEditedTitle,
  handleBlurOrSubmit,
}) => {
  const categories = categorizedConversations(conversations);

  const handleLocalBlurOrSubmit = (conversationId) => {
    const currentConversation = conversations.find(
      (conv) => conv.id === conversationId,
    );
    const titleToSubmit = editedTitle ?? currentConversation?.title ?? "";
    handleBlurOrSubmit(conversationId, titleToSubmit);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style} className="agent-chat-history-modal-container">
        <Box className="flex flex-col gap-[20px] h-full">
          <Box className="flex justify-between items-center h-[10%]">
            <Typography variant="h6" className="agent-details-modal-title">
              Chat History
            </Typography>
            <CloseIcon
              className="text-white cursor-pointer"
              onClick={onClose}
            />
          </Box>
          <Box className="chat-history-container h-[90%]">
            <button
              className="start-new-chat-button"
              onClick={() => {
                handleCreateConversation();
                onClose();
              }}
            >
              <img src={NewChatIcon} alt="New Chat Icon" />
              <span className="text-white">Start a New Chat</span>
            </button>
            {Object.keys(categories).map((category) => (
              <Box key={category} className="space-y-3 w-full">
                <h2 className="text-gray-400 font-bold mt-4 mb-2">
                  {category}
                </h2>
                {categories[category].map((conversation) => (
                  <Box
                    key={conversation.id}
                    className={`bg-[#252526] p-3 rounded-lg flex gap-2 items-center justify-between w-full text-white transition-all hover:bg-[#2C2C2C] group ${selectedConversationId === conversation.id ? "border-2 border-white" : ""}`}
                  >
                    {editingConversationId === conversation.id ? (
                      <input
                        type="text"
                        value={editedTitle || ""}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        onBlur={() => handleLocalBlurOrSubmit(conversation.id)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleLocalBlurOrSubmit(conversation.id);
                          }
                        }}
                        autoFocus
                        className="flex-1 bg-[#252526] text-white px-2 py-1"
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        className="w-full cursor-pointer"
                        onClick={() => {
                          handleConversationSelect(conversation);
                          onClose();
                        }}
                      >
                        {conversation.title}
                      </Typography>
                    )}

                    <Box className="flex items-center gap-4 opacity-0 group-hover:opacity-100 transition-opacity">
                      <Box
                        className="edit-icon hover:scale-110 transition-transform cursor-pointer"
                        onClick={() => {
                          handleRenameConversation(conversation.id);
                        }}
                      >
                        <img src={EditIcon} alt="Edit Icon" />
                      </Box>
                      <Box
                        className="delete-icon hover:scale-110 transition-transform cursor-pointer"
                        onClick={() => {
                          handleDeleteConversation(conversation.id);
                        }}
                      >
                        <img src={DeleteIcon} alt="Delete Icon" />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AgentChatHistoryModal;
