import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPasswordBG from "../assets/signupbg.jpg";
import logo from "../assets/pragya.svg"; // Ensure the logo path is correct
import { toast } from "react-toastify";
import { FiMail, FiHome } from "react-icons/fi";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "http://localhost:8000/auth/forgot-password",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        },
      );

      if (response.ok) {
        toast.success(
          "If an account with this email exists, you'll receive a reset link.",
        );
      } else {
        toast.error(
          "There was an issue processing your request. Please try again.",
        );
      }
    } catch (error) {
      toast.error("Network error. Please try again later.");
    }
  };

  return (
    <div
      className="relative flex flex-col md:flex-row items-center justify-between min-h-screen bg-cover bg-center p-4 md:p-0"
      style={{
        backgroundImage: `url(${ForgotPasswordBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Home Button */}
      <button
        onClick={() => navigate("/")}
        className="absolute top-4 left-4 md:top-12 md:left-32 flex items-center space-x-2 p-2 border-2 border-white rounded-lg text-white hover:text-blue-200 hover:border-blue-200 transition z-10"
      >
        <FiHome className="text-xl" />
        <span className="text-sm font-semibold">Home</span>
      </button>

      {/* Left Side Text */}
      <div className="w-full md:w-[492px] md:ml-32 text-white text-2xl md:text-[42px] font-light font-['Inter'] leading-normal md:leading-[54.60px] text-center md:text-left mb-8 md:mb-0 mt-16 md:mt-32 px-4 md:px-0">
        Secure Your Account - Reset Your Password Now!
      </div>

      {/* Forgot Password Box */}
      <div className="bg-white rounded-2xl shadow-xl w-full max-w-[476px] min-h-[400px] mx-4 md:mr-4 p-6 md:p-12">
        {/* Logo */}
        <div className="flex justify-center md:justify-start mb-12 md:mb-28">
          <img src={logo} alt="Prgya.ai" className="h-8" />
        </div>

        {/* Heading */}
        <div className="text-black text-center font-['Inter'] text-xl md:text-[24px] font-medium leading-normal md:leading-[24px] self-stretch mb-4">
          Choose your Email ID to <br /> recover your Password
        </div>

        <div className="text-[#595959] text-center font-['Inter'] text-sm md:text-[16px] font-normal leading-normal md:leading-[16px] self-stretch mb-8">
          You need to confirm your Identity so that we <br /> can recover your
          account.
        </div>

        {/* Form */}
        <form
          className="space-y-6 md:space-y-8 px-2 md:px-6"
          onSubmit={handleSubmit}
        >
          <div className="relative">
            <label
              htmlFor="email"
              className="block text-sm md:text-base text-[#000] ml-2 mb-2 md:mb-4"
            >
              Email
            </label>
            <div className="relative flex items-center">
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                required
                className="w-full p-3 md:p-4 border border-[#D9D9D9] rounded-full bg-white text-black pr-12 md:pr-16"
              />
              <FiMail className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-600 text-xl md:text-2xl" />
            </div>
          </div>

          {/* Send Reset Link Button */}
          <div className="mt-8 md:mt-12">
            <button
              type="submit"
              className="w-full bg-[#1677FF] text-white font-bold py-3 px-3 rounded-full hover:bg-blue-700 transition text-sm md:text-base"
            >
              Send Reset Link
            </button>
          </div>
        </form>

        {/* Back to Sign In */}
        <p className="mt-8 md:mt-12 text-center text-gray-600 text-sm md:text-base">
          <a
            href="/signin"
            className="text-[#1677FF] font-semibold hover:underline"
          >
            Back to Login
          </a>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
