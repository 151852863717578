import React, { useState, useEffect } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import Logo from "../assets/logo.svg";

const Navbar = ({ scrolled }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Check if the current page is the Landing page
  const isLandingPage = location.pathname === "/";

  // Handle navigation and scrolling
  const handleNavigation = (to) => {
    if (!isLandingPage) {
      // Navigate to the Landing page first
      navigate("/");
      // Wait for the Landing page to load, then scroll to the section
      setTimeout(() => {
        const section = document.getElementById(to);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Adjust the delay as needed
    } else {
      // If already on the Landing page, scroll to the section
      const section = document.getElementById(to);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <motion.nav
      variants={{
        visible: { y: 0 },
        hidden: { y: "-100%" },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.35, ease: "easeInOut" }}
      className={`fixed w-full h-[60px] top-0 z-50 transition-all duration-300 ${
        scrolled
          ? "bg-transparent backdrop-blur-sm shadow-lg"
          : "bg-transparent backdrop-blur-none"
      }`}
    >
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 flex items-center justify-between h-full">
        {/* Logo */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img
            src={Logo}
            alt="Smart Knowledge Base"
            className="w-[136px] h-[35px]"
          />
        </div>

        {/* Desktop Navbar */}
        <div className="hidden md:flex items-center space-x-6">
          <button
            onClick={() => navigate("/")}
            className="text-white font-semibold hover:text-[#096DF4] transition duration-300 cursor-pointer"
          >
            Home
          </button>
          <button
            onClick={() => handleNavigation("features")}
            className="text-white font-semibold hover:text-[#096DF4] transition duration-300 cursor-pointer"
          >
            Features
          </button>

          <button
            onClick={() => handleNavigation("usecases")}
            className="text-white font-semibold hover:text-[#096DF4] transition duration-300 cursor-pointer"
          >
            Use Cases
          </button>

          <button
            onClick={() => handleNavigation("pricing")}
            className="text-white font-semibold hover:text-[#096DF4] transition duration-300 cursor-pointer"
          >
            Pricing
          </button>
          <button
            onClick={() => handleNavigation("testimonials")}
            className="text-white font-semibold hover:text-[#096DF4] transition duration-300 cursor-pointer"
          >
            Testimonials
          </button>

          {/* Separator */}
          <span className="text-gray-500 hidden md:inline">|</span>

          {/* "Get Started" Button */}
          <button
            className="flex items-center justify-center w-[113px] h-[36px] text-sm font-semibold text-white rounded-[40px] border-2 border-[#00D0FF] bg-gradient-to-r from-[#0A5EF3] to-[#00D0FF]"
            onClick={() => (window.location.href = "/signin")}
          >
            Get Started
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white text-2xl">
            {isMenuOpen ? <FiX /> : <FiMenu />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-[60px] left-0 w-full bg-[#050D24]/95 backdrop-blur-sm text-white py-4 md:hidden z-40 border-t border-white/10 shadow-xl max-h-[calc(100vh-60px)] overflow-y-auto">
          <div className="container mx-auto px-4 flex flex-col space-y-2">
            <button
              onClick={() => {
                navigate("/");
                setIsMenuOpen(false);
              }}
              className="block py-2 text-base font-semibold cursor-pointer hover:text-[#096DF4] transition-colors duration-300"
            >
              Home
            </button>
            <button
              onClick={() => {
                handleNavigation("features");
                setIsMenuOpen(false);
              }}
              className="block py-2 text-base font-semibold cursor-pointer hover:text-[#096DF4] transition-colors duration-300"
            >
              Features
            </button>

            <button
              onClick={() => {
                handleNavigation("usecases");
                setIsMenuOpen(false);
              }}
              className="block py-2 text-base font-semibold cursor-pointer hover:text-[#096DF4] transition-colors duration-300"
            >
              Use Cases
            </button>

            <button
              onClick={() => {
                handleNavigation("pricing");
                setIsMenuOpen(false);
              }}
              className="block py-2 text-base font-semibold cursor-pointer hover:text-[#096DF4] transition-colors duration-300"
            >
              Pricing
            </button>
            <button
              onClick={() => {
                handleNavigation("testimonials");
                setIsMenuOpen(false);
              }}
              className="block py-2 text-base font-semibold cursor-pointer hover:text-[#096DF4] transition-colors duration-300"
            >
              Testimonials
            </button>

            {/* "Get Started" Button in Mobile Menu */}
            <button
              className="mt-2 w-full h-[40px] flex items-center justify-center text-sm font-semibold text-white rounded-[40px] border-2 border-[#00D0FF] bg-gradient-to-r from-[#0A5EF3] to-[#00D0FF] whitespace-nowrap"
              onClick={() => {
                window.location.href = "/signin";
                setIsMenuOpen(false);
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      )}
    </motion.nav>
  );
};

export default Navbar;
