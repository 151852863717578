import React from "react";

const MessageFileReference = ({ message }) => {
  return (
    <div>
      {message?.content?.includes("File:") && (
        <div className="mt-2 p-2 bg-[#1D1E20] rounded-lg border border-gray-700">
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Reference:</div>
            {message?.content
              .split("\n")
              .filter((line) => line.includes("File:"))
              .map((fileLine, index) => (
                <div
                  key={index}
                  className="text-xs bg-[#2C2C2C] px-2 py-1 rounded-md text-blue-400 hover:text-blue-300 cursor-pointer"
                >
                  {fileLine.split("File:")[1].split(",")[0].trim()}
                </div>
              ))}
          </div>
        </div>
      )}
      {message?.citations?.includes("File:") && (
        <div className="mt-2 p-2 bg-[#1D1E20] rounded-lg border border-gray-700">
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Reference:</div>
            {message?.citations
              .split("\n")
              .filter((line) => line.includes("File:"))
              .map((fileLine, index) => (
                <div
                  key={index}
                  className="text-xs bg-[#2C2C2C] px-2 py-1 rounded-md text-blue-400 hover:text-blue-300 cursor-pointer"
                >
                  {fileLine.split("File:")[1].split(",")[0].trim()}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageFileReference;
