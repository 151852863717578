import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import axiosInstance from "../services/axiosinstance";
import { toast } from "react-toastify";

// Create context
const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [projectsList, setProjectsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState("Select a Project");
  const [loading, setLoading] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes cache

  // Function to fetch projects for the current organization
  const fetchProjectsForCurrentOrg = useCallback(
    async (forceRefresh = false) => {
      // If we have projects and it hasn't been 5 minutes since last fetch, and not forcing refresh, skip
      const currentTime = new Date().getTime();
      if (
        !forceRefresh &&
        projectsList.length > 0 &&
        lastFetchTime &&
        currentTime - lastFetchTime < CACHE_DURATION
      ) {
        console.log("Using cached project data");
        return;
      }

      setLoading(true);
      try {
        const organizationId = localStorage.getItem("selectedOrg");
        const userorgId = localStorage.getItem("userorganization_id");

        if (!organizationId || !userorgId) {
          console.error("Missing organizationId or userorganization_id");
          setProjectsList([]);
          setLoading(false);
          return;
        }

        const response = await axiosInstance.get(
          `/v1/projects/${organizationId}/user/${userorgId}/`,
        );

        setProjectsList(response.data || []);
        setLastFetchTime(currentTime);

        // If we have projects and no active project is selected, select the first one
        if (response.data && response.data.length > 0) {
          const activeProjectId = localStorage.getItem("activeProjectId");
          if (!activeProjectId) {
            localStorage.setItem("activeProjectId", response.data[0].id);
            setSelectedProject(response.data[0].name);
          } else {
            // Find and set the name of the active project
            const activeProject = response.data.find(
              (project) => project.id === activeProjectId,
            );
            if (activeProject) {
              setSelectedProject(activeProject.name);
            } else if (response.data.length > 0) {
              // If active project not found in list, set to first one
              localStorage.setItem("activeProjectId", response.data[0].id);
              setSelectedProject(response.data[0].name);
            }
          }
        } else {
          setSelectedProject("No Projects Available");
          localStorage.removeItem("activeProjectId");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        toast.error("Failed to load projects");
        setProjectsList([]);
      } finally {
        setLoading(false);
      }
    },
    [projectsList.length, lastFetchTime, CACHE_DURATION],
  );

  // Handler for project selection
  const handleProjectSelect = useCallback((project) => {
    setSelectedProject(project.name);
    // Store the selected project ID in localStorage
    localStorage.setItem("activeProjectId", project.id);

    // Dispatch custom event to notify other components about the project change
    const projectChangedEvent = new CustomEvent("projectChanged", {
      detail: { projectId: project.id },
    });
    window.dispatchEvent(projectChangedEvent);
  }, []);

  // Listen for organization changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "selectedOrg" || e.key === "userorganization_id") {
        console.log("Organization changed, refreshing projects");
        fetchProjectsForCurrentOrg(true); // Force refresh when org changes
      }
    };

    // Also listen for the custom organization changed event
    const handleOrgChangeEvent = (e) => {
      if (e && e.detail && e.detail.orgId) {
        console.log("Organization changed event received:", e.detail.orgId);
        fetchProjectsForCurrentOrg(true); // Force refresh when org changes
      }
    };

    // Listen for project updates or deletions
    const handleProjectChangeEvent = () => {
      console.log("Project changed event received");
      fetchProjectsForCurrentOrg(true); // Force refresh when projects change
    };

    // Add event listeners
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("organizationChanged", handleOrgChangeEvent);
    window.addEventListener("projectDeleted", handleProjectChangeEvent);
    window.addEventListener("projectUpdated", handleProjectChangeEvent);

    // Initial fetch
    fetchProjectsForCurrentOrg();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("organizationChanged", handleOrgChangeEvent);
      window.removeEventListener("projectDeleted", handleProjectChangeEvent);
      window.removeEventListener("projectUpdated", handleProjectChangeEvent);
    };
  }, [fetchProjectsForCurrentOrg]);

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      projectsList,
      selectedProject,
      loading,
      fetchProjectsForCurrentOrg,
      handleProjectSelect,
    }),
    [
      projectsList,
      selectedProject,
      loading,
      fetchProjectsForCurrentOrg,
      handleProjectSelect,
    ],
  );

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

// Custom hook to use the project context
export const useProjects = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProjects must be used within a ProjectProvider");
  }
  return context;
};
