import React, { useEffect, useState } from "react";
import { MdOutlineArticle } from "react-icons/md";
import { RiAccountCircleFill } from "react-icons/ri";
import { BsChatLeftDots } from "react-icons/bs";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar, openSidebar } from "../redux/sidebarSlice";
import { getStoredValue } from "../services/logics.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "./axiosInstance.js";
import { MdArrowBackIos } from "react-icons/md";
import { ReactComponent as Plogo } from "../assets/p.svg"; // Import P.svg as a React component
import Logo from "../assets/pragyawhite.png";
import { MdOutlineDriveFileMoveRtl } from "react-icons/md";

import { ReactComponent as KnowledgeBaseIcon } from "../assets/knowledgebaseicon.svg";
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const projectId = getStoredValue("activeProjectId");
  const projectname = getStoredValue("project_name");
  const [projectuserslist, setProjectusersList] = useState([]);
  const [role, setRole] = useState(null);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });

  useEffect(() => {
    dispatch(openSidebar());
  }, [dispatch]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };
  const handleremove = () => {
    localStorage.removeItem("project_role");
    localStorage.removeItem("activeProjectId");
  };
  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/users/me`);
        localStorage.setItem("user_id", response.data.id);
        setUser(response.data);
      } catch (err) {
        toast.error(err.response.data.detail);
      }
    };
    fetchUserData();
  }, []);
  useEffect(() => {
    if (!projectId) return; // Don't fetch if projectId is not available

    const fetchProjectusers = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/projects/${projectId}/users`,
        );
        setProjectusersList(response.data);
        console.log(response.data);
      } catch (error) {
        toast.error("Error fetching project users");
      }
    };

    fetchProjectusers();
  }, [projectId]);

  useEffect(() => {
    if (!user || !projectuserslist.length) return; // Don't proceed if user or projectusersList is not available

    // Find the project user whose email matches the logged-in user's email
    const matchingProjectUser = projectuserslist.find(
      (projectuser) => projectuser.email === user.email,
    );

    if (matchingProjectUser) {
      // Save the role in local storage
      setRole(matchingProjectUser.role);
      localStorage.setItem("project_role", matchingProjectUser.role);
      console.log("User role saved:", matchingProjectUser.role);
    } else {
      console.log("No matching project user found.");
    }
  }, [user, projectuserslist]);
  const isActive = (path) =>
    location.pathname.startsWith(`/projects/${projectId}${path}`);

  return (
    <div
      className={`h-auto bg-[#1D1E20] text-white m-2 border  border-[#8C8C8C] rounded-lg flex flex-col justify-between ${isSidebarOpen ? "w-[276px] " : "w-[80px] "}`}
    >
      <div
        className={`flex items-center justify-between ${isSidebarOpen ? "w-[276px] px-6 py-3" : "w-[80px] p-2"}`}
      >
        {/* If Sidebar is open, show full name. If closed, show short name "P" */}
        {isSidebarOpen ? (
          <h1 className="text-2xl font-bold">
            <img src={Logo} alt="Prgya.ai Logo" className="h-8" />
          </h1>
        ) : (
          <Plogo className="ml-4 h-8" />
        )}

        {/* Button to toggle sidebar */}
        {isSidebarOpen && (
          <button onClick={handleToggleSidebar} className="text-white p-2">
            <FaAngleDoubleLeft size={20} />
          </button>
        )}
      </div>

      {/* Divider */}
      <div className="w-2xl h-px bg-white mt-2"></div>

      {/* Back to Projects or Button to Open Sidebar */}
      {isSidebarOpen ? (
        <Link
          to="/organizations"
          className={`text-white text-sm flex items-center pt-2 w-[276px] px-8`}
          onClick={() => handleremove()}
        >
          <MdArrowBackIos className="text-lg" />
          <span className="ml-1">Back to all projects</span>
        </Link>
      ) : (
        <button
          onClick={handleToggleSidebar}
          className="text-white text-sm flex items-center pt-2 w-[276px] px-6"
        >
          <FaAngleDoubleRight size={20} />
        </button>
      )}

      {/* Project Label */}
      {isSidebarOpen && (
        <h2
          className={`text-lg font-semibold text-[#1677FF] mt-4 ${isSidebarOpen ? "w-[276px] px-12" : "w-[80px] "}`}
        >
          {projectname}
        </h2>
      )}

      {/* Navigation */}
      <ul
        className={`mt-6 space-y-3 flex-1 ${isSidebarOpen ? "w-[276px] px-6" : "w-[80px] p-2"} overflow-y-auto max-h-[calc(100vh-330px)] scrollbar-hide`}
      >
        <li>
          <Link
            to={`/projects/${projectId}/assistants`}
            className={`flex items-center space-x-3 p-3 rounded-lg ${isActive("/assistants") ? "bg-[#EFEAFF] text-[#1677FF] font-semibold" : "hover:bg-gray-700 text-gray-400"}`}
          >
            <BsChatLeftDots
              size={20}
              className={
                isActive("/assistants") ? "text-[#1677FF] ml-1" : "ml-1"
              }
            />
            {isSidebarOpen && <span>Assistants</span>}
          </Link>
        </li>
        <>
          {role !== "MEMBER" && (
            <li>
              <Link
                to={`/projects/${projectId}/files`}
                className={`flex items-center space-x-3 p-3 rounded-lg ${isActive("/files") ? "bg-[#EFEAFF] text-[#1677FF] font-semibold" : "hover:bg-gray-700 text-gray-400"}`}
              >
                <MdOutlineArticle
                  size={20}
                  className={
                    isActive("/files") ? "text-[#1677FF] ml-1" : "ml-1"
                  }
                />
                {isSidebarOpen && <span>Files</span>}
              </Link>
            </li>
          )}
        </>
        <>
          {role !== "MEMBER" && (
            <li>
              <Link
                to={`/projects/${projectId}/knowledgebase`}
                className={`flex items-center space-x-3 p-3 rounded-lg ${
                  isActive("/knowledgebase")
                    ? "bg-[#EFEAFF] text-[#1677FF] font-semibold ml-1"
                    : "hover:bg-gray-700 text-gray-400 ml-1"
                }`}
              >
                <KnowledgeBaseIcon className="w-5 h-5" />{" "}
                {/* Icon inherits text color */}
                {isSidebarOpen && <span>Knowledge Bases</span>}
              </Link>
            </li>
          )}
        </>
        <>
          {role !== "MEMBER" && (
            <li>
              <Link
                to={`/projects/${projectId}/members`}
                className={`flex items-center space-x-3 p-3 rounded-lg ${isActive("/members") ? "bg-[#EFEAFF] text-[#1677FF] font-semibold" : "hover:bg-gray-700 text-gray-400"}`}
              >
                <RiAccountCircleFill
                  size={20}
                  className={
                    isActive("/members") ? "text-[#1677FF] ml-1" : "ml-1"
                  }
                />
                {isSidebarOpen && <span>Project Members</span>}
              </Link>
            </li>
          )}
        </>
      </ul>

      {/* Footer - User Profile */}
      <div className="relative mt-auto p-4border-gray-700">
        <div className="py-4 px-6">
          <button
            className={`flex items-center ${isSidebarOpen ? "space-x-4 w-full py-2 px-4" : "justify-center w-10 h-10"} rounded-lg hover:bg-gray-700`}
            onClick={() => {
              navigate(`/home-page`);
            }}
          >
            <MdOutlineDriveFileMoveRtl
              size={25}
              className={isSidebarOpen ? "" : "mx-auto"}
            />
            {isSidebarOpen && <span>Exit To Creator</span>}
          </button>
        </div>
        <div className="relative mt-auto p-4 border-t border-gray-700">
          <button
            className="flex items-center space-x-1 w-full p-2 rounded-lg hover:bg-gray-700"
            onClick={toggleUserDropdown}
          >
            <div className="w-10 h-8 rounded-full bg-gray-500 flex items-center justify-center text-lg font-medium text-white">
              {user.email.charAt(0).toUpperCase()}
            </div>
            {isSidebarOpen && (
              <span className="text-gray-300 truncate">{user.email}</span>
            )}
          </button>

          {/* Dropdown Menu */}
          {isUserDropdownOpen && (
            <div className="absolute bottom-16 left-4 w-[220px] bg-[#1D1E20] shadow-md rounded-lg border border-gray-700">
              <div className="p-3">
                <p className="text-sm text-gray-300 truncate">{user.email}</p>
              </div>
              <button
                className="w-full text-left p-3 text-sm text-gray-300 hover:bg-gray-700"
                onClick={() => {
                  localStorage.clear();
                  navigate("/signin");
                }}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
