import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../components/axiosInstance";
import knowledgebasecreate from "../../assets/knowledgebasecreate.svg";
import EmptyStateComponent from "../../components/Emptystatecomponent.jsx";
import knowledgebase from "../../assets/knowledgebase.svg";
import { IoEllipsisHorizontal } from "react-icons/io5";
import ThreeDotLoader from "../../components/dotloader.jsx";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { getStoredValue } from "../../services/logics.js";

const KnowledgeBases = () => {
  const [pdfList, setPdfList] = useState([]);
  const [kbDropdown, setkbDropdown] = useState(null);

  const [selectedPdfs, setSelectedPdfs] = useState([]);
  const [knowledgeBases, setKnowledgeBases] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [currentKnowledgeBase, setCurrentKnowledgeBase] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pageNum, setPageNum] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [isFetching, setIsFetching] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [knowledgeBaseName, setKnowledgeBaseName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingKnowledgeBase, setEditingKnowledgeBase] = useState(null);
  const [newKnowledgeBaseName, setNewKnowledgeBaseName] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [kbno, setkbno] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isKnowledgeBasesLoaded, setIsKnowledgeBasesLoaded] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folderPdfs, setFolderPdfs] = useState({});
  const [expandedFolders, setExpandedFolders] = useState([]);

  // Pagination state for Knowledge Base
  const [kbPage, setKbPage] = useState(1);
  const kbLimit = 15; // Changed from state to constant as setKbLimit is never used
  const [kbTotal, setKbTotal] = useState(0);
  const [selectedKnowledgeBaseId, setSelectedKnowledgeBaseId] = useState(null);
  const projectId = getStoredValue("activeProjectId");
  const dropdownRef = useRef(null);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setkbDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Initialize with data
    if (projectId) {
      fetchData();
      fetchKnowledgeBases(kbPage, kbLimit);
      fetchFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]); // Only when project ID changes

  useEffect(() => {
    // Refetch when pagination params change
    if (projectId) {
      fetchKnowledgeBases(kbPage, kbLimit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbPage, projectId]);

  // Clear selected knowledge base if it no longer exists in the current data
  useEffect(() => {
    if (selectedKnowledgeBaseId && knowledgeBases) {
      const exists = knowledgeBases.some(
        (kb) => kb.id === selectedKnowledgeBaseId,
      );
      if (!exists) {
        setSelectedKnowledgeBaseId(null);
      }
    }
  }, [knowledgeBases, selectedKnowledgeBaseId]);

  console.log(projectId);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);
  useEffect(() => {
    fetchKnowledgeBases(kbPage, kbLimit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbPage, kbLimit]);

  useEffect(() => {
    fetchFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchFolders = async (page = 1, limit = 10) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API_URL}/v1/folders/${projectId}`,
        {
          params: { page, limit },
        },
      );
      console.log("API Response:", response.data); // Replace with your API base URL if needed
      setFolders(response.data.folders);
      // setloading(true);
    } catch (error) {
      console.error("Error fetching folders:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  // Fetch ungrouped PDFs

  // Fetch PDFs in a folder
  // const fetchFolderPdfs = async (folderId) => {
  //     try {
  //         const response = await axiosInstance.get(`${API_URL}/v1/folders/${projectId}/documents/${folderId}`);
  //         setFolderPdfs((prev) => ({ ...prev, [folderId]: response.data.documents }));
  //     } catch (error) {
  //         toast.error("Error fetching folder PDFs");
  //     }
  // };\
  const fetchFolderContent = async (folderId) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/folders/${projectId}/documents/${folderId}`,
      );
      setFolderPdfs((prev) => ({
        ...prev,
        [folderId]: {
          files: response.data.documents || [], // Default empty array
          subfolders: response.data.subfolders || [], // Default empty array
        },
      }));
    } catch (error) {
      toast.error("Error fetching folder content");
    }
  };

  // Toggle folder expansion
  // const toggleFolder = (folderId) => {
  //     if (expandedFolders.includes(folderId)) {
  //         setExpandedFolders(expandedFolders.filter((id) => id !== folderId));
  //     } else {
  //         setExpandedFolders([...expandedFolders, folderId]);
  //         if (!folderPdfs[folderId]) {
  //             fetchFolderPdfs(folderId);
  //         }
  //     }
  // };
  const toggleFolder = (folderId) => {
    if (expandedFolders.includes(folderId)) {
      // Collapse the folder by filtering it out
      setExpandedFolders((prev) => prev.filter((id) => id !== folderId));
    } else {
      // Expand the folder
      setExpandedFolders((prev) => [...prev, folderId]);
      console.log(expandedFolders);
      // Fetch content only if not already fetched
      if (!folderPdfs[folderId]) {
        console.log(expandedFolders);
        fetchFolderContent(folderId);
      }
    }
  };

  const fetchData = async () => {
    setIsFetching(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/user/documents/${projectId}?page=${pageNum}&page_size=12`,
        {},
      );

      const data = response.data.documents;

      setPdfList(data);
      setIsFetching(false);
    } catch (error) {
      toast.error(error.response?.data?.detail);
      setIsFetching(false);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const fetchKnowledgeBases = async (page = 1, limit = 10) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/v1/user/knowledge-bases/${projectId}`,
        {
          params: { page: page, limit: limit },
        },
      );

      console.log("API Response:", response.data);
      setKnowledgeBases(response.data?.knowledge_bases || []);
      setKbTotal(response.data?.total || 0);
      setIsKnowledgeBasesLoaded(true);
    } catch (error) {
      toast.error("Error fetching knowledge bases");
    }
    setLoading(false);
  };
  // Handle checkbox change
  const handleCheckboxChange = (pdfId) => {
    setSelectedPdfs((prev) =>
      prev.includes(pdfId)
        ? prev.filter((id) => id !== pdfId)
        : [...prev, pdfId],
    );
  };

  // Create knowledge base
  const handleCreateKnowledgeBase = async () => {
    try {
      if (selectedPdfs.length === 0) {
        toast.error("Please select at least one document.");
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const response = await axiosInstance.post(
        `${API_URL}/v1/user/create-knowledge-base/${projectId}`,
        {
          name: knowledgeBaseName,
          pdf_ids: selectedPdfs,
        },
      );

      // Close the modal first
      setShowCreateModal(false);

      // Refresh the knowledge base list
      await fetchKnowledgeBases(1, kbLimit); // Reset to first page after creating

      // Do not automatically select the newly created knowledge base

      setKbPage(1); // Set page to 1 to see the newly created knowledge base
      setSelectedPdfs([]);
      setKnowledgeBaseName("");
      toast.success("Knowledge base created successfully");
    } catch (error) {
      toast.error("Error creating knowledge base");
    }
  };

  // Show PDFs of a specific knowledge base
  const handleShowKnowledgeBase = (knowledgeBase) => {
    setCurrentKnowledgeBase(knowledgeBase);
    setShowPdfModal(true);
  };

  const handleDeleteKnowledgeBase = async (id) => {
    try {
      await axiosInstance.delete(
        `${API_URL}/v1/user/delete-knowledge-base/${id}`,
      );

      // Clear selection if the deleted knowledge base was selected
      if (selectedKnowledgeBaseId === id) {
        setSelectedKnowledgeBaseId(null);
      }

      // Refresh knowledge bases
      await fetchKnowledgeBases(kbPage, kbLimit);

      // If after deleting, the current page has no items and it's not the first page,
      // go to the previous page
      if (knowledgeBases.length === 1 && kbPage > 1) {
        setKbPage(kbPage - 1);
      }
      toast.success("Knowledge base deleted successfully");
    } catch (error) {
      toast.error("Error deleting knowledge base");
    }
  };
  const handleEditKnowledgeBase = (kb) => {
    setEditingKnowledgeBase(kb);
    setNewKnowledgeBaseName(kb.name);
    setSelectedPdfs(kb.pdfs.map((pdf) => pdf.id));
    setShowEditModal(true);
  };

  // Save Edited Knowledge Base
  const handleSaveEdit = async () => {
    if (!editingKnowledgeBase) return;
    try {
      await axiosInstance.put(
        `${API_URL}/v1/user/update-knowledge-base/${editingKnowledgeBase.id}`,
        {
          name: newKnowledgeBaseName,
          pdf_ids: selectedPdfs,
        },
      );
      // Refresh knowledge bases instead of manually updating
      await fetchKnowledgeBases(kbPage, kbLimit);
      setShowEditModal(false);
      toast.success("Knowledge base updated successfully");
    } catch (error) {
      toast.error("Error updating knowledge base");
    }
  };

  // Handle pagination change
  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > Math.ceil(kbTotal / kbLimit)) return;
    // Clear selection when changing pages
    setSelectedKnowledgeBaseId(null);
    setKbPage(newPage);
  };

  // Select a knowledge base to view details
  const handleSelectKnowledgeBase = (kb) => {
    setSelectedKnowledgeBaseId(
      kb.id === selectedKnowledgeBaseId ? null : kb.id,
    );
  };

  if (loading) {
    // Show a loader while data is being fetched
    return (
      <div className="flex items-center justify-center h-screen">
        <ThreeDotLoader />
      </div>
    );
  }

  const renderFolderHierarchy = (folders) => {
    return folders.map((folder) => (
      <div key={folder.id}>
        {/* Folder Name */}
        <div
          className="cursor-pointer text-white font-inter"
          onClick={() => toggleFolder(folder.id)}
        >
          {folder.name}
        </div>

        {/* Expanded Folder Content */}
        {expandedFolders.includes(folder.id) && (
          <div className="ml-4">
            {/* Render Subfolders */}
            {folderPdfs[folder.id]?.subfolders ? (
              renderFolderHierarchy(folderPdfs[folder.id].subfolders) // Recursive call for subfolders
            ) : (
              <p>Loading subfolders...</p>
            )}

            {/* Render Files */}
            {folderPdfs[folder.id]?.files ? (
              folderPdfs[folder.id].files.map((pdf) => (
                <div key={pdf.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={selectedPdfs.includes(pdf.id)}
                    onChange={() => handleCheckboxChange(pdf.id)}
                  />
                  <label className="text-white font-inter">{pdf.name}</label>
                </div>
              ))
            ) : (
              <p>Loading files...</p>
            )}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="flex flex-col min-h-screen relative pb-24">
      <div className="flex-grow">
        {knowledgeBases?.length === 0 ? (
          <EmptyStateComponent
            icon={knowledgebasecreate}
            title="Add a Knowledge Base"
            description="Click on 'Add Knowledge Base' to create one"
            buttonText="+ Add Knowledge Base"
            onButtonClick={() => setShowCreateModal(true)}
          />
        ) : (
          <div>
            {/* Add Knowledge Base Card in its own row */}
            <div className="mb-6">
              <div
                className="w-[200px] bg-[#2b2b2b] rounded-lg outline outline-[0.50px] outline-offset-[-0.50px] flex flex-col items-center justify-center p-4 cursor-pointer hover:bg-[#323232] transition-colors"
                onClick={() => setShowCreateModal(true)}
              >
                <div className="w-10 h-10 relative mb-2 flex items-center justify-center">
                  <img
                    src={knowledgebasecreate}
                    alt="Create Knowledge Base"
                    className="w-8 h-8 object-contain"
                  />
                </div>
                <div className="text-center text-white text-sm font-normal font-['Inter']">
                  Add Knowledge Base
                </div>
              </div>
            </div>

            {/* Existing Knowledge Bases Grid */}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
              {knowledgeBases?.map((kb) => (
                <div
                  key={kb.id}
                  className={`relative cursor-pointer bg-[#1D1E21] shadow-md rounded-lg p-5 border transition-all hover:border-[#1677FF] ${
                    selectedKnowledgeBaseId === kb.id
                      ? "border-[#1677FF] border-2"
                      : "border-[#FFFFFF4D]"
                  }`}
                  onClick={() => handleSelectKnowledgeBase(kb)}
                >
                  {/* Three-dot menu */}
                  <button
                    className="absolute top-3 right-3 text-white hover:text-gray-300 transition-colors"
                    onClick={(e) => {
                      e.stopPropagation();
                      setkbDropdown(kb.id === kbDropdown ? null : kb.id);
                    }}
                  >
                    <IoEllipsisHorizontal size={20} />
                  </button>

                  {/* Icon & Text */}
                  <div className="flex items-start space-x-3">
                    <img
                      src={knowledgebase}
                      alt="Knowledge Base"
                      className="h-[40px] w-[40px]"
                    />
                    <div className="flex-1 min-w-0">
                      <h5 className="text-white font-medium text-base truncate">
                        {kb.name}
                      </h5>
                      <p className="text-sm text-gray-400 mt-1">
                        {kb.pdfs.length} document
                        {kb.pdfs.length !== 1 ? "s" : ""}
                      </p>
                    </div>
                  </div>

                  {/* Dropdown Menu */}
                  {kbDropdown === kb.id && (
                    <div
                      ref={dropdownRef}
                      className="absolute right-2 top-10 bg-[#27282B] text-white shadow-lg rounded-md w-[100px] text-sm z-10 border border-[#FFFFFF1A]"
                    >
                      <button
                        className="block w-full px-3 py-2 text-left hover:bg-[#1D1E21] transition-colors"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditKnowledgeBase(kb);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="block w-full px-3 py-2 text-left hover:bg-[#1D1E21] text-red-400 transition-colors"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteKnowledgeBase(kb.id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Knowledge Base Details Section */}
            {selectedKnowledgeBaseId && (
              <div className="mt-6 bg-[#1D1E21] rounded-lg p-6 border border-[#FFFFFF4D]">
                <div className="flex justify-between items-center mb-4">
                  <h4 className="text-xl text-white font-medium">
                    {
                      knowledgeBases.find(
                        (kb) => kb.id === selectedKnowledgeBaseId,
                      )?.name
                    }
                  </h4>
                  <button
                    className="text-white hover:text-gray-300 transition-colors"
                    onClick={() => setSelectedKnowledgeBaseId(null)}
                  >
                    <RxCross2 size={20} />
                  </button>
                </div>

                <div className="mt-4">
                  <h5 className="text-white text-base mb-3">Documents</h5>
                  <div className="bg-[#27282B] p-4 rounded-lg max-h-64 overflow-y-auto custom-scrollbar">
                    {knowledgeBases.find(
                      (kb) => kb.id === selectedKnowledgeBaseId,
                    )?.pdfs.length === 0 ? (
                      <p className="text-gray-400">
                        No documents associated with this knowledge base.
                      </p>
                    ) : (
                      knowledgeBases
                        .find((kb) => kb.id === selectedKnowledgeBaseId)
                        ?.pdfs.map((pdf, index) => (
                          <div
                            key={pdf.id}
                            className="text-white py-2 border-b border-[#FFFFFF1A] last:border-b-0"
                          >
                            <p className="flex justify-between items-center">
                              <span className="truncate">
                                {index + 1}. {pdf.name}
                              </span>
                            </p>
                          </div>
                        ))
                    )}
                  </div>
                  <div className="flex mt-4 space-x-3">
                    <button
                      className="bg-[#1677FF] text-white px-4 py-2 rounded hover:bg-[#1668FF] transition-colors"
                      onClick={() =>
                        handleShowKnowledgeBase(
                          knowledgeBases.find(
                            (kb) => kb.id === selectedKnowledgeBaseId,
                          ),
                        )
                      }
                    >
                      View Documents
                    </button>
                    <button
                      className="bg-[#27282B] text-white px-4 py-2 rounded hover:bg-[#323232] transition-colors"
                      onClick={() =>
                        handleEditKnowledgeBase(
                          knowledgeBases.find(
                            (kb) => kb.id === selectedKnowledgeBaseId,
                          ),
                        )
                      }
                    >
                      Edit Knowledge Base
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Modal for Creating Knowledge Base */}
        {showCreateModal && (
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
              <h3 className="text-xl text-white font-semibold mb-4">
                Create Knowledge Base
              </h3>
              {/* Knowledge Base Name Input */}
              <div className="mb-4">
                <label className="block text-white font-medium mb-2">
                  Knowledge Base Name
                </label>
                <input
                  type="text"
                  value={knowledgeBaseName}
                  onChange={(e) => setKnowledgeBaseName(e.target.value)}
                  placeholder="Enter a name"
                  className="w-full px-4 py-2 text-white rounded focus:outline-none bg-[#191919]"
                />
              </div>
              <h4 className="font-medium text-white py-2">Choose Files</h4>{" "}
              <div className="bg-[#191919] p-4 rounded-lg shadow-md max-h-96 overflow-y-auto scrollbar-hide">
                {/* Files Section */}
                <div className="mb-4">
                  <h4 className="font-medium text-white flex items-center">
                    Files
                    <span className="ml-2 text-sm">(Select the checkbox)</span>
                  </h4>
                  <div className="max-h-60 overflow-y-auto pr-2">
                    {" "}
                    {/* Inner scroll for Files if needed */}
                    {pdfList.map((pdf) => (
                      <div key={pdf.id} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          className="mr-3 bg-[#1677FF]"
                          checked={selectedPdfs.includes(pdf.id)}
                          onChange={() => handleCheckboxChange(pdf.id)}
                        />
                        <label className="text-white font-inter">
                          {pdf.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Folders Section */}
                <div className="mb-4">
                  <h4 className="font-medium text-white">Folders</h4>
                  <div className="pr-2">
                    {" "}
                    {/* Prevent horizontal scrollbar */}
                    {renderFolderHierarchy(folders)}
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="flex justify-end mt-6">
                <button
                  className="bg-[#1677FF] text-white px-6 py-2 rounded mr-3"
                  onClick={handleCreateKnowledgeBase}
                  disabled={selectedPdfs.length === 0 || !knowledgeBaseName}
                >
                  Create
                </button>
                <button
                  className="bg-[#D9D9D9] text-black px-6 py-2 rounded border-2 border-current"
                  onClick={() => setShowCreateModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal to Show PDFs in a Knowledge Base */}
        {showPdfModal && currentKnowledgeBase && (
          <div className="fixed inset-0 bg-[#27282B] bg-opacity-30 flex items-center justify-center">
            <div className="bg-[#27282B]  w-full max-w-lg p-6 rounded-lg shadow-lg relative">
              {/* Close Icon at Top Right */}
              <button
                onClick={() => setShowPdfModal(false)}
                className="absolute top-4 right-4 "
              >
                <RxCross2 size={24} className="text-white" />
              </button>

              <h3 className="text-xl font-semibold mb-4 text-white">
                {currentKnowledgeBase.name}
              </h3>

              <div>
                {currentKnowledgeBase.pdfs.map((pdf, index) => (
                  <p className="text-white" key={pdf.id}>
                    {index + 1}. {pdf.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}

        {showEditModal && (
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
              {/* Modal Title */}
              <h3 className="text-xl font-semibold mb-4 text-white">
                Edit Knowledge Base
              </h3>

              {/* Knowledge Base Name Input */}
              <input
                type="text"
                value={newKnowledgeBaseName}
                onChange={(e) => setNewKnowledgeBaseName(e.target.value)}
                placeholder="Enter a new name"
                className="w-full px-4 py-2 text-white rounded focus:outline-none bg-[#191919]"
              />

              {/* Related Files Section */}
              <h4 className="font-medium text-white py-2">Choose Files</h4>
              <div className="bg-[#191919] p-4 rounded-lg shadow-md max-h-96 overflow-y-auto scrollbar-hide">
                {/* Files Section */}
                <div className="mb-4">
                  <h4 className="font-medium text-white">Files</h4>
                  <div className="max-h-60 overflow-y-auto pr-2">
                    {pdfList.map((pdf) => (
                      <div key={pdf.id} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          className="mr-3 accent-[#6E45FE]" // Accent color for checkboxes
                          checked={selectedPdfs.includes(pdf.id)} // Pre-check related files
                          onChange={() => handleCheckboxChange(pdf.id)}
                        />
                        <label className="text-white font-inter">
                          {pdf.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Folders Section */}
                <div className="mb-4">
                  <h4 className="font-medium text-white">Folders</h4>
                  <div className="pr-2">{renderFolderHierarchy(folders)}</div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex justify-end mt-2">
                <button
                  className="bg-[#1677FF] text-white px-6 py-2 rounded mr-2  transition"
                  onClick={handleSaveEdit}
                >
                  Save
                </button>
                <button
                  className="bg-[#D9D9D9] text-black px-4 py-2 rounded  transition"
                  onClick={() => setShowEditModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      {kbTotal > 0 && (
        <div className="fixed bottom-0 left-0 right-0 py-4 bg-[#070707] border-t border-[#FFFFFF1A]">
          <div className="container mx-auto flex justify-end mr-8">
            {kbTotal > kbLimit && (
              <nav className="flex items-center space-x-2">
                {(() => {
                  const totalPages = Math.ceil(kbTotal / kbLimit);
                  let pages = [];

                  for (let i = 1; i <= totalPages; i++) {
                    pages.push(i);
                  }

                  return pages.map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`px-3 py-1 rounded transition-colors ${
                        kbPage === page
                          ? "bg-[#1677FF] text-white"
                          : "bg-[#27282B] text-white hover:bg-[#323232]"
                      }`}
                    >
                      {page}
                    </button>
                  ));
                })()}
              </nav>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowledgeBases;
