import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Tick from "../assets/Tick.svg"; // Using the same tick for all plans

const Pricing = () => {
  const handleMouseLeave = (e) => {
    e.target.classList.remove("animation-complete");
  };

  return (
    <div
      id="pricing"
      className="bg-[#01091C] py-8 md:py-16 flex justify-center"
    >
      <style jsx>{`
        @keyframes borderClip {
          0% {
            clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
          }
          25% {
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          }
          50% {
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
          }
          75% {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
          }
          100% {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }
        }

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(0, 208, 255, 0.4);
          }
          70% {
            box-shadow: 0 0 0 10px rgba(0, 208, 255, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(0, 208, 255, 0);
          }
        }

        @keyframes float {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(-5px);
          }
          100% {
            transform: translateY(0px);
          }
        }

        .pricing-card {
          position: relative;
          transform: translateZ(0);
          will-change: transform, opacity;
          transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        .pricing-card::before,
        .pricing-card::after {
          content: "";
          position: absolute;
          inset: 0;
          border: 1px solid transparent;
          border-radius: 8px;
          background: linear-gradient(180deg, #00d0ff 0%, #7555e0 100%)
            border-box;
          -webkit-mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          opacity: 0;
          transform: translateZ(0);
        }

        .pricing-card::before {
          opacity: 0;
        }

        .pricing-card:hover::before {
          opacity: 1;
          animation: borderClip 1.5s linear forwards;
        }

        .pricing-card.animation-complete::after {
          opacity: 1;
        }

        .pricing-card:hover {
          transform: translateY(-8px) translateZ(0);
          background: #091530;
          box-shadow:
            -3px -3px 4px 0px rgba(0, 208, 255, 0.4) inset,
            0 10px 20px rgba(0, 208, 255, 0.1);
        }

        .pricing-card:not(:hover) {
          border-color: #404040;
          background: linear-gradient(
            180deg,
            rgba(9, 21, 48, 0.95) 0%,
            rgba(9, 21, 48, 1) 100%
          );
          box-shadow: 0 4px 12px rgba(0, 208, 255, 0.05);
          transition: all 0.3s ease;
        }

        .active-pricing-card {
          animation: pulse 2s infinite;
        }

        .pricing-feature {
          transition: transform 0.2s ease;
        }

        .pricing-card:hover .pricing-feature {
          transform: translateX(5px);
        }
      `}</style>
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-12 xl:px-16">
        {/* Section Header */}
        <motion.div
          className="w-full text-left md:text-center mb-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-white text-[42px] leading-[52px] sm:text-5xl md:text-[60px] font-semibold font-['Poppins'] sm:leading-tight md:leading-[80px] tracking-[-1.2px] max-w-[800px] md:mx-auto">
            Pricing
          </h1>
          <p className="text-lg sm:text-xl text-gray-400 mt-4 max-w-[600px] md:mx-auto">
            Centralized billing for all your projects
          </p>
        </motion.div>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 justify-center max-w-7xl mx-auto">
          {/* Starter Plan */}
          <motion.div
            className="pricing-card bg-[#091530] rounded-lg shadow-lg p-6 md:p-8 text-white flex flex-col items-center justify-between min-h-[520px] md:min-h-[480px]"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            onAnimationEnd={(e) => {
              if (e.animationName === "borderClip") {
                e.target.classList.add("animation-complete");
              }
            }}
            onMouseLeave={handleMouseLeave}
          >
            <div className="w-full">
              <motion.h3
                className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                Free
              </motion.h3>
              <motion.p
                className="text-3xl md:text-4xl font-bold mb-2 text-center"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.4, type: "spring" }}
              >
                $0{" "}
                <span className="text-base md:text-lg font-normal">/month</span>
              </motion.p>
              <p className="text-white mb-4 md:mb-6 text-center text-sm md:text-base">
                Perfect for small teams
              </p>
              <div className="space-y-24 ">
                <div>
                  <ul className="space-y-2 md:space-y-3 text-gray-300 text-left text-sm md:text-base">
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.5 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      120 queries monthly
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.6 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      50 MB document limit
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.7 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      Basic support
                    </motion.li>
                  </ul>
                </div>
                <div className="mb-6 ">
                  <h4 className="text-left text-sm font-medium text-gray-300 mb-3 ">
                    Best for:
                  </h4>
                  <p className="text-left text-sm text-gray-400">
                    Individuals and small teams getting started with AI-powered
                    document analysis
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6 md:mt-auto pt-6 md:pt-8 w-full">
              <motion.a
                href="/signin"
                className="block w-full text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded-full shadow-lg transition-transform transform hover:scale-105 text-sm md:text-base text-center"
                style={{
                  borderRadius: "40px",
                  background:
                    "linear-gradient(93deg, #0A5EF3 -3.97%, #00D0FF 101.69%)",
                  position: "relative",
                  zIndex: "10",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/signin";
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started
              </motion.a>
            </div>
          </motion.div>

          {/* Professional Plan */}
          <motion.div
            className="pricing-card bg-[#091530] rounded-lg shadow-lg p-6 md:p-8 text-white flex flex-col items-center justify-between min-h-[520px] md:min-h-[480px]"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onAnimationEnd={(e) => {
              if (e.animationName === "borderClip") {
                e.target.classList.add("animation-complete");
              }
            }}
            onMouseLeave={handleMouseLeave}
          >
            <div className="w-full">
              <div className="text-center mb-6">
                <motion.h3
                  className="text-lg md:text-xl font-semibold mb-3"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  Professional
                </motion.h3>
                <motion.p
                  className="text-3xl md:text-4xl font-bold mb-2"
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.5, type: "spring" }}
                >
                  $99{" "}
                  <span className="text-base md:text-lg font-normal">
                    /month
                  </span>
                </motion.p>
                <p className="text-white text-sm md:text-base">
                  For growing organizations
                </p>
              </div>
              <div className="space-y-6">
                <div>
                  <ul className="space-y-2 md:space-y-3 text-gray-300 text-left text-sm md:text-base">
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.6 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      10000 queries monthly for gpt-4o-mini
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.7 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      Access to other models like gpt-4o and DeepseekR1
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.8 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      2 GB file upload limit
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.9 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      Priority support
                    </motion.li>
                  </ul>
                </div>
                <div>
                  <h4 className="text-left text-sm font-medium text-gray-300 mb-3">
                    Best for:
                  </h4>
                  <p className="text-left text-sm text-gray-400">
                    Growing teams that need advanced features and increased
                    capacity
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6 md:mt-8 w-full">
              <motion.a
                href="/signin"
                className="block w-full text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded-full shadow-lg transition-transform transform hover:scale-105 text-sm md:text-base text-center"
                style={{
                  borderRadius: "40px",
                  background:
                    "linear-gradient(93deg, #0A5EF3 -3.97%, #00D0FF 101.69%)",
                  position: "relative",
                  zIndex: "10",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/signin";
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started
              </motion.a>
            </div>
          </motion.div>

          {/* Enterprise Plan */}
          <motion.div
            className="pricing-card bg-[#091530] rounded-lg shadow-lg p-6 md:p-8 text-white flex flex-col items-center justify-between min-h-[520px] md:min-h-[480px]"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            onAnimationEnd={(e) => {
              if (e.animationName === "borderClip") {
                e.target.classList.add("animation-complete");
              }
            }}
            onMouseLeave={handleMouseLeave}
          >
            <div className="w-full">
              <div className="text-center mb-6">
                <motion.h3
                  className="text-lg md:text-xl font-semibold mb-3"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  Enterprise
                </motion.h3>
                <motion.p
                  className="text-3xl md:text-4xl font-bold mb-2"
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.6, type: "spring" }}
                >
                  Custom Plan
                </motion.p>
                <p className="text-white text-sm md:text-base">
                  For large organizations
                </p>
              </div>
              <div className="space-y-6">
                <div>
                  <ul className="space-y-2 md:space-y-3 text-gray-300 text-left text-sm md:text-base">
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.7 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      Custom solutions
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.8 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      Unlimited storage
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.9 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      24/7 dedicated support
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 1.0 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      Tailored security
                    </motion.li>
                    <motion.li
                      className="flex items-center pricing-feature"
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 1.1 }}
                    >
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 md:w-5 md:h-5 mr-2"
                      />
                      Scalable infrastructure
                    </motion.li>
                  </ul>
                </div>
                <div>
                  <h4 className="text-left text-sm font-medium text-gray-300 mb-3">
                    Best for:
                  </h4>
                  <p className="text-left text-sm text-gray-400">
                    Large organizations requiring custom solutions and
                    enterprise-grade support
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6 md:mt-8 w-full">
              <motion.a
                href="#contact"
                className="block w-full text-white font-semibold py-2 md:py-3 px-4 md:px-6 rounded-full shadow-lg transition-transform transform hover:scale-105 text-sm md:text-base text-center"
                style={{
                  borderRadius: "40px",
                  background:
                    "linear-gradient(93deg, #0A5EF3 -3.97%, #00D0FF 101.69%)",
                  position: "relative",
                  zIndex: "10",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("contact")
                    .scrollIntoView({ behavior: "smooth" });
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Talk to Us
              </motion.a>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
