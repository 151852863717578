import React, { useState, useRef, useCallback } from "react";

import Feature1 from "../assets/feature1.png";
import Feature1Hover from "../assets/feature1hover.png";
import Feature2 from "../assets/feature2.png";
import Feature2Hover from "../assets/feature2hover.png";
import Feature3 from "../assets/feature3.png";
import Feature3Hover from "../assets/feature3hover.png";
import Feature4 from "../assets/feature4.png";
import Feature4Hover from "../assets/feature4hover.png";
import Feature5 from "../assets/feature5.png";
import Feature5Hover from "../assets/feature5hover.png";
import Feature6 from "../assets/feature6.png";
import Feature6Hover from "../assets/feature6hover.png";

const Features = () => {
  // Add state to track which card is currently hovered
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  // Enhanced mouse leave handler that ensures proper target element
  const handleMouseLeave = useCallback((e, index) => {
    // Set the hovered card to null
    setHoveredCardIndex(null);

    // Find the feature-card element, whether it's the target or a parent
    let element = e.currentTarget;
    if (element && element.classList.contains("feature-card")) {
      element.classList.remove("animation-complete");
    }
  }, []);

  // Add mouse enter handler to ensure proper state
  const handleMouseEnter = useCallback((e, index) => {
    // Set the hovered card index
    setHoveredCardIndex(index);
  }, []);

  // Handle transition end to ensure proper cleanup
  const handleTransitionEnd = useCallback((e) => {
    if (e.propertyName === "opacity" && !e.currentTarget.matches(":hover")) {
      let element = e.currentTarget;
      if (element && element.classList.contains("feature-card")) {
        element.classList.remove("animation-complete");
      }
    }
  }, []);

  // Handle animation end for setting animation-complete class
  const handleAnimationEnd = useCallback(
    (e, index) => {
      if (e.animationName === "borderClip") {
        let element = e.currentTarget;
        if (
          element &&
          element.classList.contains("feature-card") &&
          hoveredCardIndex === index
        ) {
          element.classList.add("animation-complete");
        }
      }
    },
    [hoveredCardIndex],
  );

  return (
    <div
      id="features"
      className="py-16 bg-[#050D24] relative"
      style={{ marginTop: "-2px" }}
    >
      <style jsx>{`
        @keyframes borderClip {
          0% {
            clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
          }
          25% {
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          }
          50% {
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
          }
          75% {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
          }
          100% {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }
        }
        .feature-card {
          position: relative;
          transform: translateZ(0); /* Force GPU acceleration */
          will-change: transform, opacity;
          transition:
            transform 0.3s ease,
            border-color 0.3s ease,
            background 0.3s ease,
            box-shadow 0.3s ease;
          z-index: 1; /* Ensure proper stacking */
          pointer-events: auto;
          padding: 20px;
        }
        .feature-card::before,
        .feature-card::after {
          content: "";
          position: absolute;
          inset: 0;
          border: 2px solid transparent;
          border-radius: 8px;
          background: linear-gradient(180deg, #00d0ff 0%, #7555e0 100%)
            border-box;
          -webkit-mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        .feature-card::before {
          opacity: 0;
        }
        .feature-card:hover::before {
          opacity: 1;
          animation: borderClip 1.5s linear forwards;
        }
        .feature-card.animation-complete::after {
          opacity: 1;
        }
        .feature-card:not(:hover) {
          border-color: #404040;
          background: #091530;
          box-shadow: none;
          transform: translateY(0) translateZ(0);
        }
        .feature-card:not(:hover) .p-3 {
          background: #091530;
        }
        .feature-card:not(:hover)::before,
        .feature-card:not(:hover)::after {
          opacity: 0;
        }
        .feature-card:hover {
          border-color: transparent;
          background: #050d24;
          box-shadow: -3px -3px 4px 0px rgba(0, 208, 255, 0.4) inset;
          transform: translateY(-2px) translateZ(0);
        }
        .feature-card:hover .p-3 {
          background: #050d24;
          transition: background 0.3s ease;
        }
        .feature-card .default-image {
          opacity: 1;
          transition: opacity 0.5s ease;
          backface-visibility: hidden;
          transform: translateZ(0);
          will-change: opacity;
          filter: brightness(1.3) contrast(1.1);
          pointer-events: none; /* Prevent mouse events on images */
        }
        .feature-card .hover-image {
          opacity: 0;
          transition: opacity 0.5s ease;
          backface-visibility: hidden;
          transform: translateZ(0);
          will-change: opacity;
          filter: brightness(1.2) contrast(1.1);
          pointer-events: none; /* Prevent mouse events on images */
        }
        .feature-card * {
          pointer-events: none;
        }
        .feature-card.animation-complete:hover .default-image {
          opacity: 0;
        }
        .feature-card.animation-complete:hover .hover-image {
          opacity: 1;
        }
        .feature-card:not(:hover) .default-image {
          opacity: 1;
        }
        .feature-card:not(:hover) .hover-image {
          opacity: 0;
        }
        .feature-card .w-[424px] {
          width: 100%;
          max-width: 424px;
        }
        .feature-card .w-[280px] {
          width: 100%;
          max-width: 280px;
        }
        @media (max-width: 1200px) {
          .feature-card img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      `}</style>
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-12 xl:px-16">
        {/* Header */}
        <div className="w-full text-left mb-8">
          <div
            className="text-white text-[38px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-semibold font-['Poppins'] leading-[1.2] sm:leading-[1.3] md:leading-[1.4] lg:leading-[80px] tracking-[-1.2px]"
            style={{ maxWidth: "100%" }}
          >
            <div style={{ width: "100%", display: "block" }}>
              Features That Transform Your Workflows with Pragya
            </div>
          </div>
          <p className="text-xl text-gray-400 mt-4">
            Discover the features that make Pragya the ultimate AI solution for
            your organization.
          </p>
        </div>

        {/* Updated container with responsive grid */}
        <div className="container mx-auto max-w-[1440px] px-2 relative">
          <div className="flex flex-wrap md:grid md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-6 xl:gap-8">
            {/* Card 1 - Talk to Your AI Assistant */}
            <div className="w-full md:col-span-3 lg:col-span-4 xl:col-span-4 flex justify-center xl:justify-start mb-6 md:mb-0">
              <div
                className="feature-card w-[90vw] sm:w-full md:max-w-[400px] lg:max-w-[450px] h-auto md:h-[320px] lg:h-[383px] pt-3 flex flex-col justify-end items-center gap-3 flex-shrink-0 rounded-lg border border-[#404040] bg-[#091530] overflow-hidden transition-all duration-300 group"
                onAnimationEnd={(e) => handleAnimationEnd(e, 0)}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, 0);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, 0);
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <div className="w-full max-w-[500px] lg:max-w-[524px] h-[300px] relative bg-gradient-to-b from-black/0 via-black/10 to-black/20 rounded-lg border border-neutral-700 flex flex-col justify-center items-center overflow-hidden">
                  <img
                    className="absolute inset-0 w-full h-full object-fit rounded-lg border border-neutral-700 default-image z-10"
                    src={Feature1}
                    alt="AI Assistant default interface"
                  />
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 hover-image z-20"
                    src={Feature1Hover}
                    alt="AI Assistant hover interface"
                  />
                </div>
                <div className="p-3 bg-[#091530] w-full inline-flex flex-col justify-center items-start gap-2">
                  <div className="self-stretch text-white text-base font-semibold font-['Poppins'] tracking-[-0.32px]">
                    Talk to Your AI Assistant—Instantly!
                  </div>
                  <div className="self-stretch text-white/80 text-s font-light font-['Poppins']">
                    Get accurate, real-time answers from your AI assistant,
                    complete with source references from your documents. Trust
                    your data, trust your AI.
                  </div>
                </div>
              </div>
            </div>

            {/* Card 2 - Deploy Your Assistant */}
            <div className="w-full md:col-span-3 lg:col-span-4 xl:col-span-4 flex justify-center mb-6 md:mb-0">
              <div
                className="feature-card w-[90vw] sm:w-full md:max-w-[400px] lg:max-w-[450px] h-auto md:h-[320px] lg:h-[320px] pt-3 flex flex-col items-center gap-3 flex-shrink-0 rounded-lg border border-[#404040] bg-[#091530] overflow-hidden transition-all duration-300 group"
                onAnimationEnd={(e) => handleAnimationEnd(e, 1)}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, 1);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, 1);
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <div className="w-full max-w-[500px] lg:max-w-[524px] h-[300px] relative bg-gradient-to-b from-black/0 via-black/10 to-black/20 rounded-lg border border-neutral-700 flex flex-col justify-center items-center overflow-hidden">
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 default-image z-10"
                    src={Feature2}
                    alt="Deploy Assistant default interface"
                  />
                  <img
                    className="absolute inset-0 w-full h-full rounded-lg border border-neutral-700 hover-image z-20"
                    src={Feature2Hover}
                    alt="Deploy Assistant hover interface"
                  />
                </div>
                <div className="p-3 bg-[#091530] w-full inline-flex flex-col justify-start items-start gap-2">
                  <div className="self-stretch text-white text-base font-semibold font-['Poppins'] tracking-[-0.32px]">
                    Deploy Your Assistant—Anywhere!
                  </div>
                  <div className="self-stretch text-white/80 text-s font-light font-['Poppins']">
                    Deploy your created assistant on your website securely and
                    give your users a smooth and great experience of customer
                    service
                  </div>
                </div>
              </div>
            </div>

            {/* Card 3 - Build Your Dream Team */}
            <div className="w-full md:col-span-6 lg:col-span-8 xl:col-span-4 flex justify-center xl:justify-end mb-6 md:mb-0">
              <div
                className="feature-card w-[90vw] sm:w-full md:max-w-[400px] lg:max-w-[450px] h-auto md:h-[310px] lg:h-[310px] pt-3 flex flex-col justify-end items-center gap-3 flex-shrink-0 rounded-lg border border-[#404040] bg-[#091530] overflow-hidden transition-all duration-300 group"
                onAnimationEnd={(e) => handleAnimationEnd(e, 2)}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, 2);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, 2);
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <div className="w-full max-w-[500px] h-[300px] relative bg-gradient-to-b from-black/0 via-black/10 to-black/20 rounded-lg border border-neutral-700 flex flex-col justify-start items-start overflow-hidden">
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 default-image z-10"
                    src={Feature3}
                    alt="Build Your Dream Team"
                  />
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 hover-image z-20"
                    src={Feature3Hover}
                    alt="Build Your Dream Team hover"
                  />
                </div>
                <div className="w-full p-3 bg-[#091530] inline-flex flex-col justify-start items-start gap-2">
                  <div className="self-stretch text-white text-base font-semibold font-['Poppins'] tracking-[-0.32px]">
                    Build Your Dream Team
                  </div>
                  <div className="self-stretch text-white/80 text-s font-light font-['Poppins']">
                    Invite team members and assign roles for seamless
                    collaboration.
                  </div>
                </div>
              </div>
            </div>

            {/* Card 4 */}
            <div className="w-full md:col-span-3 lg:col-span-4 xl:col-span-4 flex justify-center xl:justify-start mb-6 md:mb-0">
              <div
                className="feature-card w-[90vw] sm:w-full md:max-w-[400px] lg:max-w-[450px] h-auto md:h-[310px] lg:h-[310px] pt-3 pb-[18px] flex flex-col items-center gap-3 flex-shrink-0 rounded-lg border border-[#404040] bg-[#091530] overflow-hidden transition-all duration-300 group"
                onAnimationEnd={(e) => handleAnimationEnd(e, 3)}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, 3);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, 3);
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <div className="w-full max-w-[500px] lg:max-w-[524px] h-[300px] relative bg-gradient-to-b from-black/0 via-black/10 to-black/20 rounded-lg border border-neutral-700 flex flex-col justify-start items-start overflow-hidden">
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 default-image z-10"
                    src={Feature4}
                    alt="Set Access Levels"
                  />
                  <img
                    className="absolute inset-0 w-full h-full rounded-lg border border-neutral-700 hover-image z-20"
                    src={Feature4Hover}
                    alt="Set Access Levels hover"
                  />
                </div>
                <div className="p-3 bg-[#091530] w-full inline-flex flex-col justify-start items-start gap-2">
                  <div className="self-stretch text-white text-base font-semibold font-['Poppins'] tracking-[-0.32px]">
                    Set Access Levels
                  </div>
                  <div className="self-stretch text-white/80 text-s font-light font-['Poppins']">
                    Control who can view, edit, or manage your projects &
                    assistants.
                  </div>
                </div>
              </div>
            </div>

            {/* Card 5 */}
            <div className="w-full md:col-span-3 lg:col-span-4 xl:col-span-4 flex justify-center mb-6 md:mb-0 md:-mt-8 lg:-mt-12 xl:-mt-16">
              <div
                className="feature-card w-[90vw] sm:w-full md:max-w-[400px] lg:max-w-[470px] xl:max-w-[470px] h-auto md:h-[310px] lg:h-[310px] pt-3 flex flex-col justify-end items-center gap-3 flex-shrink-0 rounded-lg border border-[#404040] bg-[#091530] overflow-hidden transition-all duration-300 group"
                onAnimationEnd={(e) => handleAnimationEnd(e, 4)}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, 4);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, 4);
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <div className="w-full max-w-[500px] h-[300px] relative bg-gradient-to-b from-black/0 via-black/10 to-black/20 rounded-lg border border-neutral-700 flex flex-col justify-start items-start overflow-hidden">
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 default-image z-10"
                    src={Feature5}
                    alt="Organize Your Files"
                  />
                  <img
                    className="absolute inset-0 w-full h-full rounded-lg border border-neutral-700 hover-image z-20"
                    src={Feature5Hover}
                    alt="Organize Your Files hover"
                  />
                </div>
                <div className="w-full p-3 bg-[#091530] inline-flex flex-col justify-start items-start gap-2">
                  <div className="self-stretch text-white text-base font-semibold font-['Poppins'] tracking-[-0.32px]">
                    Organize Your Files
                  </div>
                  <div className="self-stretch text-white/80 text-s font-light font-['Poppins']">
                    Organize files and documents to structure your private data
                    for easy AI access.
                  </div>
                </div>
              </div>
            </div>

            {/* Card 6 */}
            <div className="w-full md:col-span-6 lg:col-span-8 xl:col-span-4 flex justify-center xl:justify-end mb-6 md:mb-0 md:-mt-8 lg:-mt-12 xl:-mt-16">
              <div
                className="feature-card w-[90vw] sm:w-full md:max-w-[400px] lg:max-w-[470px] xl:max-w-[470px] h-auto md:h-[310px] lg:h-[310px] pt-3 pb-[18px] flex flex-col items-center gap-3 flex-shrink-0 rounded-lg border border-[#404040] bg-[#091530] overflow-hidden transition-all duration-300 group"
                onAnimationEnd={(e) => handleAnimationEnd(e, 5)}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, 5);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, 5);
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <div className="w-full max-w-[500px] lg:max-w-[524px] h-[300px] relative bg-gradient-to-b from-black/0 via-black/10 to-black/20 rounded-lg border border-neutral-700 flex flex-col justify-start items-start overflow-hidden">
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 default-image z-10"
                    src={Feature6}
                    alt="Share Across Your Organization"
                  />
                  <img
                    className="absolute inset-0 w-full h-full  rounded-lg border border-neutral-700 hover-image z-20"
                    src={Feature6Hover}
                    alt="Share Across Your Organization hover"
                  />
                </div>
                <div className="p-3 bg-[#091530] w-full inline-flex flex-col justify-start items-start gap-2">
                  <div className="self-stretch text-white text-base font-semibold font-['Poppins'] tracking-[-0.32px]">
                    Share Across Your Organization
                  </div>
                  <div className="self-stretch text-white/80 text-s font-light font-['Poppins']">
                    Use the same assistant and projects across teams for
                    consistency. Organization Collaboration for scalability.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
