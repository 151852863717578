import React, { useEffect } from "react";
import Navbar from "../pages/Navbar";
import Footer from "../components/Footer";
import Terms1 from "../assets/Terms1.png"; // Terms logo image
import TermsBg from "../assets/termsbg.png"; // Background image

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="min-h-screen flex flex-col"
      style={{
        backgroundImage: `url(${TermsBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="mb-28">
        <Navbar scrolled={true} />
      </div>

      {/* Title, Last Updated Date, and Logo */}
      <div className="w-full max-w-6xl mx-auto px-8 flex flex-col md:flex-row justify-between items-start mt-8">
        <div>
          <h1 className="text-4xl sm:text-5xl md:text-6xl mb-4 font-bold font-poppins text-white text-left">
            Terms & Policies
          </h1>
          <p className="text-white mb-8 md:mb-20 text-left font-poppins text-lg sm:text-xl font-normal leading-normal">
            Last Updated: February 28, 2025
          </p>
        </div>
        <img
          src={Terms1}
          alt="Terms Logo"
          className="w-[150px] h-auto md:w-[280px] md:h-[160.519px] md:-mt-12 md:-mr-8 mx-auto md:mx-0"
        />
      </div>

      {/* Content Area */}
      <div className="max-w-6xl mx-auto shadow-lg rounded-xl overflow-hidden border-4 border-white mt-8">
        <div className="p-8 space-y-8 text-gray-300">
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2">
              Terms of Service
            </h2>
            <p className="text-lg leading-relaxed">
              These Terms of Service ("Terms") govern your access to and use of
              Pragya, its associated services, and software (collectively
              referred to as the "Services"). By accessing or using the
              Services, you agree to these Terms. If you are using the Services
              on behalf of an organization, you affirm that you possess the
              authority to bind that organization to these Terms, in which case
              "you" and "your" shall refer to the organization. Both Pragya and
              you shall be referred to as a "Party" to these Terms.
            </p>
            <p className="text-lg leading-relaxed">
              Pragya reserves the right to modify these Terms at any time.
              Updates will be posted on the platform, and your continued use of
              the Services following such modifications constitutes your
              acceptance of the revised Terms.
            </p>

            {/* Section 1 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              1. Use of Pragya – User Commitments
            </h2>
            <p className="text-lg leading-relaxed">
              To ensure proper functioning of the Services, and in exchange for
              access to such services, you agree to the following commitments
              when accessing and using our platform:
            </p>
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-100">
                Eligibility
              </h3>
              <ul className="list-disc ml-8 space-y-2">
                <li>
                  You must be a natural person. The use of the platform by
                  automated systems or bots, unless authorized by Pragya, is
                  prohibited.
                </li>
                <li>
                  You agree to these Terms and the Privacy Policy either as an
                  individual or on behalf of your organization.
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-100">
                Account Security
              </h3>
              <ul className="list-disc ml-8 space-y-2">
                <li>
                  If you share your login credentials or access codes with a
                  third party, you will be fully responsible for any actions
                  taken by that third party on your behalf.
                </li>
                <li>
                  Pragya disclaims any liability for such actions, which will be
                  deemed as actions taken by you.
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-100">
                User Contributions
              </h3>
              <p className="text-lg leading-relaxed">
                Should you provide questions, comments, suggestions,
                enhancements, ideas, or other feedback regarding the platform,
                you grant Pragya the right to use and disclose such feedback
                without restriction and without compensation.
              </p>
            </div>

            {/* Section 2 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              2. Acceptable Use
            </h2>
            <p className="text-lg leading-relaxed">
              When using Pragya’s Services, you agree not to:
            </p>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                Provide system passwords or other login information to any
                unauthorized third party.
              </li>
              <li>
                Share nonpublic system features or content with any third party.
              </li>
              <li>
                Access the system for the purpose of developing a competitive
                product or service or to create a product utilizing similar
                ideas, features, functions, or graphics of the system.
              </li>
              <li>
                Engage in web scraping, data scraping, or any other form of data
                collection that violates these Terms.
              </li>
              <li>
                Use Pragya’s AI-driven tools or language models (LLMs) for
                generating or disseminating harmful, illegal, or unethical
                content.
              </li>
            </ul>

            {/* Section 3 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              3. Links to Third-Party Services
            </h2>
            <p className="text-lg leading-relaxed">
              Our Services may contain links to third-party websites or services
              that are not owned or controlled by Pragya. Pragya has no control
              over, and assumes no responsibility for, the content, privacy
              policies, or practices of any third-party websites or services.
            </p>

            {/* Section 4 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              4. Subscriptions and Payments
            </h2>
            <p className="text-lg leading-relaxed">
              Certain components of the Service are billed on a subscription
              basis ("Subscription(s)"). Billing cycles are established on
              either a monthly or annual basis, depending on the type of
              subscription plan you select at the time of purchase.
            </p>

            {/* Section 5 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              5. Data Ownership and Privacy
            </h2>
            <p className="text-lg leading-relaxed">
              "Your Data" refers to any data and content stored or transmitted
              via the Services by or on behalf of you or your end users, which
              may include data imported from third-party services. You retain
              full ownership of Your Data.
            </p>

            {/* Section 6 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              6. Intellectual Property
            </h2>
            <p className="text-lg leading-relaxed">
              Pragya retains ownership of all intellectual property rights
              associated with the Services, including trademarks, software,
              designs, and documentation.
            </p>

            {/* Section 7 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              7. Limitation of Liability
            </h2>
            <p className="text-lg leading-relaxed">
              To the fullest extent permitted by law, Pragya shall not be liable
              for any indirect, incidental, or consequential damages arising
              from your use of the Services.
            </p>

            {/* Section 8 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              8. Modifications and Termination
            </h2>
            <p className="text-lg leading-relaxed">
              Pragya reserves the right to modify, suspend, or discontinue any
              part of the Services at its sole discretion.
            </p>

            {/* Section 9 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              9. Governing Law
            </h2>
            <p className="text-lg leading-relaxed">
              These Terms shall be governed by and construed in accordance with
              the laws of [Your Jurisdiction], without regard to its conflict of
              law principles.
            </p>

            {/* Section 10 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              10. Contact Information
            </h2>
            <p className="text-lg leading-relaxed">
              For any questions, concerns, or feedback related to these Terms,
              please reach out to Pragya at products@neuradynamics.ai.
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer className="mt-16 mb-auto" />
    </div>
  );
};

export default TermsOfService;
