import { configureStore } from "@reduxjs/toolkit";
import orgReducer from "./orgSlice";
import activeUserSlice from "./userSlice.js";
import sidebar from "./sidebarSlice.js";
import notificationSlice from "./notificationSlice.js";
import projectReducer from "./projectSlice";
import organizationReducer from "./organizationSlice";

const store = configureStore({
  reducer: {
    activeUser: activeUserSlice,
    sidebar: sidebar,
    notification: notificationSlice,
    org: orgReducer,
    project: projectReducer,
    organization: organizationReducer,
  },
});
export default store;
