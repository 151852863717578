import { React, useState } from "react";
import Sidebar from "../components/Sidebar"; // Import your Sidebar component
import { Outlet } from "react-router-dom"; // Outlet is used to render the child route
import { setActiveProjectId } from "../redux/projectSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Layout = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [isSidebarLoaded, setIsSidebarLoaded] = useState(false);

  console.log(projectId);
  useEffect(() => {
    dispatch(setActiveProjectId(projectId));
    setIsSidebarLoaded(true); // Sidebar is considered loaded after setting projectId
  }, [dispatch, projectId]);
  return (
    <div className="flex bg-[#070707]">
      <div
        className="absolute top-0 left-0 w-[220px] h-[220px] rounded-br-full bg-[#6E45FE]  z-[-10]"
        style={{ backdropFilter: "blur(264px)" }}
      ></div>
      <div className="absolute top-0 left-0 z-0 pointer-events-none">
        <svg
          width="470"
          height="428"
          viewBox="0 0 470 428"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 left-0 w-[350px] h-[350px] opacity-50 pointer-events-none z-[1]"
        >
          <g id="Ellipse 1" filter="url(#filter0_f)">
            <circle cx="92.5" cy="51.2812" r="113.5" fill="#1677FF" />
          </g>
          <defs>
            <filter
              id="filter0_f"
              x="-285"
              y="-326.6"
              width="755"
              height="755"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="132"
                result="effect1_foregroundBlur"
              />
            </filter>
          </defs>
        </svg>
      </div>
      {/* Sidebar */}
      <Sidebar />

      {/* Main content */}
      <div className="flex-grow p-4  h-screen">
        {/* Outlet renders the current page */}
        {isSidebarLoaded ? (
          <Outlet />
        ) : (
          <p className="text-white text-center">Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Layout;
