import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import TestimonialsSection from "../components/Testimonial";
import FAQSection from "../components/FAQ";
import ContactUs from "../components/ContactUs";
import Pricing from "../pages/Pricing";
import Features from "../pages/Features";
import Usecase from "../pages/Usecase";
import Navbar from "../pages/Navbar";
import Hero from "../components/Hero"; // Import the new Hero component
import { redirectIfAuthenticated } from "../components/axiosInstance";

const Landing = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  // Check authentication on component mount
  useEffect(() => {
    redirectIfAuthenticated(navigate, "/");
  }, [navigate]);

  // Handle navbar background transition
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen overflow-x-hidden">
      {/* Navbar */}
      <Navbar scrolled={scrolled} />

      {/* Hero and Features Section Wrapper */}
      <div className="bg-[#050D24] relative">
        {/* Hero Section */}
        <Hero />

        {/* Sections */}
        <div id="home"></div>
        <Features />
      </div>

      <Usecase />
      <Pricing />

      <div id="testimonials">
        <TestimonialsSection />
      </div>

      <FAQSection />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Landing;
