import React from "react";
import "./HomePageLayout.css";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
// import HomePageSidebar from "../HomePageSidenar/HomePageSidebar";
import HomePageNavbar from "../HomePageNavbar/HomePageNavbar";

const HomePageLayout = () => {
  return (
    <Box className="relative h-screen w-full overflow-hidden bg-[#070707] px-[30px] py-[20px]">
      <Box className="sticky top-0 left-0 right-0 z-[1000] bg-[#070707]">
        <HomePageNavbar />
        <Box className="overflow-auto relative bg-[#070707]">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePageLayout;
