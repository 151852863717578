// axiosInstance.js
import axios from "axios";
// import { getRefreshToken } from "./login"; // Adjust the import based on your project structure

const API_URL = process.env.REACT_APP_API_URL;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Ensures cookies are sent with requests
});

// Request interceptor to add the Authorization header to each request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
const refreshAccessToken = async () => {
  try {
    const response = await axios.post(
      `${API_URL}/auth/jwt/refresh`,
      {},
      {
        withCredentials: true, // Include cookies for the refresh token
      },
    );

    // Update the access token in local storage
    const newAccessToken = response.data.access_token;
    localStorage.setItem("access_token", newAccessToken);
    return newAccessToken;
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    return null;
  }
};

// Response interceptor to handle 401 Unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is 401 and the request hasn't been retried yet
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        // Update the Authorization header with the new token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest); // Retry the original request
      } else {
        // If refreshing the token fails, clear storage and redirect to login
        localStorage.clear();
        window.location.href = "/signin";
      }
    }

    return Promise.reject(error);
  },
);

// Utility function to check if user is authenticated
export const isAuthenticated = () => {
  try {
    const token = localStorage.getItem("access_token");
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    // Basic validation
    if (!token || typeof token !== "string" || token.split(".").length !== 3) {
      return false;
    }

    if (isLoggedIn !== "true") {
      return false;
    }

    // Additional JWT validation - check if token is expired
    // This is a simple check, not a full validation
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const expiry = payload.exp * 1000; // Convert to milliseconds

      if (Date.now() >= expiry) {
        console.log("Token expired");
        return false;
      }
    } catch (e) {
      console.error("Error parsing JWT:", e);
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error in isAuthenticated:", error);
    return false;
  }
};

// Function to handle authentication-required redirects
export const redirectIfAuthenticated = (navigate, currentPath) => {
  const publicPages = ["/", "/signin", "/signup"];

  if (isAuthenticated() && publicPages.includes(currentPath)) {
    navigate("/home-page", { replace: true });
    return true;
  }

  return false;
};

// Function to handle logout
export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("isLoggedIn");

  // Redirect to login page
  window.location.href = "/signin";
};

export default axiosInstance;

// import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;

// // Create an Axios instance
// const axiosInstance = axios.create({
//     baseURL: API_URL,
//     withCredentials: true, // Ensures cookies are sent with requests
// });

// // Request interceptor to add the Authorization header to each request
// axiosInstance.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem("access_token");
//         if (token) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// // Refresh token function
// const refreshAccessToken = async () => {
//     try {
//         const response = await axios.post(`${API_URL}/auth/jwt/refresh`, {}, {
//             withCredentials: true, // Include cookies for the refresh token
//         });

//         // Update the access token in local storage
//         const newAccessToken = response.data.access_token;
//         localStorage.setItem("access_token", newAccessToken);
//         return newAccessToken;
//     } catch (error) {
//         console.error("Failed to refresh access token:", error);
//         return null;
//     }
// };

// // Response interceptor to handle 401 Unauthorized errors
// axiosInstance.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;

//         // Check if the error is 401 and the request hasn't been retried yet
//         if (error.response && error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;

//             const newAccessToken = await refreshAccessToken();

//             if (newAccessToken) {
//                 // Update the Authorization header with the new token
//                 originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
//                 return axiosInstance(originalRequest); // Retry the original request
//             } else {
//                 // If refreshing the token fails, clear storage and redirect to login
//                 localStorage.clear();
//                 window.location.href = "/signin";
//             }
//         }

//         return Promise.reject(error);
//     }
// );

// export default axiosInstance;
