import React, { useEffect } from "react";
import Navbar from "../pages/Navbar";
import Footer from "../components/Footer";
import Privacy1 from "../assets/privacy1.png"; // Import the image
import TermsBg from "../assets/termsbg.png"; // Import the background image

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);

  return (
    <div
      className="min-h-screen flex flex-col bg-inherit"
      style={{
        backgroundImage: `url(${TermsBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div className="mb-32">
        <Navbar scrolled={true} />
      </div>

      {/* Title, Last Updated Date, and Logo */}
      <div className="w-full max-w-6xl mx-auto px-8 flex flex-col md:flex-row justify-between items-start mt-8">
        {/* Title and Last Updated Date */}
        <div>
          <h1 className="text-4xl sm:text-5xl md:text-6xl mb-4 font-bold font-poppins text-white text-left">
            Privacy Policy
          </h1>
          <p className="text-white mb-8 md:mb-20 text-left font-poppins text-lg sm:text-xl font-normal leading-normal">
            Last Updated: February 28, 2025
          </p>
        </div>

        {/* Logo */}
        <img
          src={Privacy1}
          alt="Privacy Logo"
          className="w-[150px] h-auto md:w-[280px] md:h-[160.519px] md:-mt-12 md:-mr-8 mx-auto md:mx-0"
        />
      </div>

      {/* Content Area */}
      <div className="max-w-6xl mx-auto  bg-opacity-90 shadow-lg rounded-xl overflow-hidden border-4 border-white mt-8">
        <div className="p-8 space-y-8 text-gray-300">
          {/* Privacy Policy Content */}
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2">
              Privacy Policy
            </h2>
            <p className="text-lg leading-relaxed">
              Pragya is committed to protecting the privacy, confidentiality,
              and security of your personal information. Your privacy is a top
              priority, and we strive to safeguard your information from
              unauthorized access. This Privacy Policy outlines how we collect,
              use, and disclose your personal information in relation to our
              services. By using our platform, you agree to the terms described
              in this Privacy Policy.
            </p>

            {/* Section 1 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              1. Information We Collect
            </h2>
            <h3 className="text-xl font-semibold text-gray-100">
              a) When You Use Our Website
            </h3>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                <strong>Usage Data:</strong> We collect information about how
                you interact with our website, such as pages visited, time spent
                on pages, and navigation paths.
              </li>
              <li>
                <strong>Account Information:</strong> When you create an
                account, we collect your name, email address, and company
                affiliation to personalize your experience.
              </li>
              <li>
                <strong>Payment Information:</strong> For paid subscriptions,
                payment details are processed securely through third-party
                providers and are not stored on our servers.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-gray-100">
              b) When You Subscribe to Newsletters
            </h3>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                <strong>Contact Details:</strong> If you sign up for
                newsletters, we collect your name and email address to send
                relevant updates.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-gray-100">
              c) When You Use Our Services
            </h3>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                <strong>Server Logs:</strong> We track usage activity, including
                IP address, browser type, and geo-location, to improve service
                performance.
              </li>
              <li>
                <strong>AI Interactions:</strong> Data input during interactions
                with our AI tools, including queries and documents, may be
                temporarily stored to enhance the performance and accuracy of
                our models.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-gray-100">
              d) When You Contact Support
            </h3>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                <strong>Support Conversations:</strong> We retain communication
                records, such as emails and chat logs, to address your inquiries
                effectively. These are deleted 90 days after your account is
                closed.
              </li>
            </ul>

            {/* Section 2 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              2. How We Use Your Information
            </h2>
            <p className="text-lg leading-relaxed">
              We collect and use your personal data to provide a secure,
              efficient, and personalized experience, including:
            </p>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                Service Delivery: To enable access to our AI-driven tools.
              </li>
              <li>
                Customer Support: To resolve issues and ensure optimal use of
                our platform.
              </li>
              <li>Security: To detect and prevent unauthorized use.</li>
              <li>
                Improvements: To refine our AI models based on anonymized data.
              </li>
              <li>
                Marketing: To inform you of new features, updates, and services.
              </li>
            </ul>

            {/* Section 3 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              3. Data Retention
            </h2>
            <p className="text-lg leading-relaxed">
              Pragya retains personal information for as long as necessary to
              provide the services you've requested and in accordance with
              applicable laws. Once your account is deleted, most associated
              data is removed within 90 days unless required for legal purposes.
            </p>

            {/* Section 4 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              4. Third-Party Services
            </h2>
            <p className="text-lg leading-relaxed">
              We use reputable third-party services to enhance our platform's
              functionality, including:
            </p>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                <strong>Payment Processing:</strong> Razorpay.
              </li>
              <li>
                <strong>Marketing and Analytics:</strong> Google Analytics and
                similar platforms.
              </li>
              <li>
                <strong>Cloud Storage:</strong> Azure Services for secure data
                hosting.
              </li>
            </ul>

            {/* Section 5 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              5. Your Rights
            </h2>
            <p className="text-lg leading-relaxed">
              You have the following rights concerning your personal
              information:
            </p>
            <ul className="list-disc ml-8 space-y-2">
              <li>
                <strong>Access and Portability:</strong> Request access and a
                copy of your personal data.
              </li>
              <li>
                <strong>Correction:</strong> Correct inaccuracies in your data.
              </li>
              <li>
                <strong>Deletion:</strong> Request the deletion of your data.
              </li>
              <li>
                <strong>Restriction and Objection:</strong> Request limitations
                or object to certain uses.
              </li>
            </ul>

            {/* Section 6 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              6. Cookies and Tracking Technologies
            </h2>
            <p className="text-lg leading-relaxed">
              Pragya uses cookies to enhance user experience. You can manage
              your cookie preferences through browser settings, though disabling
              them may limit functionality.
            </p>

            {/* Section 7 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              7. Security Measures
            </h2>
            <p className="text-lg leading-relaxed">
              We employ industry-standard security measures such as encryption,
              access controls, and monitoring to protect your data.
            </p>

            {/* Section 8 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              8. Children's Privacy
            </h2>
            <p className="text-lg leading-relaxed">
              Pragya's services are not intended for individuals under 13 years
              of age. We do not knowingly collect personal information from
              children.
            </p>

            {/* Section 9 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              9. Changes to This Privacy Policy
            </h2>
            <p className="text-lg leading-relaxed">
              Pragya may update this Privacy Policy periodically. Continued use
              of the services constitutes acceptance of the updated Privacy
              Policy.
            </p>

            {/* Section 10 */}
            <h2 className="text-2xl font-bold text-white border-b-2 border-gray-600 pb-2 mt-6">
              10. Contact Information
            </h2>
            <p className="text-lg leading-relaxed">
              For questions, concerns, or to exercise your rights, please reach
              out to Pragya at products@neuradynamics.ai.
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer className="mt-16 mb-auto" />
    </div>
  );
};

export default PrivacyPolicy;
