import React from "react";
import { motion, AnimatePresence } from "framer-motion";

// Import existing images from assets

import useCase1 from "../assets/usecase1png.png";
import useCase1Hover from "../assets/usecase1hover.png";
import useCase2 from "../assets/usecase2.png";
import useCase2Hover from "../assets/usecase2hover.png";
import useCase3 from "../assets/usecase3.png";
import useCase3Hover from "../assets/usecase3hover.png";
import useCase4 from "../assets/usecase4.png";
import useCase4Hover from "../assets/usecase4hover.png";
import useCase5 from "../assets/usecase5.png";
import useCase5Hover from "../assets/usecase5hover.png";
import useCase6 from "../assets/usecase6.png";
import useCase6Hover from "../assets/usecase6hover.png";

const Usecase = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  // Modified preload function
  React.useEffect(() => {
    const imagesToPreload = [
      useCase1,
      useCase1Hover,
      useCase2,
      useCase2Hover,
      useCase3,
      useCase3Hover,
      useCase4,
      useCase4Hover,
      useCase5,
      useCase5Hover,
      useCase6,
      useCase6Hover,
    ];

    Promise.all(
      imagesToPreload.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      }),
    )
      .then(() => setIsLoaded(true))
      .catch((err) => console.error("Image preload failed:", err));
  }, []);

  const handleMouseLeave = (e) => {
    e.target.classList.remove("animation-complete");
  };

  const usecases = [
    {
      title: "Instant Access to Documentation",
      description:
        "Embed a chatbot on your website to provide instant answers to documentation and policy queries.",
      image: useCase1,
      hoverImage: useCase1Hover,
      features: ["Instant responses", "Easy embedding"],
    },
    {
      title: "AI-Powered Medical Assistant",
      description:
        "Help doctors quickly access medical guidelines, patient history, and treatment recommendations.",
      image: useCase2,
      hoverImage: useCase2Hover,
      features: ["Faster diagnosis", "Improved patient care"],
    },
    {
      title: "Specialized Legal Assistants",
      description:
        "Create agents trained on specific laws like corporate law or criminal law for accurate legal insights.",
      image: useCase3,
      hoverImage: useCase3Hover,
      features: ["Accurate legal advice", "Specialized knowledge"],
    },
    {
      title: "Personalized Study Assistant",
      description:
        "Help students with NCERT 10th standard geography by creating and evaluating questions.",
      image: useCase4,
      hoverImage: useCase4Hover,
      features: ["Custom questions", "Automated evaluation"],
    },
    {
      title: "Developer-Friendly AI Assistants",
      description:
        "Create agents for specific frameworks like FastAPI or React to assist with coding and debugging.",
      image: useCase5,
      hoverImage: useCase5Hover,
      features: ["Faster coding", "Debugging support"],
    },
    {
      title: "Automate BRD Creation",
      description:
        "Generate Business Requirement Documents (BRD) for software products based on call transcripts.",
      image: useCase6,
      hoverImage: useCase6Hover,
      features: ["Accurate BRDs", "Time-saving"],
    },
  ];

  return (
    <div id="usecases" className="relative py-20 bg-[#050D24] overflow-hidden">
      <style jsx>{`
        @keyframes borderClip {
          0% {
            clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
          }
          25% {
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          }
          50% {
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
          }
          75% {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
          }
          100% {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }
        }
        .feature-card {
          position: relative;
          transform: translateZ(0); /* Force GPU acceleration */
          will-change: transform, opacity;
        }

        .feature-card::before,
        .feature-card::after {
          content: "";
          position: absolute;
          inset: 0;
          border: 2px solid transparent;
          border-radius: 8px;
          background: linear-gradient(180deg, #00d0ff 0%, #7555e0 100%)
            border-box;
          -webkit-mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          opacity: 0;
          transform: translateZ(0);
        }

        .feature-card::before {
          opacity: 0;
        }

        .feature-card:hover::before {
          opacity: 1;
          animation: borderClip 1.5s linear forwards;
        }

        .feature-card.animation-complete::after {
          opacity: 1;
        }

        /* Image transition styles */
        .feature-card .default-image {
          opacity: 1;
          transition: opacity 0.5s ease;
          backface-visibility: hidden;
          transform: translateZ(0);
          will-change: opacity;
          filter: brightness(1.3) contrast(1.1); /* Increased brightness for default state */
        }

        .feature-card .hover-image {
          opacity: 0;
          transition: opacity 0.5s ease;
          backface-visibility: hidden;
          transform: translateZ(0);
          will-change: opacity;
          filter: brightness(1.2) contrast(1.1); /* Added brightness for hover state */
        }

        /* Adjust the overlay to be slightly less dark */
        .feature-card .image-overlay {
          background: rgba(0, 0, 0, 0.4); /* Lighter overlay than before */
        }

        /* Only show hover image after animation is complete */
        .feature-card.animation-complete:hover .default-image {
          opacity: 0;
        }

        .feature-card.animation-complete:hover .hover-image {
          opacity: 1;
        }

        /* Hover states */
        .feature-card:hover {
          transform: translateY(-2px) translateZ(0);
          background: #050d24;
          box-shadow: -3px -3px 4px 0px rgba(0, 208, 255, 0.4) inset;
        }

        .feature-card:not(:hover) {
          border-color: #404040;
          background: linear-gradient(
            180deg,
            rgba(9, 21, 48, 0.95) 0%,
            rgba(9, 21, 48, 1) 100%
          );
          box-shadow: 0 4px 12px rgba(0, 208, 255, 0.05);
        }
      `}</style>
      <div className="container mx-auto px-4 sm:px-6  lg:md:px-8px-12 xl:px-16">
        {/* Section Header */}
        <div className="w-full text-left mb-8">
          <div
            className="text-white text-[38px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-semibold font-['Poppins'] leading-[1.2] sm:leading-[1.3] md:leading-[1.4] lg:leading-[80px] tracking-[-1.2px]"
            style={{ maxWidth: "100%" }}
          >
            <div style={{ width: "100%", display: "block" }}>
              Explore use cases
            </div>
          </div>
          <p className="text-xl text-gray-400 mt-4">
            Discover how Pragya transforms workflows across industries from
            e-commerce to finance with AI-powered solutions personalized to your
            needs.
          </p>
        </div>

        {/* Use Cases Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {isLoaded &&
            usecases.map((usecase, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{
                  once: true,
                  amount: 0.3,
                  margin: "100px 0px",
                }}
                transition={{
                  duration: 0.4,
                  delay: index * 0.1,
                  ease: "easeOut",
                }}
                className="feature-card w-full min-h-[408px] p-3 bg-[#091530] rounded-lg inline-flex flex-col justify-start items-start gap-2 border border-[#404040]"
                onAnimationEnd={(e) => {
                  if (e.animationName === "borderClip") {
                    e.target.classList.add("animation-complete");
                  }
                }}
                onMouseLeave={handleMouseLeave}
              >
                {/* Image Container with optimized structure */}
                <div className="w-full h-[180px] sm:h-[220px] relative rounded-lg overflow-hidden">
                  <div className="w-full h-full absolute left-0 top-0 bg-black/40 rounded-lg image-overlay"></div>
                  <img
                    src={usecase.image}
                    alt={usecase.title}
                    className="w-full h-full object-cover default-image"
                    loading={index < 3 ? "eager" : "lazy"}
                    decoding="async"
                  />
                  {usecase.hoverImage && (
                    <img
                      src={usecase.hoverImage}
                      alt={`${usecase.title} hover`}
                      className="w-full h-full object-cover hover-image absolute inset-0"
                      loading="lazy"
                      decoding="async"
                    />
                  )}
                </div>

                {/* Content Container */}
                <div className="flex-1 flex flex-col justify-start items-start gap-1 px-1">
                  <div
                    className={`self-stretch relative justify-start text-white font-semibold font-['Poppins'] text-sm sm:text-base`}
                  >
                    {usecase.title}
                  </div>
                  <div
                    className={`self-stretch relative justify-start text-white/80 font-light font-['Poppins'] text-xs sm:text-sm`}
                  >
                    {usecase.description}
                  </div>
                </div>

                {/* Features List */}
                <div className="w-full flex flex-col justify-start items-start gap-3 sm:gap-5 px-1">
                  <div className="self-stretch h-0 relative ring-1 ring-white"></div>
                  <div className="w-full flex flex-col justify-start items-start gap-3 sm:gap-5">
                    {usecase.features.map((feature, idx) => (
                      <div
                        key={idx}
                        className="h-5 inline-flex justify-start items-center gap-3"
                      >
                        <div className="w-5 h-5 relative overflow-hidden flex items-center justify-center">
                          <div className="w-[17.50px] h-[17.50px] rounded-full border border-white/80 flex items-center justify-center bg-transparent">
                            <svg
                              width="8"
                              height="6"
                              viewBox="0 0 8 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7 1L3 5L1 3"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="relative justify-start text-white/80 text-xs sm:text-sm font-medium font-['Poppins']">
                          {feature}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Usecase;
