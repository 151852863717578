import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleSignInButton from "../components/GoogleSigninbutton";
import { redirectIfAuthenticated } from "../components/axiosInstance";
import logo from "../assets/pragya.svg";
import { toast } from "react-toastify";
import signupbg from "../assets/signupbg.jpg";
import {
  FiEye,
  FiEyeOff,
  FiHome,
  FiMail,
  FiLock,
  FiUser,
} from "react-icons/fi";

const SignUp = () => {
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
  });
  const API_URL = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  // Check authentication on component mount
  useEffect(() => {
    redirectIfAuthenticated(navigate, "/signup");
  }, [navigate]);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    fetch(`${API_URL}/auth/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          toast.success("User registered successfully");
          fetch(`${API_URL}/auth/request-verify-token`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: formData.email }),
          })
            .then((verifyResponse) => verifyResponse.json())
            .then((verifyData) => {
              toast.success("Verification email sent");
              navigate("/verify-email", { state: { email: formData.email } });
            })
            .catch((verifyError) =>
              console.error("Verification error:", verifyError),
            );
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div
      className="relative flex items-center justify-between min-h-screen w-full bg-cover bg-center overflow-x-hidden"
      style={{
        backgroundImage: `url(${signupbg})`,
        backgroundSize: "100% 100%",
      }}
    >
      {/* Home Button */}
      <button
        onClick={() => navigate("/")}
        className="absolute top-12 left-4 md:left-32 flex items-center space-x-2 p-2 border-2 border-white rounded-lg text-white hover:bg-white hover:text-black transition-all duration-300 ease-in-out"
      >
        <FiHome className="text-xl" />
        <span className="text-sm font-semibold">Home</span>
      </button>

      {/* Left Side Text */}
      <div className="hidden lg:block w-[492px] ml-8 md:ml-32 text-white text-[42px] font-light font-['Inter'] leading-[54.60px] self-start mt-32">
        Start Building Your AI Assistant Today—No Code, No Hassle!
      </div>

      {/* Sign-Up Box */}
      <div className="bg-white rounded-2xl shadow-xl p-4 sm:p-6 md:p-12 w-[95%] sm:w-[90%] md:w-[476px] min-h-[200px] lg:min-h-[200px] my-4 sm:my-8 flex-shrink-0 mx-auto lg:mr-4 relative">
        {/* Logo */}
        <div className="flex justify-start mb-2 sm:mb-4">
          <img src={logo} alt="Prgya.ai" className="h-7 sm:h-9 mb-4 sm:mb-9" />
        </div>

        {/* Sign Up Heading */}
        <div className="text-black/90 text-[28px] sm:text-[32px] font-semibold font-['Inter'] leading-[33.60px] mb-3 sm:mb-4">
          Sign Up
        </div>

        {/* Sign Up Form */}
        <form className="space-y-3 sm:space-y-4" onSubmit={handleFormSubmit}>
          {/* First Name */}
          <div className="relative">
            <label
              className="block text-gray-600 mb-1 text-sm sm:text-base"
              htmlFor="first_name"
            >
              First Name
            </label>
            <div className="flex items-center border border-gray-300 rounded-full px-3 sm:px-4 py-1">
              <FiUser className="text-gray-500" />
              <input
                id="first_name"
                type="text"
                placeholder="Enter your first name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
                minLength="3"
                className="w-full px-2 py-1.5 sm:py-2 outline-none rounded-full border-none text-sm sm:text-base"
              />
            </div>
          </div>

          {/* Last Name */}
          <div className="relative">
            <label
              className="block text-gray-600 mb-1 text-sm sm:text-base"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <div className="flex items-center border border-gray-300 rounded-full px-3 sm:px-4 py-1">
              <FiUser className="text-gray-500" />
              <input
                id="last_name"
                type="text"
                placeholder="Enter your last name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
                minLength="3"
                className="w-full px-2 py-1.5 sm:py-2 outline-none rounded-full border-none text-sm sm:text-base"
              />
            </div>
          </div>

          {/* Email */}
          <div className="relative">
            <label
              className="block text-gray-600 mb-1 text-sm sm:text-base"
              htmlFor="email"
            >
              Email
            </label>
            <div className="flex items-center border border-gray-300 rounded-full px-3 sm:px-4 py-1">
              <FiMail className="text-gray-500" />
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                title="Please enter a valid email address."
                className="w-full px-2 py-1.5 sm:py-2 outline-none rounded-full border-none text-sm sm:text-base"
              />
            </div>
          </div>

          {/* Password */}
          <div className="relative">
            <label
              className="block text-gray-600 mb-1 text-sm sm:text-base"
              htmlFor="password"
            >
              Password
            </label>
            <div className="flex items-center border border-gray-300 rounded-full px-3 sm:px-4 py-1">
              <FiLock className="text-gray-500" />
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
                title="Password must be at least 6 characters long."
                minLength="6"
                className="w-full px-2 py-1.5 sm:py-2 outline-none rounded-full border-none text-sm sm:text-base"
              />
              <button
                type="button"
                className="text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
          </div>

          {/* Sign Up Button */}
          <button
            type="submit"
            className="w-full bg-[#1677FF] text-white font-bold py-1.5 sm:py-2 rounded-full hover:bg-blue-700 transition text-sm sm:text-base"
          >
            Sign Up
          </button>
        </form>

        {/* Divider */}
        <div className="flex items-center my-2 sm:my-3">
          <hr className="flex-grow border-t border-gray-300" />
          <span className="mx-3 sm:mx-4 text-gray-500 text-sm sm:text-base">
            or
          </span>
          <hr className="flex-grow border-t border-gray-300" />
        </div>

        {/* Google Sign In */}
        <div className="mt-2 sm:mt-3 rounded-full">
          <GoogleSignInButton className="bg-transparent" />
        </div>

        {/* Sign In Link */}
        <p className="mt-3 sm:mt-4 mb-2 sm:mb-3 text-center text-gray-600 text-sm sm:text-base">
          Already have an account?{" "}
          <a
            href="/signin"
            className="text-blue-600 font-semibold hover:underline"
          >
            Sign In
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
