import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/sidebarSlice";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { BsFilesAlt, BsChatLeftDots } from "react-icons/bs";
import { GoDatabase } from "react-icons/go";
import { ReactComponent as ProjectMembersIcon } from "../../assets/projectMembersIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";

const CreatorsSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar());
  }, [dispatch]);

  const sidebarItems = [
    {
      id: "agents",
      label: "Agents",
      icon: <BsChatLeftDots size={20} className="ml-1 text-white" />,
      path: "/creator/agents",
    },
    {
      id: "files",
      label: "Files",
      icon: <BsFilesAlt size={20} className="ml-1 text-white" />,
      path: "/creator/files",
    },
    {
      id: "knowledgeBases",
      label: "Knowledge Bases",
      icon: <GoDatabase size={20} className="ml-1 text-white" />,
      path: "/creator/knowledge-bases",
    },
    {
      id: "projectMembers",
      label: "Project Members",
      icon: (
        <ProjectMembersIcon width={30} height={20} className="text-white" />
      ),
      path: "/creator/project-members",
    },
  ];

  const isActive = useCallback(
    (path) => location.pathname === path,
    [location.pathname],
  );

  const handleNavigation = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate],
  );

  return (
    <div
      className={`h-[98%] flex flex-col rounded-lg border-[0.5px] border-[rgba(205,205,205,0.4)] bg-[#2B2B2B] text-white ml-3 ${
        isSidebarOpen ? "w-[250px]" : "w-[80px]"
      }`}
    >
      <ul
        className={`mt-6 space-y-3 flex-1 ${
          isSidebarOpen ? "w-full px-4" : "w-full px-2"
        } overflow-y-auto scrollbar-hide`}
      >
        {sidebarItems.map((item) => (
          <li key={item.id}>
            <button
              onClick={() => handleNavigation(item.path)}
              className={`flex items-center space-x-3 p-3 rounded-lg w-full text-left ${
                isActive(item.path)
                  ? "bg-gradient-to-r from-[rgba(215,236,255,0.4)] to-[rgba(177,177,177,0.4)] border border-[#CDCDCD] text-white"
                  : "hover:bg-[#3D3D3D] text-white"
              }`}
            >
              {item.icon}
              {isSidebarOpen && <span>{item.label}</span>}
            </button>
          </li>
        ))}
      </ul>

      <div className="flex justify-end p-2 border-t border-[rgba(205,205,205,0.4)]">
        <button
          onClick={handleToggleSidebar}
          className="text-white hover:text-gray-300 p-1"
        >
          {isSidebarOpen ? (
            <FaAngleDoubleLeft size={20} className="ml-2" />
          ) : (
            <FaAngleDoubleRight size={20} className="mr-5" />
          )}
        </button>
      </div>
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default React.memo(CreatorsSidebar);
