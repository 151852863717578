import React from "react";
import ReactMarkdown from "react-markdown";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FiCheck, FiCopy } from "react-icons/fi";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

const customRenderers = {
  code({
    node,
    inline,
    className,
    children,
    setCopiedCode,
    copiedCode,
    ...props
  }) {
    const match = /language-(\w+)/.exec(className || "");
    const language = match ? match[1] : "";

    if (!inline && language) {
      const codeString = String(children).replace(/\n$/, "");
      const codeId = `${language}-${codeString.slice(0, 20)}`;

      const handleCopy = async () => {
        await navigator.clipboard.writeText(codeString);
        setCopiedCode(codeId);
        setTimeout(() => setCopiedCode(null), 2000);
      };

      return (
        <div className="relative group bg-[#1e1e1e] rounded-md overflow-hidden">
          <div className="flex justify-between items-center px-4 py-2 border-b border-gray-700 bg-[#1e1e1e]">
            <span className="text-xs text-gray-400 font-mono">{language}</span>
            <button
              onClick={handleCopy}
              className="text-gray-400 hover:text-white transition-colors px-2 py-1"
              title={copiedCode === codeId ? "Copied!" : "Copy code"}
            >
              {copiedCode === codeId ? (
                <FiCheck className="w-4 h-4 text-green-500" />
              ) : (
                <FiCopy className="w-4 h-4" />
              )}
            </button>
          </div>
          <SyntaxHighlighter
            style={vscDarkPlus}
            language={language}
            PreTag="div"
            customStyle={{
              margin: 0,
              padding: "1rem",
              background: "#000",
            }}
            {...props}
          >
            {codeString}
          </SyntaxHighlighter>
        </div>
      );
    }
    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

const parseTableData = (content) => {
  const lines = content.trim().split("\n");

  // Get headers and clean them up (remove asterisks)
  const headers = lines[0]
    .split("|")
    .filter((cell) => cell.trim())
    .map((cell) => cell.trim().replace(/^\*\*|\*\*$/g, "")); // Remove ** from start and end

  // Skip the separator line and get all data rows
  const rows = lines.slice(2).map(
    (line) =>
      line
        .split("|")
        .filter((cell) => cell.trim())
        .map((cell) => cell.trim().replace(/^\*\*|\*\*$/g, "")), // Remove ** from all cells
  );

  return { headers, rows };
};

const TableRenderer = ({ content }) => {
  const { headers, rows } = parseTableData(content);

  return (
    <div className="my-4">
      <table className="w-full border-collapse bg-[#1D1E20] rounded-lg overflow-hidden">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="border border-[#333] bg-[#252526] text-gray-300 px-4 py-2 text-left font-medium"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className="border border-[#333] text-gray-300 px-4 py-2"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const parseContent = (content) => {
  const segments = [];
  const lines = content.split("\n");
  let currentSegment = "";
  let isInTable = false;
  let isInCode = false;
  let codeBlock = "";
  let tableContent = "";
  let previousLine = "";

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const nextLine = lines[i + 1];

    // Check for code block start/end
    if (line.startsWith("```")) {
      if (isInCode) {
        // End of code block
        if (currentSegment) {
          segments.push({ type: "text", content: currentSegment.trim() });
          currentSegment = "";
        }
        codeBlock += line + "\n";
        segments.push({ type: "code", content: codeBlock });
        codeBlock = "";
        isInCode = false;
        continue;
      } else {
        // Start of code block
        if (currentSegment) {
          segments.push({ type: "text", content: currentSegment.trim() });
          currentSegment = "";
        }
        codeBlock = line + "\n";
        isInCode = true;
        continue;
      }
    }

    if (isInCode) {
      codeBlock += line + "\n";
      continue;
    }

    // Check for table start
    if (
      (line.includes("|") && nextLine && nextLine.includes("|-")) ||
      (line.includes("|-") && previousLine && previousLine.includes("|"))
    ) {
      if (currentSegment) {
        segments.push({ type: "text", content: currentSegment.trim() });
        currentSegment = "";
      }
      if (!isInTable) {
        isInTable = true;
        // If this is the separator row, include the previous line as header
        if (line.includes("|-")) {
          tableContent = previousLine + "\n" + line + "\n";
        } else {
          tableContent = line + "\n";
        }
      }
      continue;
    }

    // Continue collecting table content
    if (isInTable) {
      if (line.includes("|")) {
        tableContent += line + "\n";
      } else {
        // End of table
        segments.push({ type: "table", content: tableContent.trim() });
        tableContent = "";
        isInTable = false;
        currentSegment = line + "\n";
      }
      continue;
    }

    // Regular text
    currentSegment += line + "\n";
    previousLine = line;
  }

  // Handle any remaining content
  if (isInTable && tableContent) {
    segments.push({ type: "table", content: tableContent.trim() });
  } else if (isInCode && codeBlock) {
    segments.push({ type: "code", content: codeBlock.trim() });
  } else if (currentSegment) {
    segments.push({ type: "text", content: currentSegment.trim() });
  }

  return segments;
};

const MessageContent = ({ content, setCopiedCode, copiedCode }) => {
  const segments = parseContent(content);

  return (
    <div className="space-y-4">
      {segments.map((segment, index) => {
        switch (segment.type) {
          case "table":
            return (
              <TableRenderer key={`table-${index}`} content={segment.content} />
            );
          case "code":
            return (
              <ReactMarkdown
                key={`code-${index}`}
                components={{
                  ...customRenderers,
                  code: (props) =>
                    customRenderers.code({
                      ...props,
                      setCopiedCode,
                      copiedCode,
                    }),
                }}
              >
                {segment.content}
              </ReactMarkdown>
            );
          case "text":
            return (
              <ReactMarkdown
                key={`text-${index}`}
                components={{
                  ...customRenderers,
                  code: (props) =>
                    customRenderers.code({
                      ...props,
                      setCopiedCode,
                      copiedCode,
                    }),
                }}
              >
                {segment.content}
              </ReactMarkdown>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default MessageContent;
