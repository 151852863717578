/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./AgentChatInterface.css";
import Layout from "../../components/Layout";
import { Box, Typography } from "@mui/material";
import PublishButtonIcon from "../../assets/AgentChatAssets/publisg-button-icon.svg";
import NewChatButtonIcon from "../../assets/AgentChatAssets/new-chat-button-icon.svg";
import LeftArrowIcon from "../../assets/AgentChatAssets/left-back-icon.svg";
import ChatHistoryIcon from "../../assets/AgentChatAssets/chat-history-button-icon.svg";
import AgentIcon from "../../assets/HomepageAssests/agent-icon.svg";
import PragyaLogo from "../../assets/AgentChatAssets/pragya-logo.svg";
import { FiPaperclip, FiSend } from "react-icons/fi";
import { FaStopCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../components/axiosInstance";
import { toast } from "react-toastify";
import { BsStars } from "react-icons/bs";
import MessageContent from "../../components/Chat/MessageContent";
import MessageFileReference from "../../components/Chat/MessageFileReference";
import AgentChatHistoryModal from "../../components/HomePage/AgentChatHistoryModal/AgentChatHistoryModal";
import { useFlags } from "launchdarkly-react-client-sdk";

const AgentChatInterface = () => {
  const { agentId } = useParams();
  const navigate = useNavigate();
  const { selectedOrgId = "" } = useSelector(
    (state) => state?.organization || {},
  );
  const [startedConversation, setStartedConversation] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [toggleState, setToggleState] = useState("no");
  const [conversationMessages, setConversationMessages] = useState({});
  const [activeSocket, setActiveSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const WEBSOCKET_API_URL = process.env.REACT_APP_WEBSOCKET_URL;
  const user_id = localStorage.getItem("user_id");
  const messagesEndRef = useRef(null);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [editingConversationId, setEditingConversationId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const { marketplaceFlag } = useFlags();
  // Auto-scroll to the bottom when messages or loading state changes
  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    };

    // Scroll on new messages or when streaming
    scrollToBottom();

    // Also set up an interval during streaming to ensure smooth scrolling
    let scrollInterval;
    if (isStreaming) {
      scrollInterval = setInterval(scrollToBottom, 100);
    }

    // Cleanup interval
    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [messages, isStreaming, loading]);

  // Fetch conversations for a specific assistant
  const fetchConversations = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `/v1/usersV2/chat/conversations/${agentId}`,
      );
      setConversations(response.data);
    } catch (error) {
      toast.error("Error fetching conversations:", error);
    }
  }, [agentId]);

  const handleToggle = () => {
    setToggleState((prevState) => (prevState === "no" ? "yes" : "no"));
  };
  // Fetch conversations for the assistant on component load
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchConversations();

        // Get the conversations data directly from the API response
        const response = await axiosInstance.get(
          `/v1/usersV2/chat/conversations/${agentId}`,
        );
        const conversationsData = response.data;

        // If there are existing conversations, select the most recent one
        if (conversationsData && conversationsData.length > 0) {
          // Sort conversations by updated_at to get the most recent one
          const sortedConversations = [...conversationsData].sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
          );
          const mostRecentConversation = sortedConversations[0];

          // Select the most recent conversation and fetch its messages
          setSelectedConversationId(mostRecentConversation.id);
          setStartedConversation(true);
          handleConversationSelect(mostRecentConversation);
        }
      } catch (error) {
        console.error("Error in initial data fetch:", error);
        toast.error("Error loading conversations");
      }
    };

    fetchData();
  }, [agentId, user_id, fetchConversations]);

  const handleConversationSelect = async (conversation) => {
    setStartedConversation(true);
    setSelectedConversationId(conversation.id);
    setIsLoadingMessages(true);
    try {
      const response = await axiosInstance.get(
        `/v1/conversations/${conversation.id}/messages`,
      );
      // Store messages in the conversationMessages object
      setConversationMessages((prev) => ({
        ...prev,
        [conversation.id]: response.data,
      }));
      // Set current messages for display
      setMessages(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingMessages(false);
    }
  };

  const updateConversationTimestamp = async (conversationId) => {
    try {
      await axiosInstance.patch(
        `/v1/usersV2/chat/conversations/${conversationId}/update-timestamp`,
        {
          updated_at: new Date().toISOString(),
        },
      );
    } catch (error) {
      console.error("Error updating conversation timestamp:", error);
    }
  };

  const handleCreateConversation = async () => {
    try {
      const response = await axiosInstance.post(
        `/v1/usersV2/chat/conversations?agent_id=${agentId}`,
        {
          title: `Conversation ${conversations.length + 1}`,
        },
      );

      // Create new conversation object with current timestamp
      const newConversation = {
        id: response?.data?.conversation_id,
        title: response?.data?.title || "New chat",
        updated_at: new Date().toISOString(), // Add current timestamp
      };

      // Add new conversation at the beginning of the array
      setConversations((prev) => [newConversation, ...prev]);

      setSelectedConversationId(response?.data?.conversation_id);
      setStartedConversation(true);
      setMessages([]);
      return response?.data?.conversation_id;
    } catch (error) {
      toast.error("Error creating conversation");
    }
  };

  const handleSendandcreateMessage = async () => {
    setStartedConversation(true);
    try {
      const messageToSend = newMessage;
      setNewMessage("");

      // Wait for the conversation to be created
      const id = await handleCreateConversation();

      // Now send the first message with the stored message
      if (id) {
        // Update conversations list with the new timestamp
        const userMessage = { role: "user", content: messageToSend };
        // Store the conversation ID for use in socket handler
        const currentConversationId = id;
        // Set messages specifically for this new conversation
        setConversationMessages((prev) => ({
          ...prev,
          [id]: [userMessage],
        }));

        setMessages([userMessage]);
        console.log("before socket");

        // Send the message
        const socket = new WebSocket(
          `${WEBSOCKET_API_URL}/v1/agents/${agentId}/conversations/${id}/stream/?user_id=${user_id}&org_id=${selectedOrgId}&contextual=${toggleState}`,
        );
        console.log("after socket");
        setActiveSocket(socket);

        let currentBotMessage = "";

        socket.onopen = () => {
          socket.send(messageToSend);
        };

        setLoading(true);

        // Rest of your socket handling code...
        socket.onmessage = (event) => {
          setLoading(false);
          const partialResponse = event.data;

          if (partialResponse === "[DONE]") {
            socket.close();
            setActiveSocket(null);
            updateConversationTimestamp(currentConversationId);
            setConversations((prevConversations) => {
              return prevConversations.map((conv) => {
                if (conv.id === currentConversationId) {
                  return { ...conv, updated_at: new Date().toISOString() };
                }
                return conv;
              });
            });
          } else {
            currentBotMessage += partialResponse;
            // Only update messages for this specific conversation
            setConversationMessages((prev) => {
              const conversationMsgs = [...(prev[id] || [])];
              const lastMessage = conversationMsgs[conversationMsgs.length - 1];

              if (lastMessage && lastMessage.role === "bot") {
                conversationMsgs[conversationMsgs.length - 1] = {
                  ...lastMessage,
                  content: currentBotMessage,
                };
              } else {
                conversationMsgs.push({
                  role: "bot",
                  content: currentBotMessage,
                });
              }

              return {
                ...prev,
                [id]: conversationMsgs,
              };
            });

            // Update current display since we're in the new conversation
            setMessages((prev) => {
              const lastMessage = prev[prev.length - 1];
              if (lastMessage && lastMessage.role === "bot") {
                const updatedMessages = [...prev];
                updatedMessages[updatedMessages.length - 1] = {
                  ...lastMessage,
                  content: currentBotMessage,
                };
                return updatedMessages;
              } else {
                return [...prev, { role: "bot", content: currentBotMessage }];
              }
            });
          }
        };
      }
    } catch (error) {
      toast.error("Error creating conversation");
      setMessages([]); // Reset messages on error
    }
  };

  const handleSendMessage = () => {
    if (!newMessage || !selectedConversationId) return;
    const userMessage = { role: "user", content: newMessage };
    const currentConversationId = selectedConversationId;

    // Update messages for the current conversation
    setConversationMessages((prev) => ({
      ...prev,
      [currentConversationId]: [
        ...(prev[currentConversationId] || []),
        userMessage,
      ],
    }));

    // Update current display if we're still on the same conversation
    setMessages((prev) => [...prev, userMessage]);
    setNewMessage("");
    console.log("before socket");

    const socket = new WebSocket(
      `${WEBSOCKET_API_URL}/v1/agents/${agentId}/conversations/${currentConversationId}/stream/?user_id=${user_id}&org_id=${selectedOrgId}&contextual=${toggleState}`,
    );
    console.log("after socket");
    let currentBotMessage = "";

    setActiveSocket(socket);
    setIsStreaming(true); // Set streaming state to true

    socket.onopen = () => {
      socket.send(newMessage);
    };
    setLoading(true);

    socket.onmessage = (event) => {
      setLoading(false);
      const partialResponse = event.data;

      if (partialResponse === "[DONE]") {
        socket.close();
        setActiveSocket(null);
        setIsStreaming(false);
        updateConversationTimestamp(currentConversationId);
        setConversations((prevConversations) => {
          return prevConversations.map((conv) => {
            if (conv.id === currentConversationId) {
              return { ...conv, updated_at: new Date().toISOString() };
            }
            return conv;
          });
        });
      } else {
        currentBotMessage += partialResponse;

        // Update messages for the specific conversation
        setConversationMessages((prev) => {
          const conversationMsgs = [...(prev[currentConversationId] || [])];
          const lastMessage = conversationMsgs[conversationMsgs.length - 1];

          if (lastMessage && lastMessage.role === "bot") {
            conversationMsgs[conversationMsgs.length - 1] = {
              ...lastMessage,
              content: currentBotMessage,
            };
          } else {
            conversationMsgs.push({ role: "bot", content: currentBotMessage });
          }

          return {
            ...prev,
            [currentConversationId]: conversationMsgs,
          };
        });

        // Only update current display if we're still on the same conversation
        if (selectedConversationId === currentConversationId) {
          setMessages((prev) => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage && lastMessage.role === "bot") {
              const updatedMessages = [...prev];
              updatedMessages[updatedMessages.length - 1] = {
                ...lastMessage,
                content: currentBotMessage,
              };
              return updatedMessages;
            } else {
              return [...prev, { role: "bot", content: currentBotMessage }];
            }
          });
        }
      }
    };

    socket.onerror = (error) => {
      setLoading(false);
      setActiveSocket(null);
      if (error.code === 4000) {
        toast.error("Token limit exceeded", error.reason);
      }
    };

    socket.onclose = (event) => {
      setLoading(false);
      setActiveSocket(null);
      setIsStreaming(false);
      if (event.code === 4000) {
        toast.error(event.reason);
      } else if (event.code === 4001) {
        toast.error("An unexpected error occurred.");
      } else {
        console.log(
          "WebSocket closed with code:",
          event.code,
          "reason:",
          event.reason,
        );
      }
    };
  };

  // Add cleanup on component unmount
  useEffect(() => {
    return () => {
      if (activeSocket) {
        activeSocket.close();
      }
    };
  }, [activeSocket]);

  const handleStopStream = () => {
    console.log("Stopping stream");
  };

  const handleRenameConversation = (conversationId) => {
    // Find the current conversation to get its title
    const conversation = conversations.find(
      (conv) => conv.id === conversationId,
    );
    setEditingConversationId(conversationId);
    setEditedTitle(conversation?.title || ""); // Initialize with current title or empty string
  };

  const handleDeleteConversation = async (conversationId) => {
    try {
      await axiosInstance.delete(
        `/v1/usersV2/chat/conversations/${agentId}/${conversationId}`,
      );
      setConversations(
        conversations.filter((conv) => conv.id !== conversationId),
      );
      if (selectedConversationId === conversationId) {
        setSelectedConversationId(null);
        setMessages([]);
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  const handleUpdateConversation = async (conversationId, newTitle) => {
    try {
      // Find the current conversation
      const currentConversation = conversations.find(
        (conv) => conv.id === conversationId,
      );

      // If no change in title, just cancel editing
      if (currentConversation?.title === newTitle) {
        setEditingConversationId(null);
        return;
      }

      await axiosInstance.patch(
        `/v1/usersV2/chat/conversations/${conversationId}/title`,
        {
          new_title: newTitle,
        },
      );

      setConversations((prev) =>
        prev.map((conv) =>
          conv.id === conversationId ? { ...conv, title: newTitle } : conv,
        ),
      );
    } catch (error) {
      console.error(
        "Error updating conversation title:",
        error.response?.data || error.message,
      );
      // Reset to original title on error
      const conversation = conversations.find(
        (conv) => conv.id === conversationId,
      );
      setEditedTitle(conversation?.title || "");
    } finally {
      setEditingConversationId(null);
    }
  };

  const handleBlurOrSubmit = (conversationId) => {
    // Add null check and provide default empty string
    const titleToUpdate = editedTitle || "";

    if (titleToUpdate.trim()) {
      handleUpdateConversation(conversationId, titleToUpdate.trim());
    } else {
      // If empty title, just cancel editing
      setEditingConversationId(null);
      // Reset to original title
      const conversation = conversations.find(
        (conv) => conv.id === conversationId,
      );
      setEditedTitle(conversation?.title || "");
    }
  };

  const processMessageContent = (content) => {
    const fileReferences = [];
    let cleanContent = content;
    // Extract file references from the content
    const matches = content.match(/File:.*?\.pdf/g) || [];
    if (matches.length > 0) {
      // Remove everything from the first "File:" to the end of the content
      cleanContent = content.split("File:")[0].trim();
      matches.forEach((match) => {
        fileReferences.push(match);
      });
    }

    return {
      content: cleanContent,
      references: fileReferences,
    };
  };

  return (
    <Layout title="Pragya | Agent Chat Interface">
      <Box className="h-[calc(100vh-110px)] overflow-auto relative bg-[#1C1C1C] mt-4 flex flex-col rounded-lg border border-[#2B2B2B]">
        <Box className="flex justify-between items-center py-[8px] px-[20px] bg-[#1C1C1C] border-b-2 border-[#2B2B2B]">
          <Box className="flex items-center gap-4">
            <Box className="left-back-icon" onClick={() => navigate(-1)}>
              <img src={LeftArrowIcon} alt="Left Arrow" />
            </Box>
            <img src={AgentIcon} alt="Agent Icon" className="w-10 h-10" />
            <Box className="flex flex-col gap-1">
              <Typography variant="body1" className="agent-name-title">
                Teacher Bot
              </Typography>
              <Typography variant="body2" className="agent-name-subtitle">
                Klass online / My Agents / Teacher Bot
              </Typography>
            </Box>
          </Box>
          <Box className="flex items-center gap-4">
            <Box
              className="left-back-icon"
              onClick={() => {
                handleCreateConversation();
              }}
            >
              <img src={NewChatButtonIcon} alt="New Chat Button" />
            </Box>
            {marketplaceFlag && (
              <Box className="left-back-icon">
                <img src={PublishButtonIcon} alt="Publish Button" />
              </Box>
            )}
            <Box
              className="left-back-icon"
              onClick={() => setShowChatHistory(true)}
            >
              <img src={ChatHistoryIcon} alt="Chat History Button" />
            </Box>
          </Box>
        </Box>
        {!startedConversation ? (
          <Box className="flex flex-col items-center justify-center h-full gap-4">
            <img src={PragyaLogo} alt="Pragya Logo" />
            <Box className="flex flex-col items-center justify-center gap-3">
              <Typography variant="body1" className="help-text">
                Can I help you with anything?
              </Typography>
              <Typography variant="body2" className="help-description">
                Here to help with any questions or recommendations. Let's get
                started!
              </Typography>
            </Box>
            <Box className="flex flex-col bg-[#111] rounded-xl w-[75%] min-h-[100px] max-h-[450px]">
              <Box className="flex min-h-[40px] max-h-[450px]">
                <textarea
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (newMessage.trim()) {
                        handleSendandcreateMessage();
                      }
                    }
                  }}
                  placeholder="Type your message"
                  className="px-4 py-3 bg-[#111] text-gray-300 rounded-lg border-none outline-none w-[100%] h-auto resize-none overflow-y-auto flex-grow"
                  style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                />
              </Box>

              {/* Icons (Attachment & Send) */}
              <Box className="flex justify-end items-center space-x-2 mt-2 mr-4 mb-2 h-[40px]">
                <button
                  onClick={handleToggle}
                  className={`p-2 rounded-full w-10 h-6 flex items-center justify-center transition-colors duration-200 ${
                    toggleState === "yes" ? "bg-green-500" : "bg-gray-300"
                  }`}
                >
                  <div
                    className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                      toggleState === "yes"
                        ? "transform translate-x-2"
                        : "transform -translate-x-2"
                    }`}
                  />
                </button>
                {/* Attachment Icon */}
                {marketplaceFlag && (
                  <button className="p-2 text-gray-400 hover:text-white">
                    <FiPaperclip size={18} />
                  </button>
                )}

                {/* Send Button */}
                <button
                  onClick={
                    isStreaming
                      ? handleStopStream
                      : () => {
                          if (newMessage.trim()) {
                            handleSendandcreateMessage();
                          }
                        }
                  }
                  disabled={!isStreaming && !newMessage.trim()}
                  className={`p-2 ${
                    isStreaming
                      ? "bg-gray-600 hover:bg-gray-700 cursor-pointer"
                      : newMessage.trim()
                        ? "bg-blue-500 hover:bg-blue-500 cursor-pointer"
                        : "hover:bg-gray-500 cursor-not-allowed"
                  } text-white rounded-lg transition-colors duration-200 flex items-center`}
                  title={isStreaming ? "Stop generating" : "Send message"}
                >
                  {isStreaming ? (
                    <FaStopCircle
                      size={20}
                      color="gray"
                      backgroundColor="white"
                    />
                  ) : (
                    <FiSend size={20} />
                  )}
                </button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="p-6 flex-1 overflow-y-auto scrollbar-hide">
            {isLoadingMessages ? (
              <p>Loading messages...</p>
            ) : messages.length > 0 ? (
              <Box className="flex flex-col gap-4 w-[70%] mx-auto">
                {(conversationMessages[selectedConversationId] || messages).map(
                  (message, index) => (
                    <Box
                      key={index}
                      className={`flex ${message.role === "user" ? "justify-end" : "justify-start"}`}
                    >
                      <Box
                        className={`flex max-w-[95%] ${message.role === "user" ? "flex-row-reverse" : "flex-row"}`}
                      >
                        <Box
                          className={`flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center ${
                            message.role === "user"
                              ? "bg-gray-300 ml-4"
                              : "bg-[#19C37D] mr-4"
                          }`}
                        >
                          {message.role === "user" ? (
                            <span className="text-black font-medium">U</span>
                          ) : (
                            <BsStars className="text-white text-sm" />
                          )}
                        </Box>
                        <Box className={`flex flex-col space-y-2`}>
                          <Box
                            className={`prose prose-invert max-w-none text-white`}
                          >
                            <MessageContent
                              content={
                                processMessageContent(message.content).content
                              }
                              setCopiedCode={setCopiedCode}
                              copiedCode={copiedCode}
                            />
                            <MessageFileReference message={message} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ),
                )}
                {loading && (
                  <div className="flex justify-start">
                    <div className="flex max-w-[80%] flex-row">
                      <div className="flex-shrink-0 h-8 w-8 rounded-full bg-[#19C37D] mr-4 flex items-center justify-center">
                        <BsStars className="text-white text-sm" />
                      </div>
                      <div className="flex items-center">
                        <div className="flex space-x-2">
                          <span
                            className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                            style={{ animationDelay: "0s" }}
                          ></span>
                          <span
                            className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                            style={{ animationDelay: "0.2s" }}
                          ></span>
                          <span
                            className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                            style={{ animationDelay: "0.4s" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef} style={{ height: "0px" }} />
              </Box>
            ) : (
              <>
                <Box className="flex flex-col items-center justify-center h-full gap-4">
                  <img src={PragyaLogo} alt="Pragya Logo" />
                  <Box className="flex flex-col items-center justify-center gap-3">
                    <Typography variant="body1" className="help-text">
                      Can I help you with anything?
                    </Typography>
                    <Typography variant="body2" className="help-description">
                      Here to help with any questions or recommendations. Let's
                      get started!
                    </Typography>
                  </Box>
                  <Box className="flex flex-col bg-[#111] rounded-xl w-[75%] min-h-[100px] max-h-[450px]">
                    <Box className="flex min-h-[40px] max-h-[450px]">
                      <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSendMessage();
                          }
                        }}
                        placeholder="Type your message"
                        className="px-4 py-3 bg-[#111] text-gray-300 rounded-lg border-none outline-none w-[100%] h-auto resize-none overflow-y-auto flex-grow"
                        style={{
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                      />
                    </Box>

                    <Box className="flex justify-end items-center space-x-2 mt-2 mr-4 mb-2 h-[40px]">
                      <button
                        onClick={handleToggle}
                        className={`p-2 rounded-full w-10 h-6 flex items-center justify-center transition-colors duration-200 ${
                          toggleState === "yes" ? "bg-green-500" : "bg-gray-300"
                        }`}
                      >
                        <div
                          className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                            toggleState === "yes"
                              ? "transform translate-x-2"
                              : "transform -translate-x-2"
                          }`}
                        />
                      </button>
                      {marketplaceFlag && (
                        <button className="p-2 text-gray-400 hover:text-white">
                          <FiPaperclip size={18} />
                        </button>
                      )}

                      <button
                        onClick={
                          isStreaming
                            ? handleStopStream
                            : () => {
                                if (newMessage.trim()) {
                                  handleSendMessage();
                                }
                              }
                        }
                        disabled={!isStreaming && !newMessage.trim()}
                        className={`p-2 ${
                          isStreaming
                            ? "bg-gray-600 hover:bg-gray-700 cursor-pointer"
                            : newMessage.trim()
                              ? "bg-blue-500 hover:bg-blue-500 cursor-pointer"
                              : "hover:bg-gray-500 cursor-not-allowed"
                        } text-white rounded-lg transition-colors duration-200 flex items-center`}
                        title={isStreaming ? "Stop generating" : "Send message"}
                      >
                        {isStreaming ? (
                          <FaStopCircle
                            size={20}
                            color="gray"
                            backgroundColor="white"
                          />
                        ) : (
                          <FiSend size={20} />
                        )}
                      </button>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}
        {/* Input Field after the conversation has started */}
        {startedConversation && messages.length > 0 && (
          <Box className="border-t border-gray-700  pt-2">
            <Box className="flex flex-col bg-[#111] rounded-xl mb-[50px] w-[69%] mx-auto">
              <Box className="flex min-h-[40px] max-h-[450px]">
                <textarea
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  placeholder="Message..."
                  className="px-4 py-3 bg-[#111] text-gray-300 rounded-lg border-none outline-none w-[100%] h-auto resize-none overflow-y-auto flex-grow"
                  style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                />
                <Box className="flex justify-end items-center space-x-2 mt-4 mr-4">
                  <button
                    onClick={handleToggle}
                    className={`p-2 rounded-full w-10 h-6 flex items-center justify-center transition-colors duration-200 ${
                      toggleState === "yes" ? "bg-green-500" : "bg-gray-300"
                    }`}
                  >
                    <div
                      className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                        toggleState === "yes"
                          ? "transform translate-x-2"
                          : "transform -translate-x-2"
                      }`}
                    />
                  </button>
                  {marketplaceFlag && (
                    <button
                      className="p-2 text-gray-400 hover:text-white transition-colors"
                      title="Attach file"
                    >
                      <FiPaperclip size={20} />
                    </button>
                  )}
                  <button
                    onClick={isStreaming ? handleStopStream : handleSendMessage}
                    disabled={!isStreaming && !newMessage.trim()}
                    className={`p-2 ${
                      isStreaming
                        ? "bg-gray-600 hover:bg-gray-700 cursor-pointer"
                        : newMessage.trim()
                          ? "bg-blue-500 hover:bg-blue-500 cursor-pointer"
                          : "hover:bg-gray-500 cursor-not-allowed"
                    } text-white rounded-lg transition-colors duration-200 flex items-center`}
                    title={isStreaming ? "Stop generating" : "Send message"}
                  >
                    {isStreaming ? (
                      <FaStopCircle
                        size={20}
                        color="gray"
                        backgroundColor="white"
                      />
                    ) : (
                      <FiSend size={20} />
                    )}
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box className="absolute bottom-0 left-0 right-0 py-2 px-4 text-center bg-[#1C1C1C] border-t border-[#2B2B2B]">
          <Typography variant="caption" className="pragya-ai-mistake-text">
            Pragya AI can make mistakes. Check important info.
          </Typography>
        </Box>

        <AgentChatHistoryModal
          open={showChatHistory}
          onClose={() => setShowChatHistory(false)}
          conversations={conversations}
          selectedConversationId={selectedConversationId}
          handleConversationSelect={handleConversationSelect}
          handleDeleteConversation={handleDeleteConversation}
          handleCreateConversation={handleCreateConversation}
          handleRenameConversation={handleRenameConversation}
          editingConversationId={editingConversationId}
          editedTitle={editedTitle}
          setEditedTitle={setEditedTitle}
          handleBlurOrSubmit={handleBlurOrSubmit}
        />
      </Box>
    </Layout>
  );
};

export default AgentChatInterface;
