import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Function to refresh the token
export const getRefreshToken = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/refresh-token`, {
      withCredentials: true,
    });

    if (response.data && response.data.access_token) {
      localStorage.setItem("access_token", response.data.access_token);
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false;
  }
};
