import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleSignInButton from "../components/GoogleSigninbutton";
import axiosInstance, {
  redirectIfAuthenticated,
} from "../components/axiosInstance";
import logo from "../assets/pragya.svg"; // Updated logo path
import signupbg from "../assets/signupbg.jpg";
import { toast } from "react-toastify";
import { FiMail, FiLock, FiEye, FiEyeOff, FiHome } from "react-icons/fi"; // Added FiHome for home icon
import { useUser } from "../context/UserContext";

const SignIn = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const REACT_APP_FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
  const navigate = useNavigate();
  const { onLoginSuccess } = useUser();

  // Check authentication on component mount
  useEffect(() => {
    redirectIfAuthenticated(navigate, "/signin");
  }, [navigate]);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Login form submitted");

    const data = new FormData();
    data.append("username", formData.username);
    data.append("password", formData.password);

    try {
      console.log("Sending login request...");
      const response = await axiosInstance.post("/auth/jwt/login", data, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": `${REACT_APP_FRONTEND_URL}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Login response received");

      const { access_token } = response.data;
      const has_org = response.data.has_org;

      // Ensure the token is valid before saving
      if (
        !access_token ||
        typeof access_token !== "string" ||
        access_token.split(".").length !== 3
      ) {
        toast.error("Invalid token received from server");
        return;
      }

      console.log("Storing authentication data");
      // Clear any existing authentication data first
      localStorage.removeItem("access_token");
      localStorage.removeItem("isLoggedIn");

      // Set new authentication data
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("isLoggedIn", "true");

      // Call the onLoginSuccess function from UserContext to fetch user data and store userId
      console.log("Calling onLoginSuccess to fetch user data");
      await onLoginSuccess();

      // Increased delay to ensure all data (including organization users) is fetched and stored
      setTimeout(() => {
        // Navigate after we've set up everything
        console.log("Navigating to appropriate page");
        navigate(has_org ? "/home-page" : "/createorganization", {
          state: { email: formData.username },
          replace: true,
        });
      }, 500);
    } catch (error) {
      console.error("Login error:", error);

      if (error.response?.status === 399) {
        navigate("/unverified-email", { state: { email: formData.username } });
      } else if (error.response?.status === 401) {
        toast.error("Invalid email");
      } else if (error.response?.status === 402) {
        toast.error("Wrong Password");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div
      className="relative flex items-center justify-between min-h-screen w-full bg-cover bg-center overflow-x-hidden"
      style={{
        backgroundImage: `url(${signupbg})`,
        backgroundSize: "100% 100%", // Background image fits perfectly
      }}
    >
      <button
        data-testid="home-button"
        onClick={() => navigate("/")}
        className="absolute top-12 left-4 md:left-32 flex items-center space-x-2 p-2 border-2 border-white rounded-lg text-white hover:bg-white hover:text-black transition-all duration-300 ease-in-out"
      >
        <FiHome className="text-xl" />
        <span className="text-sm font-semibold">Home</span>
      </button>
      {/* Left Side Text */}
      <div className="hidden lg:block w-[492px] ml-8 md:ml-32 text-white text-[42px] font-light font-['Inter'] leading-[54.60px] self-start mt-32">
        Start Building Your AI Assistant Today—No Code, No Hassle!
      </div>

      {/* Sign-In Box - Positioned to the Right with Reduced Right Margin */}
      <div className="bg-white rounded-2xl shadow-xl p-4 sm:p-6 md:p-12 w-[95%] sm:w-[90%] md:w-[476px] min-h-[200px] lg:min-h-[200px] my-4 sm:my-8 flex-shrink-0 mx-auto lg:mr-4 relative">
        {/* Logo */}
        <div className="flex justify-start mb-2 sm:mb-4">
          <img src={logo} alt="Prgya.ai" className="h-7 sm:h-9 mb-4 sm:mb-9" />
        </div>

        {/* Sign In Heading */}
        <div className="text-black/90 text-[28px] sm:text-[32px] font-semibold font-['Inter'] leading-[33.60px] mb-3 sm:mb-4">
          Sign In
        </div>

        {/* Sign In Form */}
        <form className="space-y-3 sm:space-y-4" onSubmit={handleFormSubmit}>
          {/* Username Input */}
          <div className="relative">
            <label
              className="block text-gray-600 mb-1 text-sm sm:text-base"
              htmlFor="username"
            >
              Email
            </label>
            <div className="flex items-center border border-gray-300 rounded-full px-3 sm:px-4 py-1">
              <FiMail className="text-gray-500" />
              <input
                data-testid="email-input"
                id="username"
                type="text"
                placeholder="Enter your email address"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required
                className="w-full max-w-md px-2 py-1.5 sm:py-2 outline-none rounded-full border-none text-sm sm:text-base"
              />
            </div>
          </div>

          {/* Password Input */}
          <div className="relative">
            <label
              className="block text-gray-600 mb-1 text-sm sm:text-base"
              htmlFor="password"
            >
              Password
            </label>
            <div className="flex items-center border border-gray-300 rounded-full px-3 sm:px-4 py-1">
              <FiLock className="text-gray-500" />
              <input
                data-testid="password-input"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="8-10 digit password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
                className="w-full px-2 py-1.5 sm:py-2 outline-none rounded-full border-none text-sm sm:text-base"
              />
              <button
                type="button"
                className="text-gray-500"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
          </div>

          {/* Forgot Password */}
          <p className="text-right text-xs sm:text-sm text-blue-600 hover:underline">
            <a href="/forgot">Forgot Password?</a>
          </p>

          {/* Sign In Button */}
          <button
            data-testid="login-button"
            type="submit"
            className="w-full bg-[#1677FF] text-white font-bold py-1.5 sm:py-2 rounded-full hover:bg-blue-700 transition text-sm sm:text-base"
          >
            Sign In
          </button>
        </form>

        {/* Divider */}
        <div className="flex items-center my-2 sm:my-3">
          <hr className="flex-grow border-t border-gray-300" />
          <span className="mx-3 sm:mx-4 text-gray-500 text-sm sm:text-base">
            or
          </span>
          <hr className="flex-grow border-t border-gray-300" />
        </div>

        {/* Google Sign In */}
        <div className="mt-2 sm:mt-3 rounded-full">
          <GoogleSignInButton className="bg-transparent" />
        </div>

        {/* Sign Up Link */}
        <p className="mt-3 sm:mt-4 mb-2 sm:mb-3 text-center text-gray-600 text-sm sm:text-base">
          Don't have an account?{" "}
          <a href="/signup" className="text-blue-600 font-semibold">
            Sign Up
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
