import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
// Import assets properly
import computerSvg from "../assets/computer.svg";
import organizationSvg from "../assets/organization.svg";
import pencilIconSvg from "../assets/pencil_icon.svg";

const OrganizationSetup = () => {
  const [orgName, setOrgName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleContinue = async () => {
    if (!orgName.trim()) {
      setError("Organization name is required.");
      return;
    }
    setError("");

    try {
      const response = await axiosInstance.post("/v1/organizations/create", {
        name: orgName,
      });

      console.log("Response:", response);

      // Check for successful status (2xx) - Axios automatically throws errors for non-2xx statuses
      if (response.status >= 200 && response.status < 300) {
        navigate("/organizations");
      } else {
        // Handle unexpected success statuses if needed
        console.error("Unexpected successful status:", response.status);
        setError("An unexpected error occurred.");
      }
    } catch (error) {
      console.error("Error submitting organization:", error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(
          `Server Error: ${error.response.data.message || error.response.status}`,
        ); // Display server error message or status code
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setError("No response from server. Please check your network.");
        console.error("Request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("An error occurred while setting up the request.");
        console.error("Error message:", error.message);
      }
    }
  };

  return (
    <div
      className="relative flex px-10 items-center justify-center h-screen w-full bg-contain bg-center bg-no-repeat"
      style={{
        background: "linear-gradient(116deg, #000408 4.55%, #1677FF 90.42%)",
      }}
    >
      <div className="relative z-10 w-[670px] p-6 flex flex-col items-start gap-3 bg-white rounded-lg border border-solid border-[#CDCDCD] shadow-md">
        <h1 className="text-xl font-semibold text-black mb-2">
          Create Organization
        </h1>

        <div className="flex justify-center items-center w-full mb-6 relative">
          <div
            className="relative"
            style={{ width: "216.096px", height: "186.459px" }}
          >
            <img
              src={organizationSvg}
              alt="Organization"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0"
              style={{
                width: "216.096px",
                height: "186.459px",
                flexShrink: 0,
              }}
            />
            <img
              src={computerSvg}
              alt="Computer"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ml-2 mt-3 z-10"
              style={{
                width: "153.191px",
                height: "160px",
                flexShrink: 0,
                aspectRatio: "153.19/160.00",
              }}
            />
          </div>
        </div>

        <label className="text-sm text-gray-700 font-medium">
          Enter Organization Name
        </label>

        <div className="w-full relative">
          <img
            src={pencilIconSvg}
            alt="Edit"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 z-10"
          />

          <input
            type="text"
            placeholder="Enter Organization Name"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
            className="w-full pl-10 pr-4 py-3 rounded-md border border-[#CDCDCD]"
          />
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}

        <button
          onClick={handleContinue}
          className="w-full mt-4 bg-blue-500 text-white font-medium py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default OrganizationSetup;
