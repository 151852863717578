import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/pragya.svg";
import signupbg from "../assets/signupbg.jpg";
import { FiHome } from "react-icons/fi";
import { toast } from "react-toastify";

const UnverifiedEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const API_URL = process.env.REACT_APP_API_URL;

  const handleResendEmail = async () => {
    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await fetch(`${API_URL}/auth/resend-verification`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        toast.success("Verification email resent successfully");
      } else {
        const data = await response.json();
        toast.error(data.detail || "Failed to resend verification email");
      }
    } catch (error) {
      toast.error("Error resending verification email");
    }
  };

  return (
    <div
      className="relative flex items-center justify-between min-h-screen w-full bg-cover bg-center overflow-x-hidden"
      style={{
        backgroundImage: `url(${signupbg})`,
        backgroundSize: "100% 100%",
      }}
    >
      {/* Home Button */}
      <a
        href="/"
        className="absolute top-6 sm:top-12 left-4 md:left-32 flex items-center space-x-2 p-2 border-2 border-white rounded-lg text-white hover:bg-white hover:text-black transition-all duration-300 ease-in-out"
      >
        <FiHome className="text-xl" />
        <span className="text-sm font-semibold">Home</span>
      </a>

      {/* Left Side Text */}
      <div className="hidden lg:block w-[492px] ml-8 md:ml-32 text-white text-[42px] font-light font-['Inter'] leading-[54.60px] self-start mt-32">
        Start Building Your AI Assistant Today—No Code, No Hassle!
      </div>

      {/* Verify Email Box */}
      <div className="bg-white rounded-lg shadow-xl w-[92%] sm:w-[80%] md:w-[576px] min-h-[500px] sm:min-h-[800px] my-2 sm:my-8 flex-shrink-0 mx-auto lg:mr-4 relative overflow-hidden outline outline-[0.5px] outline-white/40 outline-offset-[-0.5px] p-4 sm:p-8">
        {/* Logo Container */}
        <div className="relative sm:absolute left-0 sm:left-[95px] top-0 sm:top-[80px] flex flex-col items-center sm:items-start w-full sm:w-auto px-4 sm:px-0">
          {/* Logo */}
          <img
            src={logo}
            alt="Prgya.ai"
            className="h-[30px] sm:h-[37px] mb-[40px] sm:mb-[150px]"
          />

          {/* Content Container */}
          <div className="flex flex-col items-center sm:items-start gap-6 sm:gap-8 w-full sm:w-[386px] px-4 sm:px-0">
            {/* Email Icon Circle */}
            <div className="flex justify-center w-[60px] sm:w-[78px] h-[60px] sm:h-[78px] overflow-hidden">
              <div className="w-full h-full bg-[#FFBB33] rounded-full flex items-center justify-center">
                <div className="w-[10px] h-[52px] relative">
                  <div className="w-[10px] h-[41px] bg-white absolute top-0" />
                  <div className="w-[8px] h-[8px] bg-white absolute bottom-0 left-[1px]" />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3 w-full text-center sm:text-left">
              <div className="text-black text-xl sm:text-2xl font-medium font-['Inter'] leading-6">
                You haven't verified your email yet!
              </div>
              <div className="text-[#595959] text-sm sm:text-base font-normal font-['Inter'] leading-[1.4] sm:leading-[22.4px]">
                To continue using your account, please confirm your email
                address.
              </div>
              <div className="text-[#595959] text-sm sm:text-base font-normal font-['Inter'] leading-[1.4] sm:leading-[22.4px]">
                We've sent a verification email to{" "}
                <span className="font-bold break-all">{email}</span>
              </div>
            </div>

            {/* Resend Option */}
            <div className="text-sm sm:text-base font-['Inter'] leading-6 text-center sm:text-left">
              <span className="text-black font-normal">
                Didn't receive a Code?{" "}
              </span>
              <button
                onClick={handleResendEmail}
                className="text-[#1677FF] font-bold hover:underline"
              >
                Resend
              </button>
            </div>
          </div>
        </div>

        {/* Support Link */}
        <div className="absolute left-0 right-0 bottom-4 sm:bottom-6 text-xs sm:text-sm font-['Inter'] leading-normal text-center px-4">
          <span className="text-black font-normal">Need help? Contact </span>
          <a
            href="mailto:support@prgya.ai"
            className="text-[#1677FF] font-bold hover:underline"
          >
            support
          </a>
        </div>
      </div>
    </div>
  );
};

export default UnverifiedEmail;
