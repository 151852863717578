import React, { useState, useEffect, useRef } from "react";
// import { FaFolder, FaPlus, FaFile } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import axiosInstance from "../components/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import DocumentsPage from "../components/Uploadfile";
import FolderDocumentsPage from "../components/Filesinfolder";
import Group3 from "../assets/Group3.svg";
import { getStoredValue } from "../services/logics.js";
import {
  FaFolder,
  FaFile,
  FaEllipsisV,
  FaPlus,
  FaTh,
  FaBars,
  FaClock,
} from "react-icons/fa";
import { HiOutlineSearch } from "react-icons/hi";
import { MdFilterList } from "react-icons/md";
import { IoEllipsisHorizontal } from "react-icons/io5";
import EmptyStateComponent from "../components/Emptystatecomponent.jsx";
import knowledgebasecreate from "../assets/knowledgebasecreate.svg";
import { toast } from "react-toastify";
import ThreeDotLoader from "../components/dotloader.jsx";
import Upload from "../assets/Upload.svg";
const Files = () => {
  // const [folders, setFolders] = useState([
  //     { id: 1, name: "Folder name goes here - might be lengthy" },
  //     { id: 2, name: "Folder name goes here - might be lengthy" },
  //     { id: 3, name: "Folder name goes here - might be lengthy" },
  //     { id: 4, name: "Folder name goes here - might be lengthy" },
  //     { id: 5, name: "Folder name goes here - might be lengthy" },
  // ]);

  const [files, setFiles] = useState([
    { id: 1, name: "File name goes here - might be lengthy", date: "24/01/25" },
    { id: 2, name: "File name", date: "24/01/25" },
    { id: 3, name: "File name", date: "24/01/25" },
    { id: 4, name: "File name", date: "24/01/25" },
    { id: 5, name: "File name", date: "24/01/25" },
    { id: 6, name: "File name", date: "24/01/25" },
    { id: 7, name: "File name", date: "24/01/25" },
    { id: 8, name: "File name", date: "24/01/25" },
  ]);
  const [folderDropdown, setFolderDropdown] = useState(null);
  const [fileDropdown, setFileDropdown] = useState(null);
  const [viewMode, setViewMode] = useState("grid"); // Toggle between "grid" and "list"
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [folders, setFolders] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [showcreateModal, setShowcreateModal] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [showFolderContent, setShowFolderContent] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    folderIndex: null,
  });
  const [newFolderName, setNewFolderName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  // const projectId = useSelector((state) => state.project?.activeProjectId);
  const projectId = getStoredValue("activeProjectId");
  const role = getStoredValue("project_role");
  const dropdownRef = useRef(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedFolderName, setEditedFolderName] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [fileCount, setFileCount] = useState(0);

  // File upload related states and handlers
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("access-token");

  // Callback function to receive the number of files
  const handleFileCountChange = (count) => {
    console.log("Files.jsx received new file count:", count);
    // Only update if the count actually changed
    if (fileCount !== count) {
      setFileCount(count);
    }
  };
  // Function to open Edit Modal
  const openEditModal = (folder) => {
    setEditedFolderName(folder.name);
    setSelectedFolderId(folder.id);
    setShowEditModal(true);
  };

  // Function to handle folder rename
  const handleEditFolder = async () => {
    if (!editedFolderName.trim()) return;

    try {
      await axiosInstance.patch(`/v1/folders/${selectedFolderId}`, {
        name: editedFolderName,
      });

      // Update UI
      setFolders((prevFolders) =>
        prevFolders.map((folder) =>
          folder.id === selectedFolderId
            ? { ...folder, name: editedFolderName }
            : folder,
        ),
      );

      setShowEditModal(false);
      toast.success("Folder renamed successfully!");
    } catch (error) {
      toast.error("Failed to rename folder.");
    }
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFolderDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // const [loading, setloading] = useState(false);
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the document
    setShowDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      closeDropdown();
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    fetchFolders();
  }, []);

  // Add a new useEffect to fetch file count on component mount
  useEffect(() => {
    fetchInitialFileCount();
  }, []);

  // Function to fetch initial file count
  const fetchInitialFileCount = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/user/documents/${projectId}?page=1&page_size=5`,
      );
      const data = response.data;
      if (data && data.total) {
        setFileCount(data.total);
        console.log("Initial file count:", data.total);
      }
    } catch (error) {
      console.error("Error fetching initial file count:", error);
    }
  };

  // Fetch folders from the server
  const fetchFolders = async (page = 1, limit = 10) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${API_URL}/v1/folders/${projectId}`,
        {
          params: { page, limit },
        },
      );
      console.log("API Response:", response.data); // Replace with your API base URL if needed
      setFolders(response.data.folders);
      // setloading(true);
    } catch (error) {
      console.error("Error fetching folders:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      alert("Folder name cannot be empty.");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `${API_URL}/v1/folders/${projectId}/create`,
        { name: newFolderName, parent_folder_id: null },
      );
      setFolders([...folders, response.data]);
      setShowCreateModal(false);
      setNewFolderName("");
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      // Send DELETE request to the API
      await axiosInstance.delete(`${API_URL}/v1/folders/${folderId}`);
      // Update the folders state by filtering out the deleted folder
      setFolders(folders.filter((folder) => folder.id !== folderId));
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
    // Hide the context menu if it's open
    setContextMenu({ ...contextMenu, visible: false });
  };
  const handleOpenFolder = (folder) => {
    if (!folder) {
      console.error("Folder data is undefined:", folder);
      return;
    }
    setCurrentFolder(folder);
    setShowFolderContent(true);
  };

  // Close folder and go back to folder list
  const handleCloseFolder = () => {
    setCurrentFolder(null);
    setShowFolderContent(false);
  };

  // File upload handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e, type) => {
    e.preventDefault(); // Prevent default behavior
    setIsDragOver(false); // Reset drag-over state

    let selectedFile;
    if (type === "drop") {
      selectedFile = e.dataTransfer.files[0]; // Get the dropped file
    } else {
      selectedFile = e.target.files[0]; // Get the file from input
    }

    // Validate file format
    if (selectedFile && selectedFile.type !== "application/pdf") {
      setErrorMessage("*Only .pdf files are allowed.");
      setFile(null);
      return;
    } else {
      // If valid, proceed with the upload
      setFile(selectedFile);
      setErrorMessage(""); // Clear any existing error message
      setUploadProgress(0);
      startUpload(selectedFile); // Start the upload
    }
  };

  const startUpload = async (uploadedFile) => {
    const maxSizeInBytes = 50 * 1024 * 1024;

    if (uploadedFile.size > maxSizeInBytes) {
      setFile(null);
      toast.error("File size exceeds the limit of 50 MB.");
      return;
    }

    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("folder_id", "");
    try {
      setIsUploading(true);
      setUploadProgress(0); // Reset progress at start

      const response = await axiosInstance.post(
        `${API_URL}/v1/user/documents/${projectId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            console.log("Upload progress:", progress);
            setUploadProgress(progress);
          },
        },
      );

      const data = response.data;
      console.log("Upload successful:", data);

      // Set progress to 100% when complete
      setUploadProgress(100);

      // Show success message
      toast.success("File uploaded successfully!");

      // Update file count and ensure it's reflected in UI
      const newFileCount = fileCount + 1;
      setFileCount(newFileCount);

      // Ensure parent component knows about the file count change
      if (handleFileCountChange) {
        handleFileCountChange(newFileCount);
      }

      // Reset upload status but keep modal open
      setTimeout(() => {
        setIsUploading(false);
        // Modal will stay open until user clicks Done or X
      }, 500);
    } catch (error) {
      console.error("Upload error:", error);
      if (error.response && error.response.status) {
        toast.error(
          error.response?.data?.detail || "Upload failed. Please try again.",
        );
      } else {
        toast.error(
          "Network error. Please check your connection and try again.",
        );
      }
      setUploadProgress(0);
      setIsUploading(false);
      setFile(null);
    }
  };

  // Function to handle closing the upload modal and resetting state
  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
    setFile(null);
    setUploadProgress(0);
    setErrorMessage("");

    // Give the server a moment to process the upload, then refresh data
    setTimeout(() => {
      console.log("Refreshing file data after modal close");
      fetchInitialFileCount();
    }, 500);
  };

  console.log(currentFolder?.id);
  if (loading) {
    // Show a loader while data is being fetched
    return (
      <div className="flex items-center justify-center h-screen">
        <ThreeDotLoader />
      </div>
    );
  }
  return (
    <div className="min-h-screen p-6 bg-[#07070703] text-white">
      {/* Header */}
      {showFolderContent && currentFolder ? (
        <div>
          <FolderDocumentsPage
            folderId={currentFolder?.id}
            rootfoldername={currentFolder?.name}
            handleCloseFolder={handleCloseFolder}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold">Files</h1>
            <div className="flex items-center gap-3">
              {/* Search Bar */}
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search"
                  className="bg-[#1D1E20] text-white px-4 py-2 pl-10 rounded-lg focus:outline-none border border-gray-700"
                />
                <HiOutlineSearch
                  className="absolute left-3 top-3 text-gray-400"
                  size={18}
                />
              </div>

              {/* Filter Button */}
              <button className="p-2 bg-[#1D1E20] rounded-lg hover:bg-gray-700">
                <MdFilterList size={20} />
              </button>

              {/* View Mode Toggle */}
              <button
                className="p-2 bg-[#1D1E20] rounded-lg hover:bg-gray-700"
                onClick={() =>
                  setViewMode(viewMode === "grid" ? "list" : "grid")
                }
              >
                {viewMode === "grid" ? (
                  <FaBars size={20} />
                ) : (
                  <FaTh size={20} />
                )}
              </button>

              {/* Create Folder Button */}
              <button
                className="bg-white text-black font-semibold px-4 py-2 rounded-lg hover:bg-gray-300"
                onClick={() => {
                  setShowDropdown(false);
                  setShowCreateModal(true);
                }}
              >
                + Create folder
              </button>

              {/* Add File Button */}
              <button
                className="bg-[#1677FF] text-white font-semibold px-4 py-2 rounded-lg hover:bg-[#1256CC]"
                onClick={() => {
                  setShowDropdown(false);
                  setShowUploadModal(true);
                }}
              >
                + Add file
              </button>
            </div>
          </div>

          {/* Folders Section */}
          {folders?.length === 0 && fileCount === 0 ? (
            <EmptyStateComponent
              icon={knowledgebasecreate}
              title="Add Files & Folders"
              description="Click on 'Add Files' or 'Add Folders' to get started"
              buttonText="+ Add Files"
              onButtonClick={() => setShowUploadModal(true)}
            />
          ) : (
            <>
              {/* Show Folders section first if folders exist */}
              {folders?.length > 0 && (
                <>
                  <h2 className="text-lg font-semibold mb-4">Folders</h2>
                  {viewMode === "grid" ? (
                    // Grid View
                    <div className="flex gap-3 overflow-x-auto scrollbar-hide">
                      {folders.map((folder) => (
                        <div
                          key={folder.id}
                          className="relative w-32 h-36 bg-[#1D1E21] text-white p-3 rounded-lg border border-gray-700 flex flex-col items-center justify-center"
                        >
                          {/* Folder Icon - Centered */}
                          <FaFolder
                            className="text-gray-400 text-4xl mb-2 cursor-pointer"
                            onClick={() => handleOpenFolder(folder)}
                          />

                          {/* Folder Name - Centered */}
                          <p className="text-sm text-center truncate">
                            {folder.name}
                          </p>

                          {/* Three-dot menu - Positioned at Top Right */}
                          <button
                            className="absolute top-2 right-2 text-gray-400 hover:text-white"
                            onClick={() =>
                              setFolderDropdown(
                                folder.id === folderDropdown ? null : folder.id,
                              )
                            }
                          >
                            <IoEllipsisHorizontal />
                          </button>

                          {/* Dropdown Menu */}
                          {folderDropdown === folder.id && (
                            <div
                              ref={dropdownRef}
                              className="absolute right-2 top-10 bg-[#1D1E21] text-white shadow-md rounded-md w-[80px] text-sm"
                            >
                              <button
                                className="block w-full px-2 py-1 text-left hover:bg-gray-700"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the folder open action
                                  setShowEditModal(true);
                                  openEditModal(folder);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="block w-full px-2 py-1 text-left hover:bg-gray-700"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the folder open action
                                  handleDeleteFolder(folder.id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    // List View
                    <div className="w-full">
                      {folders.map((folder) => (
                        <div
                          key={folder.id}
                          className="flex justify-between items-center bg-[#1D1E21] text-white p-3 rounded-lg border border-gray-700 mb-2"
                        >
                          <div className="flex items-center gap-3">
                            <FaFolder className="text-gray-400 text-2xl" />
                            <p className="text-sm">{folder.name}</p>
                          </div>
                          {/* Three-dot menu */}
                          <button
                            className="text-gray-400 hover:text-white"
                            onClick={() =>
                              setFolderDropdown(
                                folder.id === folderDropdown ? null : folder.id,
                              )
                            }
                          >
                            <IoEllipsisHorizontal />
                          </button>

                          {/* Dropdown Menu */}
                          {folderDropdown === folder.id && (
                            <div
                              ref={dropdownRef}
                              className="absolute right-4 bg-[#1D1E21] text-white shadow-md rounded-md w-24"
                            >
                              <button className="block w-full px-4 py-2 text-left hover:bg-gray-700">
                                Edit
                              </button>
                              <button
                                className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the folder open action
                                  handleDeleteFolder(folder.id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}

              {/* Then show Files section if files exist */}
              {fileCount > 0 && (
                <>
                  <div className={fileCount > 0 ? "block mt-6" : "hidden"}>
                    <h2 className="text-lg font-semibold mb-4">Files</h2>
                  </div>

                  <DocumentsPage
                    modal={showUploadModal}
                    setModal={setShowUploadModal}
                    viewMode={viewMode}
                    onFileCountChange={handleFileCountChange}
                    hidden={fileCount === 0}
                  />
                </>
              )}
            </>
          )}
        </>
      )}

      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
          <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Create Folder</h3>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter folder name"
              className="w-full px-4 py-2  bg-[#191919] rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-[#FFFFFF] text-black px-4 py-2 rounded mr-2  border-2 border-current"
                onClick={() => setShowCreateModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#1677FF] text-white px-4 py-2 rounded"
                onClick={handleCreateFolder}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
          <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Edit Folder</h3>

            {/* Input Field for Folder Name */}
            <input
              type="text"
              value={editedFolderName}
              onChange={(e) => setEditedFolderName(e.target.value)}
              placeholder="Enter new folder name"
              className="w-full px-4 py-2 bg-[#191919] text-white rounded mb-4"
            />

            {/* Action Buttons */}
            <div className="flex justify-end">
              <button
                className="bg-[#FFFFFF] text-black px-4 py-2 rounded mr-2 border-2 border-current"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#1677FF] text-white px-4 py-2 rounded"
                onClick={handleEditFolder}
                disabled={!editedFolderName.trim()} // Disable if input is empty
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* File Upload Modal */}
      {showUploadModal && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center"
          onClick={handleCloseUploadModal} // Close modal when clicking outside
        >
          <div
            className="bg-[#1D1E20] w-[794px] h-[345px] rounded-lg shadow-lg p-6 border border-[#FFFFFF66] text-white"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            {/* Modal Header */}
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold flex items-center gap-2">
                <span className="text-gray-300">📁</span> Add File
              </h2>
              <button
                onClick={handleCloseUploadModal}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>

            {/* Upload Area */}
            <div
              className="mt-4 flex flex-col items-center justify-center border-2 border-dashed border-[#FFFFFF33] rounded-lg p-8 cursor-pointer hover:border-white transition"
              onClick={() => document.getElementById("fileInput").click()}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, "drop")}
            >
              <img
                src={Upload}
                width="50px"
                height="50px"
                alt="Upload Icon"
                className="text-[#6E45FE] mb-3"
              />
              <p className="text-gray-300 text-sm">
                Click or drag file to this area to upload
              </p>
              <span className="text-gray-500 text-xs mt-1">
                Supported format: PDF (50Mb)
              </span>
              <input
                type="file"
                accept=".pdf"
                onChange={(e) => handleDrop(e, "input-file")}
                className="hidden"
                id="fileInput"
              />
            </div>
            {file && (
              <div className="mt-4 bg-[#2C2C2C] p-3 rounded-lg flex items-center space-x-4">
                {/* File Icon */}
                <div className="text-[#6E45FE]">📄</div>
                <div className="flex-1">
                  {/* File Name */}
                  <p className="text-white text-sm">{file.name}</p>
                  {/* Progress Bar */}
                  <div className="relative mt-1 w-full h-2 bg-gray-700 rounded-full">
                    <div
                      className="h-2 rounded-full transition-all"
                      style={{
                        width: `${uploadProgress}%`,
                        backgroundColor:
                          uploadProgress === 100 ? "#4CAF50" : "#6E45FE",
                      }}
                    ></div>
                  </div>
                  {/* File Size & Progress Percentage */}
                  <div className="flex justify-between text-xs text-gray-400 mt-1">
                    <span>{(file.size / 1024).toFixed(2)} kb</span>
                    <span>{uploadProgress}%</span>
                  </div>
                </div>
                {/* Delete Icon */}
                <button
                  className="text-gray-400 hover:text-red-500"
                  onClick={() => setFile(null)}
                >
                  🗑️
                </button>
              </div>
            )}
            {/* Error Message */}
            {errorMessage && (
              <p className="text-red-500 text-xs text-center mt-2">
                {errorMessage}
              </p>
            )}

            {/* Footer Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <button
                className="px-4 py-2 text-white bg-transparent border border-gray-500 rounded-lg hover:bg-gray-700"
                onClick={handleCloseUploadModal}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-lg transition-colors ${
                  file && uploadProgress === 100
                    ? "bg-[#6E45FE] hover:bg-[#5A3AD1]"
                    : "bg-gray-500 cursor-not-allowed"
                }`}
                disabled={!file || uploadProgress !== 100}
                onClick={handleCloseUploadModal}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Files;
