import React from "react";

const DeleteIcon = ({ color = "black", size = 22 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 5.50065H19.25M17.4167 5.50065V18.334C17.4167 19.2507 16.5 20.1673 15.5833 20.1673H6.41667C5.5 20.1673 4.58333 19.2507 4.58333 18.334V5.50065M7.33333 5.50065V3.66732C7.33333 2.75065 8.25 1.83398 9.16667 1.83398H12.8333C13.75 1.83398 14.6667 2.75065 14.6667 3.66732V5.50065M9.16667 10.084V15.584M12.8333 10.084V15.584"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
