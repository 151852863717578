import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../components/axiosInstance.js";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getStoredValue } from "../services/logics.js";
import { MdDelete, MdMoreVert, MdGridOn, MdViewList } from "react-icons/md";
import { Dialog } from "@headlessui/react";
import { BsChatLeftDots } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";
import EmptyStateComponent from "../components/Emptystatecomponent.jsx";
import knowledgebasecreate from "../assets/knowledgebasecreate.svg";
import ThreeDotLoader from "../components/dotloader.jsx";
import { HiOutlineSearch } from "react-icons/hi";
import {
  toggleSidebar,
  openSidebar,
  closeSidebar,
} from "../redux/sidebarSlice";

// import Navbar from '../profile/Navbar.jsx';
const CreateAssistant = () => {
  const navigate = useNavigate();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistantId, setSelectedAssistantId] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [knowledgeBases, setKnowledgeBases] = useState([]); // State to store knowledge bases
  const [modelData, setModelData] = useState({
    assistant_name: "",
    firstMessage: "",
    instructions: "",
    provider: "openai",
    model: "gpt-4o-mini",
    knowledgebase: { id: "", name: "" }, // Store both ID and name
  });
  const [errors, setErrors] = useState({
    assistant_name: false,
    instructions: false,
    knowledgebase: false,
  });
  // const projectId = useSelector((state) => state.project?.activeProjectId);
  const projectId = getStoredValue("activeProjectId");
  const project_role = getStoredValue("project_role");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState("grid"); // 'grid' or 'list'
  const [menuOpen, setMenuOpen] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectededitAssistant, setSelectedEditAssistant] = useState(null);
  const [assistantDropdown, setAssistantDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAssistants, setFilteredAssistants] = useState([]);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openSidebar());
  }, [dispatch]);

  useEffect(() => {
    fetchKnowledgeBases();
    fetchAssistants();
  }, [projectId]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuOpen(null); // ✅ Correct behavior (closes dropdown)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const fetchKnowledgeBases = async (page = 1, limit = 10) => {
    try {
      const response = await axiosInstance.get(
        `/v1/user/knowledge-bases/${projectId}`,
        {
          params: { page: page, limit: limit }, // Ensure params are explicitly set
        },
      );

      setKnowledgeBases(response.data.knowledge_bases || []);
    } catch (error) {
      toast.error("Error fetching knowledge bases");
    }
  };

  const fetchAssistants = async (page = 1, limit = 10) => {
    try {
      const response = await axiosInstance.get(
        `/v1/user/assistants/${projectId}/`,
        {
          params: { page, limit },
        },
      );
      setAssistants(response.data.assistants || []);
      setFilteredAssistants(response.data.assistants || []);
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching assistants");
    }
  };

  const validateFields = () => {
    const newErrors = {
      assistant_name: !modelData.assistant_name.trim(),
      instructions: !modelData.instructions.trim(),
      knowledgebase: !modelData.knowledgebase.id.trim(),
    };

    setErrors(newErrors);

    // If any field has an error, return false to prevent submission
    return !Object.values(newErrors).some((error) => error);
  };

  // Handle selecting an assistant
  const handleAssistantSelect = async (assistant) => {
    setSelectedAssistantId(assistant.id);
    navigate(`/projects/${projectId}/assistants/${assistant.id}/chat`);
    try {
      const response = await axiosInstance.get(
        `/v1/user/assistants/${assistant.id}`,
      );
      const data = response.data;
      setModelData({
        assistant_name: data.assistant_name,
        firstMessage: data.first_message,
        instructions: data.instructions,
        model: data.model,
        knowledgebase: {
          id: data.knowledgebase_id,
          name: data.knowledgebase_name,
        },
      });
    } catch (error) {
      toast.error("Error fetching assistant details");
    }
  };

  // Handle name change in header
  const handleNameChange = (newName) => {
    setModelData((prevData) => ({
      ...prevData,
      assistant_name: newName,
    }));

    setAssistants((prevAssistants) =>
      prevAssistants.map((assistant) =>
        assistant.id === selectedAssistantId
          ? { ...assistant, assistant_name: newName }
          : assistant,
      ),
    );
  };

  // Handle publish (create or update)

  const handleUpdateAssistant = async (selectededitAssistant) => {
    const payload = {
      assistant_name: selectededitAssistant.assistant_name,
      first_message: selectededitAssistant.firstMessage,
      instructions: selectededitAssistant.instructions,
      model: selectededitAssistant.model,
      knowledgebase_id: selectededitAssistant.knowledgebase_id,
      knowledgebase_name: selectededitAssistant.knowledgebase_name,
    };
    try {
      await axiosInstance.put(
        `/v1/update/assistants/${selectededitAssistant.id}`,
        payload,
      );
      // Update the assistant list
      setAssistants((prevAssistants) =>
        prevAssistants.map((assistant) =>
          assistant.id === selectededitAssistant.id
            ? selectededitAssistant
            : assistant,
        ),
      );
      toast.success("Assistant updated successfully");
    } catch (error) {
      toast.error("Error creating or updating assistant");
    }

    setIsEditModalOpen(false);
  };
  const handleInputChange = (field, value) => {
    setModelData((prev) => ({ ...prev, [field]: value }));

    // Remove error message if user enters valid input
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: false }));
    }
  };
  const handlePublish = async () => {
    if (!validateFields()) return;
    const payload = {
      assistant_name: modelData.assistant_name,
      first_message: modelData.firstMessage,
      instructions: modelData.instructions,
      model: modelData.model,
      knowledgebase_id: modelData.knowledgebase.id,
      knowledgebase_name: modelData.knowledgebase.name,
    };
    console.log(modelData.model);
    try {
      if (selectedAssistantId) {
        await axiosInstance.put(
          `/v1/update/assistants/${selectedAssistantId}`,
          payload,
        );
        setAssistants((prevAssistants) =>
          prevAssistants.map((asst) =>
            asst.id === selectedAssistantId ? { ...asst, ...payload } : asst,
          ),
        );
        toast.success("Assistant updated successfully");
      } else {
        const response = await axiosInstance.post(
          `/v1/user/assistants/${projectId}`,
          payload,
        );
        const newAssistant = response.data;
        setAssistants((prevAssistants) => [...prevAssistants, newAssistant]);
        setSelectedAssistantId(newAssistant.id);
        toast.success("Assistant created successfully");
      }
      fetchAssistants();
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error creating or updating assistant");
    }
  };

  // Handle delete assistant
  const handleDeleteAssistant = async (id) => {
    try {
      await axiosInstance.delete(`/v1/user/assistants/${id}`);
      setAssistants(assistants.filter((assistant) => assistant.id !== id));
      if (id === selectedAssistantId) {
        setSelectedAssistantId(null);
        setModelData({
          assistant_name: "",
          firstMessage: "",
          instructions: "",
          provider: "openai",
          model: "gpt-4o-mini",
          knowledgebase: { id: "", name: "" },
        });
      }
      toast.success("Assistant deleted successfully");
    } catch (error) {
      toast.error("Error deleting assistant");
    }
  };

  const selectedAssistant = assistants.find(
    (assistant) => assistant.id === selectedAssistantId,
  );

  if (loading) {
    // Show a loader while data is being fetched
    return (
      <div className="flex items-center justify-center h-screen">
        <ThreeDotLoader />
      </div>
    );
  }

  return (
    <div className="min-h-screen text-white flex flex-col overflow-hidden">
      {/* Top Header */}
      <header className="border-b border-gray-800 p-4 flex items-center justify-between">
        <div>
          <h1 className="text-xl font-semibold">Assistants</h1>
        </div>

        {/* Right: Search + Grid/List Toggle + Create Button */}
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              className="bg-[#1D1E20] text-white px-4 py-2 pl-10 rounded-lg focus:outline-none border border-gray-700"
            />
            <HiOutlineSearch
              className="absolute left-3 top-3 text-gray-400"
              size={18}
            />
          </div>
          <button
            className={`p-2 rounded ${viewMode === "grid" ? "bg-gray-700" : "bg-transparent"}`}
            onClick={() => setViewMode("grid")}
          >
            <MdGridOn size={20} />
          </button>
          <button
            className={`p-2 rounded ${viewMode === "list" ? "bg-gray-700" : "bg-transparent"}`}
            onClick={() => setViewMode("list")}
          >
            <MdViewList size={20} />
          </button>
          {project_role === "OWNER" ? (
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-[#1677FF]   hover:bg-[#1256CC] transition-colors px-4 py-2 rounded text-white"
            >
              + Create Assistant
            </button>
          ) : (
            <></>
          )}
        </div>
      </header>
      {assistants?.length === 0 ? (
        <EmptyStateComponent
          icon={knowledgebasecreate}
          title="Add a Assistant"
          description="Click on 'Add Assistant' to create one"
          buttonText="+ Add Assistants"
          onButtonClick={() => setIsModalOpen(true)}
        />
      ) : (
        <main className="flex-1 p-4 overflow-auto">
          {/* Grid View */}
          {viewMode === "grid" && (
            <div
              className="grid gap-4 md:grid-cols-2 lg:grid-cols-4"
              style={{
                height: "140px",
              }}
            >
              {filteredAssistants.map((assistant, index) => (
                <div
                  key={assistant.id}
                  className="bg-[#1D1E21] rounded p-4 flex justify-between items-center cursor-pointer border border-[#FFFFFF4D] transition-colors relative"
                  onClick={() => handleAssistantSelect(assistant)}
                >
                  <div className="mb-8">
                    <BsChatLeftDots size={25} style={{ color: "#8E8F90" }} />
                    <h2 className="font-semibold text-lg pt-2">
                      {assistant.assistant_name}
                    </h2>
                    {/* <span className="text-gray-500 text-sm">24/01/25</span> */}
                  </div>
                  {/* Three-dot menu */}
                  <div className="absolute top-2 right-2">
                    <button
                      className="p-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(menuOpen === index ? null : index);
                      }}
                    >
                      <IoEllipsisHorizontal
                        size={20}
                        style={{ color: "#8E8F90" }}
                      />
                    </button>

                    {menuOpen === index && (
                      <div
                        ref={dropdownRef}
                        className="absolute right-4 mt-1 bg-gray-800 shadow-lg rounded-md p-2 w-32 z-10"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedEditAssistant({
                              ...assistant,
                              // knowledgebase: assistant.knowledgebase || { id: assistant?.knowledgebase?.id, name: assistant?.knowledgebase?.name },
                            });
                            console.log(assistant);
                            setIsEditModalOpen(true);
                            setMenuOpen(false);
                          }}
                          className="block text-white text-sm px-4 py-2 hover:bg-gray-700 w-full text-left"
                        >
                          Edit
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAssistant(assistant.id);
                          }}
                          className="block text-white text-sm px-4 py-2 hover:bg-gray-700 w-full text-left"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* List View */}
          {viewMode === "list" && (
            <div className="flex flex-col space-y-2">
              {/* List Header */}
              {/* <div className="flex items-center justify-between bg-[#2A2B2E] p-3 rounded-md font-semibold text-white">
                                <span className="w-1/2">Assistant Name</span>
                                <span className="w-1/4 text-center">Actions</span>
                            </div> */}

              {/* List Items */}
              {assistants.map((assistant, index) => (
                <div
                  key={assistant.id}
                  className="flex items-center justify-between bg-[#1D1E21] p-4 rounded-md border border-[#FFFFFF4D] transition-colors hover:bg-[#2A2B2E] cursor-pointer"
                  onClick={() => handleAssistantSelect(assistant)}
                >
                  {/* Assistant Info */}
                  <div className="flex items-center space-x-4 w-1/2">
                    <BsChatLeftDots size={20} style={{ color: "#8E8F90" }} />
                    <h2 className="text-white font-medium">
                      {assistant.assistant_name}
                    </h2>
                  </div>

                  {/* Actions */}
                  <div className="flex items-center justify-center w-1/4">
                    <button
                      className="p-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(menuOpen === index ? null : index);
                      }}
                    >
                      <IoEllipsisHorizontal
                        size={20}
                        style={{ color: "#8E8F90" }}
                      />
                    </button>

                    {menuOpen === index && (
                      <div
                        ref={dropdownRef}
                        className="absolute right-4 mt-1 bg-gray-800 shadow-lg rounded-md p-2 w-32 z-10"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedEditAssistant({
                              ...assistant,
                              knowledgebase: assistant.knowledgebase || {
                                id: "",
                                name: "",
                              },
                            });
                            setIsEditModalOpen(true);
                            setMenuOpen(false);
                          }}
                          className="block text-white text-sm px-4 py-2 hover:bg-gray-700 w-full text-left"
                        >
                          Edit
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAssistant(assistant.id);
                          }}
                          className="block text-white text-sm px-4 py-2 hover:bg-gray-700 w-full text-left"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </main>
      )}
      {/* Create Assistant Modal */}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div className="bg-[#27282B] p-6 rounded-lg w-[500px]">
          <h2 className="text-lg font-semibold mb-4 flex items-center text-white">
            <span>📄</span> Create new assistant
          </h2>
          <div className="flex flex-col space-y-4">
            {/* Assistant Name */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">
                Assistants <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="w-full p-2 bg-[#191919] text-white rounded border border-gray-600"
                placeholder="Enter Assistant name"
                value={modelData.assistant_name}
                onChange={(e) =>
                  handleInputChange("assistant_name", e.target.value)
                }
              />
              {errors.assistant_name && (
                <p className="text-red-500 text-xs mt-1">
                  Assistant name is required.
                </p>
              )}
            </div>

            {/* First Message */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">First Message</label>
              <input
                type="text"
                className="w-full p-2 bg-[#191919] text-white rounded border border-gray-600"
                placeholder="First message content here"
                value={modelData.firstMessage}
                onChange={(e) =>
                  setModelData({ ...modelData, firstMessage: e.target.value })
                }
              />
            </div>

            {/* Instructions */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">
                Instructions <span className="text-red-500">*</span>
              </label>
              <textarea
                className="w-full h-32 p-2 bg-[#191919] text-white rounded border border-gray-600"
                placeholder="System prompt details"
                value={modelData.instructions}
                onChange={(e) =>
                  handleInputChange("instructions", e.target.value)
                }
              />
              {errors.instructions && (
                <p className="text-red-500 text-xs mt-1">
                  Instructions are required.
                </p>
              )}
            </div>

            {/* Provider & Model Selection */}
            <div className="mb-4 flex space-x-4">
              <div className="flex-1">
                <label className="block text-gray-400 mb-2">Provider</label>
                <select
                  className="w-full p-2 bg-[#191919] text-white rounded border border-gray-600"
                  value={modelData.provider}
                  onChange={(e) =>
                    setModelData({ ...modelData, provider: e.target.value })
                  }
                >
                  <option value="openai">OpenAI</option>
                </select>
              </div>

              <div className="flex-1">
                <label className="block text-gray-400 mb-2">Model</label>
                <select
                  className="w-full p-2 bg-[#191919] text-white rounded border border-gray-600"
                  value={modelData.model}
                  onChange={(e) =>
                    setModelData({ ...modelData, model: e.target.value })
                  }
                >
                  <option value="gpt-4o-mini">GPT-4o Mini</option>
                </select>
              </div>
            </div>

            {/* Knowledge Base */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">
                Knowledge Base <span className="text-red-500">*</span>
              </label>
              <select
                className="w-full p-2 bg-[#191919] text-white rounded border border-gray-600"
                value={modelData.knowledgebase.id}
                onChange={(e) => {
                  const selectedKb = knowledgeBases.find(
                    (kb) => kb.id === e.target.value,
                  );
                  setModelData({
                    ...modelData,
                    knowledgebase: {
                      id: selectedKb?.id || "",
                      name: selectedKb?.name || "",
                    },
                  });
                  if (errors.knowledgebase) {
                    setErrors((prev) => ({ ...prev, knowledgebase: false }));
                  }
                }}
              >
                <option value="">Select Knowledge Base</option>
                {knowledgeBases.map((kb) => (
                  <option key={kb.id} value={kb.id}>
                    {kb.name}
                  </option>
                ))}
              </select>
              {errors.knowledgebase && (
                <p className="text-red-500 text-xs mt-1">
                  Knowledge base selection is required.
                </p>
              )}
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-end mt-4 space-x-2">
            <button
              onClick={() => setIsModalOpen(false)}
              className="bg-white text-black px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button
              onClick={handlePublish}
              className={`px-4 py-2 rounded text-white ${Object.values(errors).some((error) => error) ? "bg-gray-600 cursor-not-allowed" : "bg-[#1677FF]"}`}
              disabled={Object.values(errors).some((error) => error)}
            >
              Create
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div className="bg-[#27282B] p-6 rounded-lg w-[500px]">
          <h2 className="text-lg font-semibold mb-4 flex items-center text-white">
            <span>✏️</span> Edit Assistant
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Assistant Name</label>
              <input
                type="text"
                className="w-full p-2 bg-[#191919] text-white rounded"
                placeholder="Assistant Name"
                value={selectededitAssistant?.assistant_name}
                onChange={(e) =>
                  setSelectedEditAssistant({
                    ...selectededitAssistant,
                    assistant_name: e.target.value,
                  })
                }
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-400 mb-2">First Message</label>
              <input
                type="text"
                className="w-full p-2 bg-[#191919] text-white rounded"
                placeholder="First message content here"
                value={selectededitAssistant?.firstMessage || ""}
                onChange={(e) =>
                  setSelectedEditAssistant({
                    ...selectededitAssistant,
                    firstMessage: e.target.value,
                  })
                }
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Instructions</label>
              <textarea
                className="w-full h-32 p-2 bg-[#191919] text-white rounded"
                placeholder="System prompt details"
                value={selectededitAssistant?.instructions || ""}
                onChange={(e) =>
                  setSelectedEditAssistant({
                    ...selectededitAssistant,
                    instructions: e.target.value,
                  })
                }
              />
            </div>

            <div className="mb-4 flex space-x-4">
              <div className="flex-1">
                <label className="block text-gray-400 mb-2">Provider</label>
                <select
                  className="w-full p-2 bg-[#191919] text-white rounded"
                  value={selectededitAssistant?.provider || "openai"}
                  onChange={(e) =>
                    setSelectedEditAssistant({
                      ...selectededitAssistant,
                      provider: e.target.value,
                    })
                  }
                >
                  <option value="openai">OpenAI</option>
                </select>
              </div>

              <div className="flex-1">
                <label className="block text-gray-400 mb-2">Model</label>
                <select
                  className="w-full p-2 bg-[#191919] text-white rounded"
                  value={selectededitAssistant?.model || "gpt-4o-mini"}
                  onChange={(e) =>
                    setSelectedEditAssistant({
                      ...selectededitAssistant,
                      model: e.target.value,
                    })
                  }
                >
                  <option value="gpt-4o-mini">GPT-4o Mini</option>
                </select>
              </div>
            </div>

            {!selectededitAssistant ? ( // Show loader if data is not available
              <div className="text-gray-400">Loading...</div>
            ) : (
              <div className="mb-4">
                <label className="block text-gray-400 mb-2">
                  Knowledge Base
                </label>
                <select
                  className="w-full p-2 bg-[#191919] text-white rounded"
                  value={selectededitAssistant?.knowledgebase_id} // Ensure default value is set
                  onChange={(e) => {
                    const selectedKb = knowledgeBases.find(
                      (kb) => kb.id === e.target.value,
                    );
                    setSelectedEditAssistant((prevAssistant) => ({
                      ...prevAssistant,
                      knowledgebase_id: selectedKb ? selectedKb.id : "", // Update knowledgebase_id
                      knowledgebase_name: selectedKb ? selectedKb.name : "", // Update knowledgebase_name
                    }));
                  }}
                >
                  {knowledgeBases.map((kb) => (
                    <option key={kb.id} value={kb.id}>
                      {kb.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className="flex justify-end mt-4 space-x-2">
            <button
              onClick={() => setIsEditModalOpen(false)}
              className="bg-white text-black px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button
              onClick={() => handleUpdateAssistant(selectededitAssistant)}
              className="bg-[#6E45FE] px-4 py-2 rounded text-white"
            >
              Update
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateAssistant;
