/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./HomePageNavbar.css";
import { Avatar, Box } from "@mui/material";
import PragyaLogo from "../../../assets/NavbarAssets/pragya-logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OrgDropdownArrowIcon from "../../../assets/NavbarAssets/org-dropdown-arrow-icon.svg";
import { HiOutlineChevronDown } from "react-icons/hi";
import axiosInstance from "../../../components/axiosInstance";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedOrg,
  setOrganizations,
  selectIsCreator,
} from "../../../redux/organizationSlice";
import { useFlags } from "launchdarkly-react-client-sdk";

const HomePageNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOrgId, organizations } = useSelector(
    (state) => state.organization,
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const isCreator = useSelector(selectIsCreator);

  const { marketplaceFlag } = useFlags();

  console.log("marketplaceFlag------->", marketplaceFlag);

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axiosInstance.get("/v1/organizations/user");
        dispatch(setOrganizations(response.data));
        // Check both Redux state and localStorage for selected org
        const storedOrgId = localStorage.getItem("selectedOrg");

        if (response.data.length > 0) {
          if (
            !storedOrgId ||
            !response.data.find((org) => org.id === storedOrgId)
          ) {
            // If no stored org or stored org not found in response, set first org as default
            dispatch(setSelectedOrg(response.data[0].id));
            localStorage.setItem("selectedOrg", response.data[0].id);
          } else {
            // If stored org exists and is valid, use it
            dispatch(setSelectedOrg(storedOrgId));
          }
        }
      } catch (err) {
        toast.error("Error fetching organizations");
      }
    };

    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/users/me`);
        setUser(response.data);
        localStorage.setItem("user_id", response.data.id);
      } catch (err) {
        toast.error("Error fetching user data");
      }
    };

    fetchOrganizations();
    fetchUserData();
  }, [dispatch, selectedOrgId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".home-page-org-dropdown")) {
        setIsDropdownOpen(false);
      }
      if (!event.target.closest(".user-dropdown-container")) {
        setIsUserDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelection = (orgId) => {
    const selectedOrgData = organizations.find((org) => org.id === orgId);
    if (selectedOrgData) {
      // Update Redux state
      dispatch(setSelectedOrg(orgId));

      // Immediately clear current project and set loading state
      localStorage.removeItem("activeProjectId");

      // Dispatch custom event for organization change to immediately notify components
      const orgChangedEvent = new CustomEvent("organizationChanged", {
        detail: { orgId },
      });
      window.dispatchEvent(orgChangedEvent);

      // Fetch organization users and associated projects
      const fetchProjectsForOrg = async () => {
        try {
          // First get the userorganization_id for this org
          const response = await axiosInstance.get(
            `/v1/organizations/${orgId}/users`,
          );
          const users = response.data;

          if (users && users.length > 0) {
            // Find the current user's membership in this organization
            const currentUserId = localStorage.getItem("userId");
            const currentUserOrg = users.find(
              (user) => user.user_id === currentUserId,
            );

            if (currentUserOrg) {
              // Store userorg ID for future API calls
              localStorage.setItem("userorganization_id", currentUserOrg.id);

              // Now fetch projects for this organization and user
              const projectsResponse = await axiosInstance.get(
                `/v1/projects/${orgId}/user/${currentUserOrg.id}/`,
              );

              // Set first project as active if any exist
              if (projectsResponse.data && projectsResponse.data.length > 0) {
                const firstProject = projectsResponse.data[0];
                localStorage.setItem("activeProjectId", firstProject.id);

                console.log(
                  `Setting active project to ${firstProject.name} (${firstProject.id})`,
                );

                // Dispatch custom event to notify other components
                const projectChangedEvent = new CustomEvent("projectChanged", {
                  detail: { projectId: firstProject.id },
                });
                window.dispatchEvent(projectChangedEvent);
              } else {
                // No projects available
                localStorage.removeItem("activeProjectId");

                // Dispatch event with null project ID
                const projectChangedEvent = new CustomEvent("projectChanged", {
                  detail: { projectId: null },
                });
                window.dispatchEvent(projectChangedEvent);

                console.log("No projects found for this organization");
              }
            }
          }
        } catch (error) {
          console.error("Error fetching projects for organization:", error);
          toast.error("Failed to load projects for this organization");
        }
      };

      fetchProjectsForOrg();

      // Close dropdown
      setIsDropdownOpen(false);
    }
  };

  const handleOrgChange = (orgObject) => {
    // This function is unused since we're using Redux now, but keeping it for backward compatibility
    console.log("Selected Org Name:", orgObject.name);
    // Do not call setSelectedOrg here as it conflicts with Redux
    console.log("Updated Current Org:", orgObject);
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.post(`/auth/jwt/logout`);
      localStorage.clear();
      localStorage.setItem("isLoggedIn", "false");
      navigate("/signin");
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Logout failed. Please try again.");
    }
  };

  return (
    <Box className="home-page-navbar-container">
      <Box className="home-page-navbar-logo">
        <img src={PragyaLogo} alt="logo" />
      </Box>
      <Box className="home-page-navbar-links">
        <Link
          to="/home-page"
          className={`nav-link ${isActiveLink("/home-page") ? "active" : ""}`}
        >
          Home
        </Link>
        {isCreator && (
          <Link
            to="/creator/agents"
            className={`nav-link ${location.pathname.startsWith("/creator") ? "active" : ""}`}
          >
            Creator
          </Link>
        )}
        {marketplaceFlag && (
          <Link
            to="/market-place"
            className={`nav-link ${isActiveLink("/market-place") ? "active" : ""}`}
          >
            Marketplace
          </Link>
        )}
        {marketplaceFlag && (
          <Link
            to="/settings"
            className={`nav-link ${isActiveLink("/settings") ? "active" : ""}`}
          >
            Settings
          </Link>
        )}
        <Box
          className="home-page-org-dropdown"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <img src={OrgDropdownArrowIcon} alt="dropdown" />
          <span className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
            {selectedOrgId && organizations.length > 0
              ? organizations.find((org) => org.id === selectedOrgId)?.name
              : "No Organization"}
          </span>
          <HiOutlineChevronDown
            className={`ml-auto transition-transform ${isDropdownOpen ? "rotate-180" : "rotate-0"}`}
          />
          {isDropdownOpen && (
            <ul
              className={`absolute "w-full left-4" mt-[100px] bg-[#2C2C2C] text-white border rounded-lg shadow-lg z-10`}
            >
              {organizations.map((org) => (
                <li
                  key={org.id}
                  onClick={() => handleSelection(org.id)}
                  className="flex items-center gap-3 px-4 py-2 hover:bg-gray-700 cursor-pointer"
                >
                  <img
                    src={OrgDropdownArrowIcon}
                    alt="Dropdown Icon"
                    className="w-6 h-6"
                  />
                  <span>{org.name}</span>
                </li>
              ))}
            </ul>
          )}
        </Box>
        <div className="relative user-dropdown-container">
          <button
            onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
            className="flex items-center gap-2 focus:outline-none"
          >
            <Avatar className="w-10 h-10">
              {user.email ? user.email.charAt(0).toUpperCase() : "U"}
            </Avatar>
          </button>
          {isUserDropdownOpen && (
            <div className="absolute right-2 mt-2 w-40 bg-[#2C2C2C] text-white border rounded-lg shadow-lg z-20">
              <div className="py-2">
                <div className="px-4 py-2 text-sm text-gray-300 border-b border-gray-700 max-w-[192px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {user.email}
                </div>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                >
                  Logout
                </button>
              </div>
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default HomePageNavbar;
