import React, { useState } from "react";
import "./Creator.css";
import Layout from "../../components/Layout";
import { Box, Input, Button, Menu, MenuItem, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "../../assets/HomepageAssests/filter-icon.svg";
import CreatorsSidebar from "./CreatorsSidebar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { useProjects } from "../../context/ProjectContext";
import axiosInstance from "../../services/axiosinstance";
import { toast } from "react-toastify";
import { MdDelete, MdEdit } from "react-icons/md";
import { useFlags } from "launchdarkly-react-client-sdk";
const Creator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    projectsList,
    selectedProject,
    loading,
    handleProjectSelect,
    fetchProjectsForCurrentOrg,
  } = useProjects();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [projectName, setProjectName] = useState("");

  // Add state for delete functionality
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteProject, setDeleteProject] = useState(null);
  const [confirmText, setConfirmText] = useState("");

  // Add state for edit functionality
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [newProjectName, setNewProjectName] = useState("");
  const { marketplaceFlag } = useFlags();
  // Get current section title based on route
  const getCurrentSection = () => {
    const path = location.pathname;
    if (path.includes("/creator/agents")) return "Agents";
    if (path.includes("/creator/files")) return "Files";
    if (path.includes("/creator/knowledge-bases")) return "Knowledge Bases";
    if (path.includes("/creator/project-members")) return "Project Members";
    if (path.includes("/creator/create-agent")) return "Create Agent";
    return "Agents"; // Default to Agents
  };

  const handleChangeSearch = (e) => {
    // Handle search functionality
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectProject = (project) => {
    handleProjectSelect(project);
    handleClose();
  };

  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
    handleClose();
  };

  // Add edit project function
  const handleEditProject = (project) => {
    setEditingProject(project);
    setNewProjectName(project.name);
    setShowEditModal(true);
    handleClose();
  };

  // Add save edit function
  const handleSaveEdit = async () => {
    if (!editingProject) return;
    try {
      await axiosInstance.patch(
        `/v1/projects/${editingProject.id}/update-name`,
        {
          name: newProjectName,
        },
      );

      toast.success("Project updated successfully");
      setShowEditModal(false);

      // Update the project name in the context
      fetchProjectsForCurrentOrg(true);

      // If the edited project was selected, update selection
      if (selectedProject === editingProject.name) {
        handleProjectSelect({ ...editingProject, name: newProjectName });
      }
    } catch (error) {
      toast.error(
        "Error updating project: " +
          (error.response?.data?.message || error.message),
      );
    }
  };

  // Add the delete project function
  const handleDeleteProject = async () => {
    try {
      const userorgId = localStorage.getItem("userorganization_id");

      await axiosInstance.delete(
        `/v1/projects/${deleteProject.id}/user/${userorgId}`,
      );

      toast.success("Project deleted successfully");
      setShowDeleteModal(false);
      setConfirmText("");

      // Refresh projects list
      fetchProjectsForCurrentOrg(true);

      // If the deleted project was selected, reset selection
      if (selectedProject === deleteProject.name) {
        handleProjectSelect(projectsList[0] || { name: "Select a project" });
      }
    } catch (error) {
      toast.error(
        "Error deleting project: " +
          (error.response?.data?.message || error.message),
      );
    }
  };

  const handleCreateProject = async () => {
    try {
      const organizationId = localStorage.getItem("selectedOrg");
      const userorgId = localStorage.getItem("userorganization_id");

      if (!organizationId || !userorgId) {
        toast.error("Organization or user data missing");
        return;
      }

      await axiosInstance.post(`/v1/projects/create`, {
        name: projectName,
        organization_id: organizationId,
        user_organization_id: userorgId,
      });

      toast.success("Project created successfully");
      setShowCreateModal(false);
      setProjectName("");

      // Refresh projects list
      fetchProjectsForCurrentOrg(true);
    } catch (error) {
      toast.error(
        "Error creating project: " +
          (error.response?.data?.message || error.message),
      );
    }
  };

  return (
    <Layout title="Pragya | Creator">
      <Box className="flex flex-col h-[calc(100vh-90px)] overflow-hidden relative bg-[#070707] ">
        {/* Project Header */}
        <Box className="w-full py-4 ">
          <Box className="flex justify-between items-center">
            <div className="flex items-center gap-8">
              <div
                className="w-[260px] p-2 bg-[#2b2b2b] rounded-lg outline outline-[0.50px] outline-offset-[-0.50px] inline-flex flex-col justify-center items-start"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                <div className="self-stretch inline-flex justify-start items-center gap-2">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="28" height="28" rx="14" fill="#2B6BE6" />
                    <path
                      d="M14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7ZM14 19C11.2386 19 9 16.7614 9 14C9 11.2386 11.2386 9 14 9C16.7614 9 19 11.2386 19 14C19 16.7614 16.7614 19 14 19Z"
                      fill="white"
                    />
                    <path
                      d="M14 11C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17C15.6569 17 17 15.6569 17 14C17 12.3431 15.6569 11 14 11ZM14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15Z"
                      fill="white"
                    />
                  </svg>
                  <div className="flex-1 justify-center text-white text-xl font-medium font-['Inter'] leading-7">
                    {loading ? "Loading..." : selectedProject}
                  </div>
                  <FaChevronDown className="text-[#CDCDCD] mr-1" />
                </div>
              </div>
              <h2 className="text-white text-2xl font-medium">
                {getCurrentSection()}
              </h2>
            </div>
            <Box className="flex items-center gap-4">
              {marketplaceFlag && (
                <>
                  <Input
                    sx={{
                      textDecoration: "none",
                      border: "0.2px solid var(--Border, #CDCDCD)",
                      borderRadius: "8px",
                      "&:before, &:after": {
                        borderBottom: "none !important",
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottom: "none !important",
                      },
                      padding: "8px 10px",
                      "& .MuiInputBase-input": {
                        padding: "3px 5px 3px 5px",
                      },
                      background: "var(--Filler, #1C1C1C)",
                      width: "380px",
                      height: "40px",
                      color: "#CDCDCD",
                    }}
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={handleChangeSearch}
                    startAdornment={<SearchIcon className="search-icon-css" />}
                  />
                  <Box className="filter-icon-container">
                    <img src={FilterIcon} alt="filter" />
                  </Box>
                </>
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    backgroundColor: "#2B2B2B",
                    color: "#FFF",
                    borderRadius: "8px",
                    border: "0.2px solid #CDCDCD",
                    mt: "4px",
                    width: "250px",
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#3D3D3D",
                    },
                  },
                }}
              >
                {loading ? (
                  <MenuItem disabled>Loading projects...</MenuItem>
                ) : projectsList.length === 0 ? (
                  <MenuItem disabled>No projects available</MenuItem>
                ) : (
                  projectsList.map((project) => (
                    <MenuItem
                      key={project.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        onClick={() => handleSelectProject(project)}
                        style={{ width: "70%" }}
                      >
                        {project.name}
                      </div>
                      <div className="flex gap-3">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditProject(project);
                          }}
                          style={{ color: "#1677FF", cursor: "pointer" }}
                        >
                          <MdEdit size={18} />
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteProject(project);
                            setShowDeleteModal(true);
                            handleClose();
                          }}
                          style={{ color: "#FF5252", cursor: "pointer" }}
                        >
                          <MdDelete size={18} />
                        </div>
                      </div>
                    </MenuItem>
                  ))
                )}
                <Divider sx={{ backgroundColor: "#4D4D4D", margin: "8px 0" }} />
                <MenuItem
                  onClick={handleOpenCreateModal}
                  sx={{
                    color: "#1677FF",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "8px" }}>+</span>
                  Add project
                </MenuItem>
              </Menu>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2B6BE6",
                  color: "#fff",
                  borderRadius: "8px",
                  padding: "8px 16px",
                  height: "40px",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  "&:hover": {
                    backgroundColor: "#2457b5",
                  },
                }}
                onClick={() => navigate("/creator/create-agent")}
              >
                <span className="text-[14px] font-medium">+ Create Agent</span>
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Content container with border and background */}
        <Box className="flex-1 pb-2">
          <Box className="flex h-[calc(100vh-160px)] w-full rounded-lg border border-[#2B2B2B] bg-[#1C1C1C] overflow-hidden">
            <Box className="flex-shrink-0 h-full pt-3">
              <CreatorsSidebar />
            </Box>
            <Box className="flex-1 p-6 overflow-y-auto custom-scrollbar">
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Project Creation Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
            <div className="flex items-center space-x-2 py-4">
              <svg
                width="25"
                height="25"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="28" height="28" rx="14" fill="#2B6BE6" />
                <path
                  d="M14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7ZM14 19C11.2386 19 9 16.7614 9 14C9 11.2386 11.2386 9 14 9C16.7614 9 19 11.2386 19 14C19 16.7614 16.7614 19 14 19Z"
                  fill="white"
                />
                <path
                  d="M14 11C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17C15.6569 17 17 15.6569 17 14C17 12.3431 15.6569 11 14 11ZM14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15Z"
                  fill="white"
                />
              </svg>
              <h3 className="text-xl text-white font-semibold">
                Create Project
              </h3>
            </div>

            {/* Project Name Input */}
            <div className="mb-4">
              <label className="block text-white font-medium mb-2">
                Project Name
              </label>
              <input
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="Enter a name"
                className="w-full px-4 py-2 bg-[#191919] text-white rounded focus:outline-none"
              />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-6">
              <button
                className="bg-[#FFFFFF] px-10 py-2 mr-2 rounded-lg text-[16px] font-medium font-inter text-black"
                onClick={() => setShowCreateModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#1677FF] px-10 py-2 rounded-lg text-[16px] font-medium font-inter text-white"
                onClick={handleCreateProject}
                disabled={!projectName.trim()}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Project Edit Modal */}
      {showEditModal && editingProject && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
            <div className="flex items-center space-x-2 py-4">
              <svg
                width="25"
                height="25"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="28" height="28" rx="14" fill="#2B6BE6" />
                <path
                  d="M14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7ZM14 19C11.2386 19 9 16.7614 9 14C9 11.2386 11.2386 9 14 9C16.7614 9 19 11.2386 19 14C19 16.7614 16.7614 19 14 19Z"
                  fill="white"
                />
                <path
                  d="M14 11C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17C15.6569 17 17 15.6569 17 14C17 12.3431 15.6569 11 14 11ZM14 15C13.4477 15 13 14.5523 13 14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14C15 14.5523 14.5523 15 14 15Z"
                  fill="white"
                />
              </svg>
              <h3 className="text-xl text-white font-semibold">
                Edit Project Name
              </h3>
            </div>

            {/* Project Name Input */}
            <div className="mb-4">
              <label className="block text-white font-medium mb-2">
                Project Name
              </label>
              <input
                type="text"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
                placeholder="Enter a new name"
                className="w-full px-4 py-2 bg-[#191919] text-white rounded focus:outline-none"
              />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-6">
              <button
                className="bg-[#FFFFFF] px-10 py-2 mr-2 rounded-lg text-[16px] font-medium font-inter text-black"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#1677FF] px-10 py-2 rounded-lg text-[16px] font-medium font-inter text-white"
                onClick={handleSaveEdit}
                disabled={!newProjectName.trim()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Project Delete Confirmation Modal */}
      {showDeleteModal && deleteProject && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#27282B] text-white w-[400px] p-6 rounded-lg shadow-lg border border-gray-600">
            {/* Header */}
            <div className="flex items-center mb-4">
              <span className="text-yellow-400 text-xl mr-2">⚠️</span>
              <h2 className="text-lg font-semibold">Delete Confirmation</h2>
            </div>

            {/* Message */}
            <p className="text-sm text-gray-300 mb-4">
              This action cannot be undone. The project and all its data will be
              permanently deleted.
            </p>
            <p className="text-sm mb-4">
              Are you sure you want to delete project{" "}
              <strong>"{deleteProject.name}"</strong>? Please type{" "}
              <strong>'delete'</strong> to confirm.
            </p>

            {/* Input */}
            <input
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              placeholder="Type delete"
              className="w-full p-2 rounded bg-[#1C1C1C] border border-gray-500 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />

            {/* Buttons */}
            <div className="flex justify-end mt-4 space-x-2">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setConfirmText("");
                }}
                className="bg-[#FFFFFF] px-6 py-2 mr-2 rounded-lg text-[16px] font-medium font-inter text-black"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteProject}
                disabled={confirmText.toLowerCase() !== "delete"}
                className={`px-6 py-2 mr-2 rounded-lg text-[16px] font-medium font-inter ${
                  confirmText.toLowerCase() === "delete"
                    ? "bg-red-600 hover:bg-red-500"
                    : "bg-gray-700 cursor-not-allowed"
                } text-white`}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Creator;
