import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import headerImage from "../assets/hero_screen.png";
import heroScreens from "../assets/HeroPageAssets/hero_screens.png";
import microsoftLogo from "../assets/microsoftLogo.svg"; // Assume you have a Microsoft logo SVG file

const Hero = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/signin");
  };

  return (
    <>
      <div
        className="relative w-full min-h-screen md:h-[1000px] bg-[#050D24] flex flex-col items-center justify-start pt-16 xs:pt-20 sm:pt-24 md:justify-center md:pt-0 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 overflow-hidden"
        style={{ marginBottom: "-2px" }}
      >
        {/* Background Image Container */}
        <div
          className="absolute inset-0 -left-[150px] sm:-left-[100px] md:-left-[50px] lg:left-0"
          style={{
            backgroundImage: `url(${headerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />

        {/* Grid Overlay */}
        <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(173, 216, 230, 0.08) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(173, 216, 230, 0.08) 1px, transparent 1px)
            `,
            backgroundSize: "100px 100px", // Adjusted for smaller screens
            maskImage:
              "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 1) 100%)",
            WebkitMaskImage:
              "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 1) 100%)",
          }}
        />

        {/* Additional diagonal lines for more tech feel */}
        <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `
              linear-gradient(45deg, rgba(10, 94, 243, 0.03) 1px, transparent 1px),
              linear-gradient(-45deg, rgba(0, 208, 255, 0.03) 1px, transparent 1px)
            `,
            backgroundSize: "150px 150px", // Adjusted for smaller screens
            maskImage:
              "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 1) 100%)",
            WebkitMaskImage:
              "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 1) 100%)",
          }}
        />

        <div className="flex flex-col md:flex-row items-center justify-center w-full relative z-10 mt-4 sm:mt-6 md:mt-0">
          {/* Left Side Text Content */}
          <div className="text-white text-center md:text-left w-full md:w-1/2 lg:w-1/2 xl:w-1/2 max-w-[600px] px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
            <h1 className="text-2xl xs:text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-tight mb-3 xs:mb-4 md:mb-6">
              Transform Your <br className="hidden md:block" />
              Data into Intelligent <br className="hidden md:block" />
              Conversations
            </h1>
            <p className="text-xs xs:text-sm sm:text-base md:text-base lg:text-lg xl:text-xl mb-3 xs:mb-4 md:mb-6 leading-relaxed mx-auto md:mx-0 max-w-[90%] md:max-w-full">
              Pragya empowers organizations to build custom AI assistants using
              their private data. Unlock the full potential of your
              information—no coding required.
            </p>
            {/* Desktop Get Started Button - Hidden on mobile */}
            <div className="hidden md:flex justify-center md:justify-start">
              <div
                onClick={handleGetStarted}
                className="w-[200px] xs:w-[220px] sm:w-[250px] md:w-[300px] h-[45px] sm:h-[50px] p-2 sm:p-2.5 bg-gradient-to-r from-[#0a5ef3] to-[#00d0ff] rounded-[40px] border-2 border-[#00d0fe] inline-flex justify-center items-center gap-2.5 cursor-pointer hover:opacity-90 transition-opacity"
              >
                <div className="relative text-center justify-start text-white text-base sm:text-lg font-semibold font-['Inter']">
                  Get Started
                </div>
              </div>
            </div>
          </div>

          {/* Right Side Image (hero_screens.png) */}
          <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 flex justify-center md:justify-end max-w-[900px] relative mt-6 xs:mt-8 md:mt-0">
            <motion.img
              src={heroScreens}
              alt="Hero Screens"
              className="w-[320px] xs:w-[350px] sm:w-[400px] md:w-[500px] lg:w-[600px] xl:w-[700px] h-auto object-contain"
              animate={{
                y: [0, -9, 0],
              }}
              transition={{
                duration: 2.5,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
              }}
            />

            {/* Microsoft Partner Section with Shine Effect */}
            <div className="absolute -bottom-16 sm:-bottom-20 md:-bottom-24 lg:-bottom-28 xl:-bottom-32 left-1/2 md:left-auto md:right-0 transform -translate-x-1/2 md:translate-x-0">
              <div
                className="shine-effect w-[150px] xs:w-[180px] sm:w-[200px] md:w-[250px] p-1.5 sm:p-2 md:p-3 flex flex-col items-start gap-1 sm:gap-1.5 md:gap-2 bg-black bg-opacity-70 duration-300 hover:shadow-[0_0_15px_rgba(173,208,255,0.5)] hover:border-[#ADD0FF]/80"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #ADD0FF",
                }}
              >
                {/* Logo and Microsoft Text */}
                <div className="flex items-center gap-1 sm:gap-1.5 md:gap-2">
                  <img
                    src={microsoftLogo}
                    alt="Microsoft Logo"
                    className="w-auto h-[8px] xs:h-[10px] sm:h-[12px] md:h-[16px]"
                  />
                  <p className="text-white font-poppins text-[8px] xs:text-[10px] sm:text-[12px] md:text-[14px] font-medium leading-normal tracking-[-0.28px]">
                    Microsoft
                  </p>
                </div>

                {/* Partner Text */}
                <div className="flex flex-col gap-0.5">
                  <p className="text-white font-poppins text-[7px] xs:text-[8px] sm:text-[10px] md:text-[12px] font-normal leading-normal tracking-[-0.32px]">
                    Proud to be a partner with
                  </p>
                  <p className="text-white/80 font-poppins text-[10px] xs:text-[12px] sm:text-[14px] md:text-[16px] font-semibold leading-[100%]">
                    Microsoft for Startups
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Get Started Button - Only visible on mobile, positioned below Microsoft badge */}
        <div className="md:hidden flex justify-center w-full mt-24 xs:mt-26 sm:mt-32 relative z-20">
          <div
            onClick={handleGetStarted}
            className="w-[180px] xs:w-[200px] sm:w-[220px] h-[40px] sm:h-[45px] p-2 sm:p-2.5 bg-gradient-to-r from-[#0a5ef3] to-[#00d0ff] rounded-[40px] border-2 border-[#00d0fe] inline-flex justify-center items-center gap-2.5 cursor-pointer hover:opacity-90 transition-opacity"
          >
            <div className="relative text-center justify-start text-white text-sm sm:text-base font-semibold font-['Inter']">
              Get Started
            </div>
          </div>
        </div>
      </div>

      {/* Custom CSS for animations */}
      <style jsx>{`
        .shine-effect {
          position: relative;
          overflow: hidden;
        }

        .shine-effect:before {
          content: "";
          position: absolute;
          top: -50%;
          left: -60%;
          width: 20%;
          height: 200%;
          opacity: 0;
          transform: rotate(30deg);
          background: rgba(255, 255, 255, 0.13);
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .shine-effect:hover:before {
          opacity: 1;
          left: 130%;
          transition:
            left 1.5s ease,
            top 1.5s ease,
            opacity 0.3s ease;
        }

        .shine-effect:active:before {
          opacity: 0;
        }
      `}</style>
    </>
  );
};

export default Hero;
