import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import KnowledgeBase from "./pages/Knowledgebase";
import ProtectedRoute from "./components/Protectedroute";
import CreateAssistant from "./pages/Assistant";
import Signup from "./pages/Signup";
import SignIn from "./pages/Login";
import Layout from "./pages/Layout";
import Files from "./pages/Files";
import Landing from "./pages/Landing";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/Resetpassword";
import Billing from "./pages/Billing";
import VerifyEmail from "./pages/Verifyemail";
import OrganizationSetup from "./pages/Organisation";
import MembersPage from "./Organization/Members";
import SetPassword from "./Organization/Invite";
import PrivacyPolicy from "./pages/Policy";
import TermsOfService from "./pages/Terms";
import Projectmembers from "./pages/ProjectMembers";
import Pricing from "./Organization/Plans";
import OrganizationDetails from "./Organization/OrganizationDetails";
import MemberPageSidebar from "./Organization/MemberPageSidebar.js";
import AssistantWidget from "./components/assistant-chat.jsx";
import ChatInterface from "./pages/ChatInterface/ChatInterface.jsx";
import VerifyEmailSent from "./pages/VerifyEmailSent";
import EmailVerified from "./pages/EmailVerified";
import UnverifiedEmail from "./pages/UnverifiedEmail";
import HomePage from "./pages/HomePage/HomePage.jsx";
import HomePageLayout from "./components/HomePage/HomePageLayout/HomePageLayout.jsx";
import MarketPlace from "./pages/MarketPlace/MarketPlace.jsx";
import Settings from "./pages/Settings/Settings.jsx";
import Creator from "./pages/Creator/Creator.jsx";
import Agents from "./pages/Creator/Agents.jsx";
import CreatorFiles from "./pages/Creator/Files.jsx";
import KnowledgeBases from "./pages/Creator/KnowledgeBases.jsx";
import ProjectMembers from "./pages/Creator/ProjectMembers.jsx";
import CreateAgent from "./pages/Creator/CreateAgent.jsx";
import CreatorFilesInFolder from "./pages/Creator/CreatorFilesInFolder.jsx";
import { UserProvider } from "./context/UserContext";
import { ProjectProvider } from "./context/ProjectContext";
import AgentChatInterface from "./pages/AgentChatInterface/AgentChatInterface.jsx";
import { withLDProvider } from "launchdarkly-react-client-sdk";

function App() {
  return (
    <UserProvider>
      <>
        <AssistantWidget />
        {/* Other Components */}

        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/verify-email" element={<VerifyEmailSent />} />
            <Route path="/email-verified" element={<EmailVerified />} />
            <Route path="/unverified-email" element={<UnverifiedEmail />} />
            <Route
              path="/createorganization"
              element={<ProtectedRoute element={<OrganizationSetup />} />}
            />
            <Route
              path="/organizations"
              element={<ProtectedRoute element={<MembersPage />} />}
            />
            <Route element={<HomePageLayout />}>
              <Route
                path="/home-page"
                element={<ProtectedRoute element={<HomePage />} />}
              />
              <Route
                path="/agent/:agentId/chat"
                element={<ProtectedRoute element={<AgentChatInterface />} />}
              />
              {/* Wrap Creator routes with ProjectProvider */}
              <Route
                path="/creator"
                element={
                  <ProtectedRoute
                    element={
                      <ProjectProvider>
                        <Creator />
                      </ProjectProvider>
                    }
                  />
                }
              >
                <Route path="agents" element={<Agents />} />
                <Route path="files" element={<CreatorFiles />} />
                <Route path="knowledge-bases" element={<KnowledgeBases />} />
                <Route path="project-members" element={<ProjectMembers />} />
              </Route>
              <Route
                path="/creator/create-agent"
                element={
                  <ProtectedRoute
                    element={
                      <ProjectProvider>
                        <CreateAgent />
                      </ProjectProvider>
                    }
                  />
                }
              />
              <Route
                path="/creator/folder/:folderId"
                element={
                  <ProtectedRoute
                    element={
                      <ProjectProvider>
                        <CreatorFilesInFolder />
                      </ProjectProvider>
                    }
                  />
                }
              />
              <Route
                path="/market-place"
                element={<ProtectedRoute element={<MarketPlace />} />}
              />
              <Route
                path="/settings"
                element={<ProtectedRoute element={<Settings />} />}
              />
            </Route>

            <Route
              path="/organizations/details"
              element={<ProtectedRoute element={<OrganizationDetails />} />}
            />
            <Route
              path="/organizations/billing"
              element={<ProtectedRoute element={<Billing />} />}
            />
            <Route
              path="/organizations/members"
              element={<ProtectedRoute element={<MemberPageSidebar />} />}
            />

            <Route
              path="/organizations/plans"
              element={<ProtectedRoute element={<Pricing />} />}
            />

            <Route path="/projects/:projectId" element={<Layout />}>
              <Route
                path="knowledgebase"
                element={<ProtectedRoute element={<KnowledgeBase />} />}
              />
              <Route
                path="assistants"
                element={<ProtectedRoute element={<CreateAssistant />} />}
              />
              {/* Dynamic Route for Assistant Details */}
              <Route
                path="assistants/:assistant_id"
                element={<ProtectedRoute element={<CreateAssistant />} />}
              />
              <Route
                path="files"
                element={<ProtectedRoute element={<Files />} />}
              />
              <Route
                path="assistants/:assistant_id/chat"
                element={<ProtectedRoute element={<ChatInterface />} />}
              />
              <Route
                path="members"
                element={<ProtectedRoute element={<Projectmembers />} />}
              />
              {/* <Route path="billings" element={<ProtectedRoute element={<Billing />} />} /> */}
            </Route>
          </Routes>
        </Router>
      </>
    </UserProvider>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LDCLIENT_ID,
  context: {
    kind: "user",
    key: "user-key-123abc",
    name: "Sandy Smith",
    email: "sandy@example.com",
  },
})(App);
