import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store.js";

// Initialize authentication check on app start
(function initializeAuthCheck() {
  // List of public pages that should redirect to /organizations for authenticated users
  const publicPages = ["/", "/signin", "/signup"];

  // Function to check authentication and redirect if needed
  const checkAuthAndRedirect = () => {
    const isAuthenticated =
      localStorage.getItem("access_token") &&
      localStorage.getItem("isLoggedIn") === "true";

    // If authenticated and on a public page, redirect to organizations
    if (isAuthenticated) {
      const currentPath = window.location.pathname;
      if (publicPages.includes(currentPath)) {
        window.location.href = "/home-page";
      }
    }
  };

  // Check auth immediately when app loads
  checkAuthAndRedirect();

  // Also set up a listener for storage changes (in case login happens in another tab)
  window.addEventListener("storage", (event) => {
    if (event.key === "access_token" || event.key === "isLoggedIn") {
      checkAuthAndRedirect();
    }
  });
})();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
