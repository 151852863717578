import React, { useEffect, useState } from "react";
import "./AgentDetailsModal.css";
import { Modal, Box, Typography, Avatar, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DollorIcon from "../../../assets/HomepageAssests/dollor-icon.svg";
import ChatIcon from "../../../assets/HomepageAssests/chat-icon.svg";
import axiosInstance from "../../../components/axiosInstance";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "auto",
  bgcolor: "#1C1C1C",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  outline: "none",
  border: "1px solid #CDCDCD",
};

const AgentDetailsModal = ({ open, onClose, agentId, selectedOrgId }) => {
  const navigate = useNavigate();
  const [singleAgentDetails, setSingleAgentDetails] = useState(null);

  const handleStartChat = () => {
    onClose();
    navigate(`/agent/${agentId}/chat`);
  };

  useEffect(() => {
    const getSingleAgentDetails = async () => {
      try {
        if (!agentId) {
          return;
        }

        const response = await axiosInstance.get(
          `/v1/usersV2/detail/${agentId}`,
        );
        setSingleAgentDetails(response.data);
      } catch (error) {
        setSingleAgentDetails(null);
      }
    };

    if (open && agentId) {
      setSingleAgentDetails(null); // Clear previous data
      getSingleAgentDetails();
    }
  }, [agentId, selectedOrgId, open]);

  // Early return for cases where the modal is open but we don't have an agent ID
  if (open && !agentId) {
    return (
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={style}
          className="agent-details-modal-container flex justify-center items-center"
        >
          <div className="flex flex-col items-center">
            <Typography variant="h6" className="mb-4 text-red-500">
              Error: No Agent Selected
            </Typography>
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style} className="agent-details-modal-container">
        <Box className="flex flex-col gap-4">
          {/* Header */}
          <Box className="flex justify-between items-center">
            <Typography variant="h6" className="agent-details-modal-title">
              {singleAgentDetails?.agent?.agent_name || "Agent Details"}
            </Typography>
            <CloseIcon
              className="text-white cursor-pointer"
              onClick={onClose}
            />
          </Box>

          {/* Content */}
          <Box className="created-description-container">
            <Typography variant="h6" className="created-description-title">
              Created by
            </Typography>
            <Box className="flex items-center gap-2">
              <Avatar className="!w-[18px] !h-[18px]" />
              <div className="agent-created-by-name">
                {singleAgentDetails?.agent?.creator_name || "Loading..."}
              </div>
            </Box>
            <Typography variant="h6" className="created-description-title">
              Description
            </Typography>
            <div className="h-[1px] w-full bg-[#CDCDCD]"></div>
            <div className="agent-description-text">
              {singleAgentDetails?.agent?.agent_description ||
                "Loading description..."}
            </div>
          </Box>
          <Box className="cost-base-model-container">
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Cost
                </Typography>
                <Box className="flex items-center gap-2">
                  <img src={DollorIcon} alt="dollor-icon" />
                  <div className="agent-created-by-name">
                    {singleAgentDetails?.usage_stats?.is_free !== undefined
                      ? singleAgentDetails?.usage_stats?.is_free
                        ? "Free"
                        : "Paid"
                      : "-"}
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Knowledge Base
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">
                    {singleAgentDetails?.knowledge_base?.name || "-"}
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="model-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  AI Model
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">
                    {singleAgentDetails?.agent?.model || "-"}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="cost-base-model-container">
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Organization
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">
                    {singleAgentDetails?.organization_name || "-"}
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Used By
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">
                    {singleAgentDetails?.usage_stats?.total_users
                      ? `${singleAgentDetails?.usage_stats?.total_users}+ Users`
                      : "-"}
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="model-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Chats
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">
                    {singleAgentDetails?.usage_stats?.chats_this_month
                      ? `${singleAgentDetails?.usage_stats?.chats_this_month}+ Chats this Month`
                      : "-"}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="flex justify-end">
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#007bff",
                color: "#fff",
                borderRadius: "8px",
                padding: "8px 10px",
                width: "180px",
                height: "40px",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={handleStartChat}
            >
              <img src={ChatIcon} alt="chat-icon" />
              <span className="text-[14px] font-medium">Chat with Agent</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AgentDetailsModal;
