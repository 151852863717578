import { createContext, useState, useContext, useEffect } from "react";
import axiosInstance from "../services/axiosinstance";

// Create context
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch user data
  const fetchUserData = async () => {
    setLoading(true);
    try {
      console.log("Fetching user data...");
      const token = localStorage.getItem("access_token");
      console.log("Token available:", !!token);

      const response = await axiosInstance.get("/users/me");
      console.log("User data received:", response.data);

      const userData = response.data;
      setUser(userData);

      // Store userId in localStorage
      if (userData && userData.id) {
        localStorage.setItem("userId", userData.id);
        console.log("User ID stored in localStorage:", userData.id);
      }

      // Now fetch organizations for the user
      await fetchOrganizations();

      setError(null);
    } catch (err) {
      console.error("Error fetching user data:", err);

      // Don't logout on fetch error - only log the error
      setError("Failed to fetch user data");

      // We still want to keep any existing user data
      if (!user) {
        setUser(null);
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch user's organizations
  const fetchOrganizations = async () => {
    try {
      console.log("Fetching organizations data...");
      const response = await axiosInstance.get("/v1/organizations/user");
      console.log("Organizations data received:", response.data);

      const orgs = response.data;
      setOrganizations(orgs);

      // Store the first organization ID in localStorage
      if (orgs && orgs.length > 0) {
        localStorage.setItem("organizationId", orgs[0].id);
        console.log("Organization ID stored in localStorage:", orgs[0].id);

        // Also set as selected organization if your app uses this
        localStorage.setItem("selectedOrg", orgs[0].id);

        // Fetch users for this organization
        await fetchOrganizationUsers(orgs[0].id);
      }
    } catch (err) {
      console.error("Error fetching organizations:", err);
    }
  };

  // Function to fetch users for a specific organization
  const fetchOrganizationUsers = async (organizationId) => {
    try {
      console.log("Fetching organization users data...");
      const response = await axiosInstance.get(
        `/v1/organizations/${organizationId}/users`,
      );
      console.log("Organization users data received:", response.data);

      const users = response.data;
      setOrgUsers(users);

      // Extract user IDs and store in localStorage
      if (users && users.length > 0) {
        // Store the id field instead of user_id
        const userIds = users.map((user) => user.id);
        localStorage.setItem("userOrgIds", JSON.stringify(userIds));
        console.log("User organization IDs stored in localStorage:", userIds);

        // Find the current user's userorganization_id (membership ID in this organization)
        const currentUserId = localStorage.getItem("userId");
        const currentUserOrg = users.find(
          (user) => user.user_id === currentUserId,
        );

        if (currentUserOrg) {
          localStorage.setItem("userorganization_id", currentUserOrg.id);
          console.log(
            "userorganization_id stored in localStorage:",
            currentUserOrg.id,
          );

          // Now fetch projects using this userorganization_id
          await fetchProjects(organizationId, currentUserOrg.id);
        }
      } else {
        // If no users or empty array, store empty array
        localStorage.setItem("userOrgIds", JSON.stringify([]));
      }
    } catch (err) {
      console.error("Error fetching organization users:", err);
      // Store empty array on error
      localStorage.setItem("userOrgIds", JSON.stringify([]));
    }
  };

  // Function to fetch projects for a specific organization and user
  const fetchProjects = async (organizationId, userorganizationId) => {
    try {
      console.log("Fetching projects data...");
      const response = await axiosInstance.get(
        `/v1/projects/${organizationId}/user/${userorganizationId}/`,
      );
      console.log("Projects data received:", response.data);

      const projectsData = response.data;
      setProjects(projectsData);

      // Optionally store active project if needed
      if (projectsData && projectsData.length > 0) {
        localStorage.setItem("activeProjectId", projectsData[0].id);
      }
    } catch (err) {
      console.error("Error fetching projects:", err);
    }
  };

  // Helper function to get org user IDs from localStorage
  const getUserOrgIds = () => {
    try {
      const userIdsString = localStorage.getItem("userOrgIds");
      if (userIdsString) {
        return JSON.parse(userIdsString);
      }
      return [];
    } catch (err) {
      console.error("Error parsing userOrgIds from localStorage:", err);
      return [];
    }
  };

  // Helper function to check if a user ID is in the organization
  const isUserInOrg = (userId) => {
    const userIds = getUserOrgIds();
    return userIds.includes(userId);
  };

  // Check if user is logged in when the component mounts
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    console.log("UserContext mounted, isLoggedIn:", isLoggedIn);

    if (isLoggedIn) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, []);

  // Effect to listen for changes to selectedOrg in localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const organizationId = localStorage.getItem("selectedOrg");
      const previousOrgId = localStorage.getItem("previousSelectedOrg");

      // Only fetch if the organization has changed and exists
      if (organizationId && organizationId !== previousOrgId) {
        console.log("Selected organization changed, updating users");
        localStorage.setItem("previousSelectedOrg", organizationId);
        fetchOrganizationUsers(organizationId);
      }
    };

    // Listen for storage events (when another component changes localStorage)
    window.addEventListener("storage", handleStorageChange);

    // Initial check
    handleStorageChange();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // This function will be called after successful login
  const onLoginSuccess = async () => {
    console.log("Login success, fetching user data...");
    await fetchUserData();
  };

  // This function will be called after logout
  const logout = () => {
    console.log("Logging out, removing user data");
    localStorage.removeItem("userId");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("selectedOrg");
    localStorage.removeItem("userOrgIds");
    localStorage.removeItem("userorganization_id");
    localStorage.removeItem("activeProjectId");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("access_token");
    setUser(null);
    setOrganizations([]);
    setOrgUsers([]);
    setProjects([]);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        organizations,
        orgUsers,
        projects,
        loading,
        error,
        onLoginSuccess,
        logout,
        fetchUserData,
        fetchOrganizations,
        fetchOrganizationUsers,
        fetchProjects,
        getUserOrgIds,
        isUserInOrg,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default UserContext;
