import React, { useState, useEffect } from "react";
import AddFileIcon from "../assets/documentPageAssets/AddfileIcon.svg";
import AddFileIconGreen from "../assets/documentPageAssets/AddFileIconGreen.svg";
import AssignmentIcon from "../assets/documentPageAssets/AssignmentIcon.svg";
// import { formatCustomDateTime } from "../services/logics";
import { FaFolder, FaPlus, FaFile, FaEllipsisV, FaClock } from "react-icons/fa";

import CheckCircle from "../assets/documentPageAssets/CheckCircle.svg";
import LoadingBar3 from "../assets/documentPageAssets/LoadingBar3.gif";
import BouncingGif from "../assets/documentPageAssets/bouncingGif.gif";
import axios from "axios";
import DocumentListShimmer from "./Documentskeleton.jsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TbAlertTriangle } from "react-icons/tb";
import { useSelector } from "react-redux";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import PopupModal from "../components/PopupModal";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import SucessModal from "./SuccessModal.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import DownloadIcon from "../assets/documentPageAssets/DownloadIcon.svg";
import DraftsPagination from "./Documentpagination.jsx";
import axiosInstance from "./axiosInstance.js";
import { useDispatch } from "react-redux";
import {
  setDocumentsTrue,
  setDocumentsFalse,
} from "../redux/notificationSlice.js";
import Emptydocuments from "../assets/documentPageAssets/Emptydocuments.svg";
import Upload from "../assets/Upload.svg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
// import { FiEye } from "react-icons/fi";
import { getStoredValue } from "../services/logics.js";
import { IoEllipsisHorizontal } from "react-icons/io5";

const statusSvgGenerator = (color) => {
  const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
      <circle cx="3.5" cy="3.5" r="3.5" fill="${color}" />
    </svg>
  `;
  const base64EncodedSvg = btoa(unescape(encodeURIComponent(svgString)));
  const dataURI = `data:image/svg+xml;base64,${base64EncodedSvg}`;

  return dataURI;
};
const formatCustomDateTime = (dateString, fullYear = true) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString("en-US", {
    month: "long",
    timeZone: "UTC",
  });
  const year = fullYear
    ? date.getUTCFullYear()
    : date.getUTCFullYear().toString().slice(-2); // Conditionally format year
  const time = date
    .toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "UTC",
    })
    .toLowerCase();

  if (fullYear) {
    return `${month} ${day}, ${year}`;
  } else {
    return `${day} ${month} '${year}, ${time}`;
  }
};

const DocumentsPage = ({
  modal,
  setModal,
  viewMode,
  onFileCountChange,
  hidden,
}) => {
  // const projectId = useSelector((state) => state.project?.activeProjectId);
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("access-token");
  const API_URL = process.env.REACT_APP_API_URL;
  const [isAscending, setIsAscending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const navigate = useNavigate();
  const ownerName = localStorage.getItem("firstname");
  const [totalFiles, setTotalFiles] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null); // State for storing selected file ID
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showMoveCheckbox, setShowMoveCheckbox] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]); // Track selected files
  const [showMoveModal, setShowMoveModal] = useState(false); // Modal visibility
  const [folders, setFolders] = useState([]); // Fetched folders
  const [selectedFolder, setSelectedFolder] = useState(null); // Selected folder
  const [fileurl, setFileurl] = useState(null);
  const dispatch = useDispatch();
  const projectId = getStoredValue("activeProjectId");
  const [fileDropdown, setFileDropdown] = useState(null);
  const [role, setRole] = useState(localStorage.getItem("role"));

  // Track when the modal prop changes
  useEffect(() => {
    // If modal was true and is now false (just closed), refresh files
    if (modal === false) {
      console.log("Modal closed, refreshing files");
      // Add a small delay to ensure server has processed the upload
      setTimeout(() => {
        fetchData();
      }, 500);
    }
  }, [modal]);

  // Only fetch on initial mount
  useEffect(() => {
    console.log("DocumentsPage mounted, fetching files...");
    fetchData();
  }, []);

  useEffect(() => {
    if (showMoveModal) {
      fetchFolders();
    }
  }, [showMoveModal]);

  const fetchData = async () => {
    if (isFetching) return; // Prevent duplicate fetches
    setIsFetching(true);
    try {
      console.log("Fetching files from API for project:", projectId);
      const response = await axiosInstance.get(
        `${API_URL}/v1/user/documents/${projectId}?page=1&page_size=10`,
      );

      const data = response.data;
      console.log("Fetched files:", data);
      console.log("Total files count:", data.total);

      const filteredData = data.documents.map((file) => ({
        id: file.id,
        uploaded_on: file.uploaded_on,
        name: file.name,
        status: file.status,
        url: file.url,
        size: (file.size / 1024).toFixed(2) + " KB",
      }));

      setTotalFiles(data.total);
      handleToggleNotification(data.total);

      // Notify parent component about file count
      if (onFileCountChange) {
        console.log("Updating parent component with file count:", data.total);
        onFileCountChange(data.total);
      }

      setUploadedFiles(filteredData);
    } catch (error) {
      console.error("Error fetching files:", error);
      console.error("Error details:", error.response?.data);
      toast.error(error.response?.data?.detail || "Failed to fetch files");
    } finally {
      setIsFetching(false);
      setIsInitialLoading(false);
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/folders/${projectId}/`,
      );
      setFolders(response.data);
    } catch (error) {
      toast.error("Failed to fetch folders.");
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedFiles((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((fileId) => fileId !== id)
        : [...prevSelected, id],
    );
  };
  const uncheck = (reset = false) => {
    if (reset) {
      setSelectedFiles([]); // Uncheck all checkboxes
    }
  };

  const handleMove = async () => {
    if (!selectedFolder) {
      toast.error("Please select a folder.");
      return;
    }
    try {
      await axiosInstance.post(
        `${API_URL}/v1/folders/${projectId}/assign-files/${selectedFolder}`,
        {
          file_ids: selectedFiles,
        },
      );
      toast.success("Documents moved successfully.");
      setShowMoveModal(false);
      setSelectedFiles([]);
      fetchData();
    } catch (error) {
      toast.error("Failed to move documents.");
    }
  };
  const handleToggleNotification = (totalFiles) => {
    if (totalFiles > 0) {
      dispatch(setDocumentsFalse());
    } else if (totalFiles <= 0) {
      dispatch(setDocumentsTrue());
    }
  };

  const handleCloseModal = () => {
    setFile(null);
  };
  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false);
  };
  const handleDelete = () => {
    setShowDeleteModal(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e, type) => {
    e.preventDefault(); // Prevent default behavior
    setIsDragOver(false); // Reset drag-over state

    let selectedFile;
    if (type === "drop") {
      selectedFile = e.dataTransfer.files[0]; // Get the dropped file
    } else {
      selectedFile = e.target.files[0]; // Get the file from input
    }

    // Validate file format
    if (selectedFile && selectedFile.type !== "application/pdf") {
      setErrorMessage("*Only .pdf files are allowed.");
      setFile(null);
      return;
    } else {
      // If valid, proceed with the upload
      setFile(selectedFile);
      setErrorMessage(""); // Clear any existing error message
      setUploadProgress(0);
      startUpload(selectedFile); // Start the upload
    }
  };

  function convertDateReverse(dateString) {
    const originalFormat = formatCustomDateTime(dateString);
    const [month, day, year] = originalFormat.replace(",", "").split(" ");
    return `${day} ${month} ${year}`; // Reverse the day and month without commas
  }

  const startUpload = async (uploadedFile) => {
    const maxSizeInBytes = 50 * 1024 * 1024;

    if (uploadedFile.size > maxSizeInBytes) {
      setFile(null);
      toast.error("File size exceeds the limit of 50 MB.");
      return;
    }

    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("folder_id", "");
    try {
      setIsUploading(true);
      setUploadProgress(0);

      const response = await axiosInstance.post(
        `${API_URL}/v1/user/documents/${projectId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            setUploadProgress(progress);
          },
        },
      );

      const data = response.data;
      console.log("Upload successful:", data);

      // Update the file list immediately - add to existing list instead of refetching
      const newFile = {
        id: data.id,
        name: data.name,
        status: "Processing",
        uploaded_on: data.uploaded_on,
        url: data.url,
        size: (data.size / 1024).toFixed(2) + " KB",
      };

      setUploadedFiles((prevFiles) => [newFile, ...prevFiles]);

      // Update total files count only once
      const newTotal = totalFiles + 1;
      setTotalFiles(newTotal);
      handleToggleNotification(newTotal);

      // Notify parent component only once
      if (onFileCountChange) {
        onFileCountChange(newTotal);
      }

      setUploadProgress(100);
      toast.success("File uploaded successfully!");

      // Reset upload status but keep modal open
      setTimeout(() => {
        setIsUploading(false);
        // Modal will stay open until user clicks Done or X

        // Don't call fetchData() here - we already updated the UI
      }, 500);
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(error.response?.data?.detail || "Upload failed");
      setUploadProgress(0);
      setIsUploading(false);
      setFile(null);
    }
  };

  const handlePageChange = (page) => {
    setPageNum(page);
  };

  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    try {
      const res = await axiosInstance.delete(
        `${API_URL}/v1/user/documents/${selectedFileId}`,
      );
      const data = res.data;
      toast.success("Deleted Successfully");
      // Fetch the updated list of files after deletion
      const updatedFiles = uploadedFiles.filter(
        (file) => file.id !== selectedFileId,
      );
      setUploadedFiles(updatedFiles);

      // Update total files count
      const newTotalFiles = totalFiles - 1;
      setTotalFiles(newTotalFiles);
      handleToggleNotification(newTotalFiles);

      // Notify parent component about updated file count
      if (onFileCountChange) {
        onFileCountChange(newTotalFiles);
      }

      // If the current page becomes empty after deletion, move to the previous page
      if (updatedFiles.length === 0 && pageNum > 1) {
        setPageNum((prevPage) => prevPage - 1);
      } else {
        fetchData(); // If there are still documents left, simply refresh the current page
      }
      setShowDeleteModal(false);
      setIsDeleting(false);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
      setIsDeleting(false);
    }
  };
  const handleDownloadClick = (doc) => {
    setSelectedFile(doc);
    console.log(selectedFile);
    // Store the file details
    setShowDownloadModal(true); // Show the modal
  };

  // Function to initiate the download inside the modal
  const handleConfirmDownload = () => {
    if (selectedFile) {
      const link = document.createElement("a");
      link.href = selectedFile.url;
      link.download = selectedFile.name;
      link.click();
    }
    setShowDownloadModal(false);
  };

  const handleViewClick = async () => {
    if (selectedFile) {
      try {
        setSelectedFileId(selectedFile.id);

        // Show loading indicator
        toast.info("Preparing file for viewing...");

        // Request secure URL from backend
        const res = await axiosInstance.get(
          `${API_URL}/v1/user/documents/view/${selectedFile.id}`,
        );

        // Get the URL from response
        const url = res.data;
        setFileurl(url);

        // Check if it's a PDF file
        if (selectedFile.name.toLowerCase().endsWith(".pdf")) {
          // First try: Open the PDF directly in a new tab
          // This often works best for properly configured PDFs with correct content-type
          const directWindow = window.open(url, "_blank");

          // If popup was blocked or direct opening doesn't work well, offer the enhanced viewer
          setTimeout(() => {
            if (
              !directWindow ||
              directWindow.closed ||
              directWindow.closed === undefined
            ) {
              toast.warning(
                "The PDF may not display correctly. Opening enhanced viewer...",
              );

              // Create enhanced PDF viewer with fallback options
              const enhancedWindow = window.open("", "_blank");

              if (enhancedWindow) {
                enhancedWindow.document.write(`
                  <!DOCTYPE html>
                  <html>
                    <head>
                      <title>${selectedFile.name}</title>
                      <style>
                        body, html {
                          margin: 0;
                          padding: 0;
                          height: 100%;
                          overflow: hidden;
                          display: flex;
                          flex-direction: column;
                          font-family: Arial, sans-serif;
                        }
                        #pdf-container {
                          width: 100%;
                          height: 100vh;
                          position: relative;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                        }
                        #pdf-viewer {
                          width: 100%;
                          height: 100%;
                          border: none;
                          display: none;
                        }
                        #pdf-js-viewer {
                          width: 100%;
                          height: 100%;
                          border: none;
                          display: none;
                        }
                        .error-message {
                          text-align: center;
                          max-width: 600px;
                          margin: 20px;
                          padding: 20px;
                          background-color: #f8f8f8;
                          border-radius: 8px;
                          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                        }
                        .viewer-button {
                          padding: 10px 15px;
                          margin: 10px 5px;
                          background-color: #6E45FE;
                          color: white;
                          border: none;
                          border-radius: 4px;
                          cursor: pointer;
                          font-size: 14px;
                        }
                        #controls {
                          padding: 15px;
                          background-color: #f5f5f5;
                          display: flex;
                          justify-content: center;
                          flex-wrap: wrap;
                        }
                      </style>
                    </head>
                    <body>
                      <div id="controls">
                        <button class="viewer-button" onclick="viewWithPDFJS()">View with PDF.js</button>
                        <button class="viewer-button" onclick="viewWithIframe()">View in Browser</button>
                        <button class="viewer-button" onclick="viewWithGoogle()">View with Google</button>
                        <button class="viewer-button" onclick="downloadPDF()">Download PDF</button>
                      </div>
                      <div id="pdf-container">
                        <iframe id="pdf-viewer" src="${url}" type="application/pdf"></iframe>
                        <iframe id="pdf-js-viewer" style="display:none"></iframe>
                        <div class="error-message" id="error-message">
                          <h3>Please select a viewing option above</h3>
                          <p>If you're experiencing issues viewing this PDF, try one of the alternative viewing options.</p>
                        </div>
                      </div>
                      <script>
                        function viewWithPDFJS() {
                          document.getElementById('error-message').style.display = 'none';
                          document.getElementById('pdf-viewer').style.display = 'none';
                          document.getElementById('pdf-js-viewer').style.display = 'block';
                          
                          // Use Mozilla's PDF.js viewer
                          const pdfJSUrl = 'https://mozilla.github.io/pdf.js/web/viewer.html?file=' + encodeURIComponent('${url}');
                          document.getElementById('pdf-js-viewer').src = pdfJSUrl;
                        }
                        
                        function viewWithIframe() {
                          document.getElementById('error-message').style.display = 'none';
                          document.getElementById('pdf-js-viewer').style.display = 'none';
                          document.getElementById('pdf-viewer').style.display = 'block';
                        }
                        
                        function viewWithGoogle() {
                          document.getElementById('error-message').style.display = 'none';
                          document.getElementById('pdf-js-viewer').style.display = 'none';
                          document.getElementById('pdf-viewer').style.display = 'block';
                          
                          // Use Google Docs viewer as fallback
                          const googleViewerUrl = 'https://docs.google.com/viewer?url=' + encodeURIComponent('${url}') + '&embedded=true';
                          document.getElementById('pdf-viewer').src = googleViewerUrl;
                        }
                        
                        function downloadPDF() {
                          window.location.href = '${url}';
                        }
                        
                        // Start with PDF.js viewer by default as it's most reliable
                        viewWithPDFJS();
                      </script>
                    </body>
                  </html>
                `);
                enhancedWindow.document.close();
              }
            }
          }, 1000);
        } else {
          // For non-PDF files, use Microsoft's Office viewer
          const encodedUrl = encodeURIComponent(url);
          const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
          window.open(viewerUrl, "_blank");
        }

        // Close the modal
        setShowDownloadModal(false);
      } catch (error) {
        console.error("Error viewing file:", error);

        // Handle authentication errors specifically
        if (
          error.response?.data?.detail?.includes("Authentication") ||
          error.response?.data?.detail?.includes("Signature did not match")
        ) {
          toast.error(
            "Authentication failed. The file access link may have expired. Please try again.",
          );
        } else {
          toast.error(
            error.response?.data?.detail ||
              "Unable to view this file. Please try again later.",
          );
        }
      }
    }
  };
  return (
    <div
      className={`flex flex-col mt-0 items-center transition-all duration-300 ${hidden ? "hidden" : "block"}`}
    >
      <div className="w-full mt-0 p-0">
        {isInitialLoading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : uploadedFiles.length === 0 ? (
          <div className="flex flex-col justify-center items-center mt-10">
            <p className="text-gray-400">No files uploaded yet</p>
          </div>
        ) : viewMode === "grid" ? (
          // Grid View
          <div className="grid grid-cols-2 gap-2 md:grid-cols-2 lg:grid-cols-4">
            {uploadedFiles.map((file) => (
              <div
                key={file.id}
                className="relative w-60 h-36 bg-[#1D1E21] text-white p-4 rounded-lg border border-gray-700"
              >
                <FaFile className="text-gray-400 text-4xl mb-2" />
                <p className="text-sm truncate">{file.name}</p>

                {/* Divider Line */}
                <div className="w-full h-[1px] bg-[#4B4C4F] my-2"></div>

                {/* Uploaded Time with Icon */}
                <div className="flex items-center text-xs text-gray-400 mt-2">
                  <FaClock className="mr-1" /> {/* Time Icon */}
                  <p>{file.uploaded_on}</p>
                </div>

                {/* Three-dot menu */}
                <button
                  className="absolute top-2 right-2 text-gray-400 hover:text-white"
                  onClick={() =>
                    setFileDropdown(file.id === fileDropdown ? null : file.id)
                  }
                >
                  <IoEllipsisHorizontal />
                </button>

                {/* Dropdown Menu */}
                {fileDropdown === file.id && (
                  <div className="absolute top-10 right-2 bg-[#1D1E21] text-white shadow-md rounded-md w-24">
                    <button
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                      onClick={() => handleDownloadClick(file)}
                    >
                      View
                    </button>
                    <button
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedFileId(file.id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          // List View
          <div className="w-full">
            {uploadedFiles.map((file) => (
              <div
                key={file.id}
                className="flex justify-between items-center bg-[#1D1E21] text-white p-3 rounded-lg border border-gray-700 mb-2"
              >
                <div className="flex items-center gap-3">
                  <FaFile className="text-gray-400 text-2xl" />
                  <p className="text-sm">{file.name}</p>
                </div>
                <p className="text-xs text-gray-400">{file.uploaded_on}</p>
                {/* Three-dot menu */}
                <button
                  className="text-gray-400 hover:text-white"
                  onClick={() =>
                    setFileDropdown(file.id === fileDropdown ? null : file.id)
                  }
                >
                  <IoEllipsisHorizontal />
                </button>

                {/* Dropdown Menu */}
                {fileDropdown === file.id && (
                  <div className="absolute right-4 bg-[#1D1E21] text-white shadow-md rounded-md w-24">
                    <button
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                      onClick={() => handleDownloadClick(file)}
                    >
                      View
                    </button>
                    <button
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedFileId(file.id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* {file && !isUploading && (
                <SucessModal text={'Document uploaded Successfully'} handleModal={handleCloseModal} />

            )} */}

      {/* Show delete Modal */}
      {showDeleteModal && (
        <PopupModal closeModal={handleDelete} className="bg-[#191919]">
          <div className="flex flex-col  justify-center items-center  h-[200px] w-[360px]">
            <img src={FeaturedIcon} alt="" className="mb-2" />
            <div className="text-[#ffffff] font-medium text-lg mb-2">
              Delete document
            </div>
            <p className="text-[#ffffff]  font-dmsans text-[15px] font-normal mb-4 text-center">
              Are you sure you want to delete this <br /> document? This action
              cannot be undone.
            </p>
            <div className="flex justify-center space-x-6 w-full">
              <button
                className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[39px] flex items-center justify-center"
                onClick={handleDelete}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteDocument}
                disabled={isDeleting}
                className="w-[150px] h-[39px] rounded-md bg-[#6E45FE] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
              >
                {isDeleting ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-10 w-10"
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </PopupModal>
      )}

      {/* Download Confirmation Modal */}
      {showDownloadModal && (
        <PopupModal
          closeModal={handleCloseDownloadModal}
          className="bg-[#27282B]"
        >
          <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
            <div className="flex flex-col items-center space-y-7">
              {/* <FiEye size={20} /> */}
              <p className="text-lg  text-[#ffffff] font-medium  text-center">
                Do you want to view this file?
              </p>
              <div className="flex space-x-4">
                <button
                  className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                  onClick={handleCloseDownloadModal}
                >
                  Cancel
                </button>
                <button
                  className="px-3 w-[150px] h-[44px] rounded-md  font-dmsans bg-[#6E45FE] text-base text-[#ffffff] font-medium flex items-center justify-center"
                  onClick={handleViewClick}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </PopupModal>
      )}

      {selectedFiles.length > 0 && showMoveCheckbox && (
        <div className="fixed bottom-10  w-[280px] bg-white  border-black py-4 px-6 flex justify-end items-center">
          <span className="mr-4">{selectedFiles.length} file(s) selected</span>
          <button
            onClick={() => setShowMoveModal(true)}
            className="bg-primary-700 text-black px-4 py-2 rounded-full"
          >
            Move
          </button>
          <button
            onClick={() => uncheck(true)}
            className="bg-primary-700 text-black px-4 py-2 rounded-full"
          >
            Cancel
          </button>
        </div>
      )}

      {/* Move Modal */}
      {/* Move Modal */}
      {/* Move Modal */}
      {showMoveModal && (
        <PopupModal
          closeModal={() => setShowMoveModal(false)}
          className="bg-[#F7F5F3] rounded-lg shadow-lg w-[400px] p-6"
        >
          <div className="flex flex-col space-y-6">
            <h3 className="text-lg font-semibold text-center">
              Move {selectedFiles.length} item
              {selectedFiles.length > 1 ? "s" : ""} from files
            </h3>
            <div className="flex flex-col ">
              {folders.map((folder) => (
                <div
                  key={folder.id}
                  onClick={() => setSelectedFolder(folder.id)}
                  className={`flex items-center justify-between p-3 border rounded-lg cursor-pointer ${
                    selectedFolder === folder.id
                      ? "border-black bg-gray-100"
                      : "border-[#F7F5F3]"
                  } hover:border-black`}
                >
                  <div className="flex items-center">
                    <FaFolder size={20} className=" text-black" />
                    <span className="text-base font-medium px-5">
                      {folder.name}
                    </span>
                  </div>
                  {/* <span className="text-sm text-gray-500">{folder.itemCount} item{folder.itemCount > 1 ? "s" : ""}</span> */}
                </div>
              ))}
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 w-full rounded-md border border-gray-300 text-gray-700 hover:bg-gray-100"
                onClick={() => setShowMoveModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 w-full rounded-md bg-black text-white disabled:opacity-50"
                onClick={handleMove}
                disabled={!selectedFolder}
              >
                Move
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default DocumentsPage;
