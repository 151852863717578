import React, { useState } from "react";
import { IoChevronDownOutline } from "react-icons/io5";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How does the document analysis work?",
      answer:
        "The document analysis uses advanced AI algorithms to extract, analyze, and summarize data efficiently.",
    },
    {
      question: "How is the billing handled for multiple projects?",
      answer:
        "Billing is centralized, allowing you to manage all your projects under one account for streamlined payments.",
    },
    {
      question: "How are different user roles managed?",
      answer:
        "User roles can be assigned and managed through the admin dashboard, providing control over access and permissions.",
    },
    {
      question: "How accurate are the bot responses?",
      answer:
        "Our AI bots are trained with state-of-the-art models, ensuring high accuracy for most queries.",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id="faq" className="bg-[#050D24] py-16">
      <div className="max-w-[935px] w-full mx-auto px-4 sm:px-6 lg:px-8 text-center">
        {/* Title */}
        <h2 className="text-4xl md:text-5xl font-bold text-white mb-6 tracking-wider">
          Frequently Asked <br />
          <span className="mt-2 inline-block">Questions</span>
        </h2>

        <p className="text-lg text-white mb-10">
          Everything you need to know about Pragya.ai
        </p>

        {/* FAQ List */}
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="max-w-[935px] w-full mx-auto">
              {/* Question Box */}
              <div
                tabIndex="0"
                role="button"
                className="w-full h-auto sm:h-[72px] bg-[#091530] rounded-lg px-6 sm:px-8 py-4 sm:py-6 flex justify-between items-center cursor-pointer focus:outline-none"
                onClick={() => toggleFAQ(index)}
                onKeyPress={(e) => e.key === "Enter" && toggleFAQ(index)}
              >
                <h3 className="text-[16px] font-semibold leading-[20px] sm:leading-[16px] font-poppins text-white text-left">
                  {faq.question}
                </h3>

                {/* Arrow Icon */}
                <span
                  className={`w-[25px] h-[24px] flex justify-center items-center transform transition-transform duration-300 text-white ${
                    openIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <IoChevronDownOutline className="w-5 h-5" />
                </span>
              </div>

              {/* Answer Section */}
              <div
                className={`w-full transition-all duration-500 ease-in-out overflow-hidden ${
                  openIndex === index
                    ? "h-auto opacity-100 mt-2 px-6 sm:px-8"
                    : "h-0 opacity-0"
                }`}
              >
                <p className="text-[16px] font-semibold leading-[20px] sm:leading-[16px] font-poppins text-white text-left">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
