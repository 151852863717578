import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/pragya.svg";
import signupbg from "../assets/signupbg.jpg";
import { FiHome } from "react-icons/fi";
import { toast } from "react-toastify";

const VerifyEmailSent = () => {
  const location = useLocation();
  const email = location.state?.email || "your email";
  const API_URL = process.env.REACT_APP_API_URL;

  const handleResendEmail = async () => {
    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await fetch(`${API_URL}/auth/resend-verification`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        toast.success("Verification email resent successfully");
      } else {
        const data = await response.json();
        toast.error(data.detail || "Failed to resend verification email");
      }
    } catch (error) {
      toast.error("Error resending verification email");
    }
  };

  return (
    <div
      className="relative flex items-center justify-between min-h-screen w-full bg-cover bg-center overflow-x-hidden"
      style={{
        backgroundImage: `url(${signupbg})`,
        backgroundSize: "100% 100%",
      }}
    >
      {/* Home Button */}
      <a
        href="/"
        className="absolute top-6 sm:top-12 left-4 md:left-32 flex items-center space-x-2 p-2 border-2 border-white rounded-lg text-white hover:bg-white hover:text-black transition-all duration-300 ease-in-out"
      >
        <FiHome className="text-xl" />
        <span className="text-sm font-semibold">Home</span>
      </a>

      {/* Left Side Text */}
      <div className="hidden lg:block w-[492px] ml-8 md:ml-32 text-white text-[42px] font-light font-['Inter'] leading-[54.60px] self-start mt-32">
        Start Building Your AI Assistant Today—No Code, No Hassle!
      </div>

      {/* Verify Email Box */}
      <div className="bg-white rounded-lg shadow-xl w-[92%] sm:w-[80%] md:w-[576px] min-h-[500px] sm:min-h-[800px] my-2 sm:my-8 flex-shrink-0 mx-auto lg:mr-4 relative overflow-hidden outline outline-[0.5px] outline-white/40 outline-offset-[-0.5px] p-4 sm:p-8">
        {/* Logo Container */}
        <div className="relative sm:absolute left-0 sm:left-[95px] top-0 sm:top-[80px] flex flex-col items-center sm:items-start w-full sm:w-auto px-4 sm:px-0">
          {/* Logo */}
          <img
            src={logo}
            alt="Prgya.ai"
            className="h-[30px] sm:h-[37px] mb-[40px] sm:mb-[150px]"
          />

          {/* Content Container */}
          <div className="flex flex-col items-center sm:items-start gap-6 sm:gap-8 w-full sm:w-[386px] px-4 sm:px-0">
            {/* Email Icon SVG */}
            <div className="flex justify-center w-[60px] sm:w-[78px] h-[60px] sm:h-[78px] overflow-hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M78 28.437C78 23.9496 74.36 20.312 69.875 20.312C55.7294 20.312 22.2706 20.312 8.125 20.312C3.64 20.312 0 23.9496 0 28.437V67.437C0 71.9244 3.64 75.562 8.125 75.562H69.875C74.36 75.562 78 71.9244 78 67.437C78 57.4465 78 38.4275 78 28.437Z"
                  fill="url(#paint0_linear_1620_19996)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M78 66.6432C78 66.1029 77.732 65.5983 77.2851 65.2961L39.9101 40.0899C39.3576 39.7194 38.6424 39.7194 38.0899 40.0899L0.714905 65.2961C0.26803 65.5983 0 66.1029 0 66.6432V67.437C0 71.9244 3.64 75.562 8.125 75.562H69.875C74.36 75.562 78 71.9244 78 67.437V66.6432Z"
                  fill="url(#paint1_linear_1620_19996)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 30.8558C0 31.3961 0.26803 31.9007 0.714905 32.2029L38.0899 57.4091C38.6424 57.7796 39.3576 57.7796 39.9101 57.4091L77.2851 32.2029C77.732 31.9007 78 31.3961 78 30.8558V28.437C78 23.9496 74.36 20.312 69.875 20.312C55.7294 20.312 22.2706 20.312 8.125 20.312C3.64 20.312 0 23.9496 0 28.437V30.8558Z"
                  fill="url(#paint2_linear_1620_19996)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1620_19996"
                    x1="0"
                    y1="32.3232"
                    x2="78"
                    y2="32.3232"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#EAD78F" />
                    <stop offset="1" stopColor="#F9F4B9" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1620_19996"
                    x1="0"
                    y1="47.5836"
                    x2="78"
                    y2="47.5836"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#E5BC60" />
                    <stop offset="1" stopColor="#F7E889" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1620_19996"
                    x1="0"
                    y1="28.437"
                    x2="78"
                    y2="28.437"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F2EBBE" />
                    <stop offset="1" stopColor="#FDFAE6" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            <div className="flex flex-col gap-3 w-full text-center sm:text-left">
              {/* Verify Email Heading */}
              <div className="text-black text-xl sm:text-2xl font-medium font-['Inter'] leading-6">
                Verify Your Email ID
              </div>

              {/* Verification Message */}
              <div className="text-[#595959] text-sm sm:text-base font-normal font-['Inter'] leading-[1.4] sm:leading-[22.4px]">
                We've sent a confirmation email to{" "}
                <span className="font-bold break-all">{email}</span>. Please
                check your inbox and click the link to confirm your account.
              </div>
            </div>

            {/* Resend Option */}
            <div className="text-sm sm:text-base font-['Inter'] leading-6 text-center sm:text-left">
              <span className="text-black font-normal">
                Didn't receive a Code?{" "}
              </span>
              <button
                onClick={handleResendEmail}
                className="text-[#1677FF] font-bold hover:underline"
              >
                Resend
              </button>
            </div>
          </div>
        </div>

        {/* Support Link */}
        <div className="absolute left-0 right-0 bottom-4 sm:bottom-6 text-xs sm:text-sm font-['Inter'] leading-normal text-center px-4">
          <span className="text-black font-normal">Need help? Contact </span>
          <a
            href="mailto:support@prgya.ai"
            className="text-[#1677FF] font-bold hover:underline"
          >
            support
          </a>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailSent;
