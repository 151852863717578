import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import axiosInstance from "../components/axiosInstance";
const SetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");
  const inviteIdParam = new URLSearchParams(location.search).get("invite_id");
  const [inviteId, setInviteId] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [message, setMessage] = useState("");
  const [useraccept, setUseraccept] = useState(null);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("ashutosh.s@neuradynamics.ai");
  localStorage.setItem("token", token);
  localStorage.setItem("isLoggedIn", true);
  const userresponse = [];
  useEffect(() => {
    const checkAuthAndInvite = async () => {
      setInviteId(inviteIdParam); // Set invite ID from param
      console.log("Invite ID:", inviteIdParam);

      try {
        const details = {
          invite_id: String(inviteIdParam),
        };

        // Call the first API
        const response = await axios.post(
          `${API_URL}/v1/invites/accept`,
          details,
        );

        // Store the response for later use
        setUseraccept(response.data);

        console.log("First API Response:", response.data);
        toast.success("Invitation accepted!");
      } catch (error) {
        console.error("Error accepting invitation:", error);
        toast.error(
          `${error.response?.data?.detail || error.response?.data?.error || "An unexpected error occurred"}`,
        );
      }
    };

    // Trigger the first API call
    if (inviteIdParam) {
      checkAuthAndInvite();
    }
  }, [inviteIdParam, API_URL]);

  const handlefirstnameChange = (e) => {
    setFirstname(e.target.value);
  };
  const handlelastnameChange = (e) => {
    setLastname(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      console.log(useraccept);
      const details = {
        email: useraccept.email,
        password: newPassword,
        first_name: firstname,
        last_name: lastname,
      };
      const response = await axios.patch(`${API_URL}/users/me`, details, {
        headers: {
          Authorization: `Bearer ${token}`, // Replace `yourToken` with the actual token
        },
      });
      console.log(response);
      if (response.status === 200) {
        setMessage("Password has been reset successfully.");

        setTimeout(() => navigate("/organizations"), 2000); // Redirect to login after 2 seconds
      } else {
        const data = await response.json();
        setError(data.detail || "There was an error resetting your password.");
      }
    } catch (error) {
      setError("Network error. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-700">
          Account Setup
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="newPassword"
              className="block text-gray-700 font-medium"
            >
              first_name
            </label>
            <input
              type="name"
              id="firstname"
              value={firstname}
              onChange={handlefirstnameChange}
              required
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>
          <div>
            <label
              htmlFor="newPassword"
              className="block text-gray-700 font-medium"
            >
              last_name
            </label>
            <input
              type="name"
              id="lastname"
              value={lastname}
              onChange={handlelastnameChange}
              required
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>
          <div>
            <label
              htmlFor="newPassword"
              className="block text-gray-700 font-medium"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handlePasswordChange}
              required
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 font-medium"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Continue
          </button>
        </form>
        {message && (
          <p className="mt-4 text-green-600 text-center">{message}</p>
        )}
        {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default SetPassword;
