import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../components/axiosInstance.js";
import CreateProject from "./Createproject.jsx";
import codesandbox from "../assets/Vector.svg";
import { useNavigate } from "react-router-dom";
import knowledgebasecreate from "../assets/knowledgebasecreate.svg";
import { HiFolder } from "react-icons/hi";
import { IoEllipsisHorizontal } from "react-icons/io5";
import ThreeDotLoader from "../components/dotloader.jsx";

import EmptyStateComponent from "../components/Emptystatecomponent.jsx";
const fetchUsers = async (searchTerm) => {
  const response = await new Promise((resolve) =>
    setTimeout(
      () =>
        resolve([
          {
            organization_id: 1,
            full_name: "John Doe",
            role: "MEMBER",
            email: "john.doe@example.com",
          },
          {
            organization_id: 2,
            full_name: "Jane Smith",
            role: "OWNER",
            email: "jane.smith@example.com",
          },
        ]),
      1000,
    ),
  ); // Simulate 1 second delay

  // Filter users based on searchTerm
  return response.filter((user) =>
    user.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
  );
};
const Project = ({ organization_id, userorganization_id, role }) => {
  const navigate = useNavigate();
  const [projectusersList, setProjectusersList] = useState([]);
  const [viewMode, setViewMode] = useState("grid");
  const [selectedPdfs, setSelectedPdfs] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [currentProjectid, setCurrentProjectid] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [ProjectName, setProjectName] = useState("");
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [deleteProject, setDeleteProject] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [newProjectName, setNewProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [kbno, setkbno] = useState(null);
  const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folderPdfs, setFolderPdfs] = useState({});
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [newUserRole, setNewUserRole] = useState("MEMBER");
  const [members, setMembers] = useState([]);

  // Debounced search functionality (using setTimeout)
  const [organizationUsers, setOrganizationUsers] = useState([]);
  // const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserOrganizationId, setSelectedUserOrganizationId] =
    useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = (id) => {
    setDropdownVisible(dropdownVisible === id ? null : id);
  };

  const handleEdit = (id) => {
    console.log(`Edit project with ID: ${id}`);
    setDropdownVisible(null);
  };

  const handleDelete = (id) => {
    console.log(`Delete project with ID: ${id}`);
    setDropdownVisible(null);
  };

  // const handleUserSelect = (user) => {
  //     setSearchTerm(`${user.full_name} (${user.email})`);
  //     setSelectedUserOrganizationId(user.userorganizationid);
  // };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const fetchOrganizationUsers = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/organizations/${organization_id}/users`,
        );
        setOrganizationUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };

    if (showPdfModal) {
      fetchOrganizationUsers();
    }
  }, [showPdfModal, API_URL]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = organizationUsers.filter(
        (user) =>
          user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  }, [searchTerm, organizationUsers]);

  const handleUserSelect = (user) => {
    setSearchTerm(`${user.full_name} (${user.email})`);
    setSelectedUserOrganizationId(user.id);
    setFilteredUsers([]);
    console.log("Selected User:", user);
  };

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (searchTerm) {
        const fetchedUsers = await fetchUsers(searchTerm);
        setUsers(fetchedUsers);
      } else {
        setUsers([]); // Clear results if search term is empty
      }
    }, 500); // Debounce time (wait 500ms after last keystroke)

    return () => clearTimeout(timeoutId); // Cleanup function on unmount
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    fetchProjects();
  }, [organization_id, userorganization_id]);

  // Fetch ungrouped PDFs
  const handleAddUser = async (userOrganizationId, role) => {
    console.log(
      role,
      "rrrrrrrrrrrr",
      userOrganizationId,
      "rttttttttt",
      userorganization_id,
    );
    try {
      const response = await axiosInstance.post(
        `/v1/projects/${currentProjectid}/add-user`,
        {
          userorganization_id: userorganization_id,
          addeduserorgid: userOrganizationId,
          role: role,
        },
      );
      toast.success("User added successfully!");
    } catch (error) {
      console.error("Failed to add user:", error);
      if (error.status === 400) {
        toast.error("User already exist");
      } else {
        toast.error("Failed to add user. Please try again.");
      }
    }
  };

  // Fetch PDFs in a folder
  const fetchProjectusers = async (project_id) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/projects/${project_id}/users`,
      );
      setProjectusersList(response.data);
      console.log(response.data);
    } catch (error) {
      toast.error("Error fetching folder PDFs");
    }
  };

  // Toggle folder expansion
  const toggleFolder = (folderId) => {
    if (expandedFolders.includes(folderId)) {
      setExpandedFolders(expandedFolders.filter((id) => id !== folderId));
    } else {
      setExpandedFolders([...expandedFolders, folderId]);
      if (!folderPdfs[folderId]) {
        // fetchFolderPdfs(folderId);
      }
    }
  };

  const fetchProjects = async () => {
    setLoading(true);
    try {
      console.log(userorganization_id);
      const response = await axiosInstance.get(
        `${API_URL}/v1/projects/${organization_id}/user/${userorganization_id}/`,
      );
      setProjects(response.data);
      console.log(response.data);
      setUsers(response.data);
      setIsProjectsLoaded(true);
    } catch (error) {
      toast.error("Error fetching projects");
    }
    setLoading(false);
  };
  // Handle checkbox change
  const handleCheckboxChange = (pdfId) => {
    setSelectedPdfs((prev) =>
      prev.includes(pdfId)
        ? prev.filter((id) => id !== pdfId)
        : [...prev, pdfId],
    );
  };

  const handleRoleChange = async (userorgId, newRole, project_id) => {
    console.log(userorgId, project_id, userorganization_id);
    try {
      // Update the role in the backend
      const response = await axiosInstance.patch(
        `/v1/projects/${project_id}/update-role`, // Replace with the actual API endpoint
        {
          target_userorganization_id: userorgId,
          owner_userorgid: userorganization_id,
          role: newRole,
        },
      );

      // On success, update the role in the frontend state
      if (response.status === 204) {
        setMembers((prevMembers) =>
          prevMembers.map((member) =>
            member.userorg_id === userorgId
              ? { ...member, role: newRole }
              : member,
          ),
        );
        toast.success("Role updated successfully!");
      } else {
        throw new Error("Failed to update role.");
      }
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error(
        error.response?.data?.message ||
          "An error occurred while updating the role.",
      );
    }
  };
  const handleCreateProject = async () => {
    try {
      console.log(userorganization_id);

      const response = await axiosInstance.post(
        `${API_URL}/v1/projects/create`,
        {
          name: ProjectName,
          organization_id: organization_id,
          user_organization_id: userorganization_id,
        },
      );

      const newProject = {
        id: response.data.id,
        name: ProjectName,
      };
      setProjects([...Projects, newProject]);
      setShowCreateModal(false);
      setSelectedPdfs([]);
    } catch (error) {
      toast.error("Error creating Project:", error);
    }
  };

  // Show PDFs of a specific knowledge base
  const handleShowProject = (Project) => {
    fetchProjectusers(Project);
    setCurrentProjectid(Project);
    setShowPdfModal(true);
  };

  const handleDeleteProject = async (id) => {
    try {
      await axiosInstance.delete(
        `/v1/projects/${id}/user/${userorganization_id}`,
      );
      setProjects(Projects.filter((kb) => kb.id !== id));
      toast.success("Project deleted successfully");

      // Dispatch custom event to notify other components about project deletion
      const projectDeletedEvent = new CustomEvent("projectDeleted", {
        detail: { projectId: id },
      });
      window.dispatchEvent(projectDeletedEvent);
    } catch (error) {
      toast.error("Error deleting knowledge base");
    }
    setShowDeleteModal(false);
  };
  const handleEditProject = (kb) => {
    setEditingProject(kb);
    console.log(editingProject);
    setNewProjectName(kb.name);
    setShowEditModal(true);
  };
  const removeUser = async (userproject_id, project_id) => {
    console.log(userproject_id, project_id);
    try {
      // Optimistically update the UI by filtering out the user
      setProjectusersList((prevUsers) =>
        prevUsers.filter((user) => user.id !== userproject_id),
      );

      // Make the API call to remove the user from the backend
      await axiosInstance.post(`/v1/projects/${project_id}/remove-user`, {
        target_userproject_id: userproject_id,
        owner_userorgid: userorganization_id,
      });

      toast.success(`User with ID ${userproject_id} removed successfully.`);
    } catch (err) {
      // Revert the optimistic update in case of an error
      toast.error("Failed to remove user from project");
      console.error("Error removing user:", err);
    }
  };
  // Save Edited Knowledge Base
  const handleSaveEdit = async () => {
    if (!editingProject) return;
    try {
      await axiosInstance.patch(
        `${API_URL}/v1/projects/${editingProject}/update-name`,
        {
          name: newProjectName,
        },
      );
      setProjects(
        Projects.map((kb) =>
          kb.id === editingProject.id ? { ...kb, name: newProjectName } : kb,
        ),
      );
      setShowEditModal(false);
      toast.success("Project updated successfully");

      // Dispatch custom event to notify other components about project update
      const projectUpdatedEvent = new CustomEvent("projectUpdated", {
        detail: { projectId: editingProject.id },
      });
      window.dispatchEvent(projectUpdatedEvent);
    } catch (error) {
      toast.error("Error updating Project");
    }
  };
  if (loading) {
    // Show a loader while data is being fetched
    return (
      <div className="flex items-center justify-center h-screen">
        <ThreeDotLoader />
      </div>
    );
  }
  return (
    // <div
    //     className={`flex absolute inset-0  bg-[#EFEEEA]  flex-col  ${isSidebarOpen ? 'ml-[380px]' : 'ml-[80px]'} transition-all duration-300 pt-[0px]`}
    // >
    <div>
      <div className="m-5  mb-5">
        {/* Create Knowledge Base Card */}

        {/* List of Knowledge Bases */}
        {Projects?.length === 0 ? (
          <EmptyStateComponent
            icon={knowledgebasecreate}
            title="Create a Project"
            description="Click on 'Create Project' to create one"
            buttonText="+ Create Project"
            onButtonClick={() => setShowCreateModal(true)}
          />
        ) : (
          <div>
            {/* Header Section */}
            <div className="flex items-center justify-between mb-6">
              <div>
                <h1 className="text-3xl font-bold text-white">Projects</h1>
                {/* <p className="text-gray-400 text-sm">Home &gt; Projects</p> */}
              </div>
              <div className="flex items-center space-x-4">
                {/* Search Bar */}
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    className="bg-[#2C2C2C] text-gray-400 text-sm rounded-lg px-4 py-2 w-64 focus:outline-none"
                  />
                </div>

                {/* View Switch Buttons */}
                <div className="flex items-center space-x-2">
                  <button
                    className={`p-2 rounded-lg ${
                      viewMode === "grid"
                        ? "bg-[#1677FF] text-white"
                        : "bg-[#2C2C2C] text-gray-400"
                    }`}
                    onClick={() => setViewMode("grid")}
                  >
                    {/* Grid Icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 3h7v7H3zM14 3h7v7h-7zM14 14h7v7h-7zM3 14h7v7H3z"
                      />
                    </svg>
                  </button>
                  <button
                    className={`p-2 rounded-lg ${
                      viewMode === "list"
                        ? "bg-[#1677FF] text-white"
                        : "bg-[#2C2C2C] text-gray-400"
                    }`}
                    onClick={() => setViewMode("list")}
                  >
                    {/* List Icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>

                {/* New Project Button */}
                {role !== "MEMBER" && (
                  <button
                    className="bg-[#1677FF] text-white py-2 px-4 rounded-lg"
                    onClick={() => setShowCreateModal(true)}
                  >
                    + Create Project
                  </button>
                )}
              </div>
            </div>

            {/* Projects Section */}
            <div className="space-y-4">
              {viewMode === "grid" ? (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
                  {Projects?.map((project) => (
                    <div
                      key={project.id}
                      className="bg-[#1D1E21] text-white shadow-md rounded-lg p-4 hover:bg-gray-700 cursor-pointer border border-[#FFFFFF4D]"
                      style={{ height: "140px" }}
                      onClick={() => {
                        localStorage.setItem("project_name", project.name);
                        navigate(`/projects/${project.id}/assistants`);
                      }}
                    >
                      <div className="relative">
                        {/* Three-dot button */}
                        <button
                          className="absolute top-2 right-2 text-gray-400 hover:text-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDropdownToggle(project.id);
                          }}
                        >
                          <IoEllipsisHorizontal size={25} />
                        </button>

                        {/* Dropdown menu */}
                        {dropdownVisible === project.id && (
                          <div
                            ref={dropdownRef}
                            className="absolute right-2 top-8 bg-[#1D1E21] text-white border border-[#FFFFFF4D] rounded-md shadow-md z-10"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <ul>
                              <li
                                onClick={() => handleEditProject(project.id)}
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                              >
                                Edit
                              </li>
                              <li
                                onClick={() => {
                                  setShowDeleteModal(true);
                                  setDeleteProject(project);
                                }}
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                              >
                                Delete
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>

                      {/* Project Icon and Name */}
                      <div className="flex flex-col items-start ">
                        <HiFolder size={30} style={{ color: "#8E8F90" }} />
                        <h3 className="text-lg font-medium truncate pl-1">
                          {project.name}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="space-y-2">
                  {Projects?.map((project) => (
                    <div
                      key={project.id}
                      className="bg-[#1D1E21] text-white shadow-md rounded-lg border border-[#FFFFFF4D] pl-4 flex justify-between items-center hover: cursor-pointer"
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={codesandbox}
                          alt="Project Icon"
                          className="h-[35px] w-[35px]"
                        />
                        <div>
                          <h5
                            className="text-lg font-semibold"
                            onClick={() =>
                              navigate(`/projects/${project.id}/assistants`)
                            }
                          >
                            {project.name}
                          </h5>
                        </div>
                      </div>
                      <div className="relative p-4">
                        <button
                          className=" right-2 text-white hover:text-white"
                          onClick={() => handleDropdownToggle(project.id)}
                        >
                          <IoEllipsisHorizontal size={25} />
                        </button>
                        {dropdownVisible === project.id && (
                          <div className="absolute right-2 top-8 bg-[#1D1E21] text-white border border-[#FFFFFF4D] rounded-md shadow-md z-10">
                            <ul>
                              <li
                                onClick={() => handleEditProject(project.id)}
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                              >
                                Edit
                              </li>
                              <li
                                onClick={() => {
                                  setShowDeleteModal(true);
                                  setDeleteProject(project);
                                }}
                                className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                              >
                                Delete
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Modal for Creating Knowledge Base */}
        {showCreateModal && (
          <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
            <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
              <div className="flex items-center space-x-2 py-4">
                <img
                  src={codesandbox}
                  alt="Project Icon"
                  className="h-[25px] w-[25px]"
                />
                <h3 className="text-xl text-white font-semibold">
                  Create Project
                </h3>
              </div>

              {/* Knowledge Base Name Input */}
              <div className="mb-4">
                <label className="block  text-white font-medium mb-2">
                  Project Name
                </label>
                <input
                  type="text"
                  value={ProjectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  placeholder="Enter a name"
                  className="w-full px-4 py-2 bg-[#191919] text-white rounded focus:outline-none "
                />
              </div>

              {/* Ungrouped PDFs */}
              {/* <div className="mb-4">
                                <h4 className="font-medium">Files</h4>
                                <div className="max-h-60 overflow-y-auto">
                                    {pdfList.map((pdf) => (
                                        <div key={pdf.id} className="flex items-center mb-2">
                                            <input
                                                type="checkbox"
                                                className="mr-3"
                                                checked={selectedPdfs.includes(pdf.id)}
                                                onChange={() => handleCheckboxChange(pdf.id)}
                                            />
                                            <label>{pdf.name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div> */}

              {/* Action Buttons */}
              <div className="flex justify-end mt-6">
                <button
                  className="bg-[#FFFFFF] px-10 py-2 mr-2 rounded-lg text-[16px] font-medium font-inter  text-black"
                  onClick={() => setShowCreateModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#1677FF] px-10 py-2 rounded-lg text-[16px] font-medium font-inter text-white"
                  onClick={handleCreateProject}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal to Show PDFs in a Knowledge Base */}
        {showPdfModal && (
          <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
            <div className="bg-white w-full max-w-3xl p-6 rounded-lg shadow-lg">
              <div className="flex items-center mb-4">
                {" "}
                {/* Combined search and dropdown section */}
                <div className="relative w-2/3 mr-4">
                  <input
                    type="text"
                    className="w-full px-4 py-2 border rounded focus:outline-none"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {filteredUsers.length > 0 && (
                    <ul className="absolute bg-white border rounded shadow-md mt-1 w-full max-h-40 overflow-y-auto z-10">
                      {filteredUsers.map((user) => (
                        <li
                          key={user.id}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            handleUserSelect(user);
                            setSearchTerm(`${user.full_name} (${user.email})`);
                          }}
                        >
                          {user.full_name} ({user.email})
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <select
                  value={newUserRole}
                  onChange={(e) => setNewUserRole(e.target.value)}
                  className="border rounded px-2 py-1 mr-4"
                >
                  <option value="OWNER">Owner</option>
                  <option value="MEMBER">Member</option>
                </select>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
                  onClick={() =>
                    handleAddUser(selectedUserOrganizationId, newUserRole)
                  }
                  // Disable the button if no user is selected
                >
                  Add User
                </button>
              </div>
              {projectusersList &&
                projectusersList?.map((member) => (
                  <div
                    key={member?.organization_id}
                    className="flex items-center p-4 border-b last:border-b-0"
                  >
                    <div className="rounded-full bg-gray-300 w-10 h-10 flex items-center justify-center mr-4">
                      {member.full_name.charAt(0).toUpperCase()}
                    </div>
                    <div className="flex-1">
                      <p className="font-medium">
                        {member.full_name}{" "}
                        {member.role === "OWNER" && (
                          <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                            You
                          </span>
                        )}
                      </p>
                      <p className="text-gray-500 text-sm">{member.email}</p>
                    </div>
                    <div>
                      <select
                        value={member.role}
                        onChange={(e) =>
                          handleRoleChange(
                            member.userorg_id,
                            e.target.value,
                            member.project_id,
                          )
                        }
                        className="border rounded px-2 py-1"
                      >
                        <option value="OWNER">Owner</option>
                        <option value="MEMBER">Member</option>
                      </select>
                    </div>
                    <div>
                      {member.role === "OWNER" ? (
                        <button className="text-gray-500">Leave</button>
                      ) : (
                        <button
                          className="text-red-500"
                          onClick={() =>
                            removeUser(member.id, member.project_id)
                          }
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              <button
                onClick={() => setShowPdfModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded mt-4"
              >
                Close
              </button>
            </div>
          </div>
        )}
        {showEditModal && (
          <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
            <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
              <div className="flex items-center space-x-2 py-4">
                <img
                  src={codesandbox}
                  alt="Project Icon"
                  className="h-[25px] w-[25px]"
                />
                <h3 className="text-xl font-semibold  text-white">
                  Edit Project name
                </h3>
              </div>
              <input
                type="text"
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
                placeholder="Enter a new name"
                className="w-full px-4 py-2 border rounded bg-[#191919] mb-4 text-white"
              />
              <div className="flex justify-end">
                <button
                  className="bg-[#FFFFFF] px-10 py-2 mr-2  rounded-lg text-[16px] font-medium font-inter  text-black"
                  onClick={() => setShowEditModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#6E45FE] px-10 py-2 rounded-lg text-[16px] font-medium font-inter text-white"
                  onClick={handleSaveEdit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#1D1E20] text-white w-[400px] p-6 rounded-lg shadow-lg border border-gray-600">
              {/* Header */}
              <div className="flex items-center mb-4">
                <span className="text-yellow-400 text-xl mr-2">⚠️</span>
                <h2 className="text-lg font-semibold">Delete Confirmation</h2>
              </div>

              {/* Message */}
              <p className="text-sm text-gray-300 mb-4">
                This action cannot be undone. The project and all its data will
                be permanently deleted.
              </p>
              <p className="text-sm mb-4">
                Are you sure you want to delete project{" "}
                <strong>“{deleteProject.name}”</strong>? Please type{" "}
                <strong>'delete'</strong> to confirm.
              </p>

              {/* Input */}
              <input
                type="text"
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
                placeholder="Type delete"
                className="w-full p-2 rounded bg-[#2A2B2D] border border-gray-500 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
              />

              {/* Buttons */}
              <div className="flex justify-end mt-4 space-x-2">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className="bg-[#FFFFFF] px-6 py-2 mr-2  rounded-lg text-[16px] font-medium font-inter  text-black"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleDeleteProject(deleteProject.id)}
                  disabled={confirmText.toLowerCase() !== "delete"}
                  className={`px-6 py-2 mr-2  rounded-lg text-[16px] font-medium font-inter ${
                    confirmText.toLowerCase() === "delete"
                      ? "bg-red-600 hover:bg-red-500"
                      : "bg-gray-700 cursor-not-allowed"
                  } text-white`}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Project;
