import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  IconButton,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Creator.css";
import "./UploadStyles.css";
import { LiaInfoCircleSolid } from "react-icons/lia";
import axiosInstance from "../../components/axiosInstance";
import { toast } from "react-toastify";
import { getStoredValue } from "../../services/logics.js";
import { FaPlus, FaFolder, FaUpload, FaTimes, FaFile } from "react-icons/fa";

// Mock data for providers
const mockProviders = [{ id: 1, name: "OpenAI" }];

// Mock data for models
const mockModels = [{ id: 1, name: "gpt-4o-mini" }];

const CreateAgent = () => {
  const navigate = useNavigate();
  const [agentName, setAgentName] = useState("");
  const [agentDescription, setAgentDescription] = useState("");
  const [defaultMessage, setDefaultMessage] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(1);
  const [selectedModel, setSelectedModel] = useState(1);
  const [selectedKBs, setSelectedKBs] = useState([]);
  const [searchKB, setSearchKB] = useState("");
  const [publishStatus, setPublishStatus] = useState("DRAFT"); // Default value is DRAFT
  const [knowledgeBases, setKnowledgeBases] = useState([]); // State for real knowledge bases
  const [loading, setLoading] = useState(false); // Loading state
  const activeProjectId = getStoredValue("activeProjectId");
  const organizationId = getStoredValue("selectedOrg");
  const userOrgId = getStoredValue("userorganization_id");
  const [submitting, setSubmitting] = useState(false);

  // Add new state variables for Knowledge Base creation modal
  const [showKBCreateModal, setShowKBCreateModal] = useState(false);
  const [newKBName, setNewKBName] = useState("");
  const [selectedPdfs, setSelectedPdfs] = useState([]);
  const [folders, setFolders] = useState([]);
  const [folderPdfs, setFolderPdfs] = useState({});
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [pdfList, setPdfList] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [createKBloading, setCreateKBLoading] = useState(false);

  // Add new state variables for folder creation and file upload
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadError, setUploadError] = useState("");

  // Add new state variables for editing
  const [editingKB, setEditingKB] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  // Add avatar upload states
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarUploadProgress, setAvatarUploadProgress] = useState(0);
  const [avatarUploadError, setAvatarUploadError] = useState("");
  const [isAvatarDragOver, setIsAvatarDragOver] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);

  // Add avatar upload handlers
  const handleAvatarDragOver = (e) => {
    e.preventDefault();
    setIsAvatarDragOver(true);
  };

  const handleAvatarDragLeave = (e) => {
    e.preventDefault();
    setIsAvatarDragOver(false);
  };

  const handleAvatarDrop = (e) => {
    e.preventDefault();
    setIsAvatarDragOver(false);

    const file = e.dataTransfer.files[0];
    handleAvatarFileSelect(file);
  };

  const handleAvatarFileSelect = async (file) => {
    // Validate file type
    if (!file.type.startsWith("image/")) {
      setAvatarUploadError("Please upload an image file");
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setAvatarUploadError("File size should be less than 5MB");
      return;
    }

    try {
      setAvatarFile(file);
      setAvatarUploadError("");
      setAvatarUploadProgress(0);

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload the file immediately
      const formData = new FormData();
      formData.append("file", file);

      const response = await axiosInstance.post(
        "/v1/agents/upload-avatar",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            setAvatarUploadProgress(progress);
          },
        },
      );

      // The response.data is the URL directly
      setAvatarUrl(response.data);
      toast.success("Avatar uploaded successfully!");
    } catch (error) {
      console.error("Avatar upload error:", error);
      setAvatarUploadError("Failed to upload avatar");
      setAvatarFile(null);
      setAvatarPreview(null);
      toast.error("Failed to upload avatar");
    }
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    try {
      // Validate required fields
      if (!agentName.trim()) {
        toast.error("Agent name is required");
        return;
      }

      if (!selectedProject) {
        toast.error("Please select a project");
        return;
      }

      if (selectedKBs.length === 0) {
        toast.error("Please select at least one knowledge base");
        return;
      }

      // Get user ID from localStorage
      const userId =
        localStorage.getItem("userId") || sessionStorage.getItem("userId");

      if (!userId) {
        toast.error("User ID not found. Please log in again.");
        return;
      }

      // Get the model name from the selected model ID
      const modelName =
        mockModels.find((m) => m.id === selectedModel)?.name || "gpt-4o-mini";

      // Convert knowledge base IDs to strings if they aren't already
      const knowledgebaseIds = selectedKBs.map((id) => String(id));

      // Create payload with avatar_url only if it exists
      const payload = {
        agent_name: agentName.trim(),
        agent_description: agentDescription || "",
        default_message: defaultMessage || "How can I help you?",
        model: modelName,
        knowledgebase_ids: knowledgebaseIds,
        project_id: String(selectedProject),
        publish_status: publishStatus || "DRAFT",
        organisation_id: String(organizationId || ""),
        user_id: String(userId),
        ...(avatarUrl && { avatar_url: avatarUrl }), // Only include avatar_url if it exists
      };

      setSubmitting(true);

      // Make the API call to create the agent
      const response = await axiosInstance.post(
        "/v1/agents/creator/add",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      console.log("Agent created successfully:", response.data);
      toast.success("Agent created successfully!");

      // Dispatch a custom event to notify other components about the agent creation
      const agentCreatedEvent = new CustomEvent("agentCreated", {
        detail: { agentId: response.data.id },
      });
      window.dispatchEvent(agentCreatedEvent);

      // Navigate back to the agents list
      navigate("/creator/agents");
    } catch (error) {
      console.error("Error creating agent:", error);

      // Show detailed error information
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        console.error(
          "Response data:",
          JSON.stringify(error.response.data, null, 2),
        );

        let errorMessage = "Failed to create agent";

        if (error.response.data && error.response.data.detail) {
          if (typeof error.response.data.detail === "string") {
            errorMessage = error.response.data.detail;
          } else if (Array.isArray(error.response.data.detail)) {
            // Display each validation error in detail
            error.response.data.detail.forEach((err) => {
              console.error(
                `Validation error: ${JSON.stringify(err, null, 2)}`,
              );
            });

            errorMessage = error.response.data.detail
              .map((err) => `${err.loc ? err.loc.join(".") : ""}: ${err.msg}`)
              .join(", ");
          } else {
            errorMessage = JSON.stringify(error.response.data.detail);
          }
        }

        toast.error(errorMessage);
      } else {
        toast.error("Network error or server unavailable");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleKBToggle = (id) => {
    const currentIndex = selectedKBs.indexOf(id);
    const newSelected = [...selectedKBs];

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedKBs(newSelected);
  };

  const filteredKBs = knowledgeBases.filter((kb) =>
    kb.name.toLowerCase().includes(searchKB.toLowerCase()),
  );

  // Add function to handle edit button click
  const handleEditKB = (kb) => {
    setEditingKB(kb);
    setNewKBName(kb.name);
    setSelectedPdfs(kb.pdfs.map((pdf) => pdf.id));
    setShowEditModal(true);

    // Fetch current files and folders when opening edit modal
    fetchDocuments(selectedProject);
    fetchFolders(selectedProject);

    // Reset other states
    setUploadingFile(false);
    setUploadFile(null);
    setUploadProgress(0);
    setUploadError("");
    setShowNewFolderInput(false);
    setNewFolderName("");
    setExpandedFolders([]);
    setFolderPdfs({});
  };

  // Add function to handle save edit
  const handleSaveEdit = async () => {
    if (!editingKB) return;

    try {
      await axiosInstance.put(
        `/v1/user/update-knowledge-base/${editingKB.id}`,
        {
          name: newKBName,
          pdf_ids: selectedPdfs,
        },
      );

      // Refresh knowledge bases
      await fetchKnowledgeBases(selectedProject);
      setShowEditModal(false);
      toast.success("Knowledge base updated successfully");
    } catch (error) {
      toast.error("Error updating knowledge base");
      console.error("Error updating knowledge base:", error);
    }
  };

  // Fetch projects on component mount
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch projects from API
  const fetchProjects = async () => {
    if (!organizationId || !userOrgId) {
      toast.error("Organization or user data missing");
      return;
    }

    try {
      setProjectsLoading(true);
      const response = await axiosInstance.get(
        `/v1/projects/${organizationId}/user/${userOrgId}/`,
      );
      setProjects(response.data || []);

      // Set the active project as selected by default
      if (response.data && response.data.length > 0) {
        if (activeProjectId) {
          // Find the active project in the list
          const activeProject = response.data.find(
            (project) => project.id === activeProjectId,
          );
          if (activeProject) {
            setSelectedProject(activeProject.id);
            fetchKnowledgeBases(activeProject.id);
          } else {
            // If active project not found, default to the first project
            setSelectedProject(response.data[0].id);
            fetchKnowledgeBases(response.data[0].id);
          }
        } else {
          // If no active project, default to the first project
          setSelectedProject(response.data[0].id);
          fetchKnowledgeBases(response.data[0].id);
        }
      }
    } catch (error) {
      toast.error("Error fetching projects");
      console.error("Error fetching projects:", error);
    } finally {
      setProjectsLoading(false);
    }
  };

  // Function to fetch knowledge bases from API for a specific project
  const fetchKnowledgeBases = async (projectId = selectedProject) => {
    if (!projectId) {
      setKnowledgeBases([]);
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/v1/user/knowledge-bases/${projectId}`,
        {
          params: { page: 1, limit: 50 },
        },
      );
      setKnowledgeBases(response.data?.knowledge_bases || []);
      // Clear selected KBs when switching projects
      setSelectedKBs([]);
    } catch (error) {
      toast.error("Error fetching knowledge bases");
      console.error("Error fetching knowledge bases:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle project change
  const handleProjectChange = (projectId) => {
    setSelectedProject(projectId);
    fetchKnowledgeBases(projectId);
  };

  // Add fetch documents function for the modal
  const fetchDocuments = async (projectId = selectedProject) => {
    if (!projectId) return;

    setFetchingFiles(true);
    try {
      const response = await axiosInstance.get(
        `/v1/user/documents/${projectId}?page=1&page_size=50`,
        {},
      );
      setPdfList(response.data.documents || []);
    } catch (error) {
      toast.error("Error fetching documents");
      console.error("Error fetching documents:", error);
    } finally {
      setFetchingFiles(false);
    }
  };

  // Add fetch folders function for the modal
  const fetchFolders = async (projectId = selectedProject) => {
    if (!projectId) return;

    try {
      const response = await axiosInstance.get(`/v1/folders/${projectId}`, {
        params: { page: 1, limit: 50 },
      });
      setFolders(response.data.folders || []);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  // Add fetch folder content function
  const fetchFolderContent = async (folderId) => {
    try {
      const response = await axiosInstance.get(
        `/v1/folders/${selectedProject}/documents/${folderId}`,
      );
      setFolderPdfs((prev) => ({
        ...prev,
        [folderId]: {
          files: response.data.documents || [],
          subfolders: response.data.subfolders || [],
        },
      }));

      // If the folder has files, automatically expand it
      if (response.data.documents && response.data.documents.length > 0) {
        setExpandedFolders((prev) => {
          if (!prev.includes(folderId)) {
            return [...prev, folderId];
          }
          return prev;
        });
      }
    } catch (error) {
      toast.error("Error fetching folder content");
    }
  };

  // Add toggle folder expansion function
  const toggleFolder = (folderId) => {
    if (expandedFolders.includes(folderId)) {
      setExpandedFolders((prev) => prev.filter((id) => id !== folderId));
    } else {
      setExpandedFolders((prev) => [...prev, folderId]);
      if (!folderPdfs[folderId]) {
        fetchFolderContent(folderId);
      }
    }
  };

  // Add function to handle checkbox selection for files
  const handleCheckboxChange = (pdfId) => {
    setSelectedPdfs((prev) =>
      prev.includes(pdfId)
        ? prev.filter((id) => id !== pdfId)
        : [...prev, pdfId],
    );
  };

  // Add function to create a new knowledge base
  const handleCreateKnowledgeBase = async () => {
    if (!newKBName.trim()) {
      toast.error("Please enter a name for the knowledge base");
      return;
    }

    if (selectedPdfs.length === 0) {
      toast.error("Please select at least one document");
      return;
    }

    setCreateKBLoading(true);
    try {
      await axiosInstance.post(
        `/v1/user/create-knowledge-base/${selectedProject}`,
        {
          name: newKBName,
          pdf_ids: selectedPdfs,
        },
      );

      // Reset states
      setShowKBCreateModal(false);
      setNewKBName("");
      setSelectedPdfs([]);

      // Refresh knowledge bases
      await fetchKnowledgeBases(selectedProject);

      toast.success("Knowledge base created successfully");
    } catch (error) {
      toast.error("Error creating knowledge base");
      console.error("Error creating knowledge base:", error);
    } finally {
      setCreateKBLoading(false);
    }
  };

  // Add function to handle showing the KB create modal
  const handleShowKBCreateModal = () => {
    setShowKBCreateModal(true);
    fetchDocuments(selectedProject);
    fetchFolders(selectedProject);
    setNewKBName("");
    setSelectedPdfs([]);
    setExpandedFolders([]);
    setFolderPdfs({});
  };

  // Function to render folder hierarchy
  const renderFolderHierarchy = (folders) => {
    return folders.map((folder) => (
      <div key={folder.id} className="mb-2">
        <div className="flex items-center">
          <div
            className="cursor-pointer text-white hover:text-blue-400 flex-grow flex items-center"
            onClick={() => toggleFolder(folder.id)}
          >
            {expandedFolders.includes(folder.id) ? "▼ " : "▶ "}
            <FaFolder className="mr-2 text-gray-400" size={14} />
            {folder.name}
          </div>
          <button
            className="text-gray-400 hover:text-white text-xs ml-2 px-2 py-1 rounded hover:bg-[#3D3E42]"
            onClick={(e) => {
              e.stopPropagation();
              setCurrentFolder(folder.id);
              handleShowFileUploadUI();
            }}
            title="Upload file to this folder"
          >
            <FaUpload size={14} />
          </button>
        </div>

        {expandedFolders.includes(folder.id) && (
          <div className="ml-4 mt-1">
            {folderPdfs[folder.id]?.subfolders &&
            folderPdfs[folder.id].subfolders.length > 0
              ? renderFolderHierarchy(folderPdfs[folder.id].subfolders)
              : null}

            {folderPdfs[folder.id]?.files ? (
              folderPdfs[folder.id].files.map((pdf) => (
                <div key={pdf.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={selectedPdfs.includes(pdf.id)}
                    onChange={() => handleCheckboxChange(pdf.id)}
                  />
                  <label className="text-white flex items-center">
                    <FaFile className="mr-2 text-gray-400" size={14} />
                    {pdf.name}
                  </label>
                </div>
              ))
            ) : expandedFolders.includes(folder.id) ? (
              <p className="text-gray-400 text-sm">Loading files...</p>
            ) : null}
          </div>
        )}
      </div>
    ));
  };

  // Add function to handle folder creation
  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      toast.error("Folder name cannot be empty");
      return;
    }

    setCreatingFolder(true);
    try {
      const response = await axiosInstance.post(
        `/v1/folders/${selectedProject}/create`,
        {
          name: newFolderName,
          parent_folder_id: null, // Root folder
        },
      );

      // Add new folder to state
      setFolders((prev) => [...prev, response.data]);

      // Reset input
      setNewFolderName("");
      setShowNewFolderInput(false);

      toast.success("Folder created successfully");
    } catch (error) {
      toast.error("Failed to create folder");
      console.error("Error creating folder:", error);
    } finally {
      setCreatingFolder(false);
    }
  };

  // Add function to handle file upload UI
  const handleShowFileUploadUI = () => {
    setUploadFile(null);
    setUploadProgress(0);
    setUploadError("");
    setUploadingFile(true);
  };

  // Add function to handle file drag events
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  // Add function to handle file selection
  const handleFileDrop = (e, type) => {
    e.preventDefault();
    setIsDragOver(false);

    let selectedFile;
    if (type === "drop") {
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }

    // Validate file
    if (selectedFile && selectedFile.type !== "application/pdf") {
      setUploadError("Only PDF files are allowed");
      setUploadFile(null);
      return;
    }

    // Check file size
    const maxSizeInBytes = 50 * 1024 * 1024;
    if (selectedFile && selectedFile.size > maxSizeInBytes) {
      setUploadError("File size exceeds the limit of 50 MB");
      setUploadFile(null);
      return;
    }

    // Set the file and clear errors
    setUploadFile(selectedFile);
    setUploadError("");
    setUploadProgress(0);

    // Automatically start the upload process
    if (selectedFile) {
      // Set a small timeout to allow the UI to update before starting upload
      setTimeout(() => {
        uploadSelectedFile(selectedFile);
      }, 100);
    }
  };

  // New function to handle file upload with the selected file
  const uploadSelectedFile = async (fileToUpload) => {
    if (!fileToUpload) return;

    setUploadProgress(0);
    try {
      const formData = new FormData();
      formData.append("file", fileToUpload);
      formData.append("folder_id", currentFolder || ""); // Use current folder ID if set

      const response = await axiosInstance.post(
        `/v1/user/documents/${selectedProject}`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            setUploadProgress(progress);
          },
        },
      );

      // Add a slight delay before refreshing content to ensure server has processed the upload
      setTimeout(async () => {
        if (currentFolder) {
          await fetchFolderContent(currentFolder);
        } else {
          await fetchDocuments(selectedProject);
        }

        // Add the new file to selection if we have a valid ID
        if (response.data && response.data.id) {
          setSelectedPdfs((prev) => [...prev, response.data.id]);
          toast.success("File uploaded successfully");
        }
      }, 500);
    } catch (error) {
      console.error(
        "Upload error details:",
        error.response?.data || error.message,
      );
      toast.error(
        error.response?.data?.detail ||
          "Upload failed. Check file format and size.",
      );
      setUploadProgress(0);
    }
  };

  // Modify the original handleFileUpload to use our new function
  const handleFileUpload = async () => {
    if (!uploadFile) return;
    uploadSelectedFile(uploadFile);
  };

  return (
    <Box
      className="flex flex-col mt-4 h-[calc(100vh-120px)] overflow-auto relative p-6 "
      sx={{ background: "#1C1C1C" }}
    >
      <Box className="w-full py-4 flex items-center gap-4">
        <Box
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/creator/agents")}
        >
          <Box className="w-7 h-7 flex items-center justify-center border border-[#CDCDCD] rounded-md bg-[#1C1C1C]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M11.3125 3.4107V2.20289C11.3125 2.0982 11.1922 2.04039 11.111 2.10445L4.06721 7.60601C4.00736 7.65256 3.95894 7.71215 3.92563 7.78025C3.89232 7.84836 3.875 7.92317 3.875 7.99898C3.875 8.0748 3.89232 8.14961 3.92563 8.21771C3.95894 8.28582 4.00736 8.34541 4.06721 8.39195L11.111 13.8935C11.1938 13.9576 11.3125 13.8998 11.3125 13.7951V12.5873C11.3125 12.5107 11.2766 12.4373 11.2172 12.3904L5.59221 7.99976L11.2172 3.60758C11.2766 3.5607 11.3125 3.48726 11.3125 3.4107Z"
                fill="#CDCDCD"
              />
            </svg>
          </Box>
        </Box>
        <Typography className="text-[#cdcdcd] text-xl font-medium leading-tight">
          Create Agent
        </Typography>
      </Box>

      <Box className="flex-1 pb-6">
        <Box className="flex gap-4 h-full">
          {/* Left Column */}
          <Box className="w-1/2 p-5 bg-[#1c1c1c] rounded-lg border border-[#595959] flex flex-col justify-start items-start gap-6">
            {/* Upload Banner */}
            <Box className="self-stretch bg-[#1c1c1c] rounded-lg flex flex-col justify-start items-start gap-2">
              <Typography className="self-stretch text-[#cdcdcd] text-base font-normal leading-snug">
                Add Image
              </Typography>
              <Box
                className={`self-stretch p-4 bg-[#111111] rounded-lg border ${
                  isAvatarDragOver
                    ? "border-[#6E45FE] bg-[#6E45FE]/10"
                    : "border-[#2b2b2b]"
                } flex flex-col justify-center items-center gap-4 cursor-pointer`}
                onClick={() => document.getElementById("avatarInput").click()}
                onDragOver={handleAvatarDragOver}
                onDragLeave={handleAvatarDragLeave}
                onDrop={handleAvatarDrop}
              >
                <input
                  type="file"
                  id="avatarInput"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleAvatarFileSelect(e.target.files[0])}
                />

                {avatarPreview ? (
                  <Box className="w-32 h-32 relative">
                    <img
                      src={avatarPreview}
                      alt="Avatar preview"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <IconButton
                      size="small"
                      className="absolute top-2 right-2 bg-black/50 hover:bg-black/70"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAvatarFile(null);
                        setAvatarPreview(null);
                      }}
                    >
                      <FaTimes className="text-white" />
                    </IconButton>
                  </Box>
                ) : (
                  <>
                    <Box className="w-10 h-10 relative overflow-hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M34.1641 11.7891L23.3125 0.832031C23.1367 0.65625 22.9453 0.507812 22.7383 0.382812C22.6836 0.351562 22.6289 0.320312 22.5703 0.292969C22.5352 0.273438 22.4961 0.257812 22.4609 0.242188C22.1094 0.0859375 21.7227 0 21.3281 0H7.85156C6.29688 0 5 1.25781 5 2.8125V37.1875C5 38.7422 6.29688 40 7.85156 40H32.1875C33.7422 40 34.9609 38.7422 34.9609 37.1875V13.7695C34.9609 13.0273 34.6875 12.3164 34.1641 11.7891ZM22.7734 4.3125L30.6172 12.1875H22.7734V4.3125ZM32.1484 37.1875H7.8125V2.8125H19.9609V12.1875C19.9609 13.7422 21.2578 15 22.8125 15H32.1484V37.1875ZM13.6719 27.207C13.6719 28.1523 13.3789 28.4336 12.8164 28.4336C12.4648 28.4336 12.0977 28.207 11.8477 27.7109L10.6602 28.5938C11.1836 29.4883 11.9219 29.9297 13.0547 29.9297C14.6797 29.9297 15.4297 28.7617 15.4297 27.3438V22.5391H13.6719V27.207ZM19.582 22.5391H17.0703V29.8047H18.7891V27.3828H19.6328C21.1602 27.3828 22.4883 26.6172 22.4883 24.8984C22.4883 23.1094 21.1797 22.5391 19.582 22.5391ZM19.5508 26.0156H18.7891V23.9453H19.5C20.3398 23.9453 20.8047 24.1875 20.8047 24.918C20.8047 25.625 20.3945 26.0156 19.5508 26.0156ZM26.6797 25.6641V27.0703H27.8516V28.2461C27.6953 28.3594 27.4219 28.4297 27.1602 28.4297C25.8203 28.4297 25.1797 27.5937 25.1797 26.1562C25.1797 24.7461 25.9492 23.9141 27.0195 23.9141C27.6172 23.9141 27.9961 24.1562 28.3477 24.4766L29.2734 23.3711C28.7773 22.8711 28.0195 22.4258 26.9609 22.4258C25.0234 22.4258 23.4023 23.8047 23.4023 26.2148C23.4023 28.6641 24.9648 29.9297 26.9766 29.9297C27.9883 29.9297 28.8984 29.5312 29.3789 29.0469V25.6641H26.6797Z"
                          fill="#CDCDCD"
                        />
                      </svg>
                    </Box>
                    <Box className="self-stretch flex flex-col justify-start items-center gap-1 overflow-hidden">
                      <Typography className="self-stretch text-center justify-center text-[#cdcdcd] text-base font-normal leading-normal">
                        Click or drag Image to this area to upload
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              {avatarUploadError && (
                <Typography className="text-red-500 text-sm">
                  {avatarUploadError}
                </Typography>
              )}
              {avatarUploadProgress > 0 && avatarUploadProgress < 100 && (
                <Box className="w-full">
                  <Box className="w-full bg-gray-700 rounded-full h-2 mb-2">
                    <Box
                      className="bg-[#6E45FE] h-2 rounded-full transition-all duration-300"
                      sx={{ width: `${avatarUploadProgress}%` }}
                    />
                  </Box>
                  <Typography className="text-[#cdcdcd] text-sm text-center">
                    Uploading: {avatarUploadProgress}%
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Choose Project */}
            <Box className="w-full flex flex-col justify-start items-start gap-2">
              <Typography className="self-stretch text-[#cdcdcd] text-base font-normal leading-snug">
                Choose Project
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={selectedProject}
                  onChange={(e) => handleProjectChange(e.target.value)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "#cdcdcd" }}>
                          Select a project
                        </span>
                      );
                    }
                    return (
                      projects.find((p) => p.id === selected)?.name ||
                      "Select a project"
                    );
                  }}
                  sx={{
                    minWidth: "60px",
                    padding: "0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#2b2b2b",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#595959",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#595959",
                    },
                    "& .MuiSelect-select": {
                      backgroundColor: "#111111",
                      padding: "12px 16px",
                      color: "#cdcdcd",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#cdcdcd",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "#111",
                        color: "#FFF",
                      },
                    },
                  }}
                >
                  {projectsLoading ? (
                    <MenuItem disabled>Loading projects...</MenuItem>
                  ) : projects.length === 0 ? (
                    <MenuItem disabled>No projects available</MenuItem>
                  ) : (
                    projects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* Agent Name */}
            <Box className="w-full flex flex-col justify-start items-start gap-2">
              <Typography className="self-stretch text-[#cdcdcd] text-base font-normal leading-snug">
                Agent name
              </Typography>
              <TextField
                fullWidth
                value={agentName}
                onChange={(e) => setAgentName(e.target.value)}
                placeholder="Enter agent here"
                sx={{
                  minWidth: "60px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#111111",
                    borderRadius: "8px",
                    "& fieldset": {
                      borderColor: "#2b2b2b",
                    },
                    "&:hover fieldset": {
                      borderColor: "#595959",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#595959",
                    },
                    color: "#cdcdcd",
                    padding: "3px 0",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 16px",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#cdcdcd",
                  },
                }}
              />
            </Box>

            {/* Description/Instructions */}
            <Box className="w-full flex flex-col justify-start items-start gap-2">
              <Typography className="self-stretch text-[#cdcdcd] text-base font-normal leading-snug">
                Instructions
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={agentDescription}
                onChange={(e) => setAgentDescription(e.target.value)}
                placeholder="Enter Instructions for your Agent..."
                sx={{
                  minWidth: "60px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#111111",
                    borderRadius: "8px",
                    "& fieldset": {
                      borderColor: "#2b2b2b",
                    },
                    "&:hover fieldset": {
                      borderColor: "#595959",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#595959",
                    },
                    color: "#cdcdcd",
                    padding: "3px 0",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 16px",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#cdcdcd",
                  },
                }}
              />
            </Box>

            {/* First Message */}
            <Box className="w-full flex flex-col justify-start items-start gap-2">
              <Typography className="self-stretch text-[#cdcdcd] text-base font-normal leading-snug">
                First message
              </Typography>
              <TextField
                fullWidth
                value={defaultMessage}
                onChange={(e) => setDefaultMessage(e.target.value)}
                placeholder="Add first message here"
                sx={{
                  minWidth: "60px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#111111",
                    borderRadius: "8px",
                    "& fieldset": {
                      borderColor: "#2b2b2b",
                    },
                    "&:hover fieldset": {
                      borderColor: "#595959",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#595959",
                    },
                    color: "#cdcdcd",
                    padding: "3px 0",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 16px",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#cdcdcd",
                  },
                }}
              />
            </Box>

            {/* Provider */}
            <Box className="w-full flex flex-col justify-start items-start gap-2">
              <Typography className="self-stretch text-[#cdcdcd] text-base font-normal leading-snug">
                Provider
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={selectedProvider}
                  onChange={(e) => setSelectedProvider(e.target.value)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span style={{ color: "#cdcdcd" }}>Select</span>;
                    }
                    return mockProviders.find((p) => p.id === selected)?.name;
                  }}
                  sx={{
                    minWidth: "60px",
                    padding: "0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#2b2b2b",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#595959",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#595959",
                    },
                    "& .MuiSelect-select": {
                      backgroundColor: "#111111",
                      padding: "12px 16px",
                      color: "#cdcdcd",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#cdcdcd",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "#111",
                        color: "#FFF",
                      },
                    },
                  }}
                >
                  {mockProviders.map((provider) => (
                    <MenuItem key={provider.id} value={provider.id}>
                      {provider.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Recommended Model Support */}
            <Box className="w-full flex flex-col justify-start items-start gap-2">
              <Typography className="self-stretch text-[#cdcdcd] text-base font-normal leading-snug">
                Recommended Model Support
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={selectedModel}
                  onChange={(e) => setSelectedModel(e.target.value)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "#cdcdcd" }}>Select Model</span>
                      );
                    }
                    return mockModels.find((m) => m.id === selected)?.name;
                  }}
                  sx={{
                    minWidth: "60px",
                    padding: "0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#2b2b2b",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#595959",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#595959",
                    },
                    "& .MuiSelect-select": {
                      backgroundColor: "#111111",
                      padding: "12px 16px",
                      color: "#cdcdcd",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#cdcdcd",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "#111",
                        color: "#FFF",
                      },
                    },
                  }}
                >
                  {mockModels.map((model) => (
                    <MenuItem key={model.id} value={model.id}>
                      {model.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {/* Right Column */}
          <Box className="w-1/2 flex flex-col">
            {/* Knowledge Base options */}
            <Box className="w-full rounded-lg border border-[#595959] bg-[#1C1C1C] p-4">
              <Box className="flex flex-col justify-start items-start gap-2">
                <Box className="self-stretch py-1 flex justify-start items-center gap-2 mb-2">
                  <Typography className="text-[#cdcdcd] text-base font-medium leading-tight">
                    Knowledge Base options
                  </Typography>
                </Box>

                <Box className="self-stretch flex flex-col justify-start items-start gap-4">
                  <Box className="self-stretch flex flex-col justify-start items-start gap-2">
                    <Typography className="text-[#cdcdcd]/80 text-base font-normal leading-snug">
                      Choose Knowledge Base
                    </Typography>

                    <Box className="self-stretch h-[550px] px-4 py-3 bg-[#111111] rounded-lg border border-[#595959] flex flex-col justify-start items-end gap-4 overflow-hidden">
                      {/* Header with Title and Search */}
                      <Box className="self-stretch inline-flex justify-between items-center">
                        <Typography className="text-[#cdcdcd]/80 text-base font-normal leading-snug">
                          All Knowledge Bases (Total{" "}
                          {filteredKBs.length.toString().padStart(2, "0")})
                        </Typography>

                        <Box className="bg-[#1c1c1c] flex justify-start items-center gap-4">
                          <Box className="w-[300px] h-8 px-4 bg-[#1c1c1c] rounded-lg border border-white border-opacity-30 flex items-center gap-2">
                            <Box className="flex items-center justify-center">
                              <span className="text-gray-400">🔍</span>
                            </Box>
                            <TextField
                              placeholder="Search"
                              value={searchKB}
                              onChange={(e) => setSearchKB(e.target.value)}
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              sx={{
                                input: {
                                  color: "#cdcdcd",
                                  fontSize: "14px",
                                  padding: 0,
                                  height: "100%",
                                },
                                width: "100%",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>

                      {/* Knowledge Base List */}
                      <Box
                        className="self-stretch flex flex-col justify-start items-start gap-4 overflow-y-auto"
                        sx={{
                          maxHeight: "450px",
                          scrollbarWidth: "none" /* Firefox */,
                          "&::-webkit-scrollbar": {
                            display: "none" /* Chrome, Safari, Edge */,
                          },
                          msOverflowStyle: "none" /* IE and Edge */,
                        }}
                      >
                        {loading ? (
                          <Typography className="text-center w-full text-[#cdcdcd] py-4">
                            Loading knowledge bases...
                          </Typography>
                        ) : filteredKBs.length === 0 ? (
                          <Typography className="text-center w-full text-[#cdcdcd] py-4">
                            No knowledge bases found. Please create one first.
                          </Typography>
                        ) : (
                          filteredKBs.map((kb) => (
                            <Box
                              key={kb.id}
                              className="self-stretch inline-flex justify-start items-center gap-[325px]"
                            >
                              <Box className="flex-1 flex justify-start items-center gap-2">
                                <Box className="py-[3px] flex justify-start items-start gap-2">
                                  <Checkbox
                                    checked={selectedKBs.indexOf(kb.id) !== -1}
                                    onChange={() => handleKBToggle(kb.id)}
                                    sx={{
                                      padding: 0,
                                      width: "16px",
                                      height: "16px",
                                      color: "#d9d9d9",
                                      "&.Mui-checked": {
                                        color: "#1677ff",
                                      },
                                    }}
                                  />
                                </Box>
                                <Typography className="text-[#cdcdcd] text-sm font-normal leading-snug">
                                  {kb.name}
                                </Typography>
                              </Box>
                              <IconButton
                                size="small"
                                sx={{
                                  width: "22px",
                                  height: "22px",
                                  padding: 0,
                                  color: "#1677FF",
                                }}
                                onClick={() => handleEditKB(kb)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                >
                                  <path
                                    d="M5.53652 16.1562C5.57949 16.1562 5.62246 16.152 5.66543 16.1455L9.2791 15.5117C9.32207 15.5031 9.36289 15.4838 9.39297 15.4516L18.5002 6.34434C18.5201 6.32446 18.5359 6.30085 18.5467 6.27486C18.5575 6.24887 18.563 6.22101 18.563 6.19287C18.563 6.16473 18.5575 6.13687 18.5467 6.11088C18.5359 6.08489 18.5201 6.06128 18.5002 6.04141L14.9295 2.46855C14.8887 2.42773 14.835 2.40625 14.777 2.40625C14.7189 2.40625 14.6652 2.42773 14.6244 2.46855L5.51719 11.5758C5.48496 11.608 5.46563 11.6467 5.45703 11.6896L4.82324 15.3033C4.80234 15.4184 4.80981 15.5369 4.845 15.6484C4.88019 15.76 4.94204 15.8613 5.0252 15.9436C5.16699 16.0811 5.34531 16.1562 5.53652 16.1562ZM6.98457 12.4094L14.777 4.61914L16.3518 6.19394L8.55937 13.9842L6.64941 14.3215L6.98457 12.4094ZM18.9062 17.9609H3.09375C2.71348 17.9609 2.40625 18.2682 2.40625 18.6484V19.4219C2.40625 19.5164 2.48359 19.5938 2.57812 19.5938H19.4219C19.5164 19.5938 19.5938 19.5164 19.5938 19.4219V18.6484C19.5938 18.2682 19.2865 17.9609 18.9062 17.9609Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </IconButton>
                            </Box>
                          ))
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Create new knowledge base */}
                <Box
                  className="p-1 inline-flex justify-center items-center gap-2 cursor-pointer mt-2"
                  onClick={handleShowKBCreateModal}
                >
                  <Typography className="text-[#cdcdcd] text-base font-medium leading-tight">
                    Create new knowledge base
                  </Typography>
                  <Box className="w-6 h-6 relative">
                    <span className="text-[#cdcdcd] text-xl">➕</span>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Publishing Option */}
            <Box className="w-full p-5 bg-[#1c1c1c] rounded-lg border border-[#595959] flex flex-col justify-start items-start gap-2 mt-12">
              <Box className="self-stretch flex justify-start items-center mb-2">
                <Box className="w-[22px] h-[22px] relative flex items-center justify-center text-[#cdcdcd]">
                  <LiaInfoCircleSolid size={20} />
                </Box>
                <Typography className="text-[#cdcdcd] text-base font-medium leading-tight ml-2">
                  Publishing Option
                </Typography>
              </Box>

              {/* Toggle Switch */}
              <Box className="w-full flex items-center justify-between mb-4">
                <Typography className="text-[#cdcdcd]/80 text-base font-normal">
                  Publish Agent
                </Typography>
                <Switch
                  checked={publishStatus !== "DRAFT"}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setPublishStatus("DRAFT");
                    } else {
                      setPublishStatus("ORGANISATION");
                    }
                  }}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#1677FF",
                      "&:hover": {
                        backgroundColor: "rgba(22, 119, 255, 0.08)",
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#1677FF",
                    },
                  }}
                />
              </Box>

              {/* Conditional Dropdown */}
              {publishStatus !== "DRAFT" && (
                <Box className="w-full">
                  <Typography className="text-[#cdcdcd]/80 text-base font-normal mb-2">
                    Publish Status
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={publishStatus}
                      onChange={(e) => setPublishStatus(e.target.value)}
                      displayEmpty
                      renderValue={(selected) => (
                        <Typography className="text-[#cdcdcd] text-base font-normal leading-none">
                          {selected}
                        </Typography>
                      )}
                      sx={{
                        minWidth: "60px",
                        padding: "0",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#2b2b2b",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#595959",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#595959",
                        },
                        "& .MuiSelect-select": {
                          backgroundColor: "#111111",
                          padding: "12px 16px",
                          color: "#cdcdcd",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#cdcdcd",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: "#111",
                            color: "#FFF",
                          },
                        },
                      }}
                    >
                      <MenuItem value="ORGANISATION">ORGANISATION</MenuItem>
                      <MenuItem value="PROJECT">PROJECT</MenuItem>
                      <MenuItem value="MARKETPLACE">MARKETPLACE</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Bottom Buttons */}
      <Box className="flex justify-end gap-2 pb-4 mt-2">
        <Button
          variant="outlined"
          onClick={() => navigate("/creator/agents")}
          sx={{
            width: "150px",
            height: "40px",
            padding: "0 15px",
            borderColor: "transparent",
            borderRadius: "8px",
            boxShadow: "0px 2px 0px 0px rgba(5,145,255,0.10)",
            outline: "1px solid #cdcdcd",
            outlineOffset: "-1px",
            color: "#FFF",
            textTransform: "none",
            fontFamily: "Inter, sans-serif",
            fontWeight: "normal",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              borderColor: "transparent",
              backgroundColor: "rgba(205, 205, 205, 0.1)",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => handleSubmit(e)}
          variant="contained"
          disabled={submitting}
          sx={{
            width: "150px",
            height: "40px",
            backgroundColor: "#1677FF",
            "&:hover": { backgroundColor: "#0958d9" },
            textTransform: "none",
            fontWeight: "normal",
          }}
        >
          {submitting ? "Creating..." : "Create Agent"}
        </Button>
      </Box>

      {/* Knowledge Base Creation Modal */}
      {showKBCreateModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-black bg-opacity-50"
            onClick={() => setShowKBCreateModal(false)}
          ></div>
          <div className="bg-[#27282B] w-full max-w-2xl p-6 rounded-lg shadow-lg z-10 max-h-[90vh] overflow-auto">
            <h3 className="text-xl text-white font-semibold mb-4">
              Add Knowledge Base
            </h3>

            {/* Knowledge Base Name Input */}
            <div className="mb-4">
              <label className="block text-white font-medium mb-2">
                Knowledge Base name
              </label>
              <input
                type="text"
                value={newKBName}
                onChange={(e) => setNewKBName(e.target.value)}
                placeholder="Knowledge Base 123"
                className="w-full px-4 py-2 text-white rounded focus:outline-none bg-[#191919]"
              />
            </div>

            {/* Action Buttons for File/Folder Management */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="font-medium text-white">Choose files</h4>
              <div className="flex space-x-2">
                <button
                  className="px-3 py-1.5 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-sm flex items-center"
                  onClick={() => setShowNewFolderInput(true)}
                >
                  <FaFolder className="mr-2" /> New Folder
                </button>
                <button
                  className="px-3 py-1.5 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-sm flex items-center"
                  onClick={() => {
                    setCurrentFolder(null);
                    handleShowFileUploadUI();
                  }}
                >
                  <FaUpload className="mr-2" /> Upload File
                </button>
              </div>
            </div>

            {/* New Folder Input */}
            {showNewFolderInput && (
              <div className="mb-4 bg-[#1D1E21] p-4 rounded-lg">
                <div className="flex items-center">
                  <input
                    type="text"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    placeholder="Enter folder name"
                    className="flex-grow px-3 py-2 text-white rounded focus:outline-none bg-[#191919]"
                    autoFocus
                  />
                  <div className="flex ml-2">
                    <button
                      className="px-3 py-2 text-white bg-[#6E45FE] hover:bg-[#5a35e0] rounded text-sm mr-1"
                      onClick={handleCreateFolder}
                      disabled={creatingFolder || !newFolderName.trim()}
                    >
                      {creatingFolder ? "Creating..." : "Create"}
                    </button>
                    <button
                      className="px-3 py-2 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-sm"
                      onClick={() => {
                        setShowNewFolderInput(false);
                        setNewFolderName("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* File Upload UI */}
            {uploadingFile && (
              <div className="mb-4 bg-[#1D1E21] p-4 rounded-lg">
                <div className="mb-2 flex justify-between items-center">
                  <h5 className="text-white text-sm flex items-center">
                    <FaUpload className="mr-2" />
                    {currentFolder
                      ? `Upload to folder ${folders.find((f) => f.id === currentFolder)?.name || ""}`
                      : "Upload to root"}
                  </h5>
                  <button
                    className="text-gray-400 hover:text-white"
                    onClick={() => setUploadingFile(false)}
                  >
                    <FaTimes size={18} />
                  </button>
                </div>

                <div
                  className={`border-2 border-dashed ${
                    isDragOver
                      ? "border-[#6E45FE] bg-[#6E45FE]/10"
                      : "border-gray-600"
                  } rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer mb-2`}
                  onClick={() =>
                    document.getElementById("kbModalFileInput").click()
                  }
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleFileDrop(e, "drop")}
                >
                  <input
                    type="file"
                    id="kbModalFileInput"
                    className="hidden"
                    accept=".pdf"
                    onChange={(e) => handleFileDrop(e, "input")}
                  />

                  <FaUpload className="text-gray-400 text-3xl mb-3" />
                  <p className="text-white text-sm mb-1 text-center">
                    Drag & drop file here, or click to browse
                  </p>
                  <p className="text-gray-400 text-xs text-center">
                    Only PDF files are supported (max 50MB)
                  </p>
                </div>

                {uploadFile && (
                  <div className="mt-3">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-white text-sm truncate max-w-[300px] flex items-center">
                        <FaFile className="mr-2 text-gray-400" />{" "}
                        {uploadFile.name}
                      </span>
                      <span className="text-gray-400 text-xs">
                        {(uploadFile.size / (1024 * 1024)).toFixed(2)} MB
                      </span>
                    </div>
                    <div className="w-full bg-gray-700 rounded-full h-2 mb-2">
                      <div
                        className="bg-[#6E45FE] h-2 rounded-full"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>

                    <div className="flex justify-end">
                      {uploadProgress === 100 ? (
                        <div className="flex space-x-2 mt-2">
                          <button
                            className="px-3 py-1.5 text-white bg-[#FF5252] hover:bg-[#FF3232] rounded text-xs"
                            onClick={() => {
                              setUploadingFile(false);
                              setUploadFile(null);
                              setUploadProgress(0);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="px-3 py-1.5 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-xs"
                            onClick={() => {
                              setUploadingFile(false);
                              setUploadFile(null);
                              setUploadProgress(0);
                            }}
                          >
                            Done
                          </button>
                        </div>
                      ) : uploadProgress > 0 && uploadProgress < 100 ? (
                        <button
                          className="px-3 py-1.5 text-white bg-[#FF5252] hover:bg-[#FF3232] rounded text-xs mt-2"
                          onClick={() => {
                            setUploadFile(null);
                            setUploadProgress(0);
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          className="px-3 py-1.5 text-white bg-[#6E45FE] hover:bg-[#5a35e0] rounded text-xs mt-2"
                          onClick={handleFileUpload}
                        >
                          Upload
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {uploadError && (
                  <p className="text-red-500 text-xs mt-2">{uploadError}</p>
                )}
              </div>
            )}

            <div className="bg-[#191919] p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
              {/* Files Section */}
              <div className="mb-4">
                <h4 className="font-medium text-white flex items-center">
                  <FaFile className="mr-2" /> All Files
                  <span className="ml-2 text-sm">(Total {pdfList.length})</span>
                </h4>

                {fetchingFiles ? (
                  <p className="text-gray-400 text-center py-4">
                    Loading files...
                  </p>
                ) : pdfList.length === 0 ? (
                  <p className="text-gray-400 text-center py-4">
                    No files found
                  </p>
                ) : (
                  <div className="max-h-60 overflow-y-auto pr-2 mt-2">
                    {pdfList.map((pdf) => (
                      <div key={pdf.id} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          className="mr-3"
                          checked={selectedPdfs.includes(pdf.id)}
                          onChange={() => handleCheckboxChange(pdf.id)}
                        />
                        <label className="text-white flex items-center">
                          <FaFile className="mr-2 text-gray-400" size={14} />
                          {pdf.name}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Folders Section */}
              <div className="mb-4">
                <h4 className="font-medium text-white mb-2 flex items-center">
                  <FaFolder className="mr-2" /> Folders
                  <span className="ml-2 text-sm text-gray-400">
                    (Total {folders.length})
                  </span>
                </h4>
                {folders.length === 0 ? (
                  <p className="text-gray-400 text-center py-2">
                    No folders found
                  </p>
                ) : (
                  <div className="pr-2">{renderFolderHierarchy(folders)}</div>
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-6">
              <button
                className="bg-[#1677FF] text-white px-6 py-2 rounded mr-3"
                onClick={handleCreateKnowledgeBase}
                disabled={
                  createKBloading || selectedPdfs.length === 0 || !newKBName
                }
              >
                {createKBloading ? "Creating..." : "Save"}
              </button>
              <button
                className="bg-[#D9D9D9] text-black px-6 py-2 rounded"
                onClick={() => setShowKBCreateModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Knowledge Base Modal */}
      {showEditModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-black bg-opacity-50"
            onClick={() => setShowEditModal(false)}
          ></div>
          <div className="bg-[#27282B] w-full max-w-2xl p-6 rounded-lg shadow-lg z-10 max-h-[90vh] overflow-auto">
            <h3 className="text-xl text-white font-semibold mb-4">
              Edit Knowledge Base
            </h3>

            {/* Knowledge Base Name Input */}
            <div className="mb-4">
              <label className="block text-white font-medium mb-2">
                Knowledge Base name
              </label>
              <input
                type="text"
                value={newKBName}
                onChange={(e) => setNewKBName(e.target.value)}
                placeholder="Knowledge Base 123"
                className="w-full px-4 py-2 text-white rounded focus:outline-none bg-[#191919]"
              />
            </div>

            {/* Action Buttons for File/Folder Management */}
            <div className="flex justify-between items-center mb-4">
              <h4 className="font-medium text-white">Choose files</h4>
              <div className="flex space-x-2">
                <button
                  className="px-3 py-1.5 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-sm flex items-center"
                  onClick={() => setShowNewFolderInput(true)}
                >
                  <FaFolder className="mr-2" /> New Folder
                </button>
                <button
                  className="px-3 py-1.5 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-sm flex items-center"
                  onClick={() => {
                    setCurrentFolder(null);
                    handleShowFileUploadUI();
                  }}
                >
                  <FaUpload className="mr-2" /> Upload File
                </button>
              </div>
            </div>

            {/* New Folder Input */}
            {showNewFolderInput && (
              <div className="mb-4 bg-[#1D1E21] p-4 rounded-lg">
                <div className="flex items-center">
                  <input
                    type="text"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    placeholder="Enter folder name"
                    className="flex-grow px-3 py-2 text-white rounded focus:outline-none bg-[#191919]"
                    autoFocus
                  />
                  <div className="flex ml-2">
                    <button
                      className="px-3 py-2 text-white bg-[#6E45FE] hover:bg-[#5a35e0] rounded text-sm mr-1"
                      onClick={handleCreateFolder}
                      disabled={creatingFolder || !newFolderName.trim()}
                    >
                      {creatingFolder ? "Creating..." : "Create"}
                    </button>
                    <button
                      className="px-3 py-2 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-sm"
                      onClick={() => {
                        setShowNewFolderInput(false);
                        setNewFolderName("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* File Upload UI */}
            {uploadingFile && (
              <div className="mb-4 bg-[#1D1E21] p-4 rounded-lg">
                <div className="mb-2 flex justify-between items-center">
                  <h5 className="text-white text-sm flex items-center">
                    <FaUpload className="mr-2" />
                    {currentFolder
                      ? `Upload to folder ${folders.find((f) => f.id === currentFolder)?.name || ""}`
                      : "Upload to root"}
                  </h5>
                  <button
                    className="text-gray-400 hover:text-white"
                    onClick={() => setUploadingFile(false)}
                  >
                    <FaTimes size={18} />
                  </button>
                </div>

                <div
                  className={`border-2 border-dashed ${
                    isDragOver
                      ? "border-[#6E45FE] bg-[#6E45FE]/10"
                      : "border-gray-600"
                  } rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer mb-2`}
                  onClick={() =>
                    document.getElementById("kbModalFileInput").click()
                  }
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleFileDrop(e, "drop")}
                >
                  <input
                    type="file"
                    id="kbModalFileInput"
                    className="hidden"
                    accept=".pdf"
                    onChange={(e) => handleFileDrop(e, "input")}
                  />

                  <FaUpload className="text-gray-400 text-3xl mb-3" />
                  <p className="text-white text-sm mb-1 text-center">
                    Drag & drop file here, or click to browse
                  </p>
                  <p className="text-gray-400 text-xs text-center">
                    Only PDF files are supported (max 50MB)
                  </p>
                </div>

                {uploadFile && (
                  <div className="mt-3">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-white text-sm truncate max-w-[300px] flex items-center">
                        <FaFile className="mr-2 text-gray-400" />{" "}
                        {uploadFile.name}
                      </span>
                      <span className="text-gray-400 text-xs">
                        {(uploadFile.size / (1024 * 1024)).toFixed(2)} MB
                      </span>
                    </div>
                    <div className="w-full bg-gray-700 rounded-full h-2 mb-2">
                      <div
                        className="bg-[#6E45FE] h-2 rounded-full"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>

                    <div className="flex justify-end">
                      {uploadProgress === 100 ? (
                        <div className="flex space-x-2 mt-2">
                          <button
                            className="px-3 py-1.5 text-white bg-[#FF5252] hover:bg-[#FF3232] rounded text-xs"
                            onClick={() => {
                              setUploadingFile(false);
                              setUploadFile(null);
                              setUploadProgress(0);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="px-3 py-1.5 text-white bg-[#3D3E42] hover:bg-[#4D4E52] rounded text-xs"
                            onClick={() => {
                              setUploadingFile(false);
                              setUploadFile(null);
                              setUploadProgress(0);
                            }}
                          >
                            Done
                          </button>
                        </div>
                      ) : uploadProgress > 0 && uploadProgress < 100 ? (
                        <button
                          className="px-3 py-1.5 text-white bg-[#FF5252] hover:bg-[#FF3232] rounded text-xs mt-2"
                          onClick={() => {
                            setUploadFile(null);
                            setUploadProgress(0);
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          className="px-3 py-1.5 text-white bg-[#6E45FE] hover:bg-[#5a35e0] rounded text-xs mt-2"
                          onClick={handleFileUpload}
                        >
                          Upload
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {uploadError && (
                  <p className="text-red-500 text-xs mt-2">{uploadError}</p>
                )}
              </div>
            )}

            <div className="bg-[#191919] p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
              {/* Files Section */}
              <div className="mb-4">
                <h4 className="font-medium text-white flex items-center">
                  <FaFile className="mr-2" /> All Files
                  <span className="ml-2 text-sm">(Total {pdfList.length})</span>
                </h4>

                {fetchingFiles ? (
                  <p className="text-gray-400 text-center py-4">
                    Loading files...
                  </p>
                ) : pdfList.length === 0 ? (
                  <p className="text-gray-400 text-center py-4">
                    No files found
                  </p>
                ) : (
                  <div className="max-h-60 overflow-y-auto pr-2 mt-2">
                    {pdfList.map((pdf) => (
                      <div key={pdf.id} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          className="mr-3"
                          checked={selectedPdfs.includes(pdf.id)}
                          onChange={() => handleCheckboxChange(pdf.id)}
                        />
                        <label className="text-white flex items-center">
                          <FaFile className="mr-2 text-gray-400" size={14} />
                          {pdf.name}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Folders Section */}
              <div className="mb-4">
                <h4 className="font-medium text-white mb-2 flex items-center">
                  <FaFolder className="mr-2" /> Folders
                  <span className="ml-2 text-sm text-gray-400">
                    (Total {folders.length})
                  </span>
                </h4>
                {folders.length === 0 ? (
                  <p className="text-gray-400 text-center py-2">
                    No folders found
                  </p>
                ) : (
                  <div className="pr-2">{renderFolderHierarchy(folders)}</div>
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-6">
              <button
                className="bg-[#1677FF] text-white px-6 py-2 rounded mr-3"
                onClick={handleSaveEdit}
                disabled={selectedPdfs.length === 0 || !newKBName}
              >
                Save
              </button>
              <button
                className="bg-[#D9D9D9] text-black px-6 py-2 rounded"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default CreateAgent;
