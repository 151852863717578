import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import logo from "../assets/pragya.svg";
import signupbg from "../assets/signupbg.jpg";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token) {
      fetch(`${API_URL}/auth/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Verification successful:", data);
          navigate("/email-verified");
        })
        .catch((error) => {
          console.error("Verification error:", error);
          toast.error("Email verification failed. Please try again.");
          navigate("/signin");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      toast.error("Invalid verification link");
      navigate("/signin");
    }
  }, [token, API_URL, navigate]);

  if (isLoading) {
    return (
      <div
        className="relative flex items-center justify-between min-h-screen w-full bg-cover bg-center overflow-x-hidden"
        style={{
          backgroundImage: `url(${signupbg})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="bg-white rounded-2xl shadow-xl p-4 sm:p-6 md:p-12 w-[95%] sm:w-[90%] md:w-[476px] min-h-[520px] my-4 sm:my-8 flex-shrink-0 mx-auto lg:mr-4 relative">
          {/* Logo */}
          <div className="flex justify-start mb-2 sm:mb-4">
            <img
              src={logo}
              alt="Prgya.ai"
              className="h-7 sm:h-9 mb-4 sm:mb-9"
            />
          </div>

          {/* Loading Spinner */}
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="w-12 h-12 border-4 border-blue-200 border-t-[#1677FF] rounded-full animate-spin"></div>
            <p className="text-gray-600 text-center">Verifying your email...</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default VerifyEmail;
