import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Project from "./Projects";
import axiosInstance from "../components/axiosInstance";
import { toast } from "react-toastify";
import ThreeDotLoader from "../components/dotloader";

const MembersPage = () => {
  const [organizationusers, setOrganizationusers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userIds, setUserIds] = useState([]);
  const [activeTab, setActiveTab] = useState("Projects");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/users/me`);
        console.log("User data fetched:", response.data);
        localStorage.setItem("user_id", response.data.id);
        setUser(response.data);
      } catch (err) {
        toast.error(err.response?.data?.detail || "Error fetching user data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, []);

  // Handle organization change
  const handleOrgChange = (orgObject) => {
    console.log("Selected Org Name:", orgObject.name); // Log the selected organization name
    setSelectedOrg(orgObject.id); // Store the ID
    setCurrentOrg(orgObject); // Store the entire organization object
    console.log("Updated Current Org:", orgObject); // Log the updated organization
  };

  // Fetch organizations for the logged-in user
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axiosInstance.get("/v1/organizations/user");
        console.log("Organizations fetched:", response.data);
        setOrganizations(response.data);
        if (response.data.length > 0) {
          setCurrentOrg(response.data[0]);
        }
      } catch (err) {
        toast.error("Error fetching organizations");
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  // Fetch users of the selected organization
  useEffect(() => {
    const fetchOrganizationUsers = async () => {
      if (!currentOrg?.id) return; // If no currentOrg, do not fetch

      try {
        const response = await axiosInstance.get(
          `/v1/organizations/${currentOrg.id}/users`,
        );
        console.log("Organization users fetched:", response.data);

        // Create an array of user IDs
        const ids = response.data.map((user) => user.id);
        setUserIds(ids); // Store user IDs in state
        console.log("User IDs:", ids); // Logs all user IDs in an array
      } catch (err) {
        toast.error("Error fetching organization users");
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganizationUsers();
  }, [currentOrg]);

  // Handle tab click
  const handleTabClick = (tabName) => {
    console.log("Tab clicked:", tabName);
    setActiveTab(tabName);
  };

  // Render content based on fetched data
  const renderContent = () => {
    if (!currentOrg || userIds.length === 0) {
      return <div>Loading organization or users...</div>;
    }

    console.log("Rendering Project with organization_id:", currentOrg?.id);
    console.log("Rendering Project with userorganization_id:", userIds);
    console.log("Rendering Project with user role:", user?.role);

    return (
      <div>
        <Project
          organization_id={currentOrg?.id} // current organization id
          userorganization_id={userIds[0]} // passing the first user ID here
          role={user?.role} // current user's role
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen h-full bg-[#070707]">
      {isLoading ? (
        <ThreeDotLoader />
      ) : (
        <div className="flex flex-1 relative">
          <svg
            width="470"
            height="428"
            viewBox="0 0 470 428"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-0 left-0 w-[350px] h-[350px] opacity-50 pointer-events-none z-[1]"
          >
            <g id="Ellipse 1" filter="url(#filter0_f)">
              <circle cx="92.5" cy="51.2812" r="113.5" fill="#1677FF" />
            </g>
            <defs>
              <filter
                id="filter0_f"
                x="-285"
                y="-326.6"
                width="755"
                height="755"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="132"
                  result="effect1_foregroundBlur"
                />
              </filter>
            </defs>
          </svg>
          <Sidebar
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            handleOrgChange={handleOrgChange}
            organizations={organizations}
          />
          <div className="flex-1">{renderContent()}</div>
        </div>
      )}
    </div>
  );
};

export default MembersPage;
