import React, { useState, useEffect, useRef, useCallback } from "react";
import { FiPaperclip, FiSend, FiMoreVertical } from "react-icons/fi";
import { useParams } from "react-router-dom";
import axiosInstance from "../../components/axiosInstance.js";
import { toast } from "react-toastify";
import { closeSidebar } from "../../redux/sidebarSlice";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { BsStars } from "react-icons/bs";
import "./ChatInterface.css";
import { FaStopCircle } from "react-icons/fa";
import MessageContent from "../../components/Chat/MessageContent";
import MessageFileReference from "../../components/Chat/MessageFileReference.jsx";

const getCategory = (updatedAt) => {
  const today = new Date();
  const yesterday = new Date(today);
  const last7Days = new Date(today);
  const last30Days = new Date(today);

  yesterday.setDate(today.getDate() - 1);
  last7Days.setDate(today.getDate() - 7);
  last30Days.setDate(today.getDate() - 30);

  const date = new Date(updatedAt);

  if (date.toDateString() === today.toDateString()) return "Today";
  if (date.toDateString() === yesterday.toDateString()) return "Yesterday";
  if (date > last7Days) return "Last 7 Days";
  if (date > last30Days) return "Last 30 Days";
  return "Older";
};

const categorizedConversations = (conversations) => {
  // First sort all conversations by updated_at in descending order (most recent first)
  const sortedConversations = [...conversations].sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
  );

  // Then categorize the sorted conversations
  const categorized = sortedConversations.reduce((acc, conversation) => {
    const category = getCategory(conversation.updated_at);
    if (!acc[category]) acc[category] = [];
    acc[category].push(conversation);
    return acc;
  }, {});

  // Define the preferred order of categories
  const categoryOrder = [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "Older",
  ];

  // Create a new object with ordered categories
  const orderedCategories = {};
  categoryOrder.forEach((category) => {
    if (categorized[category]) {
      orderedCategories[category] = categorized[category];
    }
  });

  return orderedCategories;
};
function ChatInterface() {
  const dispatch = useDispatch();
  const { assistant_id } = useParams();
  const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startedConversation, setStartedConversation] = useState(false);
  const isSidebarOpen = useSelector((state) => !state.sidebar.isOpen);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [editingConversationId, setEditingConversationId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [assistantname, setAssistantname] = useState("");
  const messagesEndRef = useRef(null);
  const categories = categorizedConversations(conversations);
  const user_id = localStorage.getItem("user_id");
  const org_id = localStorage.getItem("selectedOrg");
  const project_id = localStorage.getItem("activeProjectId");
  const WEBSOCKET_API_URL = process.env.REACT_APP_WEBSOCKET_URL;
  const [activeSocket, setActiveSocket] = useState(null);
  const [conversationMessages, setConversationMessages] = useState({});
  const [copiedCode, setCopiedCode] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [toggleState, setToggleState] = useState("no");

  // Auto-scroll to the bottom when messages or loading state changes
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, loading]);
  useEffect(() => {
    dispatch(closeSidebar());
  }, [dispatch]);

  // Fetch conversations for a specific assistant
  const fetchConversations = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `/v1/assistants/${assistant_id}/conversations/${user_id}`,
      );
      setConversations(response.data);
    } catch (error) {
      toast.error("Error fetching conversations:", error);
    }
  }, [assistant_id, user_id]);

  const fetchAssistants = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `/v1/user/assistants/${assistant_id}`,
      );
      setAssistantname(response.data.assistant_name || "");
    } catch (error) {
      toast.error("Error fetching assistants");
    }
  }, [assistant_id]);

  const handleToggle = () => {
    setToggleState((prevState) => (prevState === "no" ? "yes" : "no"));
  };
  // Fetch conversations for the assistant on component load
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchConversations();
        await fetchAssistants();

        // Get the conversations data directly from the API response
        const response = await axiosInstance.get(
          `/v1/assistants/${assistant_id}/conversations/${user_id}`,
        );
        const conversationsData = response.data;

        // If there are existing conversations, select the most recent one
        if (conversationsData && conversationsData.length > 0) {
          // Sort conversations by updated_at to get the most recent one
          const sortedConversations = [...conversationsData].sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
          );
          const mostRecentConversation = sortedConversations[0];

          // Select the most recent conversation and fetch its messages
          setSelectedConversationId(mostRecentConversation.id);
          setStartedConversation(true);
          handleConversationSelect(mostRecentConversation);
        }
      } catch (error) {
        console.error("Error in initial data fetch:", error);
        toast.error("Error loading conversations");
      }
    };

    fetchData();
  }, [assistant_id, user_id, fetchConversations, fetchAssistants]);

  // Create a new conversation
  const handleCreateConversation = async () => {
    try {
      const response = await axiosInstance.post(
        `/v1/assistants/${assistant_id}/conversations/`,
        {
          title: `Conversation ${conversations.length + 1}`,
        },
      );

      // Create new conversation object with current timestamp
      const newConversation = {
        id: response?.data?.conversation_id,
        title: response?.data?.title || "New chat",
        updated_at: new Date().toISOString(), // Add current timestamp
      };

      // Add new conversation at the beginning of the array
      setConversations((prev) => [newConversation, ...prev]);

      setSelectedConversationId(response?.data?.conversation_id);
      setStartedConversation(true);
      setMessages([]);
      return response?.data?.conversation_id;
    } catch (error) {
      toast.error("Error creating conversation");
    }
  };

  // Fetch messages for a selected conversation
  const handleConversationSelect = async (conversation) => {
    setStartedConversation(true);
    setSelectedConversationId(conversation.id);
    setIsLoadingMessages(true);
    try {
      const response = await axiosInstance.get(
        `/v1/conversations/${conversation.id}/messages`,
      );
      // Store messages in the conversationMessages object
      setConversationMessages((prev) => ({
        ...prev,
        [conversation.id]: response.data,
      }));
      // Set current messages for display
      setMessages(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingMessages(false);
    }
  };

  // Updated WebSocket Connection setup within handleSendMessage or App component
  const handleSendandcreateMessage = async () => {
    setStartedConversation(true);
    try {
      const messageToSend = newMessage; // Store the message before clearing
      setNewMessage(""); // Clear the input immediately

      // Wait for the conversation to be created
      const id = await handleCreateConversation();

      // Now send the first message with the stored message
      if (id) {
        // Update conversations list with the new timestamp
        const userMessage = { role: "user", content: messageToSend };
        // Store the conversation ID for use in socket handler
        const currentConversationId = id;
        // Set messages specifically for this new conversation
        setConversationMessages((prev) => ({
          ...prev,
          [id]: [userMessage],
        }));

        setMessages([userMessage]);

        // Send the message
        const socket = new WebSocket(
          `${WEBSOCKET_API_URL}/v1/assistants/${assistant_id}/conversations/${id}/stream/?user_id=${user_id}&org_id=${org_id}&proj_id=${project_id}&contextual=${toggleState}`,
        );

        setActiveSocket(socket);

        let currentBotMessage = "";

        socket.onopen = () => {
          socket.send(messageToSend);
        };

        setLoading(true);

        // Rest of your socket handling code...
        socket.onmessage = (event) => {
          setLoading(false);
          const partialResponse = event.data;

          if (partialResponse === "[DONE]") {
            socket.close();
            setActiveSocket(null);
            updateConversationTimestamp(currentConversationId);
            setConversations((prevConversations) => {
              return prevConversations.map((conv) => {
                if (conv.id === currentConversationId) {
                  return { ...conv, updated_at: new Date().toISOString() };
                }
                return conv;
              });
            });
          } else {
            currentBotMessage += partialResponse;
            // Only update messages for this specific conversation
            setConversationMessages((prev) => {
              const conversationMsgs = [...(prev[id] || [])];
              const lastMessage = conversationMsgs[conversationMsgs.length - 1];

              if (lastMessage && lastMessage.role === "bot") {
                conversationMsgs[conversationMsgs.length - 1] = {
                  ...lastMessage,
                  content: currentBotMessage,
                };
              } else {
                conversationMsgs.push({
                  role: "bot",
                  content: currentBotMessage,
                });
              }

              return {
                ...prev,
                [id]: conversationMsgs,
              };
            });

            // Update current display since we're in the new conversation
            setMessages((prev) => {
              const lastMessage = prev[prev.length - 1];
              if (lastMessage && lastMessage.role === "bot") {
                const updatedMessages = [...prev];
                updatedMessages[updatedMessages.length - 1] = {
                  ...lastMessage,
                  content: currentBotMessage,
                };
                return updatedMessages;
              } else {
                return [...prev, { role: "bot", content: currentBotMessage }];
              }
            });
          }
        };
      }
    } catch (error) {
      toast.error("Error creating conversation");
      setMessages([]); // Reset messages on error
    }
  };

  const handleSendMessage = () => {
    if (!newMessage || !selectedConversationId) return;
    const userMessage = { role: "user", content: newMessage };
    const currentConversationId = selectedConversationId;

    // Update messages for the current conversation
    setConversationMessages((prev) => ({
      ...prev,
      [currentConversationId]: [
        ...(prev[currentConversationId] || []),
        userMessage,
      ],
    }));

    // Update current display if we're still on the same conversation
    setMessages((prev) => [...prev, userMessage]);
    setNewMessage("");

    const socket = new WebSocket(
      `${WEBSOCKET_API_URL}/v1/assistants/${assistant_id}/conversations/${currentConversationId}/stream/?user_id=${user_id}&org_id=${org_id}&proj_id=${project_id}&contextual=${toggleState}`,
    );

    let currentBotMessage = "";

    setActiveSocket(socket);
    setIsStreaming(true); // Set streaming state to true

    socket.onopen = () => {
      socket.send(newMessage);
    };
    setLoading(true);

    socket.onmessage = (event) => {
      setLoading(false);
      const partialResponse = event.data;

      if (partialResponse === "[DONE]") {
        socket.close();
        setActiveSocket(null);
        setIsStreaming(false);
        updateConversationTimestamp(currentConversationId);
        setConversations((prevConversations) => {
          return prevConversations.map((conv) => {
            if (conv.id === currentConversationId) {
              return { ...conv, updated_at: new Date().toISOString() };
            }
            return conv;
          });
        });
      } else {
        currentBotMessage += partialResponse;

        // Update messages for the specific conversation
        setConversationMessages((prev) => {
          const conversationMsgs = [...(prev[currentConversationId] || [])];
          const lastMessage = conversationMsgs[conversationMsgs.length - 1];

          if (lastMessage && lastMessage.role === "bot") {
            conversationMsgs[conversationMsgs.length - 1] = {
              ...lastMessage,
              content: currentBotMessage,
            };
          } else {
            conversationMsgs.push({ role: "bot", content: currentBotMessage });
          }

          return {
            ...prev,
            [currentConversationId]: conversationMsgs,
          };
        });

        // Only update current display if we're still on the same conversation
        if (selectedConversationId === currentConversationId) {
          setMessages((prev) => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage && lastMessage.role === "bot") {
              const updatedMessages = [...prev];
              updatedMessages[updatedMessages.length - 1] = {
                ...lastMessage,
                content: currentBotMessage,
              };
              return updatedMessages;
            } else {
              return [...prev, { role: "bot", content: currentBotMessage }];
            }
          });
        }
      }
    };

    socket.onerror = (error) => {
      setLoading(false);
      setActiveSocket(null);
      if (error.code === 4000) {
        toast.error("Token limit exceeded", error.reason);
      }
    };

    socket.onclose = (event) => {
      setLoading(false);
      setActiveSocket(null);
      setIsStreaming(false);
      if (event.code === 4000) {
        toast.error(event.reason);
      } else if (event.code === 4001) {
        toast.error("An unexpected error occurred.");
      } else {
        console.log(
          "WebSocket closed with code:",
          event.code,
          "reason:",
          event.reason,
        );
      }
    };
  };

  // Add cleanup on component unmount
  useEffect(() => {
    return () => {
      if (activeSocket) {
        activeSocket.close();
      }
    };
  }, [activeSocket]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if clicked outside
      if (!event.target.closest(".dropdown-menu")) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (conversationId) => {
    setOpenDropdownId(
      openDropdownId === conversationId ? null : conversationId,
    );
  };

  // Handle delete conversation
  const handleDeleteConversation = async (conversationId) => {
    try {
      await axiosInstance.delete(
        `/v1/assistants/${assistant_id}/conversations/${conversationId}`,
      );
      setConversations(
        conversations.filter((conv) => conv.id !== conversationId),
      );
      if (selectedConversationId === conversationId) {
        setSelectedConversationId(null);
        setMessages([]);
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
    setOpenDropdownId(null);
  };

  // Handle rename conversation
  const handleRenameConversation = (conversationId, currentTitle) => {
    setEditingConversationId(conversationId);
    setEditedTitle(currentTitle);
  };

  const handleUpdateConversation = async (conversationId, newTitle) => {
    try {
      await axiosInstance.patch(`/v1/conversations/${conversationId}/title/`, {
        new_title: newTitle,
      });
      setConversations((prev) =>
        prev.map((conv) =>
          conv.id === conversationId ? { ...conv, title: newTitle } : conv,
        ),
      );
    } catch (error) {
      console.error(
        "Error updating conversation title:",
        error.response?.data || error.message,
      );
    } finally {
      setEditingConversationId(null);
    }
  };

  const updateConversationTimestamp = async (conversationId) => {
    try {
      await axiosInstance.patch(`/v1/${conversationId}/update-timestamp`, {
        updated_at: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error updating conversation timestamp:", error);
    }
  };

  const handleStopStream = () => {
    if (activeSocket) {
      activeSocket.close();
      setActiveSocket(null);
      setIsStreaming(false);
      setLoading(false);
    }
  };

  const handleBlurOrSubmit = (conversationId) => {
    if (editedTitle.trim()) {
      handleUpdateConversation(conversationId, editedTitle);
    } else {
      setEditingConversationId(null);
    }
  };
  // Handle share conversation
  const handleShareConversation = (conversationId) => {
    alert(`Sharing conversation ${conversationId}`);
    setOpenDropdownId(null);
  };

  const processMessageContent = (content) => {
    const fileReferences = [];
    let cleanContent = content;
    // Extract file references from the content
    const matches = content.match(/File:.*?\.pdf/g) || [];
    if (matches.length > 0) {
      // Remove everything from the first "File:" to the end of the content
      cleanContent = content.split("File:")[0].trim();
      matches.forEach((match) => {
        fileReferences.push(match);
      });
    }

    return {
      content: cleanContent,
      references: fileReferences,
    };
  };

  return (
    <div
      className={`flex absolute inset-0  ${isSidebarOpen ? "ml-[110px]" : "ml-[295px]"} transition-all duration-300 mt-12`}
    >
      <div className="absolute -top-10 left-6 text-white text-lg font-semibold font-inter">
        {assistantname || "Assistant Name"}
      </div>
      <aside className="w-1/5 bg-[#1D1E2066] border border-[#FFFFFF66] p-4 h-auto flex flex-col items-center rounded-lg relative pb-6 mb-6 scrollbar-hide">
        {/* Header */}
        <div className="w-full text-white text-left mb-2">
          <h2 className="text-lg font-semibold">Chat History</h2>
        </div>
        {/* Conversations List */}
        <div className="flex-1 w-full overflow-y-auto space-y-3 scrollbar-hide">
          {Object.keys(categories).map((category) => (
            <div key={category} className="space-y-3">
              <h2 className="text-gray-400 font-bold mt-4 mb-2">{category}</h2>
              {categories[category].map((conversation) => (
                <div
                  key={conversation.id}
                  onClick={() => handleConversationSelect(conversation)}
                  className={`bg-[#252526] p-3 rounded-lg flex items-center justify-between cursor-pointer w-full 
                    text-white transition-all hover:bg-[#2C2C2C] ${selectedConversationId === conversation.id ? "border-2 border-white" : ""}`}
                >
                  {editingConversationId === conversation.id ? (
                    <input
                      type="text"
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      onBlur={() => handleBlurOrSubmit(conversation.id)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleBlurOrSubmit(conversation.id);
                        }
                      }}
                      autoFocus
                      className="flex-1 bg-white text-black px-2 py-1 rounded"
                    />
                  ) : (
                    <span className="truncate">{conversation.title}</span>
                  )}

                  {/* Dropdown Button */}
                  <div className="relative">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(conversation.id);
                      }}
                      className="ml-2 text-gray-400 hover:text-white"
                    >
                      <FiMoreVertical />
                    </button>

                    {/* Dropdown Menu */}
                    {openDropdownId === conversation.id && (
                      <div
                        className="absolute right-0 mt-2 w-40 bg-black shadow-lg rounded-lg z-20 dropdown-menu"
                        style={{ top: "100%", right: "0" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ul className="text-gray-300">
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteConversation(conversation.id);
                            }}
                            className="cursor-pointer px-4 py-2 hover:bg-gray-700 rounded-t-lg"
                          >
                            Delete
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRenameConversation(conversation.id);
                            }}
                            className="cursor-pointer px-4 py-2 hover:bg-gray-700"
                          >
                            Rename
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShareConversation(conversation.id);
                            }}
                            className="cursor-pointer px-4 py-2 hover:bg-gray-700 rounded-b-lg"
                          >
                            Share
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* New Chat Button (moved to bottom) */}
        <button
          onClick={handleCreateConversation}
          className="bg-white text-[#007BFF] font-semibold w-full py-3 mt-6 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-300"
        >
          <FaPlus size={16} /> New Chat
        </button>
      </aside>

      <div className="w-full max-w-8xl shadow-xl rounded-2xl overflow-hidden h-full flex flex-col">
        {/* Conditionally render the initial input or the conversation */}
        {!startedConversation ? (
          <div className="flex flex-col items-center justify-center">
            <div className="text-center mt-32">
              <BsStars className="text-blue-500 mx-auto text-4xl" />
              <h1 className="text-white text-xl font-semibold mt-2">
                Can I help you with anything?
              </h1>
              <p className="text-gray-400 mt-1 text-sm">
                Here to help with any questions or recommendations. Let's get
                started!
              </p>
            </div>
            <div className="bg-[#1D1E20] rounded-xl flex flex-col w-[85%] mt-6 ml-6 mb-6">
              <div className="flex items-center">
                {/* Toggle Button */}

                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (newMessage.trim()) {
                        handleSendandcreateMessage(); // Changed from handleSendfirstMessage
                      }
                    }
                  }}
                  placeholder="Type your message"
                  className="px-4 py-3 bg-[#1D1E20] text-gray-300 rounded-lg border-none outline-none w-[90%]"
                />

                {/* Icons (Attachment & Send) */}
                <div className="flex justify-end items-center space-x-2 mt-3 p-2 mr-2">
                  <button
                    onClick={handleToggle}
                    className={`p-2 rounded-full w-10 h-6 flex items-center justify-center transition-colors duration-200 ${
                      toggleState === "yes" ? "bg-green-500" : "bg-gray-300"
                    }`}
                  >
                    <div
                      className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                        toggleState === "yes"
                          ? "transform translate-x-2"
                          : "transform -translate-x-2"
                      }`}
                    />
                  </button>
                  {/* Attachment Icon */}
                  <button className="p-2 text-gray-400 hover:text-white">
                    <FiPaperclip size={18} />
                  </button>

                  {/* Send Button */}
                  <button
                    onClick={
                      isStreaming
                        ? handleStopStream
                        : () => {
                            if (newMessage.trim()) {
                              handleSendandcreateMessage();
                            }
                          }
                    }
                    disabled={!isStreaming && !newMessage.trim()}
                    className={`p-2 ${
                      isStreaming
                        ? "bg-gray-600 hover:bg-gray-700 cursor-pointer"
                        : newMessage.trim()
                          ? "bg-blue-500 hover:bg-blue-500 cursor-pointer"
                          : "hover:bg-gray-500 cursor-not-allowed"
                    } text-white rounded-lg transition-colors duration-200 flex items-center`}
                    title={isStreaming ? "Stop generating" : "Send message"}
                  >
                    {isStreaming ? (
                      <FaStopCircle
                        size={20}
                        color="gray"
                        backgroundColor="white"
                      />
                    ) : (
                      <FiSend size={20} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-6 flex-1 overflow-y-auto scrollbar-hide">
            {isLoadingMessages ? (
              <p>Loading messages...</p>
            ) : messages.length > 0 ? (
              <div className="space-y-6 px-6">
                {(conversationMessages[selectedConversationId] || messages).map(
                  (message, index) => (
                    <div
                      key={index}
                      className={`flex ${message.role === "user" ? "justify-end" : "justify-start"}`}
                    >
                      <div
                        className={`flex max-w-[100%] ${message.role === "user" ? "flex-row-reverse" : "flex-row"}`}
                      >
                        {/* Avatar */}
                        <div
                          className={`flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center
              ${
                message.role === "user"
                  ? "bg-gray-300 ml-4"
                  : "bg-[#19C37D] mr-4"
              }`}
                        >
                          {message.role === "user" ? (
                            <span className="text-black font-medium">U</span>
                          ) : (
                            <BsStars className="text-white text-sm" />
                          )}
                        </div>

                        {/* Message Content */}
                        <div className={`flex flex-col space-y-2`}>
                          <div
                            className={`text-sm font-medium ${message.role === "user" ? "text-right" : "text-left"}`}
                          >
                            {message.role === "user" ? "You" : assistantname}
                          </div>
                          <div
                            className={`prose prose-invert max-w-none text-white`}
                          >
                            <MessageContent
                              content={
                                processMessageContent(message.content).content
                              }
                              setCopiedCode={setCopiedCode}
                              copiedCode={copiedCode}
                            />
                            <MessageFileReference message={message} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                )}

                {/* Loading indicator */}
                {loading && (
                  <div className="flex justify-start">
                    <div className="flex max-w-[80%] flex-row">
                      <div className="flex-shrink-0 h-8 w-8 rounded-full bg-[#19C37D] mr-4 flex items-center justify-center">
                        <BsStars className="text-white text-sm" />
                      </div>
                      <div className="flex items-center">
                        <div className="flex space-x-2">
                          <span
                            className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                            style={{ animationDelay: "0s" }}
                          ></span>
                          <span
                            className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                            style={{ animationDelay: "0.2s" }}
                          ></span>
                          <span
                            className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"
                            style={{ animationDelay: "0.4s" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            ) : (
              // <p className="text-gray-400 text-center">No messages in this conversation.</p>
              <>
                <div className="flex flex-col items-center justify-center">
                  <div className="text-center mt-32">
                    <BsStars className="text-blue-500 mx-auto text-4xl" />
                    <h1 className="text-white text-xl font-semibold mt-2">
                      Can I help you with anything?
                    </h1>
                    <p className="text-gray-400 mt-1 text-sm">
                      Here to help with any questions or recommendations. Let's
                      get started!
                    </p>
                  </div>
                  <div className="bg-[#1D1E20] rounded-xl flex flex-col w-[85%] mt-6 ml-6 mb-6">
                    <div className="flex items-center">
                      {/* Toggle Button */}

                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSendMessage();
                          }
                        }}
                        placeholder="Type your message"
                        className="px-4 py-3 bg-[#1D1E20] text-gray-300 rounded-lg border-none outline-none w-[90%]"
                      />

                      {/* Icons (Attachment & Send) */}
                      <div className="flex justify-end items-center space-x-2 mt-3 p-2 mr-2">
                        <button
                          onClick={handleToggle}
                          className={`p-2 rounded-full w-10 h-6 flex items-center justify-center transition-colors duration-200 ${
                            toggleState === "yes"
                              ? "bg-green-500"
                              : "bg-gray-300"
                          }`}
                        >
                          <div
                            className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                              toggleState === "yes"
                                ? "transform translate-x-2"
                                : "transform -translate-x-2"
                            }`}
                          />
                        </button>
                        <button className="p-2 text-gray-400 hover:text-white">
                          <FiPaperclip size={18} />
                        </button>
                        <button
                          onClick={
                            isStreaming
                              ? handleStopStream
                              : () => {
                                  if (newMessage.trim()) {
                                    handleSendMessage();
                                  }
                                }
                          }
                          disabled={!isStreaming && !newMessage.trim()}
                          className={`p-2 ${
                            isStreaming
                              ? "bg-gray-600 hover:bg-gray-700 cursor-pointer"
                              : newMessage.trim()
                                ? "bg-blue-500 hover:bg-blue-500 cursor-pointer"
                                : "cursor-not-allowed"
                          } text-white rounded-lg transition-colors duration-200 flex items-center`}
                          title={
                            isStreaming ? "Stop generating" : "Send message"
                          }
                        >
                          {isStreaming ? (
                            <FaStopCircle
                              size={20}
                              color="gray"
                              backgroundColor="white"
                            />
                          ) : (
                            <FiSend size={20} />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {/* Input Field after the conversation has started */}
        {startedConversation && messages.length > 0 && (
          <div className="border-t border-gray-700  p-4">
            <div className="max-w-4xl mx-auto relative">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
                placeholder="Message..."
                className="w-full bg-[#2C2C2C] text-white rounded-lg pl-4 pr-12 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center space-x-2">
                <button
                  onClick={handleToggle}
                  className={`p-2 rounded-full w-10 h-6 flex items-center justify-center transition-colors duration-200 ${
                    toggleState === "yes" ? "bg-green-500" : "bg-gray-300"
                  }`}
                >
                  <div
                    className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${
                      toggleState === "yes"
                        ? "transform translate-x-2"
                        : "transform -translate-x-2"
                    }`}
                  />
                </button>
                <button
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                  title="Attach file"
                >
                  <FiPaperclip size={20} />
                </button>
                <button
                  onClick={isStreaming ? handleStopStream : handleSendMessage}
                  disabled={!isStreaming && !newMessage.trim()}
                  className={`p-2 rounded-lg transition-colors ${
                    isStreaming
                      ? "text-white hover:bg-gray-600"
                      : newMessage.trim()
                        ? "text-white hover:bg-[#424242]"
                        : "text-gray-500 cursor-not-allowed"
                  }`}
                  title={isStreaming ? "Stop generating" : "Send message"}
                >
                  {isStreaming ? (
                    <FaStopCircle
                      size={20}
                      color="gray"
                      backgroundColor="white"
                    />
                  ) : (
                    <FiSend size={20} />
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatInterface;
