import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const location = useLocation();

  // More robust authentication check
  const checkAuthentication = () => {
    try {
      const token = localStorage.getItem("access_token");
      const isLoggedIn = localStorage.getItem("isLoggedIn");

      // Check if token exists and has the expected format (JWT token has 3 parts separated by dots)
      if (!token || token.split(".").length !== 3) {
        console.log("Invalid token format or missing token");
        return false;
      }

      // Check if isLoggedIn is explicitly set to "true"
      if (isLoggedIn !== "true") {
        console.log("isLoggedIn not set to true");
        return false;
      }

      // If we get here, the user is authenticated
      return true;
    } catch (error) {
      console.error("Error checking authentication:", error);
      return false;
    }
  };

  const isAuthenticated = checkAuthentication();

  // If not authenticated, redirect to login page with the current location saved
  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  // If authenticated, render the protected element
  return element;
};

export default ProtectedRoute;
