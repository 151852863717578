import axios from "axios";
import axiosInstance from "../services/axiosinstance";

const API_URL = process.env.REACT_APP_API_URL;

export const googleLogin = async (code, state) => {
  try {
    console.log("Processing Google login...");

    const res = await axios.get(
      `${API_URL}/auth/google/callback?code=${code}&state=${state}&access_type=offline&prompt=consent&include_granted_scopes=true`,
      {
        withCredentials: true,
      },
    );

    console.log("Google login response:", !!res.data);

    if (res.data && res.data.access_token) {
      localStorage.setItem("access_token", res.data.access_token);
      localStorage.setItem("isLoggedIn", "true");
      console.log("Stored access_token and set isLoggedIn to true");
    } else {
      console.error("No access_token received from Google login");
    }

    // The user context onLoginSuccess will be called by the component after this function returns

    const response = await axiosInstance.get("/auth/refresh-token");
    const has_org = response.data.has_org;

    return has_org;
  } catch (error) {
    console.error("Error during Google login:", error);
    throw error;
  }
};

// Function to refresh the token
export const getRefreshToken = async () => {
  try {
    console.log("Attempting to refresh token...");

    const response = await axios.get(`${API_URL}/auth/refresh-token`, {
      withCredentials: true,
    });

    if (response.data && response.data.access_token) {
      localStorage.setItem("access_token", response.data.access_token);
      console.log("Token refreshed successfully");
      return true;
    }
    console.error("No access_token in refresh response");
    return false;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false;
  }
};
