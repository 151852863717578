import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../components/axiosInstance.js";
import { toast } from "react-toastify";
import { getStoredValue } from "../services/logics.js";
import { HiOutlineSearch } from "react-icons/hi";
import { IoEllipsisVertical } from "react-icons/io5";
import { MdPersonAddAlt } from "react-icons/md";

const Projectmembers = () => {
  const [projectUsers, setProjectUsers] = useState([]);
  const [searchTermMembers, setSearchTermMembers] = useState("");
  const [searchTermOrgUsers, setSearchTermOrgUsers] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [newUserRole, setNewUserRole] = useState("MEMBER");
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const project_id = getStoredValue("activeProjectId");
  const user_id = getStoredValue("user_id");
  const organization_id = getStoredValue("selectedOrg");
  const dropdownRef = useRef(null);
  const [organizationUsers, setOrganizationUsers] = useState([]); // Org Users List
  const [filteredOrgUsers, setFilteredOrgUsers] = useState([]); // Filtered Org Users
  const [currentUser, setCurrentUser] = useState([]);

  useEffect(() => {
    fetchProjectUsers();
    fetchOrganizationUsers();
  }, []);

  const fetchProjectUsers = async () => {
    try {
      const response = await axiosInstance.get(
        `/v1/projects/${project_id}/users`,
      );
      setProjectUsers(response.data);
      setFilteredUsers(response.data);
      console.log(response);
      console.log(projectUsers);
    } catch (error) {
      toast.error("Error fetching project members");
    }
  };

  const fetchOrganizationUsers = async () => {
    try {
      const response = await axiosInstance.get(
        `/v1/organizations/${organization_id}/users`,
      );
      setOrganizationUsers(response.data);

      // ✅ Find current user based on user_id
      const user = response.data.find((member) => member.user_id === user_id);
      setCurrentUser(user);
    } catch (error) {
      toast.error("Error fetching organization users");
    }
  };
  useEffect(() => {
    if (showAddUserModal) fetchOrganizationUsers();
  }, [showAddUserModal]);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTermMembers(value);
    setFilteredUsers(
      projectUsers.filter(
        (user) =>
          user.full_name.toLowerCase().includes(value) ||
          user.email.toLowerCase().includes(value),
      ),
    );
  };
  const handleSearchChangeorg = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTermOrgUsers(value); // Use separate state for organization users
    setFilteredOrgUsers(
      organizationUsers.filter(
        (user) =>
          user.full_name.toLowerCase().includes(value) ||
          user.email.toLowerCase().includes(value),
      ),
    );
  };
  const handleRoleChange = async (userorgId, newRole) => {
    try {
      await axiosInstance.patch(`/v1/projects/${project_id}/update-role`, {
        target_userorganization_id: userorgId,
        owner_userorgid: currentUser.id,
        role: newRole,
      });

      setProjectUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.userorg_id === userorgId ? { ...user, role: newRole } : user,
        ),
      );

      toast.success("Role updated successfully!");
    } catch (error) {
      toast.error("Error updating role.");
    }
  };

  const removeUser = async (userproject_id) => {
    try {
      await axiosInstance.post(`/v1/projects/${project_id}/remove-user`, {
        target_userproject_id: userproject_id,
        owner_userorgid: currentUser.id,
      });

      setProjectUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== userproject_id),
      );
      toast.success("User removed successfully.");
    } catch (error) {
      toast.error("Failed to remove user.");
    }
  };

  const handleAddUser = async () => {
    if (!selectedUser) {
      toast.error("Please select a user to add.");
      return;
    }
    try {
      await axiosInstance.post(`/v1/projects/${project_id}/add-user`, {
        userorganization_id: currentUser.id,
        addeduserorgid: selectedUser.id,
        role: newUserRole,
      });

      toast.success("User added successfully!");
      fetchProjectUsers();
      setShowAddUserModal(false);
    } catch (error) {
      toast.error("Failed to add user. Please try again.");
    }
  };

  return (
    <div className=" w-full p-6 rounded-lg text-white">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Project Members</h1>
        <div className="flex items-center gap-3">
          {/* Search Input */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchTermMembers}
              onChange={handleSearchChange}
              className="bg-[#2C2C2C] text-white px-4 py-2 pl-10 rounded-lg border border-gray-700 focus:outline-none"
            />
            <HiOutlineSearch
              className="absolute left-3 top-3 text-gray-400"
              size={18}
            />
          </div>
          {/* Add User Button */}
          <button
            className="bg-[#1677FF] text-white px-4 py-2 rounded-lg flex items-center gap-2"
            onClick={() => setShowAddUserModal(true)}
          >
            <MdPersonAddAlt size={18} />
            Add User
          </button>
        </div>
      </div>

      {/* Members Table */}
      <div className="overflow-x-auto rounded-lg border border-gray-700">
        <table className="w-full text-left">
          <thead className="bg-[#2C2C2C]">
            <tr>
              <th className="py-3 px-4">Name</th>
              <th className="py-3 px-4">Email</th>
              <th className="py-3 px-4">Role</th>
              <th className="py-3 px-4">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((member) => (
              <tr
                key={member.id}
                className="border-b border-gray-700 hover:bg-[#252627]"
              >
                <td className="py-3 px-4 flex items-center space-x-3">
                  <div className="w-8 h-8 rounded-full bg-gray-500 flex items-center justify-center text-white font-medium">
                    {member.full_name.charAt(0).toUpperCase()}
                  </div>
                  <span>{member.full_name}</span>
                </td>
                <td className="py-3 px-4 text-gray-400">{member.email}</td>
                <td className="py-3 px-4">
                  <select
                    value={member.role}
                    onChange={(e) =>
                      handleRoleChange(member.userorg_id, e.target.value)
                    }
                    className="bg-[#2C2C2C] text-white border border-gray-700 px-2 py-1 rounded focus:outline-none"
                  >
                    <option value="OWNER">Owner</option>
                    <option value="MEMBER">Member</option>
                  </select>
                </td>
                <td className="py-3 px-4 relative">
                  {member.userorg_id === currentUser?.id ? (
                    // Show "Leave" button if the current user is the same as member.userorg_id
                    <button
                      className="block w-1/2 bg-[#FBFBFB] px-1 py-1 font-semibold rounded-lg text-black "
                      // onClick={() => leaveOrganization(member.id)}
                    >
                      Leave
                    </button>
                  ) : (
                    // Show "Remove" button if the user is different OR if they are the "owner"
                    <button
                      className="block w-1/2 bg-[#38ddba] px-1 py-1 font-semibold rounded-lg text-black "
                      onClick={() => removeUser(member.id)}
                    >
                      Remove
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add User Modal */}
      {showAddUserModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#1D1E21] p-6 rounded-lg shadow-lg w-[500px] border border-gray-700">
            <h3 className="text-lg font-semibold text-white mb-4">
              Add Members
            </h3>

            {/* Search User Input */}
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Search organization users..."
                className="w-full px-4 py-2 bg-[#2C2C2C] text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
                value={searchTermOrgUsers}
                onChange={handleSearchChangeorg}
              />
              {filteredOrgUsers.length > 0 && (
                <ul className="absolute bg-[#2C2C2C] border border-gray-600 rounded-lg shadow-md mt-1 w-full max-h-40 overflow-y-auto z-10">
                  {filteredOrgUsers.map((user) => (
                    <li
                      key={user.id}
                      className="px-4 py-2 hover:bg-gray-700 text-white cursor-pointer"
                      onClick={() => {
                        setSearchTermOrgUsers(user.email); // Corrected

                        setSelectedUser(user);
                        setFilteredOrgUsers([]);
                      }}
                    >
                      {user.full_name} ({user.email})
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Role Selection Dropdown */}
            <div className="mb-4">
              <label className="text-white block mb-1">Select Role</label>
              <select
                value={newUserRole}
                onChange={(e) => setNewUserRole(e.target.value)}
                className="border border-gray-600 rounded-lg px-4 py-2 bg-[#2C2C2C] text-white w-full focus:outline-none focus:ring-2 focus:ring-purple-400"
              >
                <option value="MEMBER">Member</option>
                <option value="OWNER">Owner</option>
              </select>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end">
              <button
                className="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 mr-2"
                onClick={() => setShowAddUserModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#1677FF]  text-white px-4 py-2 rounded-lg "
                onClick={handleAddUser}
                disabled={!selectedUser}
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projectmembers;
