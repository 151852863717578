import React from "react";

const EmptyStateComponent = ({
  icon,
  title,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-white">
      {/* Icon */}
      <img src={icon} alt="Empty State Icon" className="w-20 h-20 mb-4" />

      {/* Title */}
      <h2 className="text-lg font-semibold mb-2">{title}</h2>

      {/* Description */}
      <p className="text-gray-400 text-sm mb-4">{description}</p>

      {/* Action Button */}
      <button
        className="bg-[#1677FF] text-white px-6 py-2 rounded-md "
        onClick={onButtonClick}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default EmptyStateComponent;
