import React, { useState } from "react";
import axiosInstance from "../components/axiosInstance"; // Ensure correct path
import { toast } from "react-toastify";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    company_name: "",

    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateEmail(formData.email)) {
      toast.error("Invalid email format. Please enter a valid email.");
      setIsSubmitting(false);
      return;
    }

    // Format data correctly before sending
    const formDataToSend = {
      full_name: formData.full_name.trim(),
      email: formData.email.trim(),
      company_name: formData.company_name.trim() || null, // Ensure null if empty
      role: "Developer",
      message: formData.message.trim(),
    };

    try {
      console.log("Sending Form Data:", formDataToSend);

      const response = await axiosInstance.post("/v1/contact", formDataToSend, {
        headers: { "Content-Type": "application/json" }, // Ensure JSON header
      });

      if (response.status === 201) {
        toast.success("Contact form submitted successfully!");
        setFormData({
          full_name: "",
          email: "",
          company_name: "",

          message: "",
        });
      }
    } catch (error) {
      console.error("Failed to submit contact form:", error);
      toast.error("Submission failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="contact" className="bg-[#050D24] py-12">
      <div className="max-w-5xl mx-auto px-6">
        <h2 className="text-5xl font-bold text-white text-center mt-2 mb-3">
          Contact Us
        </h2>
        <p className="text-base text-white text-center mb-5">
          Unlock the future of productivity with Prgya.ai. Remember this journey
          is just getting started.
        </p>

        <div className="flex justify-center">
          <div className="bg-[#091530] rounded-lg p-8 shadow-lg w-full max-w-2xl">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm text-white mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  className="w-full bg-transparent text-white border-b border-gray-600 p-2 focus:outline-none focus:border-blue-500"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm text-white mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-transparent text-white border-b border-gray-600 p-2 focus:outline-none focus:border-blue-500"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm text-white mb-1">
                  Company Name
                </label>
                <input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  className="w-full bg-transparent text-white border-b border-gray-600 p-2 focus:outline-none focus:border-blue-500"
                />
              </div>

              <div className="mb-6">
                <label className="block text-sm text-white mb-1">Message</label>
                <textarea
                  name="message"
                  rows="3"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full bg-transparent text-white border-b border-gray-600 p-2 focus:outline-none focus:border-blue-500"
                  required
                ></textarea>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-fit text-white font-semibold py-2 px-4 rounded-full shadow-md transition-opacity hover:opacity-90 border-2 border-[#00D0FF] bg-gradient-to-r from-[#0A5EF3] to-[#00D0FF]"
                >
                  {isSubmitting ? "Submitting..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
