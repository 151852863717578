import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../components/axiosInstance";
import { useSelector } from "react-redux";
import CreatorUploadFiles from "./CreatorUploadFiles";
import { getStoredValue } from "../../services/logics.js";
import {
  FaFolder,
  FaEllipsisV,
  FaPlus,
  FaTh,
  FaBars,
  FaSearch,
} from "react-icons/fa";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PopupModal from "../../components/PopupModal";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import { useProjects } from "../../context/ProjectContext";

const Files = () => {
  const [viewMode, setViewMode] = useState("grid"); // Toggle between "grid" and "list"
  const [folders, setFolders] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    folderIndex: null,
  });
  const [newFolderName, setNewFolderName] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { projectsList, selectedProject } = useProjects();
  const projectId = localStorage.getItem("activeProjectId");
  const dropdownRef = useRef(null);
  const [editedFolderName, setEditedFolderName] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [fileCount, setFileCount] = useState(0);
  const navigate = useNavigate();

  // Callback function to receive the number of files
  const handleFileCountChange = (count) => {
    console.log("Files.jsx received new file count:", count);
    // Update both state variables to keep them in sync
    if (count !== undefined) {
      setFileCount(count);
      setUploadedFilesCount(count);
    }
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Removing reference to undefined setFolderDropdown
        // setFolderDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeDropdown = () => {
    // Removing reference to undefined setShowDropdown
    // setShowDropdown(false);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      closeDropdown();
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    fetchFolders();
  }, [projectId]);

  // Add a new useEffect to fetch file count on component mount
  useEffect(() => {
    fetchInitialFileCount();
  }, []);

  // Function to fetch initial file count
  const fetchInitialFileCount = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/user/documents/${projectId}?page=1&page_size=5`,
      );
      const data = response.data;
      if (data && data.total) {
        setFileCount(data.total);
        console.log("Initial file count:", data.total);
      }
    } catch (error) {
      console.error("Error fetching initial file count:", error);
    }
  };

  // Fetch folders from the server
  const fetchFolders = async () => {
    try {
      setIsLoading(true);
      // Get project ID from context instead of localStorage directly
      const activeProjectId = localStorage.getItem("activeProjectId");

      const response = await axiosInstance.get(
        `${API_URL}/v1/folders/${activeProjectId}`,
      );

      console.log("API Response:", response.data);

      // Check if the response has the expected structure
      if (response.data && Array.isArray(response.data.folders)) {
        setFolders(response.data.folders);
        setFilteredFolders(response.data.folders);
      } else {
        console.error("Unexpected API response structure:", response.data);
        setFolders([]);
        setFilteredFolders([]);
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      toast.error("Failed to load folders");
      setFolders([]);
      setFilteredFolders([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      alert("Folder name cannot be empty.");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `${API_URL}/v1/folders/${projectId}/create`,
        { name: newFolderName, parent_folder_id: null },
      );
      setFolders([...folders, response.data]);
      // Removing reference to undefined setShowCreateModal
      // setShowCreateModal(false);
      setNewFolderName("");
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      // Send DELETE request to the API
      await axiosInstance.delete(`${API_URL}/v1/folders/${folderId}`);
      // Update the folders state by filtering out the deleted folder
      setFolders(folders.filter((folder) => folder.id !== folderId));
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
    // Hide the context menu if it's open
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleOpenFolder = (folder) => {
    if (!folder) {
      console.error("Folder data is undefined:", folder);
      return;
    }
    // Removing references to undefined setCurrentFolder and setShowFolderContent
    // setCurrentFolder(folder);
    // setShowFolderContent(true);
  };

  // Close folder and go back to folder list
  const handleCloseFolder = () => {
    // Removing references to undefined setCurrentFolder and setShowFolderContent
    // setCurrentFolder(null);
    // setShowFolderContent(false);
  };

  // State variables
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFolders, setFilteredFolders] = useState([]);

  // Modal states
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  // Dropdown menu states
  const [folderMenuAnchor, setFolderMenuAnchor] = useState(null);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [editFolderName, setEditFolderName] = useState("");
  const [isEditingFolder, setIsEditingFolder] = useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);

  // Update filtered folders when search query changes
  useEffect(() => {
    if (searchQuery) {
      const filtered = folders.filter((folder) =>
        folder.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredFolders(filtered);
    } else {
      setFilteredFolders(folders);
    }
  }, [searchQuery, folders]);

  // Create new folder
  const handleCreateFolderAPI = async () => {
    if (!newFolderName.trim()) {
      toast.error("Folder name cannot be empty");
      return;
    }

    setIsCreatingFolder(true);
    try {
      const response = await axiosInstance.post(
        `${API_URL}/v1/folders/${projectId}/create`,
        {
          name: newFolderName,
          parent_folder_id: null,
        },
      );

      // Add the newly created folder to the folders state
      const newFolder = response.data;
      setFolders((prev) => [...prev, newFolder]);
      setFilteredFolders((prev) => [...prev, newFolder]);

      setNewFolderName("");
      setShowCreateFolderModal(false);
      toast.success("Folder created successfully");

      // Optional: Refresh folders to ensure consistency with server
      fetchFolders();
    } catch (error) {
      toast.error("Failed to create folder");
      console.error("Error creating folder:", error);
    } finally {
      setIsCreatingFolder(false);
    }
  };

  // Handle folder menu open
  const handleFolderMenuOpen = (event, folderId) => {
    event.stopPropagation();
    setFolderMenuAnchor(event.currentTarget);
    setSelectedFolderId(folderId);
  };

  // Handle folder menu close
  const handleFolderMenuClose = () => {
    setFolderMenuAnchor(null);
    setSelectedFolderId(null);
  };

  // Handle folder click to navigate
  const handleFolderClick = (folderId) => {
    navigate(`/creator/folder/${folderId}`);
  };

  // Handle edit folder
  const handleEditFolderAPI = () => {
    handleFolderMenuClose();
    const folder = folders.find((f) => f.id === selectedFolderId);
    if (folder) {
      setEditFolderName(folder.name);
      setShowEditFolderModal(true);
    }
  };

  // Save edited folder name
  const saveEditedFolderAPI = async () => {
    if (!editFolderName.trim()) {
      toast.error("Folder name cannot be empty");
      return;
    }

    setIsEditingFolder(true);
    try {
      // Since there's no dedicated endpoint to update folder, we might need to
      // use a workaround or request backend team to add the endpoint
      // For now, we'll just update the state locally

      // Update folders state
      setFolders(
        folders.map((folder) =>
          folder.id === selectedFolderId
            ? { ...folder, name: editFolderName }
            : folder,
        ),
      );

      setShowEditFolderModal(false);
      toast.success("Folder renamed successfully");
    } catch (error) {
      toast.error("Failed to rename folder");
    } finally {
      setIsEditingFolder(false);
    }
  };

  // Handle delete folder
  const handleDeleteFolderAPI = () => {
    handleFolderMenuClose();
    setShowDeleteFolderModal(true);
  };

  // Confirm delete folder
  const confirmDeleteFolderAPI = async () => {
    setIsDeletingFolder(true);
    try {
      await axiosInstance.delete(`${API_URL}/v1/folders/${selectedFolderId}`);

      // Update both folders and filteredFolders states
      const updatedFolders = folders.filter(
        (folder) => folder.id !== selectedFolderId,
      );
      setFolders(updatedFolders);
      setFilteredFolders(
        filteredFolders.filter((folder) => folder.id !== selectedFolderId),
      );

      setShowDeleteFolderModal(false);
      toast.success("Folder deleted successfully");
    } catch (error) {
      console.error("Error deleting folder:", error);
      toast.error("Failed to delete folder");
    } finally {
      setIsDeletingFolder(false);
    }
  };

  return (
    <Box className="w-full px-6 py-4">
      {/* Header */}
      <Box className="flex justify-between items-center mb-6">
        <Typography variant="h5" className="text-white font-bold">
          Files
        </Typography>
        <Box className="flex items-center gap-4">
          {/* Search input */}
          <Box className="relative">
            <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search folders"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 bg-[#1D1E21] border border-gray-700 rounded-md text-white w-64 custom-scrollbar"
            />
          </Box>

          {/* View mode buttons */}
          <Box className="flex bg-[#1D1E21] rounded-md overflow-hidden">
            <IconButton
              onClick={() => setViewMode("grid")}
              className={`text-white ${viewMode === "grid" ? "bg-[#2D2E32]" : ""}`}
            >
              <FaTh />
            </IconButton>
            <IconButton
              onClick={() => setViewMode("list")}
              className={`text-white ${viewMode === "list" ? "bg-[#2D2E32]" : ""}`}
            >
              <FaBars />
            </IconButton>
          </Box>

          {/* New folder button */}
          <Button
            variant="contained"
            startIcon={<FaPlus />}
            onClick={() => setShowCreateFolderModal(true)}
            className="bg-[#6E45FE] hover:bg-[#5a35e0] text-white font-medium py-2 px-4 rounded-md"
          >
            New Folder
          </Button>

          {/* Upload file button */}
          <Button
            variant="contained"
            startIcon={<FaPlus />}
            onClick={() => setShowUploadModal(true)}
            className="bg-[#6E45FE] hover:bg-[#5a35e0] text-white font-medium py-2 px-4 rounded-md"
          >
            Upload File
          </Button>
        </Box>
      </Box>

      {/* Folders Section */}
      <Box className="mb-8">
        <Typography variant="h6" className="text-white mb-4">
          Folders
        </Typography>

        {isLoading ? (
          <Box className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </Box>
        ) : filteredFolders.length === 0 ? (
          <Box className="text-gray-400 text-center py-8 bg-[#1D1E21] rounded-lg">
            {searchQuery
              ? "No matching folders found"
              : "No folders created yet"}
          </Box>
        ) : viewMode === "grid" ? (
          // Grid view for folders
          <Box className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 custom-scrollbar">
            {filteredFolders.map((folder) => (
              <Box
                key={folder.id}
                onClick={() => handleFolderClick(folder.id)}
                className="bg-[#1D1E21] hover:bg-[#2D2E32] rounded-lg p-4 border border-gray-700 cursor-pointer relative"
              >
                <FaFolder className="text-gray-400 text-5xl mb-3" />
                <Typography className="text-white truncate">
                  {folder.name}
                </Typography>

                {/* Folder menu button */}
                <IconButton
                  className="absolute top-2 right-2 text-gray-400 hover:text-white"
                  onClick={(e) => handleFolderMenuOpen(e, folder.id)}
                >
                  <FaEllipsisV />
                </IconButton>
              </Box>
            ))}
          </Box>
        ) : (
          // List view for folders
          <Box className="space-y-2 custom-scrollbar">
            {filteredFolders.map((folder) => (
              <Box
                key={folder.id}
                onClick={() => handleFolderClick(folder.id)}
                className="bg-[#1D1E21] hover:bg-[#2D2E32] rounded-lg p-3 border border-gray-700 cursor-pointer flex justify-between items-center"
              >
                <Box className="flex items-center gap-3">
                  <FaFolder className="text-gray-400 text-2xl" />
                  <Typography className="text-white">{folder.name}</Typography>
                </Box>

                {/* Folder menu button */}
                <IconButton
                  className="text-gray-400 hover:text-white"
                  onClick={(e) => handleFolderMenuOpen(e, folder.id)}
                >
                  <FaEllipsisV />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Files Section */}
      <Box>
        <Typography variant="h6" className="text-white mb-4">
          Files ({fileCount})
        </Typography>

        <CreatorUploadFiles
          viewMode={viewMode}
          onFileCountChange={handleFileCountChange}
          modal={showUploadModal}
          setModal={setShowUploadModal}
          hidden={false}
        />
      </Box>

      {/* Folder menu */}
      <Menu
        anchorEl={folderMenuAnchor}
        open={Boolean(folderMenuAnchor)}
        onClose={handleFolderMenuClose}
        PaperProps={{
          style: {
            backgroundColor: "#1D1E21",
            border: "1px solid #444",
            color: "white",
          },
        }}
      >
        <MenuItem onClick={handleEditFolderAPI} className="hover:bg-[#2D2E32]">
          Rename
        </MenuItem>
        <MenuItem
          onClick={handleDeleteFolderAPI}
          className="hover:bg-[#2D2E32] text-red-400"
        >
          Delete
        </MenuItem>
      </Menu>

      {/* Create Folder Modal */}
      {showCreateFolderModal && (
        <PopupModal
          closeModal={() => setShowCreateFolderModal(false)}
          className="bg-[#27282B]"
        >
          <Box className="w-[360px] p-6">
            <Typography className="text-white text-lg font-medium mb-4">
              Create New Folder
            </Typography>
            <TextField
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Folder name"
              variant="outlined"
              className="mb-4"
              InputProps={{
                style: {
                  color: "white",
                  backgroundColor: "#1D1E21",
                  borderColor: "#444",
                },
              }}
            />
            <Box className="flex justify-end space-x-3">
              <Button
                onClick={() => setShowCreateFolderModal(false)}
                className="bg-[#3D3E42] text-white hover:bg-[#4D4E52]"
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateFolderAPI}
                disabled={isCreatingFolder}
                className="bg-[#6E45FE] text-white hover:bg-[#5a35e0] disabled:opacity-50"
              >
                {isCreatingFolder ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-5 w-5"
                  />
                ) : (
                  "Create"
                )}
              </Button>
            </Box>
          </Box>
        </PopupModal>
      )}

      {/* Edit Folder Modal */}
      {showEditFolderModal && (
        <PopupModal
          closeModal={() => setShowEditFolderModal(false)}
          className="bg-[#27282B]"
        >
          <Box className="w-[360px] p-6">
            <Typography className="text-white text-lg font-medium mb-4">
              Rename Folder
            </Typography>
            <TextField
              fullWidth
              value={editFolderName}
              onChange={(e) => setEditFolderName(e.target.value)}
              placeholder="Folder name"
              variant="outlined"
              className="mb-4"
              InputProps={{
                style: {
                  color: "white",
                  backgroundColor: "#1D1E21",
                  borderColor: "#444",
                },
              }}
            />
            <Box className="flex justify-end space-x-3">
              <Button
                onClick={() => setShowEditFolderModal(false)}
                className="bg-[#3D3E42] text-white hover:bg-[#4D4E52]"
              >
                Cancel
              </Button>
              <Button
                onClick={saveEditedFolderAPI}
                disabled={isEditingFolder}
                className="bg-[#6E45FE] text-white hover:bg-[#5a35e0] disabled:opacity-50"
              >
                {isEditingFolder ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-5 w-5"
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Box>
        </PopupModal>
      )}

      {/* Delete Folder Modal */}
      {showDeleteFolderModal && (
        <PopupModal
          closeModal={() => setShowDeleteFolderModal(false)}
          className="bg-[#27282B]"
        >
          <Box className="flex flex-col justify-center items-center h-[200px] w-[360px] p-4">
            <Typography className="text-white text-lg font-medium mb-2">
              Delete Folder
            </Typography>
            <Typography className="text-white text-sm mb-6 text-center">
              Are you sure you want to delete this folder and all its contents?
              This action cannot be undone.
            </Typography>
            <Box className="flex justify-center space-x-4 w-full">
              <Button
                onClick={() => setShowDeleteFolderModal(false)}
                className="bg-[#3D3E42] text-white hover:bg-[#4D4E52]"
              >
                Cancel
              </Button>
              <Button
                onClick={confirmDeleteFolderAPI}
                disabled={isDeletingFolder}
                className="bg-[#6E45FE] text-white hover:bg-[#5a35e0] disabled:opacity-50"
              >
                {isDeletingFolder ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-5 w-5"
                  />
                ) : (
                  "Delete"
                )}
              </Button>
            </Box>
          </Box>
        </PopupModal>
      )}
    </Box>
  );
};

export default React.memo(Files);
