import React, { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../components/axiosInstance.js";
const InviteModal = ({ isOpen, onClose, organization_id }) => {
  const [emails, setEmails] = useState("");
  const [role, setRole] = useState("MEMBER");
  const [inviteToProject, setInviteToProject] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleInvite = async () => {
    try {
      const response = await axiosInstance.post(`/v1/invites/send`, {
        email: emails,
        organisation_id: organization_id,
        role: role,
      }); // Replace with your API endpoint
      console.log(response);
      toast.success("Invitation Send");
      // setinvite(response.data);
    } catch (err) {
      if (
        err.response.data.detail ===
        "Failed to send Invite: user already exists in Organisation"
      ) {
        toast.error("User already exists in organization");
      } else {
        toast.error("Error sending invite:", err);
      }
    } finally {
      setLoading(false);
    }
    onClose(); // Close the modal after inviting
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#1D1E20] text-white rounded-lg p-6 w-full max-w-lg relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white text-xl"
        >
          &times;
        </button>

        {/* Modal Title */}
        <h2 className="text-lg font-semibold mb-2">Invite members</h2>
        <p className="text-sm text-gray-400 mb-6">
          An invite link will be sent to the email to activate and set up the
          account
        </p>

        {/* Email Address and Role Input */}
        <div className="flex items-center gap-4 mb-4">
          <div className="w-1/2">
            <input
              type="text"
              placeholder="Email address"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              className="w-full bg-black border border-gray-500 rounded-lg px-3 py-2 text-sm text-white placeholder-gray-500 "
            />
          </div>
          <div className="w-1/2">
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="w-full bg-black border border-gray-500 rounded-lg px-3 py-2 text-sm text-white "
            >
              <option value="" disabled>
                Select
              </option>
              <option value="MEMBER">Member</option>
              <option value="OWNER">Owner</option>
            </select>
          </div>
        </div>

        {/* Add More Members */}

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="bg-[#FFFFFF] px-10 py-2 rounded-lg text-[16px] font-medium font-inter  text-black"
          >
            Cancel
          </button>
          <button
            onClick={handleInvite}
            className="bg-[#1677FF] px-10 py-2 rounded-lg text-[16px] font-medium font-inter"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};
export default InviteModal;
