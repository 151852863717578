import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  Modal,
  Select,
  MenuItem,
  FormControl,
  Avatar,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import axiosInstance from "../../components/axiosInstance.js";
import { toast } from "react-toastify";
import { getStoredValue } from "../../services/logics.js";

// Styled components
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#1D1E21",
  border: "1px solid #555",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ProjectMembers = () => {
  // State variables
  const [projectUsers, setProjectUsers] = useState([]);
  const [searchTermMembers, setSearchTermMembers] = useState("");
  const [searchTermOrgUsers, setSearchTermOrgUsers] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [newUserRole, setNewUserRole] = useState("MEMBER");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [filteredOrgUsers, setFilteredOrgUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [showUserList, setShowUserList] = useState(false);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [openSortMenu, setOpenSortMenu] = useState(false);

  // Additional state for improvements
  const [loading, setLoading] = useState({
    projectUsers: false,
    orgUsers: false,
    addUser: false,
    removeUser: false,
    updateRole: false,
  });
  const [error, setError] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    action: null,
    data: null,
  });
  const [filterOptions, setFilterOptions] = useState({
    showOwners: true,
    showMembers: true,
  });
  const [sortOrder, setSortOrder] = useState("nameAsc"); // nameAsc, nameDesc, roleAsc, roleDesc

  // Get stored values
  const project_id = getStoredValue("activeProjectId");
  const user_id = getStoredValue("user_id");
  const organization_id = getStoredValue("selectedOrg");

  // Fetch data on component mount
  useEffect(() => {
    if (project_id && organization_id) {
      fetchProjectUsers();
      fetchOrganizationUsers();
    } else {
      setError("Missing project or organization information");
    }

    // Listen for organization or project changes
    window.addEventListener("organizationChanged", handleOrgOrProjectChange);
    window.addEventListener("projectChanged", handleOrgOrProjectChange);

    return () => {
      window.removeEventListener(
        "organizationChanged",
        handleOrgOrProjectChange,
      );
      window.removeEventListener("projectChanged", handleOrgOrProjectChange);
    };
  }, []);

  // Re-fetch data when organization or project changes
  const handleOrgOrProjectChange = () => {
    fetchProjectUsers();
    fetchOrganizationUsers();
  };

  // Re-fetch org users when modal opens
  useEffect(() => {
    if (openAddModal) {
      fetchOrganizationUsers();
    }
  }, [openAddModal]);

  // Apply filters and sorting to users
  useEffect(() => {
    if (projectUsers.length > 0) {
      let filtered = [...projectUsers];

      // Apply role filters
      filtered = filtered.filter(
        (user) =>
          (filterOptions.showOwners && user.role === "OWNER") ||
          (filterOptions.showMembers && user.role === "MEMBER"),
      );

      // Apply search filter
      if (searchTermMembers) {
        filtered = filtered.filter(
          (user) =>
            user.full_name
              .toLowerCase()
              .includes(searchTermMembers.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTermMembers.toLowerCase()),
        );
      }

      // Apply sorting
      filtered.sort((a, b) => {
        switch (sortOrder) {
          case "nameAsc":
            return a.full_name.localeCompare(b.full_name);
          case "nameDesc":
            return b.full_name.localeCompare(a.full_name);
          case "roleAsc":
            return a.role.localeCompare(b.role);
          case "roleDesc":
            return b.role.localeCompare(a.role);
          default:
            return a.full_name.localeCompare(b.full_name);
        }
      });

      setFilteredUsers(filtered);
    }
  }, [projectUsers, searchTermMembers, filterOptions, sortOrder]);

  // Fetch project users
  const fetchProjectUsers = async () => {
    setLoading((prev) => ({ ...prev, projectUsers: true }));
    setError(null);

    try {
      const response = await axiosInstance.get(
        `/v1/projects/${project_id}/users`,
      );
      setProjectUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("Error fetching project members:", error);
      setError("Failed to load project members. Please try again.");
      toast.error("Error fetching project members");
    } finally {
      setLoading((prev) => ({ ...prev, projectUsers: false }));
    }
  };

  // Fetch organization users
  const fetchOrganizationUsers = async () => {
    setLoading((prev) => ({ ...prev, orgUsers: true }));

    try {
      const response = await axiosInstance.get(
        `/v1/organizations/${organization_id}/users`,
      );

      // Filter out users already in the project to avoid duplicates
      const projectUserIds = projectUsers.map((user) => user.userorg_id);
      const filteredOrgUsers = response.data.filter(
        (user) => !projectUserIds.includes(user.id),
      );

      setOrganizationUsers(filteredOrgUsers);

      // Find current user based on user_id
      const user = response.data.find((member) => member.user_id === user_id);
      setCurrentUser(user);
    } catch (error) {
      console.error("Error fetching organization users:", error);
      toast.error("Error fetching organization users");
    } finally {
      setLoading((prev) => ({ ...prev, orgUsers: false }));
    }
  };

  // Search handlers
  const handleSearchChange = (event) => {
    setSearchTermMembers(event.target.value);
  };

  const handleSearchOrgUsers = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTermOrgUsers(value);
    setShowUserList(true);
    setFilteredOrgUsers(
      organizationUsers.filter(
        (user) =>
          user.full_name.toLowerCase().includes(value) ||
          user.email.toLowerCase().includes(value),
      ),
    );
  };

  // Handle user role change
  const handleRoleChange = async (userorgId, newRole) => {
    setLoading((prev) => ({ ...prev, updateRole: true }));

    try {
      await axiosInstance.patch(`/v1/projects/${project_id}/update-role`, {
        target_userorganization_id: userorgId,
        owner_userorgid: currentUser.id,
        role: newRole,
      });

      setProjectUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.userorg_id === userorgId ? { ...user, role: newRole } : user,
        ),
      );

      toast.success("Role updated successfully!");
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error("Error updating role.");
    } finally {
      setLoading((prev) => ({ ...prev, updateRole: false }));
    }
  };

  // Open confirmation dialog for removing a user
  const confirmRemoveUser = (user) => {
    setConfirmationDialog({
      open: true,
      title: "Remove User",
      message: `Are you sure you want to remove ${user.full_name} from this project?`,
      action: () => removeUser(user.id),
      data: user,
    });
  };

  // Remove user from project
  const removeUser = async (userproject_id) => {
    setLoading((prev) => ({ ...prev, removeUser: true }));
    setConfirmationDialog((prev) => ({ ...prev, open: false }));

    try {
      await axiosInstance.post(`/v1/projects/${project_id}/remove-user`, {
        target_userproject_id: userproject_id,
        owner_userorgid: currentUser.id,
      });

      setProjectUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== userproject_id),
      );
      toast.success("User removed successfully.");
    } catch (error) {
      console.error("Error removing user:", error);
      toast.error("Failed to remove user.");
    } finally {
      setLoading((prev) => ({ ...prev, removeUser: false }));
    }
  };

  // Add user to project
  const handleAddUser = async () => {
    if (!selectedUser) {
      toast.error("Please select a user to add.");
      return;
    }

    setLoading((prev) => ({ ...prev, addUser: true }));

    try {
      await axiosInstance.post(`/v1/projects/${project_id}/add-user`, {
        userorganization_id: currentUser.id,
        addeduserorgid: selectedUser.id,
        role: newUserRole,
      });

      toast.success("User added successfully!");
      fetchProjectUsers();
      setOpenAddModal(false);
      setSelectedUser(null);
      setSearchTermOrgUsers("");
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Failed to add user. Please try again.");
    } finally {
      setLoading((prev) => ({ ...prev, addUser: false }));
    }
  };

  // Select user from dropdown
  const selectUser = (user) => {
    setSelectedUser(user);
    setSearchTermOrgUsers(user.email);
    setShowUserList(false);
  };

  // Toggle filter options
  const handleFilterChange = (event) => {
    setFilterOptions({
      ...filterOptions,
      [event.target.name]: event.target.checked,
    });
  };

  // Handle sorting
  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  // Get role label with color
  const getRoleChip = (role) => {
    return (
      <Chip
        label={role}
        size="small"
        sx={{
          bgcolor: role === "OWNER" ? "#8256D0" : "#1677FF",
          color: "white",
          fontSize: "0.75rem",
        }}
      />
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4" color="white" fontWeight="600">
          Project Members
        </Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          {/* Filter Button */}
          <Tooltip title="Filter by role">
            <IconButton
              aria-label="filter"
              sx={{ color: "white" }}
              onClick={() => setOpenFilterMenu((prevState) => !prevState)}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>

          {/* Sort Button */}
          <Tooltip title="Sort members">
            <IconButton
              aria-label="sort"
              sx={{ color: "white" }}
              onClick={() => setOpenSortMenu((prevState) => !prevState)}
            >
              <SortIcon />
            </IconButton>
          </Tooltip>

          {/* Add User Button */}
          <Button
            variant="contained"
            startIcon={<PersonAddAltIcon />}
            onClick={() => setOpenAddModal(true)}
            sx={{ backgroundColor: "#1677FF" }}
            disabled={loading.orgUsers}
          >
            Add User
          </Button>
        </Box>
      </Box>

      {/* Filter Options */}
      {openFilterMenu && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            bgcolor: "#2C2C2C",
            borderRadius: 1,
            border: "1px solid rgba(81, 81, 81, 0.5)",
          }}
        >
          <Typography variant="subtitle2" color="white" mb={1}>
            Filter by Role
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.showOwners}
                  onChange={handleFilterChange}
                  name="showOwners"
                  sx={{
                    color: "#8256D0",
                    "&.Mui-checked": {
                      color: "#8256D0",
                    },
                  }}
                />
              }
              label={<Typography color="white">Owners</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.showMembers}
                  onChange={handleFilterChange}
                  name="showMembers"
                  sx={{
                    color: "#1677FF",
                    "&.Mui-checked": {
                      color: "#1677FF",
                    },
                  }}
                />
              }
              label={<Typography color="white">Members</Typography>}
            />
          </FormGroup>
        </Box>
      )}

      {/* Sort Options */}
      {openSortMenu && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            bgcolor: "#2C2C2C",
            borderRadius: 1,
            border: "1px solid rgba(81, 81, 81, 0.5)",
          }}
        >
          <Typography variant="subtitle2" color="white" mb={1}>
            Sort Members
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleSortChange("nameAsc")}
              sx={{
                color: sortOrder === "nameAsc" ? "white" : "gray",
                borderColor: sortOrder === "nameAsc" ? "white" : "gray",
              }}
            >
              Name A-Z
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleSortChange("nameDesc")}
              sx={{
                color: sortOrder === "nameDesc" ? "white" : "gray",
                borderColor: sortOrder === "nameDesc" ? "white" : "gray",
              }}
            >
              Name Z-A
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleSortChange("roleAsc")}
              sx={{
                color: sortOrder === "roleAsc" ? "white" : "gray",
                borderColor: sortOrder === "roleAsc" ? "white" : "gray",
              }}
            >
              Role (Owner first)
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleSortChange("roleDesc")}
              sx={{
                color: sortOrder === "roleDesc" ? "white" : "gray",
                borderColor: sortOrder === "roleDesc" ? "white" : "gray",
              }}
            >
              Role (Member first)
            </Button>
          </Box>
        </Box>
      )}

      {/* Error Message */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Members Table */}
      <TableContainer component={Paper} sx={{ backgroundColor: "transparent" }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ backgroundColor: "#2C2C2C" }}>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Name</TableCell>
              <TableCell sx={{ color: "white" }}>Email</TableCell>
              <TableCell sx={{ color: "white" }}>
                Role
                <Tooltip title="Project roles determine what actions members can perform">
                  <IconButton size="small" sx={{ color: "white", ml: 1 }}>
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ color: "white" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading.projectUsers ? (
              <TableRow>
                <TableCell colSpan={4} align="center" sx={{ py: 3 }}>
                  <CircularProgress size={24} sx={{ color: "#1677FF" }} />
                  <Typography color="white" sx={{ mt: 1 }}>
                    Loading members...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : filteredUsers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center" sx={{ py: 3 }}>
                  <Typography color="white">
                    No members found.{" "}
                    {searchTermMembers && "Try a different search term."}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredUsers.map((member) => (
                <TableRow
                  key={member.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    borderBottom: "1px solid rgba(81, 81, 81, 0.3)",
                    "&:hover": { backgroundColor: "rgba(81, 81, 81, 0.1)" },
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ color: "white" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Avatar
                        sx={{
                          bgcolor: member.role === "OWNER" ? "#8256D0" : "gray",
                          width: 32,
                          height: 32,
                        }}
                      >
                        {member.full_name.charAt(0).toUpperCase()}
                      </Avatar>
                      <Typography>{member.full_name}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
                    {member.email}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {getRoleChip(member.role)}
                      {member.userorg_id !== currentUser?.id && (
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{ ml: 2, minWidth: 120 }}
                        >
                          <Select
                            value={member.role}
                            onChange={(e) =>
                              handleRoleChange(
                                member.userorg_id,
                                e.target.value,
                              )
                            }
                            sx={{
                              color: "white",
                              backgroundColor: "#2C2C2C",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(81, 81, 81, 0.5)",
                              },
                            }}
                            disabled={loading.updateRole}
                          >
                            <MenuItem value="OWNER">Owner</MenuItem>
                            <MenuItem value="MEMBER">Member</MenuItem>
                          </Select>
                          {loading.updateRole && (
                            <CircularProgress
                              size={16}
                              sx={{
                                color: "#1677FF",
                                position: "absolute",
                                right: 28,
                                top: "50%",
                                marginTop: "-8px",
                              }}
                            />
                          )}
                        </FormControl>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {member.userorg_id === currentUser?.id ? (
                      <Tooltip title="You cannot remove yourself from the project">
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: "#A0A0A0",
                            color: "black",
                            fontWeight: "bold",
                            "&:hover": { bgcolor: "#909090" },
                            border: "1px solid #707070",
                          }}
                          disabled={false}
                        >
                          Leave
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#38ddba",
                          color: "black",
                          "&:hover": { bgcolor: "#2bc5a4" },
                        }}
                        onClick={() => confirmRemoveUser(member)}
                        disabled={loading.removeUser}
                      >
                        {loading.removeUser ? (
                          <CircularProgress size={20} sx={{ color: "black" }} />
                        ) : (
                          "Remove"
                        )}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add User Modal */}
      <Modal
        open={openAddModal}
        onClose={() => !loading.addUser && setOpenAddModal(false)}
        aria-labelledby="add-user-modal"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" color="white">
              Add Members
            </Typography>
            <IconButton
              onClick={() => !loading.addUser && setOpenAddModal(false)}
              sx={{ color: "white" }}
              disabled={loading.addUser}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Search Organization Users */}
          <Box sx={{ position: "relative", mb: 3 }}>
            <StyledInputBase
              placeholder="Search organization users..."
              value={searchTermOrgUsers}
              onChange={handleSearchOrgUsers}
              fullWidth
              sx={{
                border: "1px solid #555",
                borderRadius: 1,
                p: 1,
                backgroundColor: "#2C2C2C",
              }}
              disabled={loading.orgUsers || loading.addUser}
            />

            {loading.orgUsers && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress size={24} sx={{ color: "#1677FF" }} />
              </Box>
            )}

            {/* Dropdown for organization users */}
            {showUserList &&
              filteredOrgUsers.length > 0 &&
              !loading.orgUsers && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    right: 0,
                    maxHeight: 200,
                    overflow: "auto",
                    zIndex: 1,
                    backgroundColor: "#2C2C2C",
                    border: "1px solid #555",
                    borderRadius: 1,
                    mt: 0.5,
                  }}
                >
                  {filteredOrgUsers.map((user) => (
                    <Box
                      key={user.id}
                      sx={{
                        p: 1.5,
                        "&:hover": { backgroundColor: "#3E3E3E" },
                        cursor: "pointer",
                      }}
                      onClick={() => selectUser(user)}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Avatar sx={{ width: 24, height: 24, bgcolor: "gray" }}>
                          {user.full_name.charAt(0).toUpperCase()}
                        </Avatar>
                        <Typography color="white">
                          {user.full_name}{" "}
                          <Typography
                            component="span"
                            color="gray"
                            fontSize="0.8rem"
                          >
                            ({user.email})
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

            {showUserList &&
              filteredOrgUsers.length === 0 &&
              searchTermOrgUsers &&
              !loading.orgUsers && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    backgroundColor: "#2C2C2C",
                    border: "1px solid #555",
                    borderRadius: 1,
                    mt: 0.5,
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography color="white">No matching users found</Typography>
                </Box>
              )}
          </Box>

          {/* Selected User */}
          {selectedUser && (
            <Box
              sx={{
                mb: 3,
                p: 2,
                backgroundColor: "#2A2A2A",
                borderRadius: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar sx={{ bgcolor: "gray" }}>
                  {selectedUser.full_name.charAt(0).toUpperCase()}
                </Avatar>
                <Box>
                  <Typography color="white">
                    {selectedUser.full_name}
                  </Typography>
                  <Typography color="gray" fontSize="0.8rem">
                    {selectedUser.email}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                size="small"
                onClick={() => {
                  setSelectedUser(null);
                  setSearchTermOrgUsers("");
                }}
                sx={{ color: "white" }}
                disabled={loading.addUser}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          )}

          {/* Role Selection */}
          <Box sx={{ mb: 3 }}>
            <Typography color="white" mb={1}>
              Select Role
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                value={newUserRole}
                onChange={(e) => setNewUserRole(e.target.value)}
                sx={{
                  color: "white",
                  backgroundColor: "#2C2C2C",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(81, 81, 81, 0.5)",
                  },
                }}
                disabled={loading.addUser}
              >
                <MenuItem value="MEMBER">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Member</Typography>
                    <Typography variant="caption" color="gray">
                      Can view and modify project content
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="OWNER">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>Owner</Typography>
                    <Typography variant="caption" color="gray">
                      Can manage project settings and members
                    </Typography>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Action Buttons */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              variant="contained"
              sx={{ bgcolor: "#555", "&:hover": { bgcolor: "#444" } }}
              onClick={() => setOpenAddModal(false)}
              disabled={loading.addUser}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ bgcolor: "#1677FF", "&:hover": { bgcolor: "#0e5dc4" } }}
              onClick={handleAddUser}
              disabled={!selectedUser || loading.addUser}
            >
              {loading.addUser ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Add User"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationDialog.open}
        onClose={() =>
          setConfirmationDialog({ ...confirmationDialog, open: false })
        }
        PaperProps={{
          sx: {
            backgroundColor: "#1D1E21",
            color: "white",
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle>{confirmationDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText color="white">
            {confirmationDialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setConfirmationDialog({ ...confirmationDialog, open: false })
            }
            sx={{ color: "gray" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              confirmationDialog.action && confirmationDialog.action()
            }
            color="error"
            variant="contained"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectMembers;
