import React, { useState, useEffect } from "react";
import Sidebar from "../Organization/Sidebar"; // ✅ Include Sidebar
import axiosInstance from "../components/axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getStoredValue } from "../services/logics.js";

const Billing = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const razorpay_id = process.env.RAZORPAY_ID;
  const organisation_id = getStoredValue("selectedOrg");
  const [currentOrg, setCurrentOrg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [selectedOrg, setSelectedOrg] = useState("");

  const [subscription, setSubscription] = useState({
    id: "",
    subscription_model: "",
    updated_at: "",
    is_active: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [payments, setPayments] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const handleOrgChange = (orgName) => {
    const orgObject = organizations.find((org) => org.id === orgName);
    setSelectedOrg(orgName);
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axiosInstance.get("/v1/organizations/user");
        setOrganizations(response.data);
        setCurrentOrg(response.data[0]);
      } catch (err) {
        toast.error("Error fetching organizations");
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  // Fetch Subscription Data
  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/subscriptions/${organisation_id}`,
        );
        if (response.status !== 200) {
          throw new Error("Failed to fetch subscription data");
        }
        setSubscription(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSubscription();
  }, [organisation_id]);

  // Fetch Payment History
  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axiosInstance.get(
          `/v1/payments/${organisation_id}`,
        );
        if (response.status !== 200) {
          throw new Error("Failed to fetch payments");
        }
        setPayments(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPayments();
  }, [organisation_id]);

  return (
    <div className="flex h-screen bg-[#070707]">
      {/* Sidebar */}
      <Sidebar
        handleOrgChange={handleOrgChange}
        organizations={organizations}
      />

      {/* Main Content */}
      <div className="flex-1 px-10 py-8 text-white">
        <svg
          width="470"
          height="428"
          viewBox="0 0 470 428"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 left-0 w-[350px] h-[350px] opacity-50 pointer-events-none z-[1]"
        >
          <g id="Ellipse 1" filter="url(#filter0_f)">
            <circle cx="92.5" cy="51.2812" r="113.5" fill="#1677FF" />
          </g>
          <defs>
            <filter
              id="filter0_f"
              x="-285"
              y="-326.6"
              width="755"
              height="755"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="132"
                result="effect1_foregroundBlur"
              />
            </filter>
          </defs>
        </svg>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="w-full text-left mb-8">
              <div
                className="text-white text-[32px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-semibold font-['Poppins'] leading-[1.2] sm:leading-[1.3] md:leading-[1.4] lg:leading-[80px] tracking-[-1.2px]"
                style={{ maxWidth: "100%" }}
              >
                <div style={{ width: "100%", display: "block" }}>
                  Plan & Billing
                </div>
              </div>
              <p className="text-xl text-gray-400 mt-4">
                Update your payment & explore more plans according to your
                needs.
              </p>
            </div>

            {/* Billing Section */}
            <div className="border border-gray-800 rounded-md p-4 mt-4 bg-[#121214] w-full">
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center gap-2">
                    <h2 className="text-white font-bold text-2xl">
                      Current Plan
                    </h2>
                    <span className="bg-gray-700 text-xs px-2 py-0.5 rounded">
                      Monthly
                    </span>
                  </div>
                  <p className="text-gray-400 text-lg mt-1">
                    {subscription?.subscription_model}
                  </p>
                </div>
                {/* <div>
                                    <h3 className="text-white font-bold text-2xl">Billing Period</h3>
                                    <p className="text-sm text-gray-400">Monthly (Next payment 28 February 2025)</p>
                                </div> */}
              </div>
              <button
                className="bg-[#1677FF] hover:bg-[#1256CC] text-white px-4 py-2 rounded text-sm mt-4"
                onClick={() => navigate("/organizations/plans")}
              >
                Explore Plans
              </button>
            </div>
            <h2 className="text-lg font-semibold mt-2 text-white font-inter">
              Billing History
            </h2>

            {/* Billing History Table */}
            <div className="mt-2 overflow-x-auto">
              <h2 className="text-lg font-semibold mt-2 text-white font-inter">
                Coming Soon....
              </h2>

              {/* <table className="w-full border-collapse">
                                <thead>
                                    <tr className="bg-[#29292F] text-[#EAEAEA] text-sm font-semibold">
                                        <th className="py-3 px-4 text-left w-1/5 border-r border-gray-700">Invoice Date</th>
                                        <th className="py-3 px-4 text-left w-1/4 border-r border-gray-700">Plan Purchased</th>
                                        <th className="py-3 px-4 text-left w-1/6 border-r border-gray-700">Amount Paid</th>
                                        <th className="py-3 px-4 text-left w-1/4 border-r border-gray-700">Payment Method</th>
                                        <th className="py-3 px-4 text-left w-1/6">Action</th>
                                    </tr>
                                </thead>

                            
                                <tbody className="text-[#606163]">
                                    {payments.length > 0 ? (
                                        payments.map((payment, index) => (
                                            <tr key={index} className="bg-[#111113] hover:bg-gray-800 border-b border-gray-800">
                                                <td className="py-3 px-4">{new Date(payment?.created_at).toLocaleDateString()}</td>
                                                <td className="py-3 px-4">{payment?.amount} {payment?.currency}</td>
                                                <td className="py-3 px-4">{payment?.order_id}</td>
                                                <td className="py-3 px-4">{payment?.is_verified ? "Success" : "Failed"}</td>

                                                <td className="py-3 px-4">
                                                    <button className="text-gray-400 hover:text-white">Download PDF</button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="py-4 text-center text-gray-400">No payment history available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Billing;
