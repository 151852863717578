import React from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import Logo from "../assets/logo.png"; // Update with actual path

const Footer = ({ className }) => {
  return (
    <footer
      className={`bg-[#081530] py-6 sm:py-8 md:py-10 text-white ${className}`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Footer Grid - Adjusts for Small Screens */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 sm:gap-8 md:gap-12">
          {/* Logo and Description */}
          <div className="col-span-2 md:col-span-1 text-center md:text-left">
            <img
              src={Logo}
              alt="Prgya.ai Logo"
              className="w-[120px] sm:w-[136px] h-auto mb-4 mx-auto md:mx-0"
            />
            <p className="text-white/80 text-sm sm:text-base mb-6 max-w-[300px] mx-auto md:mx-0">
              Transforming document management with AI-powered solutions for
              modern organizations.
            </p>
            {/* Social Media Icons */}
          </div>

          {/* Quick Links */}
          <div className="text-center md:text-left">
            <h3 className="text-sm font-semibold text-white/90 mb-4">
              Quick Links
            </h3>
            <ul className="space-y-3 text-white/80 text-sm">
              <li>
                <a
                  href="#features"
                  className="hover:text-[#096DF4] transition-colors duration-300"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  href="#usecases"
                  className="hover:text-[#096DF4] transition-colors duration-300"
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.getElementById("usecases");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    } else if (window.location.pathname !== "/") {
                      window.location.href = "/#usecases";
                    }
                  }}
                >
                  Use Cases
                </a>
              </li>
              <li>
                <a
                  href="#pricing"
                  className="hover:text-[#096DF4] transition-colors duration-300"
                >
                  Pricing
                </a>
              </li>
              <li>
                <a
                  href="#faq"
                  className="hover:text-[#096DF4] transition-colors duration-300"
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="hover:text-[#096DF4] transition-colors duration-300"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Features Section */}
          <div className="text-center md:text-left">
            <h3 className="text-sm font-semibold text-white/90 mb-4">
              Features
            </h3>
            <ul className="space-y-3 text-white/80 text-sm">
              <li>
                <span className="cursor-pointer hover:text-[#096DF4] transition-colors duration-300">
                  Document Management
                </span>
              </li>
              <li>
                <span className="cursor-pointer hover:text-[#096DF4] transition-colors duration-300">
                  Knowledge Base
                </span>
              </li>
              <li>
                <span className="cursor-pointer hover:text-[#096DF4] transition-colors duration-300">
                  AI Bot Integration
                </span>
              </li>
              <li>
                <span className="cursor-pointer hover:text-[#096DF4] transition-colors duration-300">
                  Team Management
                </span>
              </li>
            </ul>
          </div>

          {/* Contact Us Section */}
          <div className="text-center md:text-left">
            <h3 className="text-sm font-semibold text-white/90 mb-4">
              Contact Us
            </h3>
            <ul className="space-y-3 text-white/80 text-sm">
              <li>
                <a
                  href="mailto:products@neuradynamics.ai"
                  className=" flex items-center justify-center md:justify-start space-x-3  hover:text-[#096DF4]   transition-colors duration-300"
                >
                  <FaEnvelope className="text-[#096DF4] w-4 h-4" />
                  <span>products@neuradynamics.ai</span>
                </a>
              </li>
              <li>
                <a
                  href="tel:+919643301811"
                  className=" flex items-center justify-center md:justify-start space-x-3 hover:text-[#096DF4]  transition-colors duration-300"
                >
                  <FaPhoneAlt className="text-[#096DF4] w-4 h-4" />
                  <span> +91 96433 01811</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="mt-10 pt-6 border-t border-white/10">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <p className="text-white/80 text-xs sm:text-sm text-center md:text-left order-2 md:order-1">
              © 2024 Prgya.ai. All rights reserved
            </p>
            <div className="flex flex-wrap justify-center md:justify-end items-center gap-4 md:gap-6 order-1 md:order-2">
              <Link
                to="/terms"
                className="text-white/80 hover:text-[#096DF4] transition-colors duration-300 text-xs sm:text-sm whitespace-nowrap"
              >
                Terms & Conditions
              </Link>
              <span className="text-white/30 hidden md:inline">|</span>
              <Link
                to="/policy"
                className="text-white/80 hover:text-[#096DF4] transition-colors duration-300 text-xs sm:text-sm whitespace-nowrap"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
