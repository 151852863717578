import React, { useRef, useEffect, useState } from "react";
import profilepic1 from "../assets/profilepic1.png";
import profilepic2 from "../assets/profilepic2.png";
import profilepic3 from "../assets/profilepic3.png";
import testimonialpattern from "../assets/testimonialpattern.png"; // Import your pattern image here

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "Sarah Jonsom",
      title: "Project Manager at TechCorp",
      text: "Pragya.ai has revolutionized how we manage our documentation. The AI bot's ability to quickly retrieve information has saved our team countless hours.",
      image: profilepic1,
    },
    {
      name: "Michael Chen",
      title: "CTO at InnovateX",
      text: "The organization-level billing and project management features make it incredibly easy to scale our documentation needs across multiple teams.",
      image: profilepic2,
    },
    {
      name: "Emily Martinez",
      title: "Knowledge Manager at GlobalCorp",
      text: "The source referencing feature adds a layer of trust and accountability that we hadn't found in other solutions. It's exactly what we needed.",
      image: profilepic3,
    },
    {
      name: "Sarah Jonsom",
      title: "Project Manager at TechCorp",
      text: "Pragya.ai has revolutionized how we manage our documentation. The AI bot's ability to quickly retrieve information has saved our team countless hours.",
      image: profilepic1,
    },
    {
      name: "Michael Chen",
      title: "CTO at InnovateX",
      text: "The organization-level billing and project management features make it incredibly easy to scale our documentation needs across multiple teams.",
      image: profilepic2,
    },
    {
      name: "Emily Martinez",
      title: "Knowledge Manager at GlobalCorp",
      text: "The source referencing feature adds a layer of trust and accountability that we hadn't found in other solutions. It's exactly what we needed.",
      image: profilepic3,
    },
  ];

  // Duplicate testimonials to create a seamless loop
  const duplicatedTestimonials = [...testimonials, ...testimonials];

  const containerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  // Start the scroll animation when the component mounts
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scrollHeight = container.scrollHeight / 2; // Since we duplicated the testimonials
    let scrollPosition = 0;

    const scroll = () => {
      scrollPosition += 1; // Adjust speed by changing this value
      if (scrollPosition >= scrollHeight) {
        scrollPosition = 0; // Reset to the top when reaching the end
      }
      container.scrollTop = scrollPosition;

      // Calculate the active index based on scroll position
      const testimonialHeight =
        container.scrollHeight / duplicatedTestimonials.length;
      const newActiveIndex = Math.floor(scrollPosition / testimonialHeight) + 1;
      setActiveIndex(newActiveIndex);

      requestAnimationFrame(scroll);
    };

    const animationFrame = requestAnimationFrame(scroll);

    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <div className="py-16 bg-[#050D24]">
      <div className="container mx-auto px-[10px] relative">
        {/* Whole Section Background Image */}

        <div className="bg-gradient-to-r from-[#000409] to-[#0E59A9] p-8 rounded-lg flex flex-col lg:flex-row items-center lg:items-start gap-8 relative z-10">
          {/* Left Section (Heading) */}
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              background: `url(${testimonialpattern}) lightgray 50% / cover no-repeat`,
              flexShrink: 0,
              opacity: 0.1, // Set opacity to make it subtle
            }}
          ></div>
          <div className="lg:w-1/2 text-center mt-24">
            <h2 className="text-6xl md:text-6xl font-bold text-white mb-4">
              What Our <br />
              User Say
            </h2>

            <p className="text-lg text-white tracking-wider">
              Trusted by Organizations Worldwide
            </p>
          </div>

          {/* Right Section (Testimonials) */}
          <div
            className="lg:w-1/2 h-[400px] overflow-y-hidden relative"
            ref={containerRef}
          >
            <div className="flex flex-col">
              {duplicatedTestimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className={`testimonial-item p-3 rounded-lg shadow-sm text-white transition-all duration-300 ${
                    index === activeIndex ? "opacity-100" : "opacity-30"
                  }`}
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="mt-8 w-16 h-16 rounded-full object-cover"
                    />
                    <div>
                      <h3 className="text-white font-poppins text-[18px] mb-1 font-[600] leading-[21.6px]">
                        {testimonial.name}
                      </h3>

                      <p className="text-white text-base font-normal font-['Poppins'] leading-snug">
                        {testimonial.title}
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-300 ml-20">{testimonial.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
