import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { googleLogin } from "./login";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
// import LoadingAnimation from "../assets/LoadingAnimation.svg";
// import { toast } from "react-toastify";

function GoogleSignInButton(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const codeProcessed = useRef(false); // Use useRef to track if the code has been processed
  const { onLoginSuccess } = useUser();

  // Function to handle Google Sign-In by fetching the authorization URL from the backend
  const handleGoogleSignIn = async () => {
    try {
      // Call the backend to get the Google OAuth authorization URL
      const response = await axios.get(`${API_URL}/auth/google/authorize`);
      const authorizationUrl = `${response.data.authorization_url}&access_type=offline&prompt=consent`;
      // Redirect the user to the Google OAuth authorization URL
      window.location.href = `${authorizationUrl}`;
    } catch (error) {
      // Handle errors appropriately
      console.error("Google Sign-In Error:", error);
      // toast.error(`${error.response?.data?.detail}`);
      setLoading(false);
    }
  };

  const handleOAuthCallback = async (code, state) => {
    if (code && state) {
      setLoading(true);
      // codeProcessed.current = true;
      try {
        // Send the authorization code to your backend for token exchange
        const has_org = await googleLogin(code, state);

        // Call the onLoginSuccess function from UserContext to fetch user data and store userId
        console.log("Calling onLoginSuccess after Google login");
        await onLoginSuccess();

        // Increased delay to ensure all data (including organization users) is fetched properly
        setTimeout(() => {
          // Redirect to the homepage or another page after successful login
          if (has_org === true) {
            navigate("/home-page");
          } else {
            navigate("/createorganization");
          }
        }, 500);
      } catch (error) {
        console.log("Error during OAuth callback:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    console.log(code);
    // If a code exists and hasn't been processed yet, handle the OAuth callback
    if (code && state && !codeProcessed.current) {
      codeProcessed.current = true;
      handleOAuthCallback(code, state);
    }
  }, [navigate]);

  return (
    <div className="flex mt-3">
      <button
        id="google-login-btn"
        onClick={handleGoogleSignIn}
        className={`py-[10px] bg-[#F8FAFC] border border-[#0000001A] gap-2 flex justify-center items-center h-[58px] w-[430px] border-[#39F6A3] border-opacity-10 rounded-[5px] ${
          props?.isDisabled ? "cursor-not-allowed opacity-50" : ""
        }`}
        disabled={props?.isDisabled || loading}
      >
        {loading ? (
          <span className="h-full w-14 flex justify-center items-center rounded-md">
            {/* <img src={LoadingAnimation} alt="loading..." /> */}
          </span>
        ) : (
          <>
            <span className="h-full w-8 flex justify-center items-center rounded-md">
              <FcGoogle className="w-[25px] h-[25px]" />
            </span>
            <div className="text-[15px] h-[25px] font-semibold font-dmsans leading-normal w-[163px] text-[#1E293B]">
              Sign in with Google
            </div>
          </>
        )}
      </button>
    </div>
  );
}
export default GoogleSignInButton;
