import React from "react";
import "./AgentDetailsModal.css";
import { Modal, Box, Typography, Avatar, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DollorIcon from "../../../assets/HomepageAssests/dollor-icon.svg";
import ChatIcon from "../../../assets/HomepageAssests/chat-icon.svg";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "auto",
  bgcolor: "#1C1C1C",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  outline: "none",
  border: "1px solid #CDCDCD",
};

const AgentDetailsModal = ({ open, onClose, agent }) => {
  const navigate = useNavigate();

  const handleStartChat = () => {
    onClose();
    navigate(`/agent/${agent?.id}/chat`);
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style} className="agent-details-modal-container">
        <Box className="flex flex-col gap-4">
          {/* Header */}
          <Box className="flex justify-between items-center">
            <Typography variant="h6" className="agent-details-modal-title">
              {agent?.agent_name}
            </Typography>
            <CloseIcon
              className="text-white cursor-pointer"
              onClick={onClose}
            />
          </Box>

          {/* Content */}
          <Box className="created-description-container">
            <Typography variant="h6" className="created-description-title">
              Created by
            </Typography>
            <Box className="flex items-center gap-2">
              <Avatar className="!w-[18px] !h-[18px]" />
              <div className="agent-created-by-name">
                {agent?.creator_name || "Unknown"}
              </div>
            </Box>
            <Typography variant="h6" className="created-description-title">
              Description
            </Typography>
            <div className="h-[1px] w-full bg-[#CDCDCD]"></div>
            <div className="agent-description-text">
              {agent?.agent_description}
            </div>
          </Box>
          <Box className="cost-base-model-container">
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Default Message
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">
                    {agent?.default_message}
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Cost
                </Typography>
                <Box className="flex items-center gap-2">
                  <img src={DollorIcon} alt="dollor-icon" />
                  <div className="agent-created-by-name">Free</div>
                </Box>
              </Box>
            </Box>
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Knowledge Base
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">
                    {agent?.knowledgebase_ids &&
                    agent?.knowledgebase_ids?.length > 0
                      ? `${agent?.knowledgebase_ids?.length} Knowledge Base(s)`
                      : "No Knowledge Bases"}
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="model-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  AI Model
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">{agent?.model}</div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="cost-base-model-container">
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Organization
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">Your Organization</div>
                </Box>
              </Box>
            </Box>
            <Box className="cost-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Used By
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">Your Team</div>
                </Box>
              </Box>
            </Box>
            <Box className="model-container">
              <Box className="flex flex-col gap-2">
                <Typography variant="h6" className="created-description-title">
                  Chats
                </Typography>
                <Box className="flex items-center gap-2">
                  <div className="agent-created-by-name">Your Chat History</div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="flex justify-end">
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#007bff",
                color: "#fff",
                borderRadius: "8px",
                padding: "8px 10px",
                width: "180px",
                height: "40px",
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={handleStartChat}
            >
              <img src={ChatIcon} alt="chat-icon" />
              <span className="text-[14px] font-medium">Chat with Agent</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AgentDetailsModal;
