import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  profile_image: "",
  is_active: null,
  is_verified: null,
  is_super_admin: null,
};

const activeUserSlice = createSlice({
  name: "activeUser",
  initialState,
  reducers: {
    setActiveUser: (state, { payload }) => {
      state.id = payload.id;
      state.email = payload.email;
      state.profile_image = payload.profilePic;
      state.first_name = payload.name?.first || "";
      state.last_name = payload.name?.last || "";
      state.is_active = payload.isActive || null;
      state.is_verified = payload.isVerified || null;
      state.is_super_admin = payload.isSuperAdmin || null;
    },
  },
});
export const { setActiveUser, setUserNameAndBio } = activeUserSlice.actions;
export default activeUserSlice.reducer;
