import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

const DeleteConfirmationModal = ({ open, onClose, onConfirm, agentName }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          bgcolor: "#1c1c1c",
          color: "#ffffff",
          borderRadius: "12px",
          minWidth: "400px",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid #2b2b2b" }}>
        <Typography variant="h6" sx={{ color: "#ffffff" }}>
          Confirm Delete
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography sx={{ color: "#cdcdcd" }}>
          Are you sure you want to delete the agent "{agentName}"? This action
          cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #2b2b2b", p: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: "#cdcdcd",
            "&:hover": {
              bgcolor: "#2c2c2c",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          sx={{
            color: "#ff4444",
            "&:hover": {
              bgcolor: "#2c2c2c",
            },
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
