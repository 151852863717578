import { useEffect } from "react";

const AssistantWidget = () => {
  useEffect(() => {
    if (window.location.pathname === "/demo") {
      const script = document.createElement("script");
      script.src = "https://www.pragya.neuradynamics.ai/assistant-widget.js";
      script.async = true;
      script.onload = () => {
        if (window.initAssistantWidget) {
          window.initAssistantWidget({
            assistant_id: process.env.REACT_APP_ASSISTANT_ID,
            org_id: process.env.REACT_APP_ORG_ID,
            project_id: process.env.REACT_APP_PROJECT_ID,
            theme: "light",
          });
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  return null;
};

export default AssistantWidget;
