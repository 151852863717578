import React, { useState, useEffect, useRef, memo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineChevronDown, HiOutlineCog } from "react-icons/hi";
import {
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineUserGroup,
  HiOutlineCreditCard,
  HiOutlineInformationCircle,
  HiOutlineLogout,
  HiOutlineOfficeBuilding,
} from "react-icons/hi";
import axiosInstance from "../components/axiosInstance";
import { toast } from "react-toastify";
import Grid from "../assets/Grid.svg";
import { ReactComponent as Plogo } from "../assets/p.svg";
import GridBlue from "../assets/Gridblue.svg";
import Logo from "../assets/pragyawhite.png";
import IconDropdown from "../assets/Icondropdown.png";
import { MdOutlineDriveFileMoveRtl } from "react-icons/md";

const Sidebar = memo(({ handleOrgChange, organizations = [] }) => {
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [selectedOrg, setSelectedOrg] = useState(
    localStorage.getItem("selectedOrg") || "",
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenProfile, setIsDropdownOpenProfile] = useState(false);
  const [isOrgMenuOpen, setIsOrgMenuOpen] = useState(
    localStorage.getItem("isOrgMenuOpen") === "true" || false,
  );
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(
    localStorage.getItem("isSidebarExpanded") !== "false",
  );

  const [activeLink, setActiveLink] = useState("projects"); // Track active section
  const [activeSubTab, setActiveSubTab] = useState("");
  const dropdownRef = useRef(null);
  const profileDropdownRef = useRef(null); // Ref for profile dropdown
  const location = useLocation();
  const navigate = useNavigate();

  // ✅ Load selected organization from localStorage on refresh
  useEffect(() => {
    const savedOrgId = localStorage.getItem("selectedOrg");
    if (savedOrgId && organizations.length > 0) {
      const foundOrg = organizations.find((org) => org.id === savedOrgId);
      if (foundOrg) {
        setSelectedOrg(savedOrgId);
        handleOrgChange(foundOrg);
      }
    } else if (organizations.length > 0) {
      // Default to the first organization if no selection is saved
      setSelectedOrg(organizations[0].id);
      handleOrgChange(organizations[0]);
      localStorage.setItem("selectedOrg", organizations[0].id);
    }
  }, [organizations]);

  // ✅ Handle "My Organization" click
  const handleOrgClick = () => {
    setIsOrgMenuOpen(!isOrgMenuOpen);
    setActiveLink("organization"); // Set "My Organization" as active
  };

  // ✅ Handle "Projects" click
  const handleProjectsClick = () => {
    navigate("/organizations"); // Navigate to "/organizations"
    setActiveLink("projects"); // Set "Projects" as active
    setActiveSubTab(""); // Clear active sub-tab
    setIsOrgMenuOpen(false); // Close "My Organization" dropdown
  };

  // ✅ Toggle sidebar expanded/collapsed state
  const toggleSidebar = () => {
    const newState = !isSidebarExpanded;
    setIsSidebarExpanded(newState);
    localStorage.setItem("isSidebarExpanded", newState);
  };

  // ✅ Synchronize state with the current route
  useEffect(() => {
    const path = location.pathname;

    if (path === "/organizations") {
      setActiveLink("projects"); // Set "Projects" as active
      setActiveSubTab(""); // Clear active sub-tab
      setIsOrgMenuOpen(false); // Ensure "My Organization" dropdown is closed
    } else if (path.includes("details")) {
      setActiveLink("organization"); // Set "My Organization" as active
      setActiveSubTab("details");
    } else if (path.includes("members")) {
      setActiveLink("organization"); // Set "My Organization" as active
      setActiveSubTab("members");
    } else if (path.includes("billing")) {
      setActiveLink("organization"); // Set "My Organization" as active
      setActiveSubTab("billing");
    }
  }, [location.pathname]);

  // ✅ Handle organization selection
  const handleSelection = (orgId) => {
    const selectedOrgData = organizations.find((org) => org.id === orgId);
    if (selectedOrgData) {
      localStorage.setItem("selectedOrg", orgId);
      setSelectedOrg(orgId);
      handleOrgChange(selectedOrgData);
    }
  };

  // ✅ Handle logout
  const handleLogout = async () => {
    try {
      await axiosInstance.post(`/auth/jwt/logout`);
      localStorage.clear();
      window.location.href = "/signin";
    } catch (err) {
      toast.error("Logout failed.");
    }
  };

  // ✅ Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close organization dropdown if clicked outside
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      // Close profile dropdown if clicked outside
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpenProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // ✅ Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/users/me`);
        localStorage.setItem("user_id", response.data.id);
        setUser(response.data);
      } catch (err) {
        toast.error(err.response.data.detail);
      }
    };
    fetchUserData();
  }, []);

  // ✅ Persist isOrgMenuOpen state in localStorage
  useEffect(() => {
    localStorage.setItem("isOrgMenuOpen", isOrgMenuOpen);
  }, [isOrgMenuOpen]);

  return (
    <div
      className={`${
        isSidebarExpanded ? "w-[276px]" : "w-[80px]"
      } h-auto bg-[#1D1E2066] text-white m-4 border border-[#848093] rounded-lg flex flex-col justify-between transition-all duration-300`}
    >
      <div>
        {/* Logo and Toggle Button */}
        <div className="flex items-center justify-between p-4">
          {isSidebarExpanded ? (
            <>
              <img src={Logo} alt="Smart Knowledge Base" className="h-[35px]" />
              <button
                onClick={toggleSidebar}
                className="text-gray-400 hover:text-white p-1 rounded-full hover:bg-gray-700"
              >
                <HiOutlineChevronLeft className="text-xl" />
              </button>
            </>
          ) : (
            <div className="w-full flex justify-center">
              <Plogo className="h-8" />
            </div>
          )}
        </div>

        {/* Divider */}
        <div className="w-full h-px bg-[#848093]"></div>

        {/* Toggle Button - Only shown when sidebar is collapsed */}
        {!isSidebarExpanded && (
          <div className="flex justify-center mt-4">
            <button
              onClick={toggleSidebar}
              className="text-gray-400 hover:text-white p-1 rounded-full hover:bg-gray-700"
            >
              <HiOutlineChevronRight className="text-2xl" />
            </button>
          </div>
        )}

        {/* Organization Dropdown */}
        <div className="relative p-4" ref={dropdownRef}>
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={`flex items-center justify-center text-white border border-[#848093] rounded-lg hover:bg-[#2C2C2C] ${
              isSidebarExpanded ? "w-full px-4 py-2" : "w-10 h-10 mx-auto p-2"
            }`}
          >
            <div
              className={`flex items-center ${isSidebarExpanded ? "gap-3 w-full" : "justify-center"}`}
            >
              {isSidebarExpanded ? (
                <>
                  <img
                    src={IconDropdown}
                    alt="Dropdown Icon"
                    className="w-8 h-8"
                  />
                  <span className="text-xl">
                    {selectedOrg && organizations.length > 0
                      ? organizations.find((org) => org.id === selectedOrg)
                          ?.name
                      : "No Organization"}
                  </span>
                </>
              ) : (
                <img
                  src={IconDropdown}
                  alt="Dropdown Icon"
                  className="w-6 h-6"
                />
              )}
              {isSidebarExpanded && (
                <HiOutlineChevronDown
                  className={`ml-auto transition-transform ${isDropdownOpen ? "rotate-180" : "rotate-0"}`}
                />
              )}
            </div>
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <ul
              className={`absolute ${
                isSidebarExpanded ? "w-full left-4" : "w-48 left-full ml-2"
              } mt-2 bg-[#2C2C2C] text-white border rounded-lg shadow-lg z-10`}
            >
              {organizations.map((org) => (
                <li
                  key={org.id}
                  onClick={() => handleSelection(org.id)}
                  className="flex items-center gap-3 px-4 py-2 hover:bg-gray-700 cursor-pointer"
                >
                  <img
                    src={IconDropdown}
                    alt="Dropdown Icon"
                    className="w-6 h-6"
                  />
                  <span>{org.name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Main Navigation */}
        <ul className="p-4 rounded">
          {/* Projects */}
          <li>
            <NavLink
              to="/organizations"
              end
              className={({ isActive }) =>
                `flex items-center rounded-lg gap-3 ${isSidebarExpanded ? "px-6" : "px-4 justify-center"} py-3 text-base font-medium cursor-pointer ${
                  isActive && activeLink === "projects"
                    ? "bg-[#EFEAFF] text-[#1677FF]"
                    : "hover:bg-[#2C2C2C] text-gray-400"
                }`
              }
              onClick={handleProjectsClick} // Handle "Projects" click
            >
              <img
                src={
                  location.pathname === "/organizations" &&
                  activeLink === "projects"
                    ? GridBlue
                    : Grid
                }
                alt="Projects"
                className={`${isSidebarExpanded ? "w-5 h-5" : "w-6 h-6"}`}
              />
              {isSidebarExpanded && <span>Projects</span>}
            </NavLink>
          </li>

          {/* My Organization */}
          <li>
            <button
              onClick={handleOrgClick}
              className={`w-full flex ${isSidebarExpanded ? "items-center" : "justify-center"} rounded-lg gap-3 ${isSidebarExpanded ? "px-6" : "px-4"} py-3 text-sm font-medium cursor-pointer ${
                isOrgMenuOpen || activeLink === "organization"
                  ? "bg-[#EFEAFF] text-[#1677FF]"
                  : "hover:bg-[#2C2C2C] text-gray-400"
              }`}
            >
              <HiOutlineCog
                className={`${isSidebarExpanded ? "text-xl" : "text-2xl"}`}
              />
              {isSidebarExpanded && (
                <>
                  <span>My Organization</span>
                  <HiOutlineChevronDown
                    className={`ml-auto transition-transform ${
                      isOrgMenuOpen ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </>
              )}
            </button>

            {/* Sub-tabs (Only show if expanded) */}
            {isOrgMenuOpen && (
              <ul
                className={`${isSidebarExpanded ? "ml-6" : "flex flex-col items-center gap-4"} mt-2`}
              >
                <li className="w-full">
                  <NavLink
                    to="/organizations/details"
                    onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                    className={({ isActive }) =>
                      `flex items-center gap-3 ${isSidebarExpanded ? "px-[18px]" : "justify-center"} py-1 text-sm font-medium cursor-pointer ${
                        isActive || activeSubTab === "details"
                          ? "text-[#1677FF]"
                          : "text-gray-400"
                      }`
                    }
                  >
                    {isSidebarExpanded ? (
                      <span
                        className={`w-2 h-2 rounded-full ${
                          activeSubTab === "details"
                            ? "bg-[#1677FF]"
                            : "bg-gray-400"
                        }`}
                      ></span>
                    ) : (
                      <HiOutlineInformationCircle
                        className={`${isSidebarExpanded ? "text-xl" : "text-2xl"}`}
                      />
                    )}
                    {isSidebarExpanded && <span>Organization Details</span>}
                  </NavLink>
                </li>
                {isSidebarExpanded && (
                  <div className="h-4 ml-[21px] border-l-2 border-gray-400"></div>
                )}
                <li className="w-full">
                  <NavLink
                    to="/organizations/members"
                    onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                    className={({ isActive }) =>
                      `flex items-center gap-3 ${isSidebarExpanded ? "px-[18px]" : "justify-center"} py-1 text-sm font-medium cursor-pointer ${
                        isActive || activeSubTab === "members"
                          ? "text-[#1677FF]"
                          : "text-gray-400"
                      }`
                    }
                  >
                    {isSidebarExpanded ? (
                      <span
                        className={`w-2 h-2 rounded-full ${
                          activeSubTab === "members"
                            ? "bg-[#1677FF]"
                            : "bg-gray-400"
                        }`}
                      ></span>
                    ) : (
                      <HiOutlineUserGroup
                        className={`${isSidebarExpanded ? "text-xl" : "text-2xl"}`}
                      />
                    )}
                    {isSidebarExpanded && <span>Members</span>}
                  </NavLink>
                </li>
                {isSidebarExpanded && (
                  <div className="h-4 ml-[21px] border-l-2 border-gray-400"></div>
                )}
                <li className="w-full">
                  <NavLink
                    to="/organizations/billing"
                    onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                    className={({ isActive }) =>
                      `flex items-center gap-3 ${isSidebarExpanded ? "px-[18px]" : "justify-center"} py-1 text-sm font-medium cursor-pointer ${
                        isActive ? "text-[#1677FF]" : "text-gray-400"
                      }`
                    }
                  >
                    {isSidebarExpanded ? (
                      <span
                        className={`w-2 h-2 rounded-full ${
                          activeSubTab === "billing"
                            ? "bg-[#1677FF]"
                            : "bg-gray-400"
                        }`}
                      ></span>
                    ) : (
                      <HiOutlineCreditCard
                        className={`${isSidebarExpanded ? "text-xl" : "text-2xl"}`}
                      />
                    )}
                    {isSidebarExpanded && <span>Plan & Billing</span>}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>

      {/* User Profile */}
      <div
        className="relative mt-auto p-4border-gray-700"
        ref={profileDropdownRef}
      >
        <div className="py-4 px-6">
          <button
            className={`flex items-center ${isSidebarExpanded ? "space-x-4 w-full py-2 px-4" : "justify-center w-10 h-10"} rounded-lg hover:bg-gray-700`}
            onClick={() => {
              navigate(`/home-page`);
            }}
          >
            <MdOutlineDriveFileMoveRtl
              size={25}
              className={isSidebarExpanded ? "" : "mx-auto"}
            />
            {isSidebarExpanded && <span>Exit To Creator</span>}
          </button>
        </div>
        <div
          className="relative mt-auto p-4 border-t border-gray-700"
          ref={profileDropdownRef}
        >
          <button
            className={`flex items-center ${isSidebarExpanded ? "space-x-1" : "justify-center"} w-full p-2 rounded-lg hover:bg-gray-700`}
            onClick={() => setIsDropdownOpenProfile(!isDropdownOpenProfile)} // Toggle dropdown
          >
            <div
              className={`${isSidebarExpanded ? "w-10 h-8" : "w-12 h-10"} rounded-full bg-gray-500 flex items-center justify-center ${isSidebarExpanded ? "text-lg" : "text-xl"} font-medium text-white`}
            >
              {user.email?.charAt(0)?.toUpperCase() || "?"}
            </div>
            {isSidebarExpanded && (
              <span className="text-gray-300 truncate">{user.email}</span>
            )}
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpenProfile && (
            <div
              className={`absolute bottom-16 ${isSidebarExpanded ? "left-4 w-[220px]" : "left-0 w-[120px]"} bg-[#1D1E20] shadow-md rounded-lg border border-gray-700`}
            >
              {isSidebarExpanded && (
                <div className="p-3">
                  <p className="text-sm text-gray-300 truncate">{user.email}</p>
                </div>
              )}
              <button
                className={`w-full text-left p-3 text-sm text-gray-300 hover:bg-gray-700 ${isSidebarExpanded ? "" : "flex justify-center items-center"}`}
                onClick={() => {
                  localStorage.clear();
                  navigate("/signin");
                }}
              >
                {isSidebarExpanded ? (
                  "Logout"
                ) : (
                  <HiOutlineLogout
                    className={`${isSidebarExpanded ? "text-xl" : "text-2xl"}`}
                  />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
