import { createSlice } from "@reduxjs/toolkit";

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    selectedOrgId: localStorage.getItem("selectedOrg") || "",
    organizations: [],
  },
  reducers: {
    setSelectedOrg: (state, action) => {
      state.selectedOrgId = action.payload;
      localStorage.setItem("selectedOrg", action.payload);
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
      // If there's no selected org, set the first one as default
      if (!state.selectedOrgId && action.payload.length > 0) {
        state.selectedOrgId = action.payload[0].id;
        localStorage.setItem("selectedOrg", action.payload[0].id);
      }
    },
  },
});

// Create selectors
export const selectSelectedOrgId = (state) => state.organization.selectedOrgId;
export const selectOrganizations = (state) => state.organization.organizations;

// Selector for is_creator status
export const selectIsCreator = (state) => {
  const selectedOrg = state.organization.organizations.find(
    (org) => org.id === state.organization.selectedOrgId,
  );
  return selectedOrg?.is_creator || false;
};

export const { setSelectedOrg, setOrganizations } = organizationSlice.actions;
export default organizationSlice.reducer;
