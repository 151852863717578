import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "./axiosInstance.js";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  setDocumentsTrue,
  setDocumentsFalse,
} from "../redux/notificationSlice.js";

import Upload from "../assets/Upload.svg";
import { TbAlertTriangle } from "react-icons/tb";
import SucessModal from "./SuccessModal.jsx";
import DocumentsPage from "./Uploadfile.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import DownloadIcon from "../assets/documentPageAssets/DownloadIcon.svg";
import DocumentListShimmer from "./Documentskeleton.jsx";
import {
  FaFolder,
  FaFile,
  FaEllipsisV,
  FaPlus,
  FaTh,
  FaBars,
  FaClock,
} from "react-icons/fa";
import { HiOutlineSearch } from "react-icons/hi";
import { MdFilterList } from "react-icons/md";
import { IoEllipsisHorizontal } from "react-icons/io5";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import PopupModal from "../components/PopupModal";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import EmptyStateComponent from "./Emptystatecomponent.jsx";
import knowledgebasecreate from "../assets/knowledgebasecreate.svg";
import Files from "../pages/Files.jsx";

const FolderDocumentsPage = ({
  folderId,
  rootfoldername,
  handleCloseFolder,
}) => {
  const [folderStack, setFolderStack] = useState([
    { id: folderId, name: rootfoldername },
  ]); // Folder navigation stack
  const [currentFolderId, setCurrentFolderId] = useState(folderId);
  const [folders, setFolders] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API_URL;
  const projectId = localStorage.getItem("activeProjectId");
  const token = localStorage.getItem("access-token");
  const role = localStorage.getItem("role");
  const [folderDropdown, setFolderDropdown] = useState(null);
  const [fileDropdown, setFileDropdown] = useState(null);
  const [viewMode, setViewMode] = useState("grid");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedFolderName, setEditedFolderName] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [fileurl, setFileurl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Function to open Edit Modal
  const openEditModal = (folder) => {
    setEditedFolderName(folder.name);
    setSelectedFolderId(folder.id);
    setShowEditModal(true);
  };

  // Function to handle folder rename
  const handleEditFolder = async () => {
    if (!editedFolderName.trim()) return;

    try {
      await axiosInstance.patch(`/v1/folders/${selectedFolderId}`, {
        name: editedFolderName,
      });

      // Update UI
      setFolders((prevFolders) =>
        prevFolders.map((folder) =>
          folder.id === selectedFolderId
            ? { ...folder, name: editedFolderName }
            : folder,
        ),
      );

      setShowEditModal(false);
      toast.success("Folder renamed successfully!");
    } catch (error) {
      toast.error("Failed to rename folder.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentFolderId]);

  // Fetch folder content
  const dropdownRef = useRef(null);
  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false);
  };
  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFolderDropdown(null);
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const fetchData = async () => {
    setIsFetching(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/folders/${projectId}/documents/${currentFolderId}`,
      );

      const data = response.data.documents;
      const subfolders = response.data.subfolders;
      console.log(response);
      setUploadedFiles(
        data.map((file) => ({
          id: file.id,
          uploaded_on: file.uploaded_on,
          name: file.name,
          status: file.status,
          url: file.url,
          size: (file.size / 1024).toFixed(2) + " KB",
        })),
      );
      setFolders(subfolders);

      setTotalFiles(data.total);
      handleToggleNotification(data.total);
      setIsFetching(false);
    } catch (error) {
      toast.error(error.response?.data?.detail || "Failed to fetch data");
      setIsFetching(false);
    }
  };
  const handleDelete = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    try {
      const res = await axiosInstance.delete(
        `${API_URL}/v1/user/documents/${selectedFileId}`,
      );
      const data = res.data;
      toast.success("Deleted Successfully");
      // Fetch the updated list of files after deletion
      const updatedFiles = uploadedFiles.filter(
        (file) => file.id !== selectedFileId,
      );
      setUploadedFiles(updatedFiles);

      // If the current page becomes empty after deletion, move to the previous page
      // if (updatedFiles.length === 0 && pageNum > 1) {
      //     setPageNum(prevPage => prevPage - 1);
      // } else {
      //     fetchData(); // If there are still documents left, simply refresh the current page
      // }
      setShowDeleteModal(false);
      setIsDeleting(false);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
      setIsDeleting(false);
    }
  };
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the document
    setShowDropdown((prev) => !prev);
  };
  const handleDeleteFolder = async (folderId) => {
    try {
      // Send DELETE request to the API
      await axiosInstance.delete(`${API_URL}/v1/folders/${folderId}`);
      // Update the folders state by filtering out the deleted folder
      setFolders(folders.filter((folder) => folder.id !== folderId));
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
    // Hide the context menu if it's open
  };
  // Toggle notification based on document count
  const handleToggleNotification = (totalFiles) => {
    if (totalFiles > 0) {
      dispatch(setDocumentsFalse());
    } else {
      dispatch(setDocumentsTrue());
    }
  };
  const handleCloseModal = () => {
    setFile(null);
  };
  const handleDownloadClick = (doc) => {
    setSelectedFile(doc); // Store the file details
    setShowDownloadModal(true); // Show the modal
  };

  const handleViewClick = async () => {
    console.log(selectedFile);
    if (selectedFile) {
      setSelectedFileId(selectedFile.id);
      console.log(selectedFileId);

      try {
        // Fetch the file URL from the API
        const res = await axiosInstance.get(
          `${API_URL}/v1/user/documents/view/${selectedFile.id}`,
        );
        const url = res.data;
        setFileurl(url);
        console.log(url);

        // Directly open the file URL in a new tab
        window.open(url);
      } catch (error) {
        toast.error(`${error.response?.data?.detail}`);
      }
    }
  };
  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      alert("Folder name cannot be empty.");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `${API_URL}/v1/folders/${projectId}/create`,
        { name: newFolderName, parent_folder_id: currentFolderId },
      );
      setFolders([...folders, response.data]);
      setShowCreateModal(false);
      setNewFolderName("");
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };
  const handleDrop = (e, type) => {
    e.preventDefault();
    setIsDragOver(false);
    let selectedFile;
    if (type === "drop") {
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }

    // Validate file format
    if (selectedFile && selectedFile.type !== "application/pdf") {
      // setErrorMessage('*Only .pdf files are allowed.');
      setFile(null);
      return;
    } else {
      // If valid, proceed with the upload
      setFile(selectedFile);
      // Clear any existing error message
      setUploadProgress(0);

      // Start the upload
      startUpload(selectedFile);
    }
  };
  const startUpload = async (uploadedFile) => {
    const maxSizeInBytes = 10 * 1024 * 1024;

    if (uploadedFile.size > maxSizeInBytes) {
      setFile(null);
      toast.error("File size exceeds the limit of 50 MB.");
      return;
    }

    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("folder_id", currentFolderId);

    try {
      setIsUploading(true);
      setUploadProgress(0);

      const response = await axiosInstance.post(
        `${API_URL}/v1/user/documents/${projectId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            setUploadProgress(progress);
          },
        },
      );

      const data = response.data;
      console.log("Upload successful:", data);

      // Update the file list immediately
      const newFile = {
        id: data.id,
        name: data.name,
        status: "Processing",
        uploaded_on: data.uploaded_on,
        url: data.url,
        size: (data.size / 1024).toFixed(2) + " KB",
      };

      setUploadedFiles((prevFiles) => [newFile, ...prevFiles]);
      setTotalFiles((prev) => prev + 1);
      handleToggleNotification(totalFiles + 1);

      setUploadProgress(100);
      toast.success("File uploaded successfully!");

      // Reset upload status but keep modal open
      setTimeout(() => {
        setIsUploading(false);
        // Modal will stay open until user clicks Done or X

        // Don't need to refresh the file list here - we already updated the UI
        // fetchData();
      }, 500);
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(error.response?.data?.detail || "Upload failed");
      setUploadProgress(0);
      setIsUploading(false);
      setFile(null);
    }
  };
  // Navigate into a folder
  const handleOpenFolder = (folder) => {
    if (!folder) {
      console.error("Folder data is undefined:", folder);
      return;
    }
    setFolderStack([...folderStack, { id: folder.id, name: folder.name }]);
    setCurrentFolderId(folder.id);
  };

  // Navigate back to the parent folder
  const handleGoBack = () => {
    if (folderStack.length > 1) {
      const newStack = [...folderStack];
      newStack.pop();
      const previousFolder = newStack[newStack.length - 1];
      setFolderStack(newStack);
      setCurrentFolderId(previousFolder.id);
    }
  };

  // Render folder hierarchy as breadcrumbs
  const renderBreadcrumbs = () => {
    return (
      <div className=" flex items-center space-x-3 bg-gray-100 px-4 py-2 rounded-lg shadow-md">
        {/* Root Breadcrumb */}
        <span
          className="cursor-pointer text-blue-600 font-semibold hover:underline"
          onClick={handleCloseFolder} // Navigate back to the parent component
        >
          Root
        </span>
        {folderStack.map((folder, index) => (
          <span key={folder.id} className="flex items-center space-x-3">
            {/* Separator */}
            <span className="text-gray-400">/</span>
            <span
              className={`cursor-pointer ${
                index === folderStack.length - 1
                  ? "text-gray-800 font-bold"
                  : "text-blue-600 hover:underline"
              }`}
              onClick={() => {
                if (index < folderStack.length - 1) {
                  setFolderStack(folderStack.slice(0, index + 1)); // Trim stack to this folder
                  setCurrentFolderId(folder.id);
                }
              }}
            >
              {folder.name}
            </span>
          </span>
        ))}
      </div>
    );
  };

  return (
    <div>
      {/* Breadcrumbs */}

      {/* Back Button */}
      {/* {folderStack.length > 1 && (
                <button
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mb-4"
                    onClick={handleGoBack}
                >
                    Back
                </button>
            )} */}
      {/* {renderBreadcrumbs()} */}
      <div className="flex justify-between items-center mb-4">
        {renderBreadcrumbs()}
        <div className="flex items-center gap-3">
          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              className="bg-[#1D1E20] text-white px-4 py-2 pl-10 rounded-lg focus:outline-none border border-gray-700"
            />
            <HiOutlineSearch
              className="absolute left-3 top-3 text-gray-400"
              size={18}
            />
          </div>

          {/* Filter Button */}
          <button className="p-2 bg-[#1D1E20] rounded-lg hover:bg-gray-700">
            <MdFilterList size={20} />
          </button>

          {/* View Mode Toggle */}
          <button
            className="p-2 bg-[#1D1E20] rounded-lg hover:bg-gray-700"
            onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
          >
            {viewMode === "grid" ? <FaBars size={20} /> : <FaTh size={20} />}
          </button>

          {/* Create Folder Button */}
          <button
            className="bg-white text-black font-semibold px-4 py-2 rounded-lg "
            onClick={() => {
              setShowDropdown(false);
              setShowCreateModal(true);
            }}
          >
            + Create folder
          </button>

          {/* Add File Button */}
          <button
            className="bg-[#1677FF] text-white font-semibold px-4 py-2 rounded-lg "
            onClick={() => {
              setShowDropdown(false);
              setShowUploadModal(true);
            }}
          >
            + Add file
          </button>
        </div>
      </div>

      {/* Folders Section */}

      {folders?.length === 0 && uploadedFiles.length === 0 ? (
        <EmptyStateComponent
          icon={knowledgebasecreate}
          title="Add Files & Folders"
          description="Click on 'Add Files' or 'Add Folders' to get started"
          buttonText="+ Add Files"
          onButtonClick={() => setShowUploadModal(true)}
        />
      ) : (
        <>
          {/* Always show Files section if files exist */}
          {uploadedFiles.length > 0 && (
            <>
              <h3 className="p-2">Files</h3>
              {viewMode === "grid" ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {uploadedFiles.map((doc) => (
                    <div
                      key={doc.id}
                      className="relative w-60 h-36 bg-[#1D1E21] text-white p-4 rounded-lg border border-gray-700"
                    >
                      <FaFile className="text-gray-400 text-4xl mb-2" />
                      <p className="text-sm truncate">{doc.name}</p>

                      {/* Divider Line */}
                      <div className="w-full h-[1px] bg-[#4B4C4F] my-2"></div>

                      {/* Uploaded Time with Icon */}
                      <div className="flex items-center text-xs text-gray-400 mt-2">
                        <FaClock className="mr-1" /> {/* Time Icon */}
                        <p>{doc.uploaded_on}</p>
                      </div>

                      {/* Three-dot menu */}
                      <button
                        className="absolute top-2 right-2 text-gray-400 hover:text-white"
                        onClick={() =>
                          setDropdownOpen(
                            dropdownOpen === doc.id ? null : doc.id,
                          )
                        }
                      >
                        <IoEllipsisHorizontal />
                      </button>

                      {dropdownOpen === doc.id && (
                        <div
                          ref={dropdownRef}
                          className="absolute top-6 right-0 bg-[#2C2C2C] text-white text-sm shadow-md rounded-md w-20"
                        >
                          <button
                            className="block w-full px-3 py-1 text-left hover:bg-gray-700"
                            onClick={() => handleDownloadClick(doc)}
                          >
                            View
                          </button>
                          <button
                            className="block w-full px-3 py-1 text-left hover:bg-gray-700"
                            onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedFileId(doc.id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                // List View
                <div className="overflow-x-auto  mb-2">
                  {/* Files List */}
                  {uploadedFiles.map((doc) => (
                    <div
                      key={doc.id}
                      className="flex items-center justify-between px-6 py-3 bg-[#2C2C2C] text-white rounded-md mt-2"
                    >
                      {/* Name */}
                      <div className="w-[40%] flex items-center gap-3">
                        <FaFile className="text-gray-400 text-lg" />
                        <span className="truncate">{doc.name}</span>
                      </div>

                      {/* Date */}
                      <div className="w-[25%] text-center text-gray-400">
                        {doc.uploaded_on}
                      </div>

                      {/* Actions */}
                      <div className="w-[20%] flex items-center justify-end gap-3">
                        <button
                          className="text-gray-400 hover:text-white"
                          onClick={() => handleDownloadClick(doc)}
                        >
                          View
                        </button>
                        <button
                          className="text-gray-400 hover:text-white"
                          onClick={() => {
                            setShowDeleteModal(true);
                            setSelectedFileId(doc.id);
                          }}
                        >
                          <DeleteIcon className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {/* Show Folders section if folders exist */}
          {folders?.length > 0 && (
            <>
              <h3 className="p-2">Folders</h3>
              {viewMode === "grid" ? (
                // Grid View
                <div className="flex gap-3 overflow-x-auto scrollbar-hide">
                  {folders.map((folder) => (
                    <div
                      key={folder.id}
                      className="relative w-32 h-36 bg-[#1D1E21] text-white p-3 rounded-lg border border-gray-700 flex flex-col items-center justify-center"
                    >
                      {/* Folder Icon - Centered */}
                      <FaFolder
                        className="text-gray-400 text-4xl mb-2 cursor-pointer"
                        onClick={() => handleOpenFolder(folder)}
                      />

                      {/* Folder Name - Centered */}
                      <p className="text-sm text-center truncate">
                        {folder.name}
                      </p>

                      {/* Three-dot menu - Positioned at Top Right */}
                      <button
                        className="absolute top-2 right-2 text-gray-400 hover:text-white"
                        onClick={() =>
                          setFolderDropdown(
                            folder.id === folderDropdown ? null : folder.id,
                          )
                        }
                      >
                        <IoEllipsisHorizontal />
                      </button>

                      {/* Dropdown Menu */}
                      {folderDropdown === folder.id && (
                        <div
                          ref={dropdownRef}
                          className="absolute right-2 top-10 bg-[#1D1E21] text-white shadow-md rounded-md w-[80px] text-sm"
                        >
                          <button
                            className="block w-full px-2 py-1 text-left hover:bg-gray-700"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the folder open action
                              setShowEditModal(true);
                              openEditModal(folder);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="block w-full px-2 py-1 text-left hover:bg-gray-700"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the folder open action
                              handleDeleteFolder(folder.id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                // List View
                <div className="w-full">
                  {folders.map((folder) => (
                    <div
                      key={folder.id}
                      className="flex justify-between items-center bg-[#1D1E21] text-white p-3 rounded-lg border border-gray-700 mb-2"
                    >
                      <div className="flex items-center gap-3">
                        <FaFolder className="text-gray-400 text-2xl" />
                        <p className="text-sm">{folder.name}</p>
                      </div>
                      {/* Three-dot menu */}
                      <button
                        className="text-gray-400 hover:text-white"
                        onClick={() =>
                          setFolderDropdown(
                            folder.id === folderDropdown ? null : folder.id,
                          )
                        }
                      >
                        <IoEllipsisHorizontal />
                      </button>

                      {/* Dropdown Menu */}
                      {folderDropdown === folder.id && (
                        <div
                          ref={dropdownRef}
                          className="absolute right-4 bg-[#1D1E21] text-white shadow-md rounded-md w-24"
                        >
                          <button
                            className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the folder open action
                              setShowEditModal(true);
                              openEditModal(folder);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the folder open action
                              handleDeleteFolder(folder.id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}

      {file && !isUploading && (
        <SucessModal
          text={"Document uploaded Successfully"}
          handleModal={handleCloseModal}
        />
      )}

      {showUploadModal && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center"
          onClick={() => {
            setShowUploadModal(false);
            // Refresh data when modal is closed
            fetchData();
          }} // Close modal when clicking outside
        >
          <div
            className="bg-[#1D1E20] w-[794px] h-[345px] rounded-lg shadow-lg p-6 border border-[#FFFFFF66] text-white"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            {/* Modal Header */}
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold flex items-center gap-2">
                <span className="text-gray-300">📁</span> Add File
              </h2>
              <button
                onClick={() => {
                  setShowUploadModal(false);
                  // Refresh data when modal is closed with X button
                  fetchData();
                }}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>

            {/* Folder Name Input */}

            {/* Upload Area */}
            <div
              className="mt-4 flex flex-col items-center justify-center border-2 border-dashed border-[#FFFFFF33] rounded-lg p-8 cursor-pointer hover:border-white transition"
              onClick={() => document.getElementById("fileInput").click()}
              onDragOver={handleDragOver} // Add this
              onDragLeave={handleDragLeave} // Add this
              onDrop={(e) => handleDrop(e, "drop")} // Add this
            >
              <img
                src={Upload}
                width="50px"
                height="50px"
                alt="Upload Icon"
                className="text-[#1677FF] mb-3"
              />
              <p className="text-gray-300 text-sm">
                Click or drag file to this area to upload
              </p>
              <span className="text-gray-500 text-xs mt-1">
                Supported format: PDF (50Mb)
              </span>
              <input
                type="file"
                accept=".pdf"
                onChange={(e) => handleDrop(e, "input-file")}
                className="hidden"
                id="fileInput"
              />
            </div>
            {file && (
              <div className="mt-4 bg-[#2C2C2C] p-3 rounded-lg flex items-center space-x-4">
                {/* File Icon */}
                <div className="text-[#1677FF]">📄</div>
                <div className="flex-1">
                  {/* File Name */}
                  <p className="text-white text-sm">{file.name}</p>
                  {/* Progress Bar */}
                  <div className="relative mt-1 w-full h-2 bg-gray-700 rounded-full">
                    <div
                      className="h-2 rounded-full transition-all"
                      style={{
                        width: `${uploadProgress}%`,
                        backgroundColor:
                          uploadProgress === 100 ? "#3A8FFF" : "#1677FF",
                      }}
                    ></div>
                  </div>
                  {/* File Size & Progress Percentage */}
                  <div className="flex justify-between text-xs text-gray-400 mt-1">
                    <span>{(file.size / 1024).toFixed(2)} kb</span>
                    <span>{uploadProgress}%</span>
                  </div>
                </div>
                {/* Delete Icon */}
                <button
                  className="text-gray-400 hover:text-red-500"
                  onClick={() => setFile(null)}
                >
                  🗑️
                </button>
              </div>
            )}
            {/* Error Message */}
            {errorMessage && (
              <p className="text-red-500 text-xs text-center mt-2">
                {errorMessage}
              </p>
            )}

            {/* Footer Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <button
                className="px-4 py-2 text-white bg-transparent border border-gray-500 rounded-lg hover:bg-gray-700"
                onClick={() => {
                  setShowUploadModal(false);
                  // Refresh data when modal is closed with Cancel button
                  fetchData();
                }}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-lg transition-colors ${
                  file && uploadProgress === 100
                    ? "bg-[#1677FF] hover:bg-[#1256CC]"
                    : "bg-gray-500 cursor-not-allowed"
                }`}
                disabled={!file || uploadProgress !== 100}
                onClick={() => {
                  setShowUploadModal(false);
                  // Refresh data when modal is closed with Done button
                  fetchData();
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}

      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
          <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Create Folder</h3>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter folder name"
              className="w-full px-4 py-2  bg-[#191919] rounded mb-4"
            />
            <div className="flex justify-end">
              <button
                className="bg-[#FFFFFF] text-black px-4 py-2 rounded mr-2  border-2 border-current"
                onClick={() => setShowCreateModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#1677FF] text-white px-4 py-2 rounded"
                onClick={handleCreateFolder}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModal && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
          <div className="bg-[#27282B] w-full max-w-lg p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Edit Folder</h3>

            {/* Input Field for Folder Name */}
            <input
              type="text"
              value={editedFolderName}
              onChange={(e) => setEditedFolderName(e.target.value)}
              placeholder="Enter new folder name"
              className="w-full px-4 py-2 bg-[#191919] text-white rounded mb-4"
            />

            {/* Action Buttons */}
            <div className="flex justify-end">
              <button
                className="bg-[#FFFFFF] text-black px-4 py-2 rounded mr-2 border-2 border-current"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#1677FF] text-white px-4 py-2 rounded"
                onClick={handleEditFolder}
                disabled={!editedFolderName.trim()} // Disable if input is empty
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <PopupModal closeModal={handleDelete} className="bg-[#27282B]">
          <div className="flex flex-col justify-center items-center  h-[200px] w-[360px]">
            <img src={FeaturedIcon} alt="" className="mb-2" />
            <div className="text-[#FFFFFF] font-medium text-lg mb-2">
              Delete document
            </div>
            <p className="text-[#FFFFFF] font-dmsans text-[15px] font-normal mb-4 text-center">
              Are you sure you want to delete this <br /> document? This action
              cannot be undone.
            </p>
            <div className="flex justify-center space-x-6 w-full">
              <button
                className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[39px] flex items-center justify-center"
                onClick={handleDelete}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteDocument}
                disabled={isDeleting}
                className="w-[150px] h-[39px] rounded-md bg-[#1677FF] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
              >
                {isDeleting ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-10 w-10"
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </PopupModal>
      )}
      {showDownloadModal && (
        <PopupModal
          closeModal={handleCloseDownloadModal}
          className="bg-[#27282B]"
        >
          <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
            <div className="flex flex-col items-center space-y-7">
              {/* <FiEye size={20} /> */}
              <p className="text-lg  text-[#ffffff] font-medium  text-center">
                Do you want to view this file?
              </p>
              <div className="flex space-x-4">
                <button
                  className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                  onClick={handleCloseDownloadModal}
                >
                  Cancel
                </button>
                <button
                  className="px-3 w-[150px] h-[44px] rounded-md  font-dmsans bg-[#1677FF] text-base text-[#ffffff] font-medium flex items-center justify-center"
                  onClick={handleViewClick}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default FolderDocumentsPage;
