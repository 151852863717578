import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../components/axiosInstance";
import { toast } from "react-toastify";
import { FaFile, FaClock, FaArrowLeft, FaPlus } from "react-icons/fa";
import { IoEllipsisHorizontal } from "react-icons/io5";
import PopupModal from "../../components/PopupModal";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import FeaturedIcon from "../../assets/FeaturedIcon.svg";
import { getStoredValue } from "../../services/logics.js";

const CreatorFilesInFolder = () => {
  const { folderId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const projectId = getStoredValue("activeProjectId");
  const navigate = useNavigate();
  const token = localStorage.getItem("access-token");

  const [folderData, setFolderData] = useState(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileDropdown, setFileDropdown] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [isDeletingFile, setIsDeletingFile] = useState(false);

  // Upload file related states
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchFolderData = useCallback(async () => {
    setIsLoading(true);
    try {
      // Get folder info by getting all folders and filtering by ID
      // (Since there's no dedicated endpoint for getting a single folder)
      const folderListResponse = await axiosInstance.get(
        `${API_URL}/v1/folders/${projectId}`,
      );

      // Find the current folder in the list
      const currentFolder = folderListResponse.data.folders.find(
        (folder) => folder.id === folderId,
      );

      if (!currentFolder) {
        toast.error("Folder not found");
        navigate("/creator/files");
        return;
      }

      setFolderData(currentFolder);
      setFolderName(currentFolder.name);

      // Fetch files in folder using the documents endpoint
      const filesResponse = await axiosInstance.get(
        `${API_URL}/v1/folders/${projectId}/documents/${folderId}`,
      );

      // The response structure should match the API's response
      setFiles(filesResponse.data.documents || []);
    } catch (error) {
      console.error("Error fetching folder data:", error);
      toast.error("Failed to load folder data");
      navigate("/creator/files");
    } finally {
      setIsLoading(false);
    }
  }, [API_URL, projectId, folderId, navigate]);

  useEffect(() => {
    fetchFolderData();
  }, [folderId, fetchFolderData]);

  const handleEditFolder = async () => {
    if (!folderName.trim()) {
      toast.error("Folder name cannot be empty");
      return;
    }

    setIsEditing(true);
    try {
      await axiosInstance.put(
        `${API_URL}/v1/folders/${projectId}/${folderId}/`,
        {
          name: folderName,
        },
      );

      toast.success("Folder renamed successfully");
      setShowEditModal(false);

      // Update local state
      setFolderData((prev) => ({
        ...prev,
        name: folderName,
      }));
    } catch (error) {
      toast.error("Failed to rename folder");
    } finally {
      setIsEditing(false);
    }
  };

  const handleDeleteFolder = async () => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete(
        `${API_URL}/v1/folders/${projectId}/${folderId}/`,
      );
      toast.success("Folder deleted successfully");
      setShowDeleteModal(false);
      navigate("/creator/files");
    } catch (error) {
      toast.error("Failed to delete folder");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDownloadClick = (file) => {
    setSelectedFile(file);
    setShowDownloadModal(true);
  };

  const handleViewClick = async () => {
    if (selectedFile) {
      try {
        // Show loading indicator
        toast.info("Preparing file for viewing...");

        // Request secure URL from backend
        const res = await axiosInstance.get(
          `${API_URL}/v1/user/documents/view/${selectedFile.id}`,
        );

        // Get the URL from response
        const url = res.data;

        // Check if it's a PDF file
        if (selectedFile.name.toLowerCase().endsWith(".pdf")) {
          // Open in new tab with PDF.js viewer
          const pdfJsUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(url)}`;
          window.open(pdfJsUrl, "_blank");
        } else {
          // For non-PDF files, use Microsoft's Office viewer
          const encodedUrl = encodeURIComponent(url);
          const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
          window.open(viewerUrl, "_blank");
        }

        // Close the modal
        setShowDownloadModal(false);
      } catch (error) {
        console.error("Error viewing file:", error);
        toast.error(
          error.response?.data?.detail ||
            "Unable to view this file. Please try again later.",
        );
      }
    }
  };

  const handleDeleteFile = async () => {
    setIsDeletingFile(true);
    try {
      await axiosInstance.delete(
        `${API_URL}/v1/user/documents/${selectedFileId}`,
      );
      toast.success("File deleted successfully");

      // Update local state
      setFiles(files.filter((file) => file.id !== selectedFileId));
      setShowDeleteFileModal(false);
    } catch (error) {
      toast.error("Failed to delete file");
    } finally {
      setIsDeletingFile(false);
    }
  };

  // Add file upload handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setIsDragOver(false);

    let selectedFile;
    if (type === "drop") {
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }

    // Validate file format
    if (selectedFile && selectedFile.type !== "application/pdf") {
      setErrorMessage("*Only .pdf files are allowed.");
      setFile(null);
      return;
    } else {
      // If valid, proceed with the upload
      setFile(selectedFile);
      setErrorMessage(""); // Clear any existing error message
      setUploadProgress(0);
      startUpload(selectedFile); // Start the upload
    }
  };

  const startUpload = async (uploadedFile) => {
    const maxSizeInBytes = 50 * 1024 * 1024;

    if (uploadedFile.size > maxSizeInBytes) {
      setFile(null);
      toast.error("File size exceeds the limit of 50 MB.");
      return;
    }

    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("folder_id", folderId); // Include the folder ID here

    try {
      setUploadProgress(0);

      const response = await axiosInstance.post(
        `${API_URL}/v1/user/documents/${projectId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            setUploadProgress(progress);
          },
        },
      );

      const data = response.data;
      console.log("Upload successful:", data);

      // Update the file list immediately - add to existing list instead of refetching
      const newFile = {
        id: data.id,
        name: data.name,
        status: "Processing",
        uploaded_on: data.uploaded_on,
        url: data.url,
        size: (data.size / 1024).toFixed(2) + " KB",
      };

      setFiles((prevFiles) => [newFile, ...prevFiles]);

      setUploadProgress(100);
      toast.success("File uploaded successfully!");

      // Reset upload status and close modal
      setTimeout(() => {
        setShowUploadModal(false);
        // Optionally refresh to ensure latest data
        fetchFolderData();
      }, 500);
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(error.response?.data?.detail || "Upload failed");
      setUploadProgress(0);
      setFile(null);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-auto custom-scrollbar">
      {/* Header with back button, folder name, and actions */}
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => navigate("/creator/files")}
          className="mr-3 text-gray-400 hover:text-white"
        >
          <FaArrowLeft />
        </button>
        <h1 className="text-2xl text-white font-semibold flex-grow">
          {folderData?.name || "Folder"}
        </h1>
        <div className="flex space-x-2">
          <button
            onClick={() => setShowUploadModal(true)}
            className="px-3 py-1 bg-[#6E45FE] text-white rounded-md hover:bg-[#5a35e0]"
          >
            <FaPlus className="inline mr-1" /> Upload File
          </button>
          <button
            onClick={() => setShowEditModal(true)}
            className="px-3 py-1 bg-[#2D2E32] text-white rounded-md hover:bg-[#3D3E42]"
          >
            Rename
          </button>
          <button
            onClick={() => setShowDeleteModal(true)}
            className="px-3 py-1 bg-[#2D2E32] text-white rounded-md hover:bg-[#3D3E42]"
          >
            Delete
          </button>
        </div>
      </div>

      {/* Files list */}
      <div className="bg-[#1D1E21] rounded-lg p-4">
        <h2 className="text-white text-lg mb-4">Files in this folder</h2>

        {files.length === 0 ? (
          <div className="text-gray-400 py-8 text-center">
            No files in this folder
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {files.map((file) => (
              <div
                key={file.id}
                className="relative bg-[#27282B] rounded-lg p-4 border border-gray-700"
              >
                <FaFile className="text-gray-400 text-3xl mb-2" />
                <p className="text-white truncate">{file.name}</p>

                {/* Divider line */}
                <div className="w-full h-[1px] bg-[#4B4C4F] my-2"></div>

                {/* Upload time */}
                <div className="flex items-center text-xs text-gray-400">
                  <FaClock className="mr-1" />
                  <p>{new Date(file.uploaded_on).toLocaleString()}</p>
                </div>

                {/* Menu button */}
                <button
                  className="absolute top-2 right-2 text-gray-400 hover:text-white"
                  onClick={() =>
                    setFileDropdown(file.id === fileDropdown ? null : file.id)
                  }
                >
                  <IoEllipsisHorizontal />
                </button>

                {/* Dropdown menu */}
                {fileDropdown === file.id && (
                  <div className="absolute z-10 top-10 right-2 bg-[#1D1E21] text-white shadow-md rounded-md w-24">
                    <button
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                      onClick={() => handleDownloadClick(file)}
                    >
                      View
                    </button>
                    <button
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700"
                      onClick={() => {
                        setSelectedFileId(file.id);
                        setShowDeleteFileModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Edit Folder Modal */}
      {showEditModal && (
        <PopupModal
          closeModal={() => setShowEditModal(false)}
          className="bg-[#27282B]"
        >
          <div className="w-[360px] p-6">
            <h3 className="text-lg text-white font-medium mb-4">
              Rename Folder
            </h3>
            <input
              type="text"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              className="w-full bg-[#1D1E21] text-white p-2 rounded-md border border-gray-700 mb-4"
              placeholder="Folder name"
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowEditModal(false)}
                className="px-4 py-2 bg-[#3D3E42] text-white rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleEditFolder}
                disabled={isEditing}
                className="px-4 py-2 bg-[#6E45FE] text-white rounded-md disabled:opacity-50"
              >
                {isEditing ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-5 w-5 mx-auto"
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </PopupModal>
      )}

      {/* Delete Folder Modal */}
      {showDeleteModal && (
        <PopupModal
          closeModal={() => setShowDeleteModal(false)}
          className="bg-[#27282B]"
        >
          <div className="flex flex-col justify-center items-center h-[200px] w-[360px] p-4">
            <img src={FeaturedIcon} alt="" className="mb-2" />
            <div className="text-white font-medium text-lg mb-2">
              Delete Folder
            </div>
            <p className="text-white text-sm mb-4 text-center">
              Are you sure you want to delete this folder and all its contents?
              This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-4 w-full">
              <button
                className="px-4 py-2 bg-[#3D3E42] text-white rounded-md"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteFolder}
                disabled={isDeleting}
                className="px-4 py-2 bg-[#6E45FE] text-white rounded-md disabled:opacity-50"
              >
                {isDeleting ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-5 w-5 mx-auto"
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </PopupModal>
      )}

      {/* View File Modal */}
      {showDownloadModal && (
        <PopupModal
          closeModal={() => setShowDownloadModal(false)}
          className="bg-[#27282B]"
        >
          <div className="flex flex-col justify-center items-center h-[200px] w-[360px] p-4">
            <div className="flex flex-col items-center">
              <p className="text-lg text-white font-medium text-center mb-6">
                Do you want to view this file?
              </p>
              <div className="flex space-x-4">
                <button
                  className="px-4 py-2 bg-[#3D3E42] text-white rounded-md"
                  onClick={() => setShowDownloadModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-[#6E45FE] text-white rounded-md"
                  onClick={handleViewClick}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </PopupModal>
      )}

      {/* Delete File Modal */}
      {showDeleteFileModal && (
        <PopupModal
          closeModal={() => setShowDeleteFileModal(false)}
          className="bg-[#27282B]"
        >
          <div className="flex flex-col justify-center items-center h-[200px] w-[360px] p-4">
            <img src={FeaturedIcon} alt="" className="mb-2" />
            <div className="text-white font-medium text-lg mb-2">
              Delete File
            </div>
            <p className="text-white text-sm mb-4 text-center">
              Are you sure you want to delete this file? This action cannot be
              undone.
            </p>
            <div className="flex justify-center space-x-4 w-full">
              <button
                className="px-4 py-2 bg-[#3D3E42] text-white rounded-md"
                onClick={() => setShowDeleteFileModal(false)}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteFile}
                disabled={isDeletingFile}
                className="px-4 py-2 bg-[#6E45FE] text-white rounded-md disabled:opacity-50"
              >
                {isDeletingFile ? (
                  <img
                    src={WhiteLoadingAnimation}
                    alt="Loading..."
                    className="h-5 w-5 mx-auto"
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </PopupModal>
      )}

      {/* Add the upload modal */}
      {showUploadModal && (
        <PopupModal
          closeModal={() => setShowUploadModal(false)}
          className="bg-[#27282B]"
        >
          <div className="w-[500px] p-6">
            <h3 className="text-white text-lg font-medium mb-4">
              Upload File to {folderData?.name}
            </h3>

            <div
              className={`border-2 border-dashed ${
                isDragOver
                  ? "border-[#6E45FE] bg-[#6E45FE]/10"
                  : "border-gray-600"
              } rounded-lg p-8 flex flex-col items-center justify-center cursor-pointer`}
              onClick={() =>
                document.getElementById("folderUploadInput").click()
              }
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, "drop")}
            >
              <input
                type="file"
                id="folderUploadInput"
                className="hidden"
                accept=".pdf"
                onChange={(e) => handleDrop(e, "input")}
              />

              <FaPlus className="text-gray-400 text-4xl mb-4" />
              <p className="text-white mb-2 text-center">
                Drag & drop files here, or click to browse
              </p>
              <p className="text-gray-400 text-sm mb-4 text-center">
                Only PDF files are supported (max 50MB)
              </p>
            </div>

            {file && (
              <div className="mt-4 bg-[#1D1E21] p-4 rounded-lg">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-white truncate max-w-[300px]">
                    {file.name}
                  </span>
                  <span className="text-gray-400">
                    {(file.size / (1024 * 1024)).toFixed(2)} MB
                  </span>
                </div>
                <div className="w-full bg-gray-700 rounded-full h-2 mb-1">
                  <div
                    className="bg-[#6E45FE] h-2 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <div className="text-right text-gray-400 text-sm">
                  {uploadProgress}%
                </div>
              </div>
            )}

            {errorMessage && (
              <p className="text-red-500 mt-2">{errorMessage}</p>
            )}

            <div className="flex justify-end mt-6 gap-2">
              <button
                className="px-4 py-2 bg-[#3D3E42] text-white rounded-md"
                onClick={() => setShowUploadModal(false)}
              >
                Cancel
              </button>
              {file && uploadProgress === 100 && (
                <button
                  className="px-4 py-2 bg-[#6E45FE] text-white rounded-md"
                  onClick={() => setShowUploadModal(false)}
                >
                  Done
                </button>
              )}
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default CreatorFilesInFolder;
