import React, { useState, useEffect } from "react";
import axiosInstance from "../components/axiosInstance";
import Group3 from "../assets/Group3.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStoredValue } from "../services/logics.js";

export default function Pricing() {
  const API_URL = process.env.REACT_APP_API_URL;
  const razorpay_id = process.env.RAZORPAY_ID;
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [billingCycle, setBillingCycle] = useState("Monthly");
  const organisation_id = getStoredValue("selectedOrg");
  const [subscription, setSubscription] = useState({
    id: "",
    subscription_model: "",
    updated_at: "",
    is_active: false,
  });
  const navigate = useNavigate();

  const [currency, setCurrency] = useState("USD"); // Default currency
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        // Replace with actual organisation ID
        const response = await axiosInstance.get(
          `/v1/subscriptions/${organisation_id}`,
        );

        if (response.status !== 200) {
          throw new Error("Failed to fetch subscription data");
        }

        const data = response.data;
        setSubscription(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [organisation_id]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(amount) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js",
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log(amount);
    const payload = {
      organisation_id: organisation_id,
      amount: amount * 100, // amount in smallest currency unit (e.g., paise for INR)
      currency: currency,
    };

    try {
      const result = await axiosInstance.post(
        `${API_URL}/v1/payments/create_order/`,
        payload,
      );

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data;
      const options = {
        key: razorpay_id,
        amount: amount.toString(),
        currency: currency,
        name: "Neuradynamics Pvt. Ltd",
        description: "Test Transaction",
        image: { Group3 },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            organisation_id: organisation_id,
            order_id: order_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          const result = await axiosInstance.post(
            `${API_URL}/v1/payments/verify_payment/`,
            data,
          );

          if (result.data === 200) {
            toast.success("payment successfull");
          } else {
            toast.error("payment verification failed");
          }
        },
        prefill: {
          name: "test",
          email: user.email,
          contact: "",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Payment Error:", error);
      alert("An error occurred while processing your payment.");
    }
  }

  const pricingData = {
    Quarterly: { Individuals: 0, Teams: 50, Enterprise: 250 },
    Monthly: { Individuals: 0, Teams: 20, Enterprise: 100 },
    Yearly: { Individuals: 0, Teams: 200, Enterprise: 1000 },
  };

  const plans = [
    {
      name: "Free",
      key: "Individuals",
      buttonText:
        subscription.subscription_model === "FREE"
          ? "Current Plan"
          : "Get Started",
      highlighted: subscription?.subscription_model === "FREE",
      features: [
        "✔ 1 user",
        "✔ 1 Assistant",
        "✔ 120 queries monthly",
        "✔ 50 MB document limit",
      ],
    },
    {
      name: "Pro",
      key: "Teams",
      buttonText:
        subscription.subscription_model === "PAID"
          ? "Current Plan"
          : "Coming soon",
      highlighted: subscription?.subscription_model === "PAID",
      features: [
        "✔ 5 users",
        "✔ 3 Assistants",
        "✔ 500 queries monthly",
        "✔ 500 MB document limit",
      ],
    },
    {
      name: "Custom",
      key: "Enterprise",
      buttonText: "Coming Soon",
      highlighted: false,
      features: [
        "✔ Unlimited users",
        "✔ Custom Assistants",
        "✔ Unlimited queries",
        "✔ 5 GB document limit",
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center py-12">
      {/* Back Button */}
      <button
        onClick={() => navigate("/organizations/billing")}
        className="absolute top-12 left-6 px-4 py-2 bg-[#1677FF] text-white rounded-md hover:bg-[#3A8FFF] transition-colors duration-300"
      >
        Back
      </button>

      <h2 className="text-2xl font-bold">Simple pricing based on your needs</h2>

      {/* Billing Cycle Toggle */}
      {/* <div className="mt-6 flex space-x-2 bg-gray-800 p-1 rounded-lg">
                {["Quarterly", "Monthly", "Yearly"].map((cycle) => (
                    <button
                        key={cycle}
                        className={`px-4 py-2 rounded-md ${billingCycle === cycle ? "bg-[#1677FF]" : "bg-transparent"
                            }`}
                        onClick={() => setBillingCycle(cycle)}
                    >
                        {cycle}
                    </button>
                ))}
            </div> */}

      {/* Pricing Cards */}
      <div className="mt-10 flex flex-wrap justify-center gap-6">
        {plans.map((plan) => (
          <div
            key={plan.key}
            className={`p-6 w-72 rounded-xl ${
              plan.highlighted
                ? "border-2 border-[#1677FF]bg-gradient-to-r from-gray-900 to-[#1677FF]"
                : "bg-gray-900"
            }`}
          >
            <h3 className="text-xl font-semibold">{plan.name}</h3>
            {/* <p className="mt-2 text-3xl font-bold">
                            ${pricingData[billingCycle][plan.key]} <span className="text-sm">per user/{billingCycle.toLowerCase()}</span>
                        </p> */}
            <p className="mt-2 text-gray-400"></p>
            <button
              className={`mt-4 w-full py-2 rounded-md ${
                plan.highlighted ? "bg-[#1677FF]" : "bg-white text-black"
              }`}
              // onClick={() => {
              //     displayRazorpay(pricingData[billingCycle][plan.key]);
              // }}
            >
              {plan.buttonText}
            </button>
            <ul className="mt-4 text-sm text-gray-300 space-y-1">
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
