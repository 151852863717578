import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoRocketOutline } from "react-icons/io5";

import AgentIcon from "../../assets/HomepageAssests/agent-icon.svg";
import ThreeDotsIcon from "../../assets/HomepageAssests/three-dots-icon.svg";
import AgentDetailsModal from "./AgentDetailsModal/AgentDetailsModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import "./Agents.css";
import axiosInstance from "../../services/axiosinstance";

const Agents = () => {
  const [draftAgents, setDraftAgents] = useState([]);
  const [orgAgents, setOrgAgents] = useState([]);
  const [projectAgents, setProjectAgents] = useState([]);
  const [marketplaceAgents, setMarketplaceAgents] = useState([]);
  const [loading, setLoading] = useState({
    draft: true,
    org: true,
    project: true,
    marketplace: true,
  });
  const [activeProjectId, setActiveProjectId] = useState(
    localStorage.getItem("activeProjectId") || null,
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Combine all fetching functions into a single useCallback to avoid dependency cycles
  const fetchAllAgentTypes = useCallback(async (projectId) => {
    if (!projectId) {
      console.error("No active project ID provided");
      return;
    }

    setLoading({
      draft: true,
      org: true,
      project: true,
      marketplace: true,
    });

    try {
      // Function to fetch draft agents
      const fetchDraftAgents = async () => {
        try {
          console.log("Fetching draft agents for project:", projectId);
          const response = await axiosInstance.get(
            `/v1/agents/creator/draft/${projectId}`,
          );
          console.log("Draft agents response:", response.data);

          if (response.data) {
            if (Array.isArray(response.data)) {
              setDraftAgents(response.data);
            } else if (
              response.data.agents &&
              Array.isArray(response.data.agents)
            ) {
              setDraftAgents(response.data.agents);
            } else {
              console.error(
                "Unexpected API response format for drafts:",
                response.data,
              );
              setDraftAgents([]);
            }
          }
        } catch (error) {
          console.error("Error fetching draft agents:", error);
          setDraftAgents([]);
        } finally {
          setLoading((prev) => ({ ...prev, draft: false }));
        }
      };

      // Function to fetch organization agents
      const fetchOrgAgents = async () => {
        try {
          console.log("Fetching organization agents for project:", projectId);
          const response = await axiosInstance.get(
            `/v1/agents/creator/orgagents/${projectId}`,
          );
          console.log("Organization agents response:", response.data);

          if (response.data) {
            if (Array.isArray(response.data)) {
              setOrgAgents(response.data);
            } else if (
              response.data.agents &&
              Array.isArray(response.data.agents)
            ) {
              setOrgAgents(response.data.agents);
            } else {
              console.error(
                "Unexpected API response format for org agents:",
                response.data,
              );
              setOrgAgents([]);
            }
          }
        } catch (error) {
          console.error("Error fetching organization agents:", error);
          setOrgAgents([]);
        } finally {
          setLoading((prev) => ({ ...prev, org: false }));
        }
      };

      // Function to fetch project agents
      const fetchProjectAgents = async () => {
        try {
          console.log("Fetching project agents for project:", projectId);
          const response = await axiosInstance.get(
            `/v1/agents/creator/projectagents/${projectId}`,
          );
          console.log("Project agents response:", response.data);

          if (response.data) {
            if (Array.isArray(response.data)) {
              setProjectAgents(response.data);
            } else if (
              response.data.agents &&
              Array.isArray(response.data.agents)
            ) {
              setProjectAgents(response.data.agents);
            } else {
              console.error(
                "Unexpected API response format for project agents:",
                response.data,
              );
              setProjectAgents([]);
            }
          }
        } catch (error) {
          console.error("Error fetching project agents:", error);
          setProjectAgents([]);
        } finally {
          setLoading((prev) => ({ ...prev, project: false }));
        }
      };

      // Function to fetch marketplace agents
      const fetchMarketplaceAgents = async () => {
        try {
          console.log("Fetching marketplace agents for project:", projectId);
          const response = await axiosInstance.get(
            `/v1/agents/creator/marketplace/${projectId}`,
          );
          console.log("Marketplace agents response:", response.data);

          if (response.data) {
            if (Array.isArray(response.data)) {
              setMarketplaceAgents(response.data);
            } else if (
              response.data.agents &&
              Array.isArray(response.data.agents)
            ) {
              setMarketplaceAgents(response.data.agents);
            } else {
              console.error(
                "Unexpected API response format for marketplace agents:",
                response.data,
              );
              setMarketplaceAgents([]);
            }
          }
        } catch (error) {
          console.error("Error fetching marketplace agents:", error);
          setMarketplaceAgents([]);
        } finally {
          setLoading((prev) => ({ ...prev, marketplace: false }));
        }
      };

      // Fetch different agent types in parallel
      await Promise.all([
        fetchDraftAgents(),
        fetchOrgAgents(),
        fetchProjectAgents(),
        fetchMarketplaceAgents(),
      ]);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  }, []);

  // Listen for changes to the active project ID
  useEffect(() => {
    // Function to handle storage changes from other tabs/windows
    const handleStorageChange = (e) => {
      if (e.key === "activeProjectId") {
        const newProjectId = e.newValue;
        setActiveProjectId(newProjectId);
        fetchAllAgentTypes(newProjectId);
      }
    };

    // Custom event for same-tab communication
    const handleProjectChange = (e) => {
      if (e && e.detail) {
        const newProjectId = e.detail.projectId;
        setActiveProjectId(newProjectId);
        fetchAllAgentTypes(newProjectId);
      }
    };

    // Add event listeners
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("projectChanged", handleProjectChange);

    // Initial fetch if we have an activeProjectId
    if (activeProjectId) {
      fetchAllAgentTypes(activeProjectId);
    }

    // Cleanup
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("projectChanged", handleProjectChange);
    };
  }, [activeProjectId, fetchAllAgentTypes]);

  // Listen specifically for organization changes
  useEffect(() => {
    const handleOrgChange = (e) => {
      if (e.key === "selectedOrg") {
        const newOrgId = e.newValue;

        console.log("Organization changed in localStorage to:", newOrgId);

        // Clear current agents and set loading state
        setDraftAgents([]);
        setOrgAgents([]);
        setProjectAgents([]);
        setMarketplaceAgents([]);
        setLoading({
          draft: true,
          org: true,
          project: true,
          marketplace: true,
        });

        localStorage.setItem("selectedOrg", newOrgId);

        // If there's no active project yet, it means the HomePageNavbar is still fetching projects
        // We'll add a polling mechanism to check for project ID updates
        let attempts = 0;
        const maxAttempts = 10; // try for about 3 seconds max

        const checkForProjectId = () => {
          const updatedProjectId = localStorage.getItem("activeProjectId");
          if (updatedProjectId) {
            console.log("Found new active project ID:", updatedProjectId);
            setActiveProjectId(updatedProjectId);
            fetchAllAgentTypes(updatedProjectId);
          } else if (attempts < maxAttempts) {
            attempts++;
            setTimeout(checkForProjectId, 300);
          } else {
            // If we've tried enough times and still no project ID, stop loading
            console.log("No project ID found after multiple attempts");
            setLoading({
              draft: false,
              org: false,
              project: false,
              marketplace: false,
            });
          }
        };

        // Start checking for project ID updates
        checkForProjectId();
      }
    };

    // Custom event for same-tab organization changes
    const handleOrgChangeEvent = (e) => {
      if (e && e.detail && e.detail.orgId) {
        const newOrgId = e.detail.orgId;

        console.log("Organization changed via event to:", newOrgId);

        // Clear current agents and set loading state
        setDraftAgents([]);
        setOrgAgents([]);
        setProjectAgents([]);
        setMarketplaceAgents([]);
        setLoading({
          draft: true,
          org: true,
          project: true,
          marketplace: true,
        });

        localStorage.setItem("selectedOrg", newOrgId);

        // Same polling mechanism as above
        let attempts = 0;
        const maxAttempts = 10;

        const checkForProjectId = () => {
          const updatedProjectId = localStorage.getItem("activeProjectId");
          if (updatedProjectId) {
            console.log("Found new active project ID:", updatedProjectId);
            setActiveProjectId(updatedProjectId);
            fetchAllAgentTypes(updatedProjectId);
          } else if (attempts < maxAttempts) {
            attempts++;
            setTimeout(checkForProjectId, 300);
          } else {
            console.log("No project ID found after multiple attempts");
            setLoading({
              draft: false,
              org: false,
              project: false,
              marketplace: false,
            });
          }
        };

        checkForProjectId();
      }
    };

    window.addEventListener("storage", handleOrgChange);
    window.addEventListener("organizationChanged", handleOrgChangeEvent);

    return () => {
      window.removeEventListener("storage", handleOrgChange);
      window.removeEventListener("organizationChanged", handleOrgChangeEvent);
    };
  }, [fetchAllAgentTypes]);

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null); // Track which menu is open

  // Add effect to close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // If a menu is open and the click is not on a menu item or menu toggle button
      if (
        openMenuId &&
        !event.target.closest(".menu-item") &&
        !event.target.closest(".menu-toggle")
      ) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuId]);

  const handleOpenModal = (agent) => {
    setSelectedAgent(agent);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAgent(null);
  };

  const toggleMenu = (e, agentId) => {
    e.stopPropagation(); // Prevent card click event
    setOpenMenuId((prevId) => (prevId === agentId ? null : agentId));
  };

  const handleAction = (action, agentId, agentName, event) => {
    event.stopPropagation(); // Prevent card click event
    if (action === "delete") {
      setAgentToDelete({ id: agentId, name: agentName });
      setDeleteModalOpen(true);
    } else {
      console.log(`${action} agent with id: ${agentId}`);
    }
    setOpenMenuId(null); // Close the menu
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosInstance.delete(
        `/v1/agents/creator/delete/${agentToDelete.id}`,
      );
      // Refresh the agents list
      fetchAllAgentTypes(activeProjectId);
      setDeleteModalOpen(false);
      setAgentToDelete(null);
      setSnackbar({
        open: true,
        message: "Agent deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting agent:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete agent. Please try again.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Component to render a section of agents
  const AgentSection = ({ title, agents, isLoading }) => (
    <Box sx={{ marginBottom: "40px" }}>
      <Typography className="my-agents-title" sx={{ marginBottom: "30px" }}>
        {title}
      </Typography>

      {/* Agents Grid */}
      <Box className="flex flex-wrap gap-4 mt-6">
        {isLoading ? (
          <Typography>Loading agents...</Typography>
        ) : agents.length > 0 ? (
          agents.map((agent) => (
            <Box
              key={agent.id}
              className="single-agent-container relative"
              onClick={() => handleOpenModal(agent)}
              style={{ cursor: "pointer", backgroundColor: "#111" }}
            >
              <Box className="flex items-center justify-between w-full border-b-[0.5px] border-[#CDCDCD] pb-2">
                <Box className="flex items-center gap-3">
                  <img
                    src={agent.avatar_url || AgentIcon}
                    alt="agent"
                    className="w-5 h-5 object-cover rounded-full flex-shrink-0"
                    style={{ aspectRatio: "1/1" }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = AgentIcon;
                    }}
                  />
                  <div className="agent-name">{agent.agent_name}</div>
                </Box>
                <IconButton
                  onClick={(e) => toggleMenu(e, agent.id)}
                  size="small"
                  sx={{ color: "white" }}
                  className="menu-toggle"
                >
                  <img src={ThreeDotsIcon} alt="menu" />
                </IconButton>

                {/* Custom dropdown menu */}
                {openMenuId === agent.id && (
                  <div
                    className="absolute top-9 right-0 z-50 bg-[#1c1c1c] shadow-lg border border-[#2b2b2b] rounded-lg overflow-hidden w-[120px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      onClick={(e) =>
                        handleAction("edit", agent.id, agent.agent_name, e)
                      }
                      className="px-3 py-2 hover:bg-[#2c2c2c] cursor-pointer flex items-center gap-2 menu-item"
                    >
                      <CiEdit className="text-[#cdcdcd]" />
                      <span className="text-[#cdcdcd] text-sm font-medium">
                        Edit
                      </span>
                    </div>
                    <div
                      onClick={(e) =>
                        handleAction("delete", agent.id, agent.agent_name, e)
                      }
                      className="px-3 py-2 hover:bg-[#2c2c2c] cursor-pointer flex items-center gap-2 border-t border-[#2b2b2b] menu-item"
                    >
                      <RiDeleteBin6Line className="text-[#cdcdcd]" />
                      <span className="text-[#cdcdcd] text-sm font-medium">
                        Delete
                      </span>
                    </div>
                    <div
                      onClick={(e) =>
                        handleAction("deploy", agent.id, agent.agent_name, e)
                      }
                      className="px-3 py-2 hover:bg-[#2c2c2c] cursor-pointer flex items-center gap-2 border-t border-[#2b2b2b] menu-item"
                    >
                      <IoRocketOutline className="text-[#cdcdcd]" />
                      <span className="text-[#cdcdcd] text-sm font-medium">
                        Deploy
                      </span>
                    </div>
                  </div>
                )}
              </Box>

              <Box className="flex flex-col gap-2">
                <div className="created-by-title">Created by</div>
                <Box className="flex items-center gap-2">
                  <Avatar className="!w-[15px] !h-[15px]" />
                  <div className="agent-created-by-name">
                    {agent.creator_name || "Unknown"}
                  </div>
                </Box>
              </Box>

              <Box className="flex flex-col gap-2">
                <div className="agent-description-title">Descriptions</div>
                <div className="agent-description max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {agent.agent_description}
                </div>
              </Box>
            </Box>
          ))
        ) : (
          <Typography>
            No {title.toLowerCase()} found for this project
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      {/* Always show all agent sections */}
      {/* <Box> */}
      {/* Draft Agents Section */}
      <AgentSection
        title="My Drafts"
        agents={draftAgents}
        isLoading={loading.draft}
      />

      {/* Organization Agents Section */}
      <AgentSection
        title="Organization Agents"
        agents={orgAgents}
        isLoading={loading.org}
      />

      {/* Project Agents Section */}
      <AgentSection
        title="Project Agents"
        agents={projectAgents}
        isLoading={loading.project}
      />

      {/* Marketplace Agents Section */}
      <AgentSection
        title="Marketplace Agents"
        agents={marketplaceAgents}
        isLoading={loading.marketplace}
      />
      {/* </Box> */}

      <AgentDetailsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        agent={selectedAgent}
      />

      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setAgentToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
        agentName={agentToDelete?.name || ""}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Agents;
