import React, { useState, useEffect } from "react";
import Sidebar from "../Organization/Sidebar";
import axiosInstance from "../components/axiosInstance";
import { toast } from "react-toastify";

const OrganizationDetails = () => {
  const [currentOrg, setCurrentOrg] = useState(null);
  const [formData, setFormData] = useState({ address: "", phone_no: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState("");

  // Fetch Organization Data
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axiosInstance.get("/v1/organizations/user");
        setOrganizations(response.data);
        setCurrentOrg(response.data[0]);
      } catch (err) {
        toast.error("Error fetching organizations");
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  // Update formData when modal opens
  useEffect(() => {
    if (isModalOpen) {
      setFormData({
        address: currentOrg?.address || "",
        phone_no: currentOrg?.phone_no || "",
      });
    }
  }, [isModalOpen, currentOrg]);

  // Handle input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOrgChange = (orgName) => {
    const orgObject = organizations.find((org) => org.id === orgName);
    setSelectedOrg(orgName);
  };

  // Handle Organization Update
  const handleUpdateOrganization = async () => {
    try {
      await axiosInstance.patch(
        `/v1/organizations/${currentOrg.id}/update-details`,
        formData,
      );
      setCurrentOrg({ ...currentOrg, ...formData });
      toast.success("Organization details updated successfully!");
      setIsModalOpen(false);
    } catch {
      toast.error("Failed to update organization details.");
    }
  };

  return (
    <div className="flex h-screen bg-[#070707]">
      {/* Sidebar */}
      <Sidebar
        handleOrgChange={handleOrgChange}
        organizations={organizations}
      />

      {/* Main Content */}
      <div className="flex-1 px-10 py-8 text-white">
        <svg
          width="470"
          height="428"
          viewBox="0 0 470 428"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 left-0 w-[350px] h-[350px] opacity-50 pointer-events-none z-[1]"
        >
          <g id="Ellipse 1" filter="url(#filter0_f)">
            <circle cx="92.5" cy="51.2812" r="113.5" fill="#1677FF" />
          </g>
          <defs>
            <filter
              id="filter0_f"
              x="-285"
              y="-326.6"
              width="755"
              height="755"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="132"
                result="effect1_foregroundBlur"
              />
            </filter>
          </defs>
        </svg>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <h1 className="text-3xl font-bold mb-6">Organization Details</h1>

            <div className="rounded-lg shadow-lg">
              <div className="flex items-start justify-between">
                <div className="flex items-start gap-4">
                  <div className="w-16 h-16 bg-gray-500 flex items-center justify-center rounded-lg text-3xl font-bold text-white">
                    {currentOrg?.name.charAt(0).toUpperCase()}
                  </div>

                  <div>
                    <h2 className="text-xl font-bold">{currentOrg?.name}</h2>
                    <p className="text-sm text-[#EAEAEA]">
                      {currentOrg?.address || "No address provided"}
                    </p>
                  </div>
                </div>

                <button
                  onClick={() => setIsModalOpen(true)}
                  className="bg-[#00000005] text-white border-[#D9D9D9] border px-4 py-2 rounded-lg text-sm font-medium"
                >
                  Edit details
                </button>
              </div>

              <hr className="border-t border-gray-600 my-6" />

              <div>
                <h3 className="text-lg font-semibold mb-4">Admin details</h3>
                <div className="space-y-4">
                  <div>
                    <p className="text-gray-500 text-sm">Email address</p>
                    <p className="font-medium">{currentOrg?.email}</p>
                  </div>
                  <div>
                    <p className="text-gray-500 text-sm">Phone</p>
                    <p className="font-medium">
                      {currentOrg?.phone_no || "No phone number provided"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-[#1D1E20] text-white rounded-lg w-96 p-6 relative">
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-white text-xl"
              >
                &times;
              </button>

              <h2 className="text-xl font-bold mb-4">
                Edit Organization Details
              </h2>

              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-500 mb-2">
                    Organization Name
                  </p>
                  <input
                    type="text"
                    className="w-full bg-black border border-gray-500 rounded-lg px-3 py-2 text-sm text-white"
                    value={currentOrg?.name || ""}
                    readOnly
                  />
                </div>

                <div>
                  <p className="text-sm text-gray-500 mb-2">
                    Organization Address
                  </p>
                  <input
                    type="text"
                    name="address"
                    className="w-full bg-black border border-gray-500 rounded-lg px-3 py-2 text-sm text-white"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Enter address"
                  />
                </div>

                <div>
                  <p className="text-sm text-gray-500 mb-2">Phone Number</p>
                  <input
                    type="text"
                    name="phone_no"
                    className="w-full bg-black border border-gray-500 rounded-lg px-3 py-2 text-sm text-white"
                    value={formData.phone_no}
                    onChange={handleInputChange}
                    placeholder="Enter phone number"
                  />
                </div>
              </div>

              <div className="flex justify-end mt-6 gap-4">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-[#FFFFFF] px-10 py-2 rounded-lg text-[16px] font-medium text-black"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateOrganization}
                  className="bg-[#1677FF] px-10 py-2 rounded-lg text-[16px] font-medium"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationDetails;
