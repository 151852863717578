import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Organization/Sidebar";
import axiosInstance from "../components/axiosInstance";
import { toast } from "react-toastify";
import InviteModal from "./Invitemodal";
import { FiUserPlus, FiSearch, FiMoreHorizontal } from "react-icons/fi";

// ✅ Change Role Modal Component
const ChangeRoleModal = ({ isOpen, onClose, member, onSave }) => {
  const [selectedRole, setSelectedRole] = useState(member?.role || "Member");

  if (!isOpen || !member) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg w-[400px]">
        <h2 className="text-xl font-semibold text-white mb-4">Change role</h2>

        {/* Name */}
        <div className="mb-2">
          <p className="text-gray-400 text-sm">Name</p>
          <p className="text-white font-semibold">
            {member.first_name} {member.last_name}
          </p>
        </div>

        {/* Email */}
        <div className="mb-2">
          <p className="text-gray-400 text-sm">Email Address</p>
          <p className="text-white">{member.email}</p>
        </div>

        {/* Role Selection */}
        <div className="mb-4">
          <p className="text-gray-400 text-sm">Role</p>
          <select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            className="w-full p-2 bg-[#29292F] text-white border border-gray-600 rounded-md focus:outline-none"
          >
            <option value="Member">Member</option>
            <option value="Owner">Owner</option>
          </select>
        </div>

        {/* Buttons */}
        <div className="flex justify-between">
          <button
            className="px-4 py-2 bg-white text-black rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#1677FF] text-white rounded-lg"
            onClick={() => onSave(member, selectedRole)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const MemberPageSidebar = () => {
  const [members, setMembers] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openMenu, setOpenMenu] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [currentOrg, setCurrentOrg] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const menuRefs = useRef({}); // 🔹 Step 1: Create a reference for the menu

  // ✅ Function to toggle the menu when clicking the three dots
  const toggleMenu = (userId) => {
    setOpenMenu(openMenu === userId ? null : userId);
  };

  // ✅ Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openMenu !== null &&
        menuRefs.current[openMenu] &&
        !menuRefs.current[openMenu].contains(event.target)
      ) {
        setOpenMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenu]);

  const removeUser = async (user_id) => {
    console.log("Removing user from organization:", selectedOrg);
    try {
      setMembers((prevMembers) =>
        prevMembers.filter((user) => user.user_id !== user_id),
      );
      await axiosInstance.post(`/v1/organizations/${selectedOrg}/remove-user`, {
        target_user_id: user_id,
      });
      toast.success(`User removed successfully.`);
    } catch (err) {
      console.error("Error removing user:", err); // Log the error for debugging
      toast.error("Error removing user");
    }
  };

  const openChangeRoleModal = (member) => {
    setSelectedMember(member);
    setRoleModalOpen(true);
  };

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get(`/users/me`); // Fetch user info
      localStorage.setItem("user_id", response.data.id); // Store user ID in local storage

      setUser(response.data);
    } catch (err) {
      toast.error(err.response.data.detail);
    }
  };

  const fetchMembersAndInvites = async () => {
    if (!selectedOrg) return;

    setIsLoading(true);

    try {
      // Check if selectedOrg is an object and has the 'id' property
      const orgId =
        typeof selectedOrg === "object" ? selectedOrg.id : selectedOrg;

      // Make the API request using orgId
      const membersResponse = await axiosInstance.get(
        `/v1/organizations/${orgId}/users`,
      );
      setMembers(membersResponse.data || []);
    } catch (err) {
      toast.error("Error fetching organization data");
    } finally {
      setIsLoading(false);
    }
  };

  // ✅ Ensure `selectedOrg` updates properly
  useEffect(() => {
    const savedOrg = localStorage.getItem("selectedOrg");
    if (savedOrg && savedOrg !== selectedOrg) {
      setSelectedOrg(savedOrg);
    }
    fetchMembersAndInvites();
  }, [selectedOrg]);

  const handleOrgChange = (orgName) => {
    const orgObject = organizations.find((org) => org.id === orgName);
    setSelectedOrg(orgName);
    if (orgObject) {
      setCurrentOrg(orgObject); // Set the selected organization as currentOrg
    }
  };
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axiosInstance.get("/v1/organizations/user");
        setOrganizations(response.data);
        setCurrentOrg(response.data[0]);
      } catch (err) {
        toast.error("Error fetching organizations");
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  const changeUserRole = async (userId, newRole, organisation_id) => {
    try {
      console.log(
        "Updating role for:",
        userId,
        "New Role:",
        newRole,
        "Org ID:",
        organisation_id,
      );

      const payload = {
        target_user_id: userId,
        role: newRole, // Ensure correct case
      };

      const response = await axiosInstance.patch(
        `/v1/organizations/${organisation_id}/update-role`,
        payload,
        { headers: { "Content-Type": "application/json" } },
      );

      if (response.status === 204) {
        toast.success(`Role changed to ${newRole}`);

        // ✅ Ensure UI updates immediately
        setMembers((prevMembers) =>
          prevMembers.map((m) =>
            m.user_id === userId ? { ...m, role: newRole } : m,
          ),
        );

        setRoleModalOpen(false);
      } else {
        throw new Error("Failed to update role.");
      }
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error(
        error.response?.data?.detail ||
          "An error occurred while updating the role.",
      );
    }
  };

  return (
    <div className="flex h-screen bg-[#121212] text-white">
      {/* Sidebar */}
      <Sidebar
        handleOrgChange={handleOrgChange}
        organizations={organizations}
      />

      {/* Main Content */}
      <div className="flex-1 p-8">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <svg
            width="470"
            height="428"
            viewBox="0 0 470 428"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-0 left-0 w-[350px] h-[350px] opacity-50 pointer-events-none z-[1]"
          >
            <g id="Ellipse 1" filter="url(#filter0_f)">
              <circle cx="92.5" cy="51.2812" r="113.5" fill="#1677FF" />
            </g>
            <defs>
              <filter
                id="filter0_f"
                x="-285"
                y="-326.6"
                width="755"
                height="755"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="132"
                  result="effect1_foregroundBlur"
                />
              </filter>
            </defs>
          </svg>
          <h1 className="text-[#EAEAEA] text-[28px] font-semibold">Members</h1>

          {/* Right side: Search and Invite */}
          <div className="flex items-center space-x-4">
            {/* Search Bar */}
            <div className="relative">
              <div className="flex items-center bg-[#1E1E1E] border border-gray-600 rounded-lg px-3 py-2 w-[280px]">
                <FiSearch className="text-gray-400" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="bg-transparent text-white outline-none flex-1 pl-2"
                />
              </div>
            </div>

            {/* Invite Member Button */}
            <button
              className="flex items-center bg-[#1677FF]  hover:bg-[#1256CC] text-white px-4 py-2 rounded-lg"
              onClick={() => setIsModalOpen(true)}
            >
              <FiUserPlus className="mr-2" />
              Invite member
            </button>
          </div>
        </div>

        <div className="mb-6">
          <span className="text-[#EAEAEA] text-sm">
            My Organization &gt; Members
          </span>
        </div>

        <InviteModal
          organization_id={selectedOrg}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />

        {isLoading ? (
          <p className="text-gray-400">Loading members...</p>
        ) : (
          <div className=" rounded-lg border border-gray-700">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-[#29292F] text-sm font-semibold">
                  <th className="py-3 px-8 text-left text-gray-300">Name</th>

                  <th className="py-3 px-8 text-left text-white relative pl-8">
                    <span className="absolute left-1 top-2 bottom-2 w-[2px] bg-[#FFFFFF]"></span>
                    <span className="pl-4">Email Address</span>
                  </th>

                  <th className="py-3 px-8 text-left text-white relative pl-8">
                    <span className="absolute left-1 top-2 bottom-2 w-[2px] bg-[#FFFFFF]"></span>
                    <span className="pl-4">Role</span>
                  </th>

                  <th className="py-3 px-8 text-left text-white relative pl-24">
                    <span className="absolute left-16 top-2 bottom-2 w-[2px] bg-[#FFFFFF]"></span>
                    <span className="pl-4">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {members.map((member) => (
                  <tr
                    key={member.user_id}
                    className="bg-[#18181B] hover:bg-[#222226] text-gray-300 relative"
                  >
                    <td className="py-4 px-8 flex items-center space-x-3">
                      {/* Profile Picture or Initial Avatar */}
                      {member.profilePicture ? (
                        <img
                          src={member.profilePicture}
                          alt={member.first_name}
                          className="w-8 h-8 rounded-full"
                        />
                      ) : (
                        <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-600 text-white font-bold">
                          {member.email.charAt(0).toUpperCase()}
                        </div>
                      )}

                      {/* Name */}
                      <span>
                        {member.first_name} {member.last_name}
                      </span>
                    </td>

                    <td className="py-4 px-8">{member.email}</td>
                    <td className="py-4 px-12">{member.role}</td>
                    <td className="py-4 pl-32 relative">
                      <button onClick={() => toggleMenu(member.user_id)}>
                        <FiMoreHorizontal />
                      </button>
                      {openMenu === member.user_id && (
                        <div
                          ref={(el) => (menuRefs.current[member.user_id] = el)}
                          className="absolute right-0 mt-2 w-40 bg-[#29292F] text-white rounded-lg shadow-lg border border-gray-700 z-50"
                        >
                          <button
                            className="block w-full px-4 py-2 text-gray-300 hover:bg-gray-700"
                            onClick={() => openChangeRoleModal(member)}
                          >
                            Change Role
                          </button>
                          <button
                            className="block w-full px-4 py-2 text-red-500 hover:bg-gray-700"
                            onClick={() => removeUser(member.user_id)}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <ChangeRoleModal
        isOpen={roleModalOpen}
        onClose={() => setRoleModalOpen(false)}
        member={selectedMember}
        onSave={(member, selectedRole) =>
          changeUserRole(
            member.user_id,
            selectedRole.toUpperCase(),
            selectedOrg,
          )
        }
      />
    </div>
  );
};

export default MemberPageSidebar;
